import { useState } from "react";
import "react-simple-toasts/dist/theme/dark.css";
import { useFormik } from "formik";
import { TextField, Button, CircularProgress } from "@mui/material";
import toast from "react-simple-toasts";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { ForgotAPI } from "../../services/helpers/Users";
import { initialValuesForgot as initialValues, validationSchemaForgot as validationSchema } from "../../services/validate/Users";
import { useDispatch } from "react-redux";
import logo from "../../assets/img/logo.jpg";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState();

  const { mutate, isLoading } = useMutation(ForgotAPI, {
    mutationKey: "login",
    onSuccess: (res) => {
      console.log("skjdflkj", res.data);
      if (res?.status === false) {
        setLoading(false);
        toast(res.message, {
          theme: "dark",
          position: "top-center",
        });
      } else if (res?.status === true) {
        setLoading(false);
        toast(res.message, {
          theme: "dark",
          position: "top-center",
        });
        formik.resetForm();
        setTimeout(() => {
          navigate("/login");
        }, 1000);
      }
    },
    onError: () => {
      setLoading(false);
    },
    onSettled: () => {},
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      console.log(values);
      let payload = {
        email: values.email,
        baseurl: window.location.origin,
      };
      mutate(payload);
      setLoading(true);
    },
  });

  return (
    <div className="h-[100vh] flex justify-center items-center">
      <div className="w-11/12 md:w-8/12 lg:w-4/12 h-[88vh] m-auto shadow bg-white">
        <div style={{ backgroundColor: "#6673FD" }} className=" h-1/4 md:h-1/6 lg:h-1/4 ">
          <div className="flex justify-center pt-3 md:pt-4 lg:pt-10 ">
            <div className="bg-white w-[80px] h-[80px] rounded-full flex justify-center items-center">
              <img src={logo} alt="LogoImage" className="w-full h-auto rounded-full" />
            </div>
          </div>
        </div>

        <form onSubmit={formik.handleSubmit}>
          <div style={{ marginTop: "-27px" }} className="px-4 loginshadow w-4/5 m-auto bg-white">
            <h1 className="text-2xl pt-5 pb-3 font-medium text-center">Forgot Password</h1>
            <hr className="w-10/12 m-auto mt-1" />
            <div className="w-full text-center">
              <TextField
                className="w-11/12 m-auto !mt-[3.25rem]"
                id="email"
                name="email"
                label="Email *"
                variant="standard"
                {...formik.getFieldProps("email")}
                error={formik.touched.email && formik.errors.email}
                helperText={formik.touched.email && formik.errors.email}
              />
              <p
                className="underline text-right text-sm mr-4 mt-2 cursor-pointer hover:font-medium"
                onClick={() => {
                  navigate("/login");
                }}
              >
                Login
              </p>
            </div>

            <div className="flex justify-center">
              <Button
                type="submit"
                className="!mb-8 !bg-Primary hover:!bg-PrimaryHover  !text-[18px] w-11/12  !mt-16 transition-all duration-300"
                variant="contained"
                // disabled={loading}
                sx={{
                  "&.Mui-disabled": {
                    color: "#d1d1d1",
                  },
                }}
              >
                {loading && <CircularProgress size="1rem" className="absolute !text-white" />}
                <span className={`${loading ? "invisible" : ""}`}>Submit</span>
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
