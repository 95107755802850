import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { examSelectedId, examUpdateById } from "../../../store/exam";
import { examinationUpdateById } from "../../../../../store/examination";
// import AuidoTest from "../../../../../pages/Videotest/AuidoTest";
// import { AudioUpload } from "../../../../AudioUpload";
import { AuidoRecordComp } from "./AuidoRecordComp";

const AuidoRecordExam = ({ change, time, mediaType }) => {
  const examination = useSelector((state) => state.examination);
  const dispatch = useDispatch();
  const questionsAnswersHandle = (id, answer) => {
    dispatch(examinationUpdateById({ id: id, answer }));
  };

  return (
    <>
      <div className="mt-3">
        <AuidoRecordComp
          questionsAnswersHandle={questionsAnswersHandle}
          id={examination.mediaQuestion.selectedQuestion.id}
          mediaType={mediaType}
        />
      </div>
    </>
  );
};

export default AuidoRecordExam;