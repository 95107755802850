import { useEffect, useRef, useState } from "react";
import { HiOutlineUpload } from "react-icons/hi";
import toast from "react-simple-toasts";
import { useMutation } from "react-query";
import "./index.css";
import { uploadImage } from "../../services/helpers/Users";
import DarkButton from "../common/CustomButtons/DarkButton";

const ProfileImage = ({ imgFile, setImgFile, previewImage, setPreviewImage, pathImage,setFlag }) => {
  const [imgLoading, setImageLoading] = useState(false);

  const formdata = new FormData();

  const handleImageUpload = (name) => {
    console.log(name);
    formdata.append("file", name);
    mutate(formdata);
  };

  const { mutate, isLoading1 } = useMutation(uploadImage, {
    mutationKey: "uploadImage",
    onSuccess: (res) => {
      if (res?.status === false) {
      } else if (res?.status === true) {
        setFlag(true);
        console.log("resons- -> ", res.data.path);
        pathImage(res.data.fileName);

        toast(res.message, {
          theme: "dark",
          position: "top-center",
        });
      }
    },
    onError: () => {},
    onSettled: () => {},
  });

  const fileRef = useRef();
  const editIconHandle = () => {
    fileRef.current.click();
  };
  const avatarInputHandle = (e) => {
    const file = e.target.files[0];
    if (file) {
      setPreviewImage(URL.createObjectURL(file));
    }

    setImgFile(e.target.files[0]);
    handleImageUpload(e.target.files[0]);
    console.log(e.target.files[0]);
    setImageLoading(true);
    setTimeout(() => {
      setImageLoading(false);
    }, 100);
  };

  return (
    <>
      <div className="flex w-full justify-center items-center m-auto relative ">
        {imgLoading ? (
          <p>Loading...</p>
        ) : (
          <div className="flex flex-col justify-center items-center gap-4">
            <div className="w-[90px] h-[90px]">
              <img src={previewImage} alt="" className="rounded-full w-full h-full" />
            </div>
            <div
              data-bs-toggle="tooltip"
              title="Change Image"
              className=" cursor-pointer flex flex-1 items-center  "
              onClick={(e) => {
                editIconHandle();
              }}
            >
              <div className="border flex p-2 bg-[#1E78FE] rounded-md justify-center items-center">
                <span className="ml-2 text-white">{"Upload Profile Image"}</span> <HiOutlineUpload className="text-white text-xl ml-2" />
              </div>
            </div>
          </div>
        )}
        <input type="file" multiple accept="image/*" className="bg-black" ref={fileRef} onChange={avatarInputHandle} hidden />
      </div>
    </>
  );
};

export default ProfileImage;
