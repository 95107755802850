import * as yup from "yup";

export const initialValues = {
  sectionName:"",
  // sectionTitle:"",
  time:"",
  second:"",
  sectionType:"",
  examTypeId:"",
  sectionTitle:"",

  sectionTypeValue:""

  

  
};

export const validationSchema = yup.object().shape({
  sectionName: yup.string().required("Title is required"),
  // sectionTitle: yup.string().required("Title is required"),
  time: yup.string().required("Minute is required"),
  second: yup.string().required("Second is required"),
  sectionType: yup.string().required("Section is required"),
  // sectionTypeValue:yup.string().required("QuestionType is required"),
  examTypeId: yup.string().required("Exam Module is required"),
  sectionTitle:yup.string().required("Instruction is required")
  


  
  // testType: yup.string().required("Type is required"),
  // question: yup.string().required("Question is required"),
  // opt1: yup.string().required("Option is required"),
  // opt2: yup.string().required("Option is required"),
  // opt3: yup.string().required("Option is required"),
  // opt4: yup.string().required("Option is required"),
});

// export const initialValueMcq = {
//   question: "",
//   opt1: "",
//   opt2: "",
//   opt3: "",
//   opt4: "",
//   answer: "",
//   time: "",
// };

// export const validationSchemaMcq = yup.object().shape({
//   question: yup.string().required("Question is required"),
//   answer: yup.string().required("Answer is required"),
//   opt1: yup.string().required("Option is required"),
//   opt2: yup.string().required("Option is required"),
//   opt3: yup.string().required("Option is required"),
//   opt4: yup.string().required("Option is required"),
//   time: yup.string().required("Time is required"),
// });

// export const initialValueFillInTheBlanks = {
//   question: "",
//   writtenAnswer: "",
//   time: "",
// };

// export const validationSchemaFillInTheBlanks = yup.object().shape({
//   question: yup.string().required("Question is required"),
//   writtenAnswer: yup.string().required("Answer is required"),
//   time: yup.string().required("Time is required"),
// });

// export const initialValueWriting = {
//   question: "",
//   time: "",
// };

// export const validationSchemaWriting = yup.object().shape({
//   question: yup.string().required("Question is required"),
//   time: yup.string().required("Time is required"),
// });
export const initialValuestest = {
  section:"",
  
  
};
export const validationSchematest = yup.object().shape({
  section: yup.string().required("Section is required"),
  // title: yup.string().required("Title is required"),
  // time: yup.string().required("Time is required"),
  // examType: yup.string().required("ExamType is required"),
  


  
  // testType: yup.string().required("Type is required"),
  // question: yup.string().required("Question is required"),
  // opt1: yup.string().required("Option is required"),
  // opt2: yup.string().required("Option is required"),
  // opt3: yup.string().required("Option is required"),
  // opt4: yup.string().required("Option is required"),
});