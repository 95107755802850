import { useFormik } from "formik";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { setToastMessage } from "../../../store/toast";
import { AddQuestionAPI } from "../../../services/helpers/Question";
import { initialValueBoolean as initialValues, validationSchemaBoolean as validationSchema } from "../../../services/validate/AddQuestion";
import { Button, Stack } from "@mui/material";
import AddSharpIcon from "@mui/icons-material/AddSharp";
import RemoveIcon from '@mui/icons-material/Remove';
import { useNavigate, useParams } from "react-router-dom";
import BooleanForm from "./BooleanForm";
const Boolean = ({ questionType, examId, booleanQList, setBooleanQList, sectionId }) => {
  const dispatch = useDispatch();
  // const {sectionId} = useParams()
  const navigate = useNavigate();
  const { mutate, isLoading } = useMutation(AddQuestionAPI, {
    mutationKey: "addFillQuestion",
    onSuccess: (res) => {
      if (res?.status === false) {
      } else if (res?.status === true) {
        dispatch(
          setToastMessage({
            isActive: true,
            message: res?.message,
            type: "success",
          })
        );
        formik.resetForm();
        navigate(`/exams/${examId}/${sectionId}`)
      }
    },
    onError: () => { },
    onSettled: () => { },
  });

  const submitHandler = (values) => {
    // console.log("formik values ==> ", values)
    // const newValues = {
    //   // ...formik.values,
    //   sectionId: sectionId,
    //   questionType: questionType,
    //   examId: examId,
    //   boolean: values.questions.map((question, index) => ({
    //     question: question[`question`],
    //     correctAnswer: question[`correctAnswer`],


    //   })),
    // };
    // if(sectionId){
    //   mutate(newValues);
    // } else {
    //   alert("Select Section of question")
    // }
    if(sectionId){
      const newValues = {
        sectionId: sectionId,
        questionType: questionType,
        examId: examId,
        boolean: values.questions.map((question, index) => ({
          question: question[`question`],
          options: [
            { id: 1, title: question[`opt1`] },
            { id: 2, title: question[`opt2`] },
            { id: 3, title: question[`opt3`] },
            { id: 4, title: question[`opt4`] },
          ],
          correctAnswer: question[`answer`],
          time: 1,
          // marks: question[`marks`]
        })),
      };
      
      mutate(newValues);
    } else {
      alert('Please select section')
    }
  };

  const formik = useFormik({
    initialValues: {
      questions: Array.from({ length: booleanQList.length }, (_, index) => ({
        [`question`]: "",
        [`opt1`]: "",
        [`opt2`]: "",
        [`opt3`]: "",
        [`opt4`]: "",
        [`answer`]: "",
        [`time`]: "1",
        // ['marks']:"",
      })),
    },
    validationSchema: validationSchema,
    onSubmit: (values) => { 
      console.log("Valuesdjasjdajsjajksdjk", values)
      submitHandler(values);

      
    },
  });

  console.log("formik Boolean ==> ", formik)

  return (
    <div className="w-full mt-4">
      <div className="flex justify-end gap-4">
    

        <Button variant="contained" onClick={() => {
          setBooleanQList((prevState) => {
            return [...prevState, prevState.length]
          })
          formik.values.questions.push({
            [`question`]: "",
            [`opt1`]: "",
            [`opt2`]: "",
            [`opt3`]: "",
            [`opt4`]: "",
            [`answer`]: "",
            [`time`]: "1",
            // ['marks']:"",
          })
        }}>Add Question <AddSharpIcon className="ml-2" /></Button>
       
        {booleanQList.length !== 1 && <Button variant="contained" onClick={() => {
          if (booleanQList.length !== 1) {
            let questions = formik.values.questions
            questions.pop()
            formik.values.questions = questions
            setBooleanQList((prevState) => {
              prevState.pop()
              return [...prevState]
            })
          }
        }}>Remove Question <RemoveIcon className="ml-2"/></Button>}
      </div>
      <BooleanForm formik={formik} btnTitle="Add Question" isLoading={isLoading} booleanQList={booleanQList} />
    </div>
  );
};

export default Boolean;
