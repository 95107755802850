import { useEffect, useState } from "react";
import "react-simple-toasts/dist/theme/dark.css";
// import MockUsers from "./dummydata";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { SelectInputField } from "../Forms";
import { TextField, Button, InputAdornment, IconButton, CircularProgress, Select, MenuItem, FormControl } from "@mui/material";
import CookieStorage from "../../services/cookieStorage";
import toast from "react-simple-toasts";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { LoginAPI, SIGNUP_API } from "../../services/helpers/Users";
import { initialValuesSignUp as initialValues, validationSchemaSignUp as validationSchema } from "../../services/validate/Users";
import { useDispatch } from "react-redux";
import { profileUpdate } from "../../store/profile";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Logindata } from "../../store/userData";
import Cookies from "js-cookie";

const CreateUser = () => {

    const options = [
       
        {
            label: "Admin",
            userId: "super_admin",
        },
        {
            label: "Institute",
            userId: "institute",
        },
        {
            label: "Student",
            userId: "student",
        }

    ];
    const loginData = useSelector((state) => state?.caretaker?.login);
    console.log("loginData", loginData);
    //   console.log(MockUsers);
    const cookie = new CookieStorage();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState();
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const { mutate, isLoading } = useMutation(SIGNUP_API, {
        mutationKey: "signup",
        onSuccess: (res) => {
            if (res?.status === false) {
            } else if (res?.status === true) {
                toast("", {
            theme: "dark",
            position: "top-center",
          });
                
                // dispatch(profileUpdate(res.data));
                // cookie.setLogin(res.data.tokens.access.token, res.data.user.id);
                // navigate("/");
            }
        },
        onError: () => { },
        onSettled: () => { },
    });
    //   const handleLogin = () => {
    //     // Find the user with the provided username
    //     // const user = MockUsers.find((u) => u.username === username);

    //     if (!user) {
    //       toast("User not found", {
    //         theme: "dark",
    //         position: "top-center",
    //       });
    //     } else if (user.password !== password) {
    //       toast("Incorrect Password", {
    //         theme: "dark",
    //         position: "top-center",
    //       });
    //     } else {
    //       // Successful login - Redirect or set user role in state
    //       console.log({
    //         email: username,
    //         password: password,
    //         role: user.role,
    //       });

    //       dispatch(
    //         Logindata({
    //           role: user.role,
    //         })
    //       );
    //       cookie.setLogin("dskadksadjskjdksaklakld");
    //       if (user.role === "student") {
    //         navigate("/test");
    //       } else {
    //         navigate("/dashboard");
    //       }

    //       toast("Logged in Successfully", {
    //         theme: "dark",
    //         position: "top-center",
    //       });
    //       //  window.location.reload();

    //       Cookies.set("email", username);
    //       Cookies.set("role", user.role);
    //     }
    //   };

    // useEffect(() => {
    //   console.log("useffect", loginData);
    // }, [loginData]);

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            console.log(values)
            mutate(values);
            
        },
    });

    return (
        <div className="h-[100vh] flex justify-center items-center">
            {/* <img className="fix -z-40 " src={photo} alt="BGImage" /> */}
            <div className="w-11/12 md:w-8/12 lg:w-4/12 h-[88vh] m-auto shadow bg-white">
                <div style={{ backgroundColor: "#6673FD" }} className=" h-1/4 md:h-1/6 lg:h-1/4 "></div>


                <form onSubmit={formik.handleSubmit} >
                    <div style={{ marginTop: "-34px" }} className="px-4 loginshadow w-4/5 m-auto bg-white">
                        <h1 className="text-2xl pt-5 pb-3 font-medium text-center">SIGN UP</h1>
                        <hr className="w-10/12 m-auto mt-1" />
                        <div className="w-full text-center">
                            <TextField
                                className="w-11/12 m-auto !mt-[2.25rem]"
                                id="fullName"
                                name="fullName"
                                label="Full Name *"
                                variant="standard"

                            {...formik.getFieldProps("fullName")}
                            error={formik.touched.fullName && formik.errors.fullName}
                            helperText={formik.touched.fullName && formik.errors.fullName}
                            />

                            <TextField
                                className="w-11/12 m-auto !mt-[2.25rem]"
                                id="phone"
                                name="phone"
                                label="Phone *"
                                variant="standard"
                                {...formik.getFieldProps("phone")}
                                error={formik.touched.email && formik.errors.phone}
                                helperText={formik.touched.email && formik.errors.phone}
                        
                            />

                            <TextField
                                className="w-11/12 m-auto !mt-[2.25rem]"
                                id="email"
                                name="email"
                                label="Email *"
                                variant="standard"
                              

                            {...formik.getFieldProps("email")}
                            error={formik.touched.email && formik.errors.email}
                            helperText={formik.touched.email && formik.errors.email}
                            />

                            <TextField
                                className="w-11/12 !mt-[2.25rem]"
                                id="password"
                                name="password"
                                type={showPassword ? "text" : "password"}
                                label="Password *"
                                variant="standard"

                                // onChange={(e) => setPassword(e.target.value)}
                                // value={password}


                                {...formik.getFieldProps("password")}
                                error={formik.touched.password && formik.errors.password}
                                helperText={formik.touched.password && formik.errors.password}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword}>
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />

                            <div className="w-full mt-8 flex justify-around mr-10 ">

                                <label className=" "> Choose Role </label>
                                <FormControl  variant="standard" >
                                    <Select
                                        className="!w-full mr-4"
                                        labelId="demo-simple-select-autowidth-label"
                                        name="role"
                                        {...formik.getFieldProps("role")}
                                        error={formik.touched["role"] && formik.errors["role"]}
                                        onChange={(e) => {
                                            // console.log("sdjlfkjsdf ==")
                                            formik.handleChange(e);
                                            // handleChange(e);
                                        }}
                                        helperText={formik.touched["role"] && formik.errors["role"]}
                                        autoWidth
                                        label="role"
                                    >
                                        {options.map((item, index) => (
                                            <MenuItem key={index} value={item.userId} className="w-full">
                                                {item.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {formik.touched["role"] && formik.errors["role"] && <p className="text-red-500 text-sm pl-4">{formik.errors["role"]}</p>}
                                    </FormControl>

                            </div>
                        
                    </div>


                    {/* <div className="flex justify-end w-11/12 m-auto">
              <div className="text-xs mt-3">Forgot password</div>
            </div> */}
                    <div className="flex justify-center">
                        <Button
                            type="submit"
                            // onClick={() => {
                            //   handleLogin();
                            // }}
                            // onClick={(e) => {

                            //   const user = MockUsers.find((u) => u.username === "student@123");

                            //   if (!user) {
                            //     console.log("User not found");
                            //   } else if (user.password !== "student123") {
                            //     console.log("Incorrect password");
                            //   } else {
                            //     // Successful login - Redirect or set user role in state
                            //     console.log(`Logged in as ${user.role}`);
                            //     cookie.setLogin("dskadksadjskjdksaklakld");
                            //     navigate("/dashboard")
                            //     window.location.reload();
                            //   }

                            // }}

                            className="!mb-8 !bg-Primary hover:!bg-PrimaryHover  !text-[18px] w-11/12  !mt-[48px] transition-all duration-300"
                            variant="contained"
                            // disabled={loading}
                            sx={{
                                "&.Mui-disabled": {
                                    color: "#d1d1d1",
                                },
                            }}
                        >
                            {loading && <CircularProgress size="1rem" className="absolute !text-white" />}
                            <span className={`${loading ? "invisible" : ""}`}>SIGN UP</span>
                        </Button>
                    </div>
            </div>
        </form>
            </div >
        </div >
    );
};

export default CreateUser;
