import Cookies from "js-cookie";

class CookieStorage {
  setLogin = (token, id,userId, insId="") => {
    Cookies.set("token", token);
    Cookies.set("id", id);
    Cookies.set("userId", userId);
    Cookies.set("InsId", insId)
  };
  getLogin = () => {
    if (Cookies.get("token")) {
      return true;
    } else {
      return false;
    }
  };
  logout = () => {
    Cookies.remove("token");
    Cookies.remove("id");
    Cookies.remove("userId");
  };
}

export default CookieStorage;
