import React from "react";
import Cookies from "js-cookie";
import { useState } from "react";
import { useQuery } from "react-query";
import SearchIcon from "@mui/icons-material/Search";
import BreadcrumbsC from "../../components/common/BreadcrumbsC";
import { CustomPagination, LoaderSpiner } from "../../components";
import { feedbackListAPI } from "../../services/helpers/Feedback";
import FeebackCard from "../../components/common/Card/FeebackCard";
import { IconButton, InputAdornment, TextField } from "@mui/material";

const FeedBack = () => {
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState(null);
  const [feedbackList, setFeedbackList] = useState(null);

  const breadcrumbb = [
    {
      id: 1,
      title: "Dashboard",
      link: "/dashboard",
    },
    {
      id: 1,
      title: "Feedback",
      link: "/feedback",
    },
  ];

  useQuery(
    ["feedback", page],
    () => {
      const queryParams = {
        pageNum: page,
      };
      if (Cookies.get("id") === "institute") {
        queryParams.instituteId = Cookies.get("userId");
      }
      const paramsData = {
        queryParams,
      };
      return feedbackListAPI(paramsData);
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,

      mutationKey: "feedback",
      enabled: true,
      onSuccess: (res) => {
        if (res?.status === true) {
          setFeedbackList(res?.data?.feedbackList);
          setPagination(res.data.pagination);
        } else {
        }
      },
    }
  );

  console.log("feedbackList", feedbackList);

  return (
    <div className={`relative ${Cookies.get("id") === "student" ? "ml-0" : "md:ml-64"} px-8 py-4 bg-[#F3F3F3] `}>
      <div className="w-full">
        <div className="flex justify-between items-center">
          <BreadcrumbsC data={breadcrumbb} />
        </div>
        <div className="flex justify-end items-center mt-4">
          <div className="flex items-center gap-4">
            <TextField
              id="outlined-start-adornment"
              className=""
              size="small"
              sx={{ backgroundColor: "white" }}
              placeholder="Search or Type Here"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton aria-label="toggle password visibility" edge="end">
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
      </div>

      {/* lower section */}
      {feedbackList ? (
        <div className={`bg-white mt-8 rounded-lg p-5 mb-10 ${feedbackList.length > 2 && feedbackList.length <= 4 ? "mb-40" : "" || feedbackList.length <= 2 ? "mb-64" : ""}`}>
          {feedbackList.length!==0?<div className="grid md:grid-cols-2 gap-4">
            {feedbackList?.map((item, index) => (
              <FeebackCard key={index} name={item.studentName} id={item.studentId} feedback={item.content} width="md:w-[49%] w-full" />
            ))}
          </div>: <div className="text-center"><p className="text-2xl">No Feedback  Found</p></div>}
          {feedbackList.length!==0?<CustomPagination page={page} setPage={setPage} paginationData={pagination} />:null}
        </div>
      ) : (
        <div className="w-full flex justify-center items-center h-[50vh]">
          <LoaderSpiner />
        </div>
      )}
    </div>
  );
};

export default FeedBack;
