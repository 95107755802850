import {  DashboadPagesLayout } from "../../components";
import BreadcrumbsC from "../../components/common/BreadcrumbsC";
import { AddSectionForm } from "../AddSectionForm";

export const AddSection = ()=>{

    const breadcrumbb = [
        {
          id: 1,
          title: "Dashboard",
          link: "/dashboard",
        },
        {
          id: 2,
          title: "Add Exam",
          link: "/exams/add",
        },
        {
          id: 3,
          title: "Add Section",
          link: "/add-section",
        },
      ];
    return (
        <DashboadPagesLayout>
        <div className="w-full px-8 py-4">
          <div className="flex justify-start items-center">
            <BreadcrumbsC data={breadcrumbb} />
          </div>
          <div className={`bg-white mt-8 rounded-lg p-5 mb-10 min-h-[60vh] `}>
            <AddSectionForm/>
            {/* <div style={{boxShadow: "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px"}} className="flex w-full ">
             <AddSectionForm/>
            </div> */}
          </div>
        </div>
        </DashboadPagesLayout>
      
    )

    
}