import { MyTimer, } from "../../Timer";
import {useState} from "react"
import {useNavigate} from "react-router-dom"
const ExamFormHeader = () => {
  const navigate = useNavigate();
  const [running,setRunning] = useState(true)
  
  const time = new Date();
  time.setSeconds(time.getSeconds() + 8); 
  if(!running){
    return  navigate("/writing-exam")
   
  }
  return (
    <div className="flex justify-between ">
    {/* <div className="flex justify-between mb-6"> */}
      
      {/* <h1>
        Test taker ID: <span>110145</span>
      </h1> */}
      <div className="flex justify-center"></div>
      
    
      {/* <h2>
        Part: <span>1</span>
      </h2> */}
    </div>
  );
};

export default ExamFormHeader;
