import { useState } from "react";
import { useFormik } from "formik";
import toast from "react-simple-toasts";
import Cookies from "js-cookie";
import { StudentForm } from "../../components";
import { useMutation, useQuery } from "react-query";
import { AddStudent, SelectInstituteAPI } from "../../services/helpers/Users";
import { initialValuesStudent, validationSchemaStudent,initialValuesInstitute,validationSchemaInstitute} from "../../services/validate/student";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useSelector } from 'react-redux'
import { removeSelectedInstituteId } from "../../store/student";
import { useDispatch } from 'react-redux'
const AddStudentForm = () => {
  const navigate = useNavigate();
  const [loading,setLoading] = useState(false)
  const [imgFile, setImgFile] = useState();
  const [flag,setFlag] = useState(true);
  const [institutelist, setInstituteList] = useState([]);
  const [previewImage, setPreviewImage] = useState(imgFile || "https://img.freepik.com/free-icon/user_318-159711.jpg");
  const {instituteId} = useSelector((state)=> state.student)
  const dispatch = useDispatch()

  useQuery(
    ["SelectInstituteAPI"],
    () => {
      const queryParams = {};
      const paramsData = {
        queryParams,
      };
      return SelectInstituteAPI(paramsData);
    },
    {
      retry: false,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: 0,
      mutationKey: "InstituteAPI",
      enabled: true,
      onSuccess: (res) => {
        if (res?.status === true) {
          let institute = res.data.map((data) => {
            return {
              label: data.instituteName,
              option: data.institueId,
            };
          });
          setInstituteList(institute);
        } else {
        }
      },
    }
  );

  const { mutate, isLoading } = useMutation(AddStudent, {
    mutationKey: "addstudent",
    onSuccess: (res) => {
      console.log("reerwserwerwer", res)
      setLoading(false)
      if (res?.status === false) {
      } else if (res?.status === true) {
        dispatch(removeSelectedInstituteId())
        navigate('/students')
        // window.location.reload();
        toast(res.message, {
          theme: "dark",
          position: "top-center",
        });
        formik.resetForm();
        setPreviewImage("https://img.freepik.com/free-icon/user_318-159711.jpg");
      }
    },
    onError: () => {},
    onSettled: () => {},
  });
  console.log(Cookies.get("id"))
  const formik = useFormik({
    initialValues: Cookies.get("id")=="super_admin"?{...initialValuesStudent,country:"IN", instituteId:instituteId}:initialValuesInstitute,
    validationSchema: Cookies.get("id")=="super_admin"?validationSchemaStudent:validationSchemaInstitute,
    onSubmit: (values) => {
      formik.setFieldValue("role", "student");
      setLoading(true)
      mutate(values);

      // if(values.profileImg == ""){
      //   setFlag(false)
      // }
     

      // if (!values.profileImg == "") {
      //   mutate(values);
      // }
    },
  });

  function pathImage(name) {
    console.log("imagesss", name);
    formik.setFieldValue("profileImg", name);
  }

  return (
    <div className="w-full ">
      <div className="p-8">
        <div className="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6 ">
          <div>
            <div>
            <ArrowBackIcon className="cursor-pointer" onClick={()=>{navigate('/students')}} />
            <h1 className="text-xl text-center font-semibold ">Add Student</h1>
            </div>
          </div>
          <StudentForm formik={formik} previewImage={previewImage} setPreviewImage={setPreviewImage} pathImage={pathImage} institue={institutelist} setImgFile={setImgFile} flag={flag} setFlag={setFlag} loading={loading} />
          {/* <StudentForm
            formik={formik}
            isLoading={isLoading}
            pathImage={pathImage}
            setImgFile={setImgFile}
            institue={institutelist}
            previewImage={previewImage}
            setPreviewImage={setPreviewImage}
          /> */}
        </div>
      </div>
    </div>
  );
};

export default AddStudentForm;
