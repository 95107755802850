// src/components/RichTextEditor.js
import React, { useState, useRef, useEffect, useMemo } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ImageUploader from 'quill-image-uploader';
import axios from 'axios';
import { Formik } from 'formik';
import { BASE_URL } from '../../../services/utils/defines';

// Register the ImageUploader module with Quill
const Quill = ReactQuill.Quill;
Quill.register('modules/imageUploader', ImageUploader);

const QuillNew = ({setContentData, newValue='',setLoading,paddingBottom,formik,fieldName}) => {
  const quillRef = useRef(null);
  console.log("formik",formik)
  const [content, setContent] = useState(newValue);
  console.log("field Name quill_new",fieldName);

  //   const handleMemo = useMemo(
  //     (content, delta, source, editor) => setValue(content),
  //     [value]
  // );
  console.log(content)

  useEffect(() => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();

      // Attach the image uploader module to Quill
      quill.getModule('toolbar').addHandler('image', () => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();

        // Handle the image selection and upload
        input.onchange = async () => {
          const file = input.files[0];

          try {
            // Upload the image and get the URL
            const formData = new FormData();
            formData.append('file', file);
            setLoading(true)
            const response = await fetch(`${BASE_URL}users/upload-icon`, {
              method: 'POST',  
              
              body: formData,  
            })
              .then(response => response.json())
              if(response.status){
                setLoading(false)
              }
              
            const imageUrl = response?.data?.path
            const cursorPosition = quill.getSelection(true).index;
            quill.clipboard.dangerouslyPasteHTML(cursorPosition, `<img src="${imageUrl}"/>`);
          } catch (error) {
            console.error('Error uploading image:', error.message);
          }
        };
      });
    }
  }, []);

  const handleContentChange = (value) => {
    setContent(value);
    setContentData(value);
    formik.setFieldValue(fieldName,value)
    
  };
  const modules = useMemo(() => ({
    toolbar: {
      container: [
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ['link', 'image'],
        ['clean'],
      ],
    },
    imageUploader: {
      upload: (file) => {
        // The upload function should return a Promise
        return new Promise(async (resolve, reject) => {
          try {
            // Upload the image and get the URL
            const formData = new FormData();
            formData.append('image', file);

            const response = await axios.post('YOUR_IMAGE_UPLOAD_ENDPOINT', formData);

            // Get the URL of the uploaded image
            const imageUrl = response.data.imageUrl;

            // Resolve the promise with the image URL
            resolve(imageUrl);
          } catch (error) {
            // Reject the promise with an error
            reject(error.message);
          }
        });
      },
    },
  }), []);  

  const formats = [
    'header', 'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'link', 'image',
  ];

  return (
    <div className='w-full'>
      <ReactQuill
      style={{height:"300px",paddingBottom:paddingBottom}}
        ref={quillRef}
        value={content}
        onChange={handleContentChange}
        modules={modules}
        formats={formats}
        placeholder="Write something amazing..."
      />
    </div>
  );
};

export default QuillNew;