import { TextField, Select, MenuItem, FormControl, InputLabel  } from "@mui/material";
import { InputTextField, SelectInputField, TextAreaField } from "../../Forms";
import DarkButton from "../../common/CustomButtons/DarkButton";
import { useState } from "react";
import {CircularProgress,Backdrop} from "@mui/material";
import QuillEdit from "../../QuillEditor";

const WrittingForm = ({ formik, btnTitle, isLoading, writtenQList, initialValue }) => {
  const [contentdata,setContentData] = useState();
  const [loading,setLoading] = useState(false);
  const [selectedExam, setSelectedExam] = useState("");
  const handleChange = (event) => {
    setSelectedExam(event.target.value);
  };
  const minutes_arr = [];
  for (var i = 0; i < 60; i++) {
    let obj = { label: i, option: i };
    minutes_arr.push(obj);
  }
  return (
    <form onSubmit={formik.handleSubmit}>
      {writtenQList?.map((questionNumber, index) => (
        <div className="grid grid-cols-1  gap-8">
          <fieldset className="fieldsetinit mb-8">
          {btnTitle=="Update Question"?null:<legend className="my-4">Question {++questionNumber}</legend>}
            {loading ? <div className="flex justify-center min-h-[5vh] items-center">
                <Backdrop
                  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                  open={loading}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
              </div> : null}
            <QuillEdit setContentData={setContentData} formik={formik} index={`questions[${index}]question`} initialValue={initialValue?.questions[0]?.question} loading={loading} setLoading={setLoading}  />
          <p className="text-red-600 text-sm mt-16 min-h-[32px]">{(!!formik.errors[`questions`] && !!formik.errors[`questions`][index] && !!formik.errors[`questions`][index]['question'] && formik.errors[`questions`][index]['question'])}</p>
            {/* <TextField
              variant="outlined"
              fullWidth
              type="text"
              id={`questions[${index}]question`}
              label={"Add Question"}
              multiline
              rows={4}
              placeholder={"Add Question"}
              value={formik.values[`questions[${index}]question`]}
              onChange={formik.handleChange}
              {...formik.getFieldProps(`questions[${index}].question`)}
              error={!!formik.errors[`questions`] && !!formik.errors[`questions`][index] && !!formik.errors[`questions`][index]["question"]}
              helperText={
                !!formik.errors[`questions`] &&
                !!formik.errors[`questions`][index] &&
                !!formik.errors[`questions`][index]["question"] &&
                formik.errors[`questions`][index]["question"]
              }
            /> */}
            {/* <TextAreaField type="text" id="question" label="Add Question" formik={formik} placeholder="Add Question" /> */}
            <div className="mt-2 grid grid-cols-1 sm:grid-cols-1 gap-4">
              {/* <TextField
                  variant="outlined"
                  fullWidth
                  type="text"
                  id={`questions[${index}]time`}
                  label={`Time in minute`}
                  placeholder={`Time in minute`}
                  value={formik.values[`questions[${index}]time`]}
                  onChange={formik.handleChange}
                  {...formik.getFieldProps(`questions[${index}]time`)}
                  error={(!!formik.errors[`questions`] && !!formik.errors[`questions`][index] && !!formik.errors[`questions`][index]['time'])}
                  helperText={(!!formik.errors[`questions`] && !!formik.errors[`questions`][index] && !!formik.errors[`questions`][index]['time'] && formik.errors[`questions`][index]['time'])}
                /> */}
              {/* <div className=""><SelectInputField flag={true} id="duration" label="Choose Minutes" exam={setSelectedExam} options={[...minutes_arr, { label: 60, option: 60 }].slice(1)} formik={formik} /></div> */}
              {/* <InputTextField type="text" id="time" label="Time in minute" formik={formik} placeholder="Time in minute" /> */}
              {/* <FormControl className="w-full" size="small">
                <InputLabel id="demo-simple-select-autowidth-label">Choose Minutes</InputLabel>
                <Select
                  width={"100%"}
                  // value={selectedExam}
                  value={formik.values[`questions[${index}]time`]}
                  defaultValue={formik.values[`questions[${index}]time`]}
                  labelId="demo-simple-select-autowidth-label"
                  name={`questions[${index}]time`}
                  {...formik.getFieldProps(`questions[${index}]time`)}
                  error={formik.touched[`questions[${index}]time`] && formik.errors[`questions[${index}]time`]}
                  onChange={(e) => {
                    formik.handleChange(e);
                    handleChange(e);
                  }}
                  helperText={formik.touched[`questions[${index}]time`] && formik.errors[`questions[${index}]time`]}
                  label={"Choose Minutes"}
                >
                  {[...minutes_arr, { label: 60, option: 60 }].slice(1).map((item, index) => (
                    <MenuItem style={{ width: "100%" }} key={index} value={item.option} className="">
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
                {!!formik.errors[`questions`] &&
                !!formik.errors[`questions`][index] &&
                !!formik.errors[`questions`][index]["time"]  && (
                  <p className="text-red-500 text-sm pl-4">{formik.errors[`questions`][index]["time"]}</p>
                )}
              </FormControl> */}
            </div>
          </fieldset>
        </div>
      ))}

      <div className="mt-10  flex justify-center">
        <DarkButton type="submit" btnTitle={btnTitle} loading={isLoading} />
      </div>
    </form>
  );
};

export default WrittingForm;
