import { Button } from "@mui/material";

const InstructionModule = ({ selectedModule, setInstructionToggle }) => {
  return (
    <>
      <div className="w-full">
        <div className="w-full relative px-8 py-4  bg-[#fff] min-h-[60px] ">
          <h3 className="text-center text-2xl ">
            <span className="font-semibold">Exam Instruction</span>
          </h3>
        </div>
        <div className="relative px-8 py-4 lg:flex-row flex-col flex bg-[#F3F3F3] min-h-[80vh] gap-8">
          <div className="flex justify-around items-center min-h-[80vh] w-full bg-[#fff] flex-col mt-4 py-8">
            <div className="w-[90%] mb-20">
              {selectedModule?.moduleInstruction?.length>0 ? <p>
                <div
                  dangerouslySetInnerHTML={{
                    __html: selectedModule?.moduleInstruction,
                  }}
                />
              </p>
              :<p className="text-center font-semibold text-red-400">
                Instruction is not available
              </p>}
            </div>

            <Button
              variant="contained"
              onClick={() => {
                // startExamModule();
                setInstructionToggle(false)
              }}
            >
              Start Exam
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default InstructionModule;
