import React, { useEffect, useState } from "react";

import TextField from "@mui/material/TextField";

import { DarkButton, LoaderSpiner } from "../../../components";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import { DIRECTSTUDENTLISTAPI } from "../../../services/helpers/Student";
import { useMutation, useQuery } from "react-query";
import ListCardCheckbox from "./ListCardCheckbox";
import { AddAssignStudentAPI, AddScheduleStudentAPI } from "../../../services/helpers/ScheduleExam";

const ExamListCheckbox = ({ handleClose, instituteIdexam }) => {
  console.log("instituteIdexaminstituteIdexaminstituteIdexam=", instituteIdexam)
  const [checkedItems, setCheckedItems] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const { id } = useParams();

  const handleCheckboxChange = (id) => {
    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [id]: !prevCheckedItems[id],
    }));
  };

  const { data, isLoading, hasNextPage, isFetching, refetch  } = useQuery(
    ["data"],
    () => {
      const queryParams = {
        examId: id,
      };
      if (Cookies.get("id") === "institute") {
        queryParams.addById = Cookies.get("userId");
      } else {
        queryParams.addById = instituteIdexam;
      }
      const paramsData = {
        queryParams,
      };
      return DIRECTSTUDENTLISTAPI(paramsData);
    },
    {
      keepPreviousData: false,
      staleTime: 50, // milliseconds
    }
  );

  const { mutate, isLoading: addstudentloading } = useMutation(AddAssignStudentAPI, {
    mutationKey: "addScheduleExam",
    onSuccess: (res) => {
      if (res?.status === false) {
      } else if (res?.status === true) {
        handleClose();
      }
    },
    onError: () => {},
    onSettled: () => {},
  });

  const submitHanlder = () => {
    console.log("SubmitHanlder")
    const studentFilterList = [];

    for (const key in checkedItems) {
      if (checkedItems[key] === true) {
        studentFilterList.push({ studentId: key });
      }
    }
    if (studentFilterList.length > 0) {
      let payload = {
        examId: id,
        studentList: studentFilterList,
        // addById: Cookies.get("userId")
      };
      if (Cookies.get("id") === "institute") {
        payload.addById = Cookies.get("userId");
      } else {
        payload.addById = instituteIdexam;
      }
      mutate(payload);
    } else {
      alert("Please select a student");
    }
  };

  useEffect(()=>{
    refetch()
  },[])

  return (
    <div className="w-full">
      <h1 className="text-xl text-center mb-4 font-semibold">Add Student</h1>
      {isLoading || isFetching ? (
        <div className="w-full flex justify-center items-center h-[50vh]">
          <LoaderSpiner />
        </div>
      ) : (
        <>
          {data?.data?.length > 0 ? (
            <>
              <TextField
                label="Search by Student Name"
                variant="outlined"
                className="w-full "
                sx={{
                  "& label": {
                    lineHeight: "14px", // Add your custom height here
                  },
                  "& input": {
                    padding: "10px",
                  },
                }}
                //    InputProps={{
                //     sx: {
                //       '& input': {
                //         height: '1.5rem', // Add your custom height here
                //       },
                //       '& input::placeholder': {
                //         height: '1.5rem', // Add your custom height here for the placeholder
                //       },
                //     },
                //   }}
                // InputProps={{
                //   classes: {
                //     input: '!h-2', // Add your custom input class here
                //   },
                // }}
                onChange={(e) => setSearchTerm(e.target.value)}
                value={searchTerm}
              />
              <div className="w-full flex border-b min-h-[40px] items-center font-bold">
                <p className="flex-1">
                  <span className="pl-12">Student Name:</span>
                </p>
                <p className="flex-1">Student Number:</p>
              </div>
              <div className="overflow-auto">
              {data?.data?.length > 0 && <ListCardCheckbox handleCheckboxChange={handleCheckboxChange} checkedItems={checkedItems} searchTerm={searchTerm} data={data?.data} />}
              </div>
              <div className="mt-2  flex justify-center">
                <DarkButton
                  type="submit"
                  btnTitle="Submit"
                  handler={() => {
                    submitHanlder();
                  }}
                  loading={addstudentloading}
                />
              </div>
            </>
          ) : (
            <div className="flex min-h-[60vh] justify-center items-center">
            <h3 className="text-2xl">Student List not found</h3>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ExamListCheckbox;
