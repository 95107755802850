import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { examSelectedId, examUpdateById } from "../../../store/exam";
import ViedeTest from "../../../../../pages/Videotest/ViedeTest";
import { examinationUpdateById } from "../../../../../store/examination";

const VdeoRecordExam = ({ change, time, mediaType }) => {
  console.log("changeselectedexam", change, time);
  const examination = useSelector((state) => state.examination);
  console.log("selectedModuleselectedModuleselectedModule", examination);
  const [examD, setExamD] = useState();
  const dispatch = useDispatch();
  // useEffect(() => {
  //   if (selectedExam.length > 0) {
  //     setExamD(selectedExam[change]);
  //     // dispatch(examSelectedId(selectedExam[change].id));
  //     console.log("selectedExam[change] ==", selectedExam[change]);
  //   }
  // }, [change, selectedExam]);

  const questionsAnswersHandle = (id, answer) => {
    dispatch(examinationUpdateById({ id: id, answer }));
  };

  return (
    <>
      <div className="mt-3">
        <ViedeTest
          questionsAnswersHandle={questionsAnswersHandle}
          id={examination.mediaQuestion.selectedQuestion.id}
          mediaType={mediaType}
        />
      </div>
    </>
  );
};

export default VdeoRecordExam;
