import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DrawIcon from "@mui/icons-material/Draw";
import InstructionModule from "./InstructionModule";
import ExamModules from "../ExamModules";
import { moduleExamEnd } from "../../../store/examination";

const SeprateExamInstruction = ({ StudentExamDetails, selectedModule, activeSection, examName }) => {
  const dispatch = useDispatch()
  const examination = useSelector((state) => state.examination);

  // const [activeSection, setActiveSection] = useState(0);
  // useEffect(()=>{
  //   if(examination?.submitExamStatus?.startNewModule){
  //     setInstructionToggle(true)
  //   }

  // },[examination?.submitExamStatus?.startNewModule])

  const setInstructionToggle = () => {
    if (examination?.submitExamStatus?.startModuleExam == false) {
      dispatch(moduleExamEnd({ key: "startModuleExam", value: true }))
    }
  }

  return (
    <>
      {!examination?.submitExamStatus?.startModuleExam ? (
        <InstructionModule
          selectedModule={selectedModule}
          setInstructionToggle={setInstructionToggle}
        />
      ) : (
        <ExamModules
          selectedModule={selectedModule}
          activeSection={activeSection}
          examName={examName}
        />
      )}
    </>
  );
};

export default SeprateExamInstruction;
