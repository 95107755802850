// ScheduleStudent.js
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { SCHEDULESTUDENTLISTAPI } from "../../../services/helpers/ScheduleExam";
import ScheduleStudentTable from "./ScheduleStudentTable";
import { useParams } from "react-router-dom";

const ScheduleStudent = (props) => {
  const [pagination,setPagination] = useState();
  const { scheduleId } = useParams();
  const { refetchState, setRefetchState, setInstituteIdexam } = props
  const paramsData = {
    queryParams: {},
  };
  paramsData.queryParams = {
    scheduleId: scheduleId,
  };
  const [page, setPage] = useState(1);

  const { data, isLoading, hasNextPage, refetch, isFetching } = useQuery(
    ["data", page],
    () => {
      return SCHEDULESTUDENTLISTAPI(paramsData);
    },
    {
      retry: true,
      keepPreviousData: false,
      refetchOnWindowFocus: true,
      staleTime: 100,
      mutationKey: "STUDENTDETAILSAPI",
      enabled: true
    }
  );
  

  const handlePrevClick = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextClick = () => {
    setPage((prevPage) => prevPage + 1);
  };

  useEffect(()=>{
    console.log("isLoadingisLoadingisLoadingisLoading")
    if(refetchState){
      refetch()
      setRefetchState(false)
    }

  },[refetchState])

  useEffect(()=>{
    if(data){
      setInstituteIdexam(data?.data?.examDetails?.addById)
    }
  },[data])

  return (
    <>
      <ScheduleStudentTable
        {...props}
        data={data}
        isLoading={isLoading||isFetching}
        hasNextPage={hasNextPage}
        page={page}
        setPage={setPage}
        handlePrevClick={handlePrevClick}
        handleNextClick={handleNextClick}
      />
    </>
  );
};

export { ScheduleStudent };
