import Box from "@mui/material/Box";
import { useFormik } from "formik";
import Modal from "@mui/material/Modal";
import { GrClose } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import { setToastMessage } from "../../../store/toast";
import { useMutation, useQueryClient } from "react-query";
import McqForm from "../../AddQuestion/McqQuestion/McqForm";
import { updateQuestionAPI } from "../../../services/helpers/Question";
import { validationSchemaMcq as validationSchema } from "../../../services/validate/AddQuestion";
import { useDispatch } from "react-redux";
import {useParams} from 'react-router-dom'
import BooleanForm from "../../AddQuestion/Boolean/BooleanForm";

const style = {
  bgcolor: "background.paper",
};

const McqUpdateModal = ({ handleClose, open, headingText, questionId, initialValue, questionDetails }) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const{id} = useParams()
  const formik = useFormik({
    initialValues: {questions: [initialValue]},
    validationSchema: validationSchema,
    onSubmit: (values) => {
      submitHandler(values);
    },
  });
  console.log("formik initialValue", initialValue,formik)

  const submitHandler = ( values ) => {
    let option = [1, 2, 3, 4].map((value) => {
      return {
        id: value,
        title: formik.values["opt" + value],
      };
    });
    const newValues = {
      questionId: questionId,
      questionType: questionDetails.examType || values.questions[0]["examType"],
      question: values.questions[0]["question"],
      examId: id,
      options: [
        { id: 1, title: values.questions[0][`opt1`] },
        { id: 2, title: values.questions[0][`opt2`] },
        { id: 3, title: values.questions[0][`opt3`] },
        { id: 4, title: values.questions[0][`opt4`] },
      ],
      correctAnswer: values.questions[0].answer,
      time: formik.values.time || values.questions[0].time,
    };
    console.log("valuese", formik.values)
    mutate(newValues);
  };

  const { mutate, isLoading } = useMutation(updateQuestionAPI, {
    mutationKey: "updateExam",
    onSuccess: (data) => {
      if (data.status === true) {
        dispatch(
          setToastMessage({
            isActive: true,
            message: data?.message,
            type: "success",
          })
        );
        handleClose();
        queryClient.invalidateQueries("EXAMDETAILSAPI");
        window.location.reload();
      }
    },
  });

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={style} className="min-w-[400px] top-[50%] left-[50%] absolute translate-x-[-50%] translate-y-[-50%] shadow-lg outline-none rounded p-4">
        <div className="pt-4 px-5 flex justify-between">
          <h2 className="text-bold font-bold text-lg">{headingText}</h2>
          <GrClose className="cursor-pointer" onClick={handleClose} />
        </div>
        {/* <div className="w-100 bg-slate-200  mt-2 mb-4 shadow-lg " /> */}
        <div className="overflow-y-auto h-[72vh] mt-4">

        <McqForm formik={formik} btnText="Update Question" isLoading={isLoading} initialValue={initialValue} mcqQList={[0]} />
        {/* <BooleanForm formik={formik} btnText="Update Question" isLoading={isLoading} initialValue={initialValue} mcqQList={[0]}/> */}

        {/* <div className="w-100 bg-slate-200  mt-6 mb-2 shadow-lg" /> */}
        <div className="w-full p-5 pb-4 flex justify-between">
          <button onClick={handleClose} className="w-full py-2 rounded border">
            Cancel
          </button>
        </div>
        </div>
      </Box>
    </Modal>
  );
};

export default McqUpdateModal;
