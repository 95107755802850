import { useTimer}  from 'react-timer-hook';
import {useEffect} from "react"
export function MyTimer({ expiryTimestamp,running }) {
    const {
      totalSeconds,
      seconds,
      minutes,
      hours,
      days,
      isRunning,
      start,
      pause,
      resume,
      restart,
    } = useTimer({ expiryTimestamp, onExpire: () => {
        
    } });


    useEffect(()=>{

      running(isRunning)

    },[isRunning])


    
  
  
    return (
      <div style={{textAlign: 'center'}}>

        {/* <h1>react-timer-hook </h1>
        <p>Timer Demo</p> */}
        <div style={{fontSize: '20px',marginBottom:20}}>
         Time: <span>{hours}</span>:<span>{minutes}</span>:<span>{seconds} / 20 minutes</span>
        </div>
        {/* <p>{isRunning ? 'Running' : 'Not running'}</p> */}
        {/* <button onClick={start}>Start</button>
        <button onClick={pause}>Pause</button>
        <button onClick={resume}>Resume</button>
        <button onClick={() => {
          // Restarts to 5 minutes timer
          const time = new Date();
          time.setSeconds(time.getSeconds() + 300);
          restart(time)
        }}>Restart</button> */}
      </div>
    );
  }