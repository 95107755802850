export const ButtonPrimary = ({ text, Icon, handler, type = "primary" }) => {
  const getClass = () => {
    switch (type) {
      case "primary":
        return "btn-primary";
      case "filter":
        return "btn-filter";
      case "none":
        return "btn-none"
      default:
        return "btn-primary";
    }
  };
  return (
    <button onClick={handler} className={`${getClass(type)}`}>
      {Icon} {text}
    </button>
  );
};

export const ButtonSwitch = ({ text, handler, active, css }) => {
  return (
    <div className="flex ">
      <button onClick={handler} className={`${active ? "btn-switch-active" : "btn-switch-disabled"} ${css}`}>
        {" "}
        {text}
      </button>
    </div>
  );
};
