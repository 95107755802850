import { Link } from "react-router-dom";

import Cookies from "js-cookie";
const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className={`bg-blueGray-100 bg-Primary md:ml-64 flex items-center`}>
      <div className="text-white relative px-8 py-4 text-center w-full">
        <div className="text-base font-medium flex justify-between flex-col-reverse xl:flex-row">
          <p className="text-white">&copy; All Rights Reserved {currentYear}</p>
          <div className="flex justify-between flex-col-reverse xl:flex-row">
            <Link className="pr-8" to="https://www.tychotechnologies.com">
              Designed By :{" "}
              <span className="bg-clip-text bg-gradient-to-r text-transparent hover:bg-gradient-to-l  from-[#fff] to-[#ccc] transition ease-in	 delay-300">
                Tycho Technologies Pvt Ltd
              </span>
            </Link>

            <Link to="https://iyaantechnologies.com/">
              Powered By :{" "}
              <span className="bg-clip-text bg-gradient-to-r text-transparent hover:bg-gradient-to-l  from-[#fff] to-[#ccc] transition ease-in	 delay-300">
                Iyaan - IT Solutions and Services LLP
              </span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
