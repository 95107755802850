import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    profileUpdate: (state, action) => {
      return action.payload;
    },
  },
});

export const { profileUpdate } = profileSlice.actions;

export default profileSlice.reducer;
