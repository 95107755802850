import { useFormik } from "formik";
import toast from "react-hot-toast";
import { useMutation } from "react-query";
import { initialValuesExam, validationSchemaExam } from "../../../services/validate/examStatus";
import UpdateStatusModal from "../../common/UpdateStatusModal";
import { AddStudent, ExamStatusAPI, InstituteStatusAPI, StudentExamStatusAPI, updateExamStatusAPI } from "../../../services/helpers/Users";

const InstituteUpdateModal = ({ className, title, deleteText, deleteHandle, handleClose, open, loading, id, option, defaultValue, isupdated, setIsupdated }) => {
  console.log("Institute", defaultValue);
  const { mutate, isLoading } = useMutation(InstituteStatusAPI, {
    mutationKey: "updateStatus",
    onSuccess: (res) => {
      if (res?.status === false) {
      } else if (res?.status === true) {
        handleClose();
        setIsupdated(true);
        toast(res.message, {
          theme: "dark",
          position: "top-center",
        });
        formik.resetForm();
      }
    },
    onError: () => {},
    onSettled: () => {},
  });

  const formik = useFormik({
    initialValues: { ...initialValuesExam, action: defaultValue.isActive },
    validationSchema: validationSchemaExam,
    onSubmit: (values) => {
      let payload = {
        action: values?.action,
        instituteId: defaultValue?.instituteId,
      };

      mutate(payload);
    },
  });
  return (
    <UpdateStatusModal
      defaultValue={defaultValue}
      open={open}
      formik={formik}
      id="action"
      option={option}
      loading={loading}
      deleteHandle={deleteHandle}
      handleClose={handleClose}
      deleteText={deleteText}
      title={title}
      className={className}
    />
  );
};

export default InstituteUpdateModal;
