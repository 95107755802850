import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { initialValuesExam, validationSchemaExam } from "../../../services/validate/examStatus";
import UpdateStatusModal from '../../common/UpdateStatusModal';
import { AddStudent, StudentExamStatusAPI, updateExamStatusAPI } from '../../../services/helpers/Users';

const StudentStatusModal = ({ className, title, deleteText, deleteHandle, handleClose, open, loading ,id,option,defaultValue,isupdated,setIsupdated}) => {
    console.log("ope ==> ", defaultValue)
  const { mutate, isLoading } = useMutation(StudentExamStatusAPI, {
    mutationKey: "updateStatus",
    onSuccess: (res) => {
      if (res?.status === false) {
      } else if (res?.status === true) {
        handleClose();
        setIsupdated(true)
        toast(res.message, {
          theme: "dark",
          position: "top-center",
        });
        formik.resetForm();

        // dispatch(profileUpdate(res.data));
        // cookie.setLogin(res.data.tokens.access.token, res.data.user.id);
        // navigate("/");
      }
    },
    onError: () => {},
    onSettled: () => {},
  });
  // const formik = useFormik({
  //   initialValues: initialValuesStudent,
  //   validationSchema: validationSchemaStudent,
  //   onSubmit: (values) => {
  //     console.log(values);

  //     formik.setFieldValue("role", "student");

  //     // {...values,{values.profileImg:res}}
  //     if(imgFile){
  //       mutate(values);
  //     }
     

  //     //   submitHandler(values);
  //   },
  // });
    const formik = useFormik({
        initialValues: {...initialValuesExam, action: defaultValue.isActive },
        validationSchema: validationSchemaExam,
        onSubmit: (values) => {
            // handleSubmit();
            let payload = {
                action: values?.action,
                userId: defaultValue?.userId
            }
            mutate(payload)
            

          
          
    
          
        },
      });
      const handleSubmit = ()=>{
        formik.setFieldValue("userId",defaultValue.userId)
      }
  return (
    <UpdateStatusModal
      defaultValue={defaultValue}
        open={open}
        formik={formik}
        id="action"
        option={option}
        loading={loading}
        deleteHandle={deleteHandle}
        handleClose={handleClose}
        deleteText={deleteText}
        title={title}
        className={className}
      />
  )
}

export default StudentStatusModal