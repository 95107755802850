import { useRef } from "react";
import { useState, useEffect } from "react"
import headphone from "../../../assets/img/earphones_3964887.png"
const AudioCard = () => {
  
  const audioRef = useRef(null);
  const [hasPermission, setHasPermission] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  const toggleAudio = () => {
    if (audioRef.current.paused) {
      audioRef.current.play();
      setIsPlaying(true);
    } else {
      audioRef.current.pause();
      setIsPlaying(false);
    }
  };

  useEffect(() => {
    async function getMicrophonePermission() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        stream.getTracks().forEach((track) => track.stop());
        setHasPermission(true);
      } catch (error) {
        console.error('Error accessing microphone:', error);
      }
    }

    if (!hasPermission) {
      getMicrophonePermission();
    }
  }, [hasPermission]);
  return (
    <div className="bgColor w-full flex flex-col justify-center items-center h-[70vh]  mt-4 bg-slate-500 rounded py-5 px-4">
      <img className="w-4/12" src = {headphone}/>
      <h2 className="mb-8 text-white font-medium text-lg text-center" >You will be listening to an audio clip during this test.After listening answer the  Questions?</h2>

      <div>
      {hasPermission ? (
        <div>
          <audio controls ref={audioRef} src="https://ia800905.us.archive.org/19/items/FREE_background_music_dhalius/backsound.mp3" type="audio/mp3" />
          <div className="flex justify-center">
          <button className="text-white  mt-8 bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2" onClick={toggleAudio}>
            {isPlaying ? 'Pause Audio' : 'Play Audio'}
          </button>
          </div>
        </div>
      ) : (
        <p className="text-white">Requesting microphone permission...</p>
      )}
    </div>

      {/* <audio controls controlsList="nodownload" className="w-full md:w-4/5 lg:w-3/5">
        <source src="https://ia800905.us.archive.org/19/items/FREE_background_music_dhalius/backsound.mp3" type="audio/mp3" />
      </audio> */}


    </div>
  );
};

export default AudioCard;
