import SearchIcon from "@mui/icons-material/Search";
import AddSharpIcon from "@mui/icons-material/AddSharp";
import { useNavigate, useParams } from "react-router-dom";
import { IconButton, InputAdornment, TextField, Card } from "@mui/material";
import BreadcrumbsC from "../../../components/common/BreadcrumbsC";
import { DarkButton } from "../../../components";
import { ScheduleStudent as ScheduleStudentC } from "./ScheduleStudent";
import ScheduleStudentAdd from "./ScheduleStudentAdd";
import { useState } from "react";

const ScheduleStudent = () => {
  const [open, setOpen] = useState(false);
  const [instituteIdexam, setInstituteIdexam] = useState()
  const [refetchState, setRefetchState] = useState(true) 
  const handleOpen = () => setOpen(true);
  const handleClose = () => {setOpen(false);setRefetchState(true)};
  const handleSearch = (e) => {};
  const breadcrumbb = [
    {
      id: 1,
      title: "Dashboard",
      link: "/dashboard",
    },
    {
      id: 1,
      title: "Exam Schedule",
      link: "/examschedule",
    },
  ];

  return (
    <div className="relative px-8 py-4 bg-[#F3F3F3] md:ml-64">
      <div className="w-full">
        <div className="flex justify-between items-center">
          <BreadcrumbsC data={breadcrumbb} />
        </div>
        <div className="flex flex-col-reverse sm:grid sm:grid-cols-2 justify-center sm:justify-between items-center mt-8 gap-5">
          <form onSubmit={handleSearch}>
            <div className="flex items-center w-full gap-4">
              <TextField
                id="outlined-start-adornment"
                className="w-full sm:w-auto"
                size="small"
                onChange={(e) => {}}
                sx={{ backgroundColor: "white" }}
                placeholder="Search or Type Here"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton aria-label="toggle password visibility" edge="end">
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </form>
          <div className="flex w-full sm:justify-end">
            <DarkButton
              btnTitle="Add Student"
              endIcon={<AddSharpIcon />}
              handler={() => {
                handleOpen();
              }}
            />
          </div>
        </div>
      </div>
      <div className={`bg-white mt-4 rounded-lg p-5 mb-10 `}>
        <ScheduleStudentC setRefetchState={setRefetchState} refetchState={refetchState} setInstituteIdexam={setInstituteIdexam} />
      </div>
      <ScheduleStudentAdd open={open} handleClose={handleClose} instituteIdexam={instituteIdexam} />
    </div>
  );
};

export default ScheduleStudent;
