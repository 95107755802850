import { useDispatch, useSelector } from "react-redux";
import routes from "./services/config/Routes";
import StudentRoutes from "./services/config/StudentRoutes";
import { Routes, Route } from "react-router-dom";
import { routeController } from "./utils/Utility";
import CookieStorage from "./services/cookieStorage";
import { Header, Footer, AppSideBar } from "./components";
import Cookies from "js-cookie";
import { StudentNav } from "./components/StudentNav";
import { useQuery } from "react-query";
import { profileUpdate } from "./store/profile";
import { UserProfile } from "./services/helpers/Users";
import StudentSection from "./components/StudentSection";

const AppNavigator = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const appid = state.profile?.userId;
  const cookie = new CookieStorage();

  // const queryParams = {
  //   userId: Cookies.get("userId"),
  // };

  // const paramsData = { queryParams, isAuthorized: true };
  // const { isLoading } = useQuery(["getuserprofile"], () => UserProfile(paramsData), {
  //   retry: false,
  //   refetchOnWindowFocus: false,
  //   mutationKey: "getuserprofile",
  //   enabled: Cookies.get("userId") ? true : false,
  //   onSuccess: (res) => {
  //     if (res?.status === true) {
  //       dispatch(profileUpdate({ isLoading: false, ...res.data }));
  //     } else if (res?.status === false) {
  //       dispatch(profileUpdate({ message: res.message }));
  //     }
  //   },
  // });

  return (
    <div className="flex flex-col h-[100vh]">
      {/* {cookie.getLogin() && (
        <>
          <SideBarC />
          <Header />
          </>
        )} */}
      {cookie.getLogin() && (
        <>
          {/* {((Cookies.get("id") === "super_admin" || (Cookies.get("id") === "institute")) && <Header />)} */}
          {((Cookies.get("id") === "super_admin" || (Cookies.get("id") === "institute")) && <div className="hidden md:block"><Header /></div>)}
          {((Cookies.get("id") === "super_admin" || (Cookies.get("id") === "institute")) && <AppSideBar />)}

          {/* {Cookies.get("id") === "student" ?   <StudentSection />:<AppSideBar/>} */}
        </>
      )}

      {/* {Cookies.get("role") === "student" ? null : <Header />} */}

      <main className="grow ">
        <Routes>
          {routes.map(({ id, path, component, protectedRoute, type = "purchaser" }) => (
            <Route exact key={id} path={path} element={routeController(component, protectedRoute)} />
          ))}
          {/* {StudentRoutes.map(({ id, path, component, protectedRoute, type = "purchaser" }) => (
          <Route key={id} path={path} element={routeController(component, protectedRoute)} />
        ))} */}
        </Routes>
      </main>

      {cookie.getLogin() && (
        <>
          <Footer />
        </>
      )}
    </div>
  );
};

export default AppNavigator;
