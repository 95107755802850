import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import BreadcrumbsC from "../BreadcrumbsC";
import "./addckeditor.css";
import { CircularProgress, Paper } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { SelectInputField } from "../../Forms";

const AddCKEditor = ({ contentData, setContentData, heading, submitHandle }) => {
  console.log("contentData ==", contentData);
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      <div className="w-full">
        {/* <div className="flex justify-between items-center">
          <BreadcrumbsC data={breadcrumbb} />
        </div>
        */}
        {/* <div className="flex justify-between items-center">
        <SelectInputField formik={formik} options={options}/>
        </div> */}
      </div>

      <Paper>
        <div className="w-full">
          <div className="my-6 p-4">
            <h1 className="pt-2 mb-6 font-bold text-2xl">{heading}</h1>
            <div className="pb-4"></div>

            <CKEditor
              editor={ClassicEditor}
              data={contentData}
              config={{
                toolbar: ["undo", "redo", "|", "heading", "|", "bold", "italic", "|", "blockQuote", "insertTable", "|", "bulletedList", "numberedList"],
                shouldNotGroupWhenFull: false,
              }}
              onReady={(editor) => {
                editor.editing.view.change((writer) => {
                  writer.setStyle("padding-left", "30px", editor.editing.view.document.getRoot());
                });
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                setContentData(data);
              }}
              onBlur={(event, editor) => {
                console.log("Blur.", editor);
              }}
              onFocus={(event, editor) => {
                console.log("Focus.", editor);
              }}
            />
            <div className="flex justify-center items-center mt-8 mb-12">
              <button
                type="submit"
                className={`btn-primary relative`}
                disabled={isLoadingBtn}
                onClick={(e) => {
                  e.preventDefault();
                  submitHandle();
                }}
              >
                {isLoadingBtn && <CircularProgress size="1rem" className="absolute top-[30%] left-[40%] !text-white" />}{" "}
                <span className={`${isLoadingBtn ? "invisible" : ""}`}>Submit</span>
              </button>
            </div>
          </div>
        </div>
      </Paper>
    </>
  );
};

export default AddCKEditor;
