import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DrawIcon from "@mui/icons-material/Draw";
import InstructionModule from "./InstructionModule";
import ExamModules from "../ExamModules";
import ExamSideBar from "../ExamSideBar";
import {
  examStatusSubmitted,
  examinationUpdateQuestion,
  moduleExamEnd,
  submitExamStatusReset,
} from "../../../store/examination";
import SeprateExamInstruction from "./SeprateExamInstruction";
import DashboadPagesLayout from "../../common/Layouts/DashboadPagesLayout";
import AppSideBarTest from "../../common/AppSideBar/AppSideBarTest";

const ExamInstruction = ({ StudentExamDetails }) => {
  const dispatch = useDispatch();
  const [selectedModule, setSelectedModule] = useState();
  const [selectedModuleSide, setSelectedModuleSide] = useState();
  // Active
  const [activeSection, setActiveSection] = useState(0);
  const [activeModule, setActiveModule] = useState(0);

  const examination = useSelector((state) => state.examination);

  useEffect(() => {
    let module =
      StudentExamDetails?.module?.length > activeModule
        ? StudentExamDetails?.module[activeModule]
        : null;

    if (module) {
      let questionList = [];
      if (module?.sections.length > 0) {
        let sectionDetail = module?.sections
          ?.map((section, index) => {
            let { questions, ...rest } = section;
            if (!questions.length > 0) {
              return undefined;
            }
            let qA = questions.map((question) => {
              return {
                id: question?.id,
                answer: null,
                attempt: null,
                questionTypeId: question?.questionTypeId,
              };
            });
            questionList.push(...qA);
            let sectionTime =
              rest && rest?.time ? rest?.time?.split(":") : "00:00"?.split(":");
            let secTime = sectionTime[0] * 1 * 60 + sectionTime[1] * 1;
            return {
              ...rest,
              questions,
              // time: 6,
              time: secTime,
              totalTime: section.time,
              set: section.sectionName,
              link: "/",
              icon: <DrawIcon fontSize="small" className="mr-1" />,
            };
          })
          .filter((element) => element != undefined);
        setSelectedModuleSide({ ...module, sections: sectionDetail });
      }
      if (StudentExamDetails?.module?.length == activeModule + 1) {
        dispatch(moduleExamEnd({ key: "lastModule", value: true }));
      }
      dispatch(examinationUpdateQuestion(questionList));
      setSelectedModule(module);
    }
  }, [activeModule]);

  const stopExamModule = () => {
    dispatch(moduleExamEnd({ key: "moduleTimerEnd", value: true }));
  };

  useEffect(() => {
    if (examination?.submitExamStatus?.startNewModule) {
      if (StudentExamDetails?.module?.length > activeModule + 1) {
        dispatch(submitExamStatusReset());
        setActiveSection(0);
        setSelectedModule(null);
        setSelectedModuleSide(null);
        setActiveModule((prev) => {
          return prev + 1;
        });
      }
    }
  }, [examination?.submitExamStatus?.startNewModule]);
  return (
    <>
      {selectedModuleSide && selectedModule && (
        <>
          {examination?.submitExamStatus?.startModuleExam ? (
            <ExamSideBar
              type={"exam"}
              activeSection={activeSection}
              setActiveSection={setActiveSection}
              examStudentSidebar={selectedModuleSide}
              stopExamModule={stopExamModule}
            />
          ) : (
            <AppSideBarTest />
          )}
          <DashboadPagesLayout>
            {selectedModule && (
              <SeprateExamInstruction
                selectedModule={selectedModule}
                activeSection={activeSection}
                examName={{
                  examName: StudentExamDetails?.examName,
                  duration: StudentExamDetails?.duration,
                }}
              />
            )}
          </DashboadPagesLayout>
        </>
      )}
    </>
  );
};

export default ExamInstruction;
