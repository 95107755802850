import React, { useEffect } from "react";
import { Test } from ".";
import ExamHeader from "../../components/ExamHeader";
import { DashboadPagesLayout } from "../../components";
import AppSideBarExam from "../../components/common/AppSideBar/AppSideBarExam";
import { FaBars } from "react-icons/fa";
import { TfiClose } from "react-icons/tfi";
import DrawIcon from "@mui/icons-material/Draw";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import LogoutSharpIcon from "@mui/icons-material/LogoutSharp";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import { useSelector } from 'react-redux'
const examSection = [
  {
    id: 1,
    time: 20,
    set: "Section A",
    link: "/reading-exam",
    icon: <AutoStoriesIcon fontSize="small" className="mr-1" />,
  },
  {
    id: 2,
    time: 20,
    set: "Section B",
    link: "/writing-exam",
    icon: <DrawIcon fontSize="small" className="mr-1" />,
  },
  {
    id: 3,
    time: 20,
    set: "Section C",
    link: "/listening-exam",
    icon: <HeadsetMicIcon fontSize="small" className="mr-1" />,
  },
  {
    id: 4,
    time: 20,
    set: "Section D",
    link: "/speaking-exam",
    icon: <RecordVoiceOverIcon fontSize="small" className="mr-1" />,
  },
];

const ExamNavSide = () => {
  const exam = useSelector((state) => state.exam);
  const state = useSelector((state)=> state)
  useEffect(()=>{
    console.log("exam status ==",exam.SwitchtoExam)
    if(exam.SwitchtoExam){
      window.location.reload()
    }
  },[exam.SwitchtoExam])
  return (
    <div>
      <AppSideBarExam type="dashboard"  />
      <ExamHeader />

      <DashboadPagesLayout>
        <Test />
      </DashboadPagesLayout>
    </div>
  );
};

export default ExamNavSide;
