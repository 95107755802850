import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isActive: false,
  message: "",
  type: "error",
};

export const toastMessageSlice = createSlice({
  name: "toast_data",
  initialState: initialState,
  reducers: {
    setToastMessage: (state, action) => {
      state.isActive = action.payload?.isActive;
      state.message = action.payload?.message;
      state.type = action.payload?.type || "error";
    },
  },
});

export const { setToastMessage } = toastMessageSlice.actions;

export default toastMessageSlice.reducer;
