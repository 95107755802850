import { Avatar } from "@mui/material";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
// import avatarhead from "../../../assets/img/avatarhead.png";

const Header = () => {
  const navigate = useNavigate();

  if(Cookies.get('role')==="student"){
    if(window.location.pathname.split('/')[1].length==0){
      navigate("/test")
      
    }
  }
  return (
    <div className="flex justify-end items-center border-b-[1px] p-4 md:ml-64">
      <div className="flex items-center justify-center gap-2">
        <Avatar alt={`${Cookies.get('id') ==='super_admin' ? 'Admin' : Cookies.get('id')?.toUpperCase()}`} src="/static/images/avatar/1.jpg" sx={{ width: 36, height: 36 }} />
      </div>
    </div>
  );
};

export default Header;
