import { useState } from "react";
import Cookies from "js-cookie";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import AddSharpIcon from "@mui/icons-material/AddSharp";
import BreadcrumbsC from "../../components/common/BreadcrumbsC";
import LoaderSpiner from "../../components/common/LoaderSpiner";
import { instituteListAPI } from "../../services/helpers/Institute";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { CustomPagination, DarkButton } from "../../components";
import SectionTable from "../../components/SectionTable";
import { useEffect } from "react";
import { SECTIONLIST, SELECTLIST } from "../../services/helpers/Users";

const Section = () => {
  const { id } = useParams();
  const [isupdated, setIsupdated] = useState(false)
  const [instituteList, setInstituteList] = useState([]);
  const [change, setChange] = useState(false);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState(null);
  const [page, setPage] = useState(1);
  const [serial, setSerial] = useState(null);
  const navigate = useNavigate();

  const handler = () => {
    navigate("/institute/add");
  };

  const breadcrumbb = [
    {
      id: 1,
      title: "Dashboard",
      link: "/dashboard",
    },
    {
      id: 1,
      title: "Institute",
      link: "/institute",
    },
  ];




  const getStartingSerialNumber = () => {
    return setSerial((page - 1) * pagination?.numberPerPage + 1);
  };

  useEffect(() => {
    getStartingSerialNumber();
  }, [instituteList]);

  useQuery(
    ["Section", page, change, isupdated],
    () => {
      const queryParams = {
        examId: id,
      };
      const paramsData = {
        queryParams,
      };
      return SECTIONLIST(paramsData);
    },
    {
      retry: false,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: 0,
      mutationKey: "SectionList",
      enabled: true,
      onSuccess: (res) => {
        setLoading(false);
        if (res?.status === true) {
          console.log(res?.data)
          setChange(false);
          setIsupdated(false)
          setInstituteList(res?.data);
          //   setPagination(res?.data.pagination);
        } else {
        }
      },
    }
  );

  return (
    <div className="relative px-8 py-4 bg-[#F3F3F3] ">
      <div className="w-full">
        <div className="flex justify-between items-center">
          {/* <BreadcrumbsC data={breadcrumbb} /> */}
        </div>
        <div className="flex flex-col-reverse sm:grid sm:grid-cols-2 justify-center sm:justify-between items-center mt-8 gap-5">
          {/* <div className="flex items-center w-full gap-4">
            <TextField
              id="outlined-start-adornment"
              className="w-full sm:w-auto"
              size="small"
              sx={{ backgroundColor: "white" }}
              placeholder="Search or Type Here"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton aria-label="toggle password visibility" edge="end">
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div> */}
          {/* <div className="flex w-full sm:justify-end">
            <DarkButton btnTitle="Add Institute" endIcon={<AddSharpIcon />} handler={handler} />
          </div> */}
        </div>
      </div>

      {/* lower section */}
      <div className={`bg-white mt-8 rounded-lg p-5 mb-10`}>
        <h3 className="text-2xl mb-4 text-center">Section List</h3>
        <div className="flex w-full">
          {loading ? (
            <div className="w-full flex justify-center items-center h-[30vh]">
              <LoaderSpiner />
            </div>
          ) : (
            <>
              <SectionTable instituteList={instituteList} serial={serial} setChange={setChange} setIsupdated={setIsupdated} isupdated={isupdated} />
            </>
          )}
        </div>
      </div>
      {/* <CustomPagination page={page} setPage={setPage} paginationData={pagination} /> */}
    </div>
  );
};

export default Section;
