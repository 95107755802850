import { Navigate } from "react-router-dom";
import CookieStorage from "../../services/cookieStorage";
import Cookies from "js-cookie";


export const routeController = (component, protectedRoute = false) => {
  // const profile = useSelector((state) => state.profile);
  const cookie = new CookieStorage();
  if (protectedRoute) {
    if (cookie.getLogin()) {
      // if(Cookies.get("role")==="student"){
      //    <Navigate to="/test"/>
      // }
      return component;
    } else {
      return <Navigate to="/login" replace />;
    }
  } else {
    if (cookie.getLogin()) {
      if(Cookies.get('id')==="student"){
        return <Navigate to ="/test"/>
      }
      return <Navigate to={Cookies.get("id")==="student"?"/test":"/dashboard"} replace />
    } else {
      return component;
    }
  }
};
