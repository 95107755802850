import React, { useState } from "react";
import avatar from "../../../assets/img/avatar.png";

const FeebackCard = ({ name, path, feedback, image, date, width = "w-1/6" }) => {
  const [error, setError] = useState(false);
  const handleError = () => {
    setError(true);
  };

  return (
    <div className={`flex flex-col md:flex-row bg-slate-100 items-center md:items-start p-3 mt-4 rounded-lg`}>
      <img style={{ width: image ? "60px" : `60px`, height: "60px" }} className="rounded " onError={handleError} src={avatar} alt="feedback-img" />
      <div className="md:pl-3 mt-3 md:mt-0 w-full text-center md:text-start">
        <span className="flex justify-center md:justify-start">
          <h2 className="font-bold text-center md:text-start">{name}</h2>
          {/* <h2 className="font-normal">{date}</h2> */}
        </span>
        <p className="text-base">{feedback}</p>
      </div>
    </div>
  );
};

export default FeebackCard;
