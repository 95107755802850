import React from "react";
import ApplicantTable from "./ApplicantTable";
import { ButtonSwitch } from "../../common/ButtonC";

const studentDetails = {
  id: 1,
  examName: "Abroad University",
  examType: "Listening, Reading, Writting",
  applyFor: "Study",
  appliedStudents: 505,
  appearedStudents: 480,
  passedSudents: 300,
  country: "US",
};

const resultData = [
  {
    id: 1,
    applicantName: "Dig Vijay",
    bandSore: 4.8,
    resultStatus: 1,
  },
  {
    id: 1,
    applicantName: "Vimleshwar",
    bandSore: 4.5,
    resultStatus: 0,
  },
  {
    id: 1,
    applicantName: "Justin P",
    bandSore: 7.8,
    resultStatus: 1,
  },
];

const ResultDetail = () => {
  function formatKey(key) {
    const camelCaseKey = key.replace(/_([a-z])/g, (match, letter) => letter.toUpperCase());

    return camelCaseKey.charAt(0).toUpperCase() + camelCaseKey.slice(1).replace(/([A-Z])/g, " $1");
  }

  const handler = () => {};
  return (
    <div className="w-full">
      <h1 className="text-xl text-center font-semibold pb-4">Result Details</h1>
      <hr />
      <div className="mt-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mb-8 ">
        {Object.keys(studentDetails).map((key) => (
          <div key={key} className={`flex flex-wrap py-1`}>
            <h2 className="font-medium min-w-[36%]">{formatKey(key)} :</h2>
            <p className="text-start capitalize">{studentDetails[key]}</p>
          </div>
        ))}
      </div>
      {/* <hr /> */}
      <div className="mt-16">
        <div className="grid grid-cols-1 gap-4 sm:flex justify-between items-center py-2 mb-1">
          <h1 className="text-xl text-start font-semibold">Applicant List</h1>
          <div className="grid grid-cols-1 gap-y-2 px-2 justify-center items-center  sm:flex">
            <ButtonSwitch handler={handler} text="View All" active={false} css="sm:border-r-0 sm:rounded-r-none" />
            <ButtonSwitch handler={handler} text="Passed" active={true} css="sm:border-x-0 sm:rounded-none" />
            <ButtonSwitch handler={handler} text="Failed" active={true} css="sm:border-l-0 sm:rounded-l-none" />
          </div>
        </div>
        <ApplicantTable data={resultData} />
      </div>
    </div>
  );
};

export default ResultDetail;
