import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import InfoDevice from "../../assets/img/information.png";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  //   width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 4,
};

export default function DeviceHint({show, modalCleanup}) {
  const [open, setOpen] = useState(show);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    modalCleanup();
  };
  useEffect(() => {
    return () => {
      modalCleanup();
    };
  }, []);

  return (
    <>
      {open && (
        <div>
          {/* <Button onClick={handleOpen}>Open modal</Button> */}
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <h3 className="text-2xl text-center mb-4">Please check webcam and mic</h3>
              <img
                src={InfoDevice}
                alt="lksjd"
                className="max-h-[80vh] min-h-[60vh]"
              />
            </Box>
          </Modal>
        </div>
      )}
    </>
  );
}
