import McqForm from "./McqForm";
import { useFormik } from "formik";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { setToastMessage } from "../../../store/toast";
import { AddQuestionAPI } from "../../../services/helpers/Question";
import { initialValueMcq, validationSchemaMcq } from "../../../services/validate/AddQuestion";
import { Button } from "@mui/material";
import AddSharpIcon from "@mui/icons-material/AddSharp";
import RemoveIcon from '@mui/icons-material/Remove';
import { useNavigate, useParams } from "react-router-dom";
const McqQuestion = ({ questionType, examId, mcqQList, setMcqQList, sectionId }) => {
  // const {sectionId} = useParams()
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { mutate, isLoading } = useMutation(AddQuestionAPI, {
    mutationKey: "addMcqQuestion",
    onSuccess: (res) => {
      if (res?.status === true) {
        dispatch(
          setToastMessage({
            isActive: true,
            message: res?.message,
            type: "success",
          })
        );
        formik.resetForm();
        navigate(`/exams/${examId}/${sectionId}`)
      }
    },
    onError: () => { },
    onSettled: () => { },
  });
  const submitHandler = (values) => {
    if(sectionId){
      const newValues = {
        questionType: questionType,
        examId: examId,
        sectionId: sectionId,
        mcq: values.questions.map((question, index) => ({
          question: question[`question`],
          options: [
            { id: 1, title: question[`opt1`] },
            { id: 2, title: question[`opt2`] },
            { id: 3, title: question[`opt3`] },
            { id: 4, title: question[`opt4`] },
          ],
          correctAnswer: question[`answer`],
          time: 1,
          // marks: question[`marks`]
        })),
      };
      
      mutate(newValues);
    } else {
      alert('Please select section')
    }
    
  };
  const formik = useFormik({
    initialValues: {
      questions: Array.from({ length: mcqQList.length }, (_, index) => ({
        [`question`]: "",
        [`opt1`]: "",
        [`opt2`]: "",
        [`opt3`]: "",
        [`opt4`]: "",
        [`answer`]: "",
        [`time`]: "1",
        // ['marks']:"",
      })),
    },
    validationSchema: validationSchemaMcq,
    onSubmit: (values) => {
      submitHandler(values);
    },
  });

  console.log("formik correctAnswer --> ", formik)

  return (
    <div className="w-full mt-4">
      <div className="flex justify-end gap-4 ">
        <Button variant="contained" onClick={() => {
          setMcqQList((prevState) => {
            return [...prevState, prevState.length]
          })
          formik.values.questions.push({
            [`question`]: "",
            [`opt1`]: "",
            [`opt2`]: "",
            [`opt3`]: "",
            [`opt4`]: "",
            [`answer`]: "",
            [`time`]: "1",
            // ['marks']:"",
          })
        }}>Add Question <AddSharpIcon className="ml-2" /></Button>
        {mcqQList.length !== 1 && <Button variant="contained" onClick={() => {
          if (mcqQList.length !== 1) {
            let questions = formik.values.questions
            questions.pop()
            formik.values.questions = questions
            setMcqQList((prevState) => {
              prevState.pop()
              return [...prevState]
            })
          }
        }}>Remove Question <RemoveIcon className="ml-2"/></Button>}
        </div>
      <McqForm formik={formik} btnText="Add Question" isLoading={isLoading} setMcqQList={setMcqQList} mcqQList={mcqQList} />
    </div>
  );
};
export default McqQuestion;