import { getApiCall, putApiCall } from "../../utils/axios";
import { BASE_URL, API } from "../../utils/defines";

export const updateStudentAPI = async (paramsData) => {
  const url = `${BASE_URL}${API.UPDATE_STUDENT}`;
  try {
    const response = await putApiCall(url, paramsData);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};

export const STUDENTLISTAPI = async (paramsData) => {
  const url = `${BASE_URL}${API.STUDENTLISTALL}`;
  try {
    const response = await getApiCall(url, paramsData);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};
export const DIRECTSTUDENTLISTAPI = async (paramsData) => {
  const url = `${BASE_URL}${API.DIRECTSTUDENTLISTALL}`;
  try {
    const response = await getApiCall(url, paramsData);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};

export const STUDENTRESULTDETAIL = async (paramsData) => {
  const url = `${BASE_URL}${API.STUDENTDETAILSUBMITTED}`;
  try {
    const response = await getApiCall(url, paramsData);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};