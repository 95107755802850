import Table from "@mui/material/Table";
import { styled } from "@mui/material/styles";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import DarkButton from "../../common/CustomButtons/DarkButton";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },

  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const ExamDetailsTable = ({ questionTypeDetails, handleOpen, student = false }) => {
  console.log("questionDetails1", questionTypeDetails);
  const { fillInTheBlanksCount, mcqCount, questionAnswerCount, booleanCount } = questionTypeDetails;

  return (
    <TableContainer>
      <Table aria-label="customized table">
        <TableBody>
          <StyledTableRow>
            <StyledTableCell align="left">
              <strong>MCQ</strong>
            </StyledTableCell>
            <StyledTableCell align="left">
              <p className="flex capitalize">{mcqCount} Questions</p>
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell align="left">
              <strong>Fill in The Blanks</strong>
            </StyledTableCell>
            <StyledTableCell align="left">
              <p className="flex capitalize">{fillInTheBlanksCount} Queations</p>
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell align="left">
              <strong>Written</strong>
            </StyledTableCell>
            <StyledTableCell align="left">
              <p className="flex capitalize">{questionAnswerCount} Questions</p>
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell align="left">
              <strong>Boolean</strong>
            </StyledTableCell>
            <StyledTableCell align="left">
              <p className="flex capitalize">{booleanCount} Questions</p>
            </StyledTableCell>
          </StyledTableRow>
          {student && (
            <StyledTableRow>
              {/* <StyledTableCell> */}
              <DarkButton btnTitle="Assigned Student" handler={handleOpen} />
              {/* </StyledTableCell> */}
            </StyledTableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ExamDetailsTable;
