import React from "react";

const ExamHeadingCard = ({ title }) => {

  return (
    <div className="mb-2">
      <div className="flex bg-gray-200 px-2 py-1 rounded">
        <h2 className="text-2xl font-semibold drop-shadow-sm shadow-black">{title ==1 && 'MCQ' || title ==2 && 'Fill in the blank' || title ==3 && 'Written' || 'unknown'}</h2>
      </div>
    </div>
  );
};

export default ExamHeadingCard;
