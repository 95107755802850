import { useEffect, useState } from "react";
import { DarkButton } from "../../../components";
import { InputTextField, SelectInputField } from "../../../components/Forms";
import SelectMultipleInput from "../../../components/Forms/SelectMultipleInput";
import ModuleTypeCheck from "./ModuleTypeCheck";
import Cookies from "js-cookie";


let checkData2 = [
  {
      "id": "12",
      "Name": "General Reading",
      "Time": "60",
      checked: false,
  },
  {
      "id": "11",
      "Name": "Academic Reading",
      "Time": "60",
      checked: false,
  },

]

let checkData = [
  {
      "id": "1",
      "Name": "Reading Exam",
      "Time": "60",
      checked: false,
  },
  {
      "id": "2",
      "Name": "Writing Exam",
      "Time": "60",
      checked: false,
  },
  {
      "id": "3",
      "Name": "Listening Exam",
      "Time": "30",
      checked: false,
  },
  {
      "id": "4",
      "Name": "Speaking Exam",
      "Time": "15",
      checked: false,
  }
]

const ExamForm = ({ formik, isLoading, arr, data }) => {
  console.log("initialValueinitialValueinitialValue", data)
  // const minutes_arr = [];
  // const hour_arr = [];
  // for (var i = 0; i < 60; i++) {
  //   let obj = { label: i, option: i };
  //   minutes_arr.push(obj);
  // }
  // for (var i = 0; i <= 24; i++) {
  //   let obj = { label: i, option: i };
  //   hour_arr.push(obj);
  // }
  const [selectedExam, setSelectedExam] = useState([]);
  const [time,setTime] = useState(formik.values.duration);

  useEffect(()=>{
    findExamTime(data,selectedExam);
  },[selectedExam])
  const findExamTime = (examList, selectedExam) => {
    console.log("selectedExamselectedExamselectedExam", examList, selectedExam)
    let totalTime = 0;
    console.log(data,selectedExam)
    console.log("test")
    examList?.forEach(exam => {
      console.log(selectedExam.includes(String(exam.id)))
      if (selectedExam.includes(exam.id)) {
        totalTime += parseInt(exam.Time, 10);
      }
    });
  
    setTime(totalTime);
    formik.setFieldValue("duration", totalTime)
  };

  const minutesToHoursAndMinutes = (totalMinutes) => {
    const hours = Math.floor(totalMinutes / 60);
    const remainingMinutes = totalMinutes % 60;
  
    return { hours, minutes: remainingMinutes };

  };
  const { hours, minutes } = minutesToHoursAndMinutes(time);
 let institute = instituteList?.map((data) => {
  return {
    label: data.instituteName,
    option: data.institueId,
  };
});
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="grid grid-cols-1 gap-8">
        <div className="grid lg:grid-cols-2 gap-6 ">
          {/* <SelectMultipleInput
            flag={true}
            id="examType"
            label="Choose Module Type"
            exam={(selectedExams) => {
              formik.setFieldValue("examType", selectedExams);
              setSelectedExam(selectedExams);
            }}
            options={arr}
            formik={formik}
          /> */}
          {/* <SelectInputField flag={true} id="examType" label="Choose Exam Type" exam={setSelectedExam} options={arr} formik={formik} /> */}
          <div>
          <InputTextField type="text" id="examName" label="Exam Name" formik={formik} placeholder="Exam Name" />
          <div className="mt-6">
          {Cookies.get("id") == "super_admin" ? (
            <SelectInputField
            id="instituteId"
            label="Select Institute "
            defaultValue="Select"
            options={institute}
            formik={formik}
            />
            ) : null}
            </div>
            </div>
          <div>
          <p className="font-semibold my-2">Choose Module Type</p>
          <ModuleTypeCheck formik={formik} moduleType={checkData} readingType={checkData2} setSelectedExam={setSelectedExam} />
        {time ? <><p className="mt-4 ml-4">Exam Time -{hours} Hours {minutes} Minutes  </p></>:null}
        <p className="text-red-600 text-[13px] ml-6">{formik.touched["examType"] && formik.errors["examType"]}</p>
          </div>
        </div>
        
        {/* <div>
        <SelectInputField flag={true} id="min" label="Choose Exam Type" exam={setSelectedExam} options={arr} formik={formik} />
        </div> */}
        {/* <div>

          <p className="mb-2">Exam Duration*</p>
          <div className="grid lg:grid-cols-2 gap-2 ">
            <div className=""><SelectInputField flag={true} id="duration" label="Choose Minutes" exam={setSelectedExam} options={[...minutes_arr, { label: 60, option: 60 }].slice(1)} formik={formik} /></div>
            <div className=""><SelectInputField flag={true} id="second" label="Choose Seconds" exam={setSelectedExam} options={minutes_arr} formik={formik} /></div>
          </div>
         
        </div> */}
        {/* <InputTextField type="text" id="duration" label="Time (in min)" formik={formik} placeholder="Time (in min)" /> */}
        {/* <div>

          <p className="mb-2">Exam Timing*</p>
          <div className="flex justify-between gap-4 ">
            <div className="w-full"><SelectInputField flag={true} id="timingHour" label="Choose Hours" exam={setSelectedExam} options={hour_arr} formik={formik} /></div>
            <div className="w-full"><SelectInputField flag={true} id="timingMinute" label="Choose Minutes" exam={setSelectedExam} options={minutes_arr} formik={formik} /></div>
          </div>
        </div> */}
        {/* <div className="">
          <div className="block min-w-[200px]">

          <input type="date" id="examDate" label="Date" formik={formik} placeholder="Select Date" onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.examDate}
            //  className="border-2 border-gray-300 rounded-md p-2 focus:outline-none focus:border-blue-500"
            className={`min-w-[200px] border-2 border-gray-300 rounded-md p-2 focus:outline-none focus:border-blue-500 ${formik.touched.examDate && formik.errors.examDate ? 'border-red-500' : '' // Adjust border color for errors
          }`}
          />
          {formik.touched.examDate && formik.errors.examDate && (
            <p className="text-red-400 text-sm">{formik.errors.examDate}</p>
            )}
            </div>
        </div> */}
        {/* <div className="lg:w-6/12">
          <InputTextField type="number" id="totalMarks" label="Total band" formik={formik} placeholder="Total band" />
        </div> */}
      </div>
      <div className="mt-10  flex justify-center">
        <DarkButton type="submit" btnTitle="Submit" loading={isLoading} />
      </div>
    </form>
  );
};

const instituteList = [
  {
      "institueId": "05e7d82c-38f9-4664-9911-47a6069c7a53",
      "instituteName": "hp instititute"
  },
  {
      "institueId": "0aaab25c-81ee-4564-95c7-3bae1d89b023",
      "instituteName": "Ielts college "
  },
  {
      "institueId": "1dae325c-7d17-460b-8ccc-c24cd8adf847",
      "instituteName": "New Institute "
  },
  {
      "institueId": "2f69dcd0-84b7-4f16-a18f-147f8eabef29",
      "instituteName": "testing2"
  },
  {
      "institueId": "4102a30f-f5d4-4878-a165-c1f9d3c2ad90",
      "instituteName": "Noida Institute"
  },
  {
      "institueId": "45805e0f-b417-40b6-b603-a02d60a39bcc",
      "instituteName": "Testing cred college "
  },
  {
      "institueId": "47b391c5-a431-4c01-ad12-70e0585368a8",
      "instituteName": "Dheer Institute"
  },
  {
      "institueId": "59147747-92b2-4d32-856e-9264af2470f4",
      "instituteName": "test"
  },
  {
      "institueId": "7a51807a-ce8f-487d-8b0d-6a4ea5e6ed7b",
      "instituteName": "Ejjejrj"
  },
  {
      "institueId": "975a8366-fc7e-46eb-917d-ea1a720999da",
      "instituteName": "Patna Institute"
  },
  {
      "institueId": "9c1fe520-9b17-4b3f-9854-42e912ed370f",
      "instituteName": "BMR Inspire"
  },
  {
      "institueId": "a37107a5-b731-4280-bbbe-7591fedbfc46",
      "instituteName": "Test Instatitute 2024"
  },
  {
      "institueId": "c837d25a-df39-4b2c-9717-35cb71e9ff80",
      "instituteName": "jklljklsjf"
  },
  {
      "institueId": "ccfac1a3-42d5-4d34-88d1-ffa142257a13",
      "instituteName": "Tycho institute "
  },
  {
      "institueId": "da117322-c14a-4a9e-997d-452d03f68b71",
      "instituteName": "delhi"
  },
  {
      "institueId": "e3e4e634-7a99-478f-87d9-a72c1c5d335f",
      "instituteName": "edunext"
  },
  {
      "institueId": "e8a74e09-2bb9-4c77-a5e8-60f2549540cf",
      "instituteName": "New Institute "
  },
  {
      "institueId": "ea9e8086-f761-4110-8104-e9067ed96271",
      "instituteName": "Bits institute"
  },
  {
      "institueId": "fb7a555d-d67d-48b5-979e-16f157c37ee3",
      "instituteName": "BMR Inspire"
  },
  {
      "institueId": "fef7f8e2-f3ad-468c-81fb-6cb47ed74e35",
      "instituteName": "bh "
  }
]

export default ExamForm;
