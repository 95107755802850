import { ExamFormHeader, AnswerCardButton, ExamHeadingCard, ExamPagination, ReadingQuiz, CustomBreadcrums, QueChangerButton } from "../../../components";
import { useState } from "react";
import { useNavigate } from "react-router-dom"
import { MyTimer } from "../../../components/Timer";
import { StudentNav } from "../../../components/StudentNav";
const ReadingExam = () => {
  const [change, setChange] = useState(0)
  const navigate = useNavigate();
  const [running, setRunning] = useState(true)

  const time = new Date();
  time.setSeconds(time.getSeconds() + 50);
  if (!running) {
    return navigate("/writing-exam");

  }

  const breadcrumbb = [
    {
      id: 1,
      title: "Dashboard",
      link: "/dashboard",
    },
    {
      id: 1,
      title: "Reading Exam",
      link: "/reading-exam",
    },
  ];

  const examsTables = [];
  const currTime = new Date().toLocaleTimeString("en-US");
  return (
    <div className="md:ml-64">
      {/* <StudentNav /> */}
      <div className=" m-auto relative px-8 lg:flex gap-8 " >
        <div className=" flex justify-center  py-4 lg:w-10/12 ">
          <div className="bg-white p-4 rounded-lg shadow">
            <h2 className="text-2xl font-bold mb-4">Comprehension Passage</h2>
            <p className="mb-4 px-4 text-center">
              In the 16th century, an age of great marine and terrestrial exploration, Ferdinand Magellan led the first expedition to sail around the world. As a young Portuguese noble, he served the king of Portugal, but he became involved in the quagmire of political intrigue at court and lost the king’s favor. After he was dismissed from service by the king of Portugal, he offered to serve the future Emperor Charles V of Spain.

              A papal decree of 1493 had assigned all land in the New World west of 50 degrees W longitude to Spain and all the land east of that line to Portugal. Magellan offered to prove that the East Indies fell under Spanish authority. On September 20, 1519, Magellan set sail from Spain with five ships. More than a year later, one of these ships was exploring the topography of South America in search of a water route across the continent. This ship sank, but the remaining four ships searched along the southern peninsula of South America. Finally they found the passage they sought near 50 degrees S latitude. Magellan named this passage the Strait of All Saints, but today it is known as the Strait of Magellan.

              One ship deserted while in this passage and returned to Spain, so fewer sailors were privileged to gaze at that first panorama of the Pacific Ocean. Those who remained crossed the meridian now known as the International Date Line in the early spring of 1521 after 98 days on the Pacific Ocean. During those long days at sea, many of Magellan’s men died of starvation and disease.

              Later, Magellan became involved in an insular conflict in the Philippines and was killed in a tribal battle. Only one ship and 17 sailors under the command of the Basque navigator Elcano survived to complete the westward journey to Spain and thus prove once and for all that the world is round, with no precipice at the edge.
            </p>
            <p className="mb-4">
              Questions may follow the passage to assess comprehension.
            </p>
          </div>
        </div>
        <div className=" relative w-full px-2 py-4  ">


          {/* lower section */}
          <div className={`bg-white  lg:h-[60vh]  rounded-lg p-5  mb-10 ${examsTables.length > 2 && examsTables.length <= 4 ? "mb-40" : "" || examsTables.length <= 2 ? "mb-20" : ""}`}>
            <ExamFormHeader />
            <div className="w-full  mt-10">

              <div className="w-full    lg:pr-10">
                <MyTimer running={setRunning} expiryTimestamp={time} />
                <ExamHeadingCard title="Multiple Choice Questions" />
                <div className="flex flex-col justify-center">
                  <ReadingQuiz change={change} />
                  <div className="mt-4 ">
                    <QueChangerButton change={change} setChange={setChange} flag={true} />
                  </div>
                </div>
              </div>
              <div className="bg-slate-200   py-4 mt-4 w-full rounded-md">
                {/* <div>
                  <MyTimer expiryTimestamp={time} />
                </div> */}

                <div>
                  {/* <h2 className="text-center text-lg font-semibold">
                  Time: <span className="color-Primary">{currTime}</span>
                </h2> */}
                </div>
                <div className="">
                  <AnswerCardButton change={change} setChange={setChange} flag={true} />
                </div>
              </div>
            </div>
          </div>
          {/* <ExamPagination /> */}
        </div>
      </div>
    </div>
  );
};

export default ReadingExam;


// <div className={`bg-white  lg:h-[60vh] lg:w-10/12   rounded-lg p-5  mb-10 ${examsTables.length > 2 && examsTables.length <= 4 ? "mb-40" : "" || examsTables.length <= 2 ? "mb-20" : ""}`}>
//   <ExamFormHeader />
//   <div className="w-full  mt-10">
//     <div className="w-full  pr-10">
//       <MyTimer running={setRunning} expiryTimestamp={time} />
//       <ExamHeadingCard title="Writing Exam" />

//       <div className="flex flex-col justify-center"><WritingQuiz change={change} />
//         <div className="mt-20"><QueChangerButton change={change} setChange={setChange} flag={true} /></div>
//       </div>
//     </div>
//     <div className="bg-slate-200   py-4 mt-4 w-full rounded-md">
//       <div className="flex flex-col justify-between">
//         {/* <h2 className="text-center text-lg font-semibold">
//                 Time: <span className="color-Primary">{currTime}</span>
//               </h2> */}
//       </div>
//       <AnswerCardButton change={change} setChange={setChange} flag={true} />
//     </div>
//   </div>
// </div>