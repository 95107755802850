import { useDispatch } from "react-redux";
import { useState, Suspense, useEffect } from "react";
import { useFormik } from "formik";
import ReactHtmlParser from 'react-html-parser';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { AddCKEditor } from "../../../components";
import { useMutation, useQuery } from "react-query";
import { setToastMessage } from "../../../store/toast";
import { AddPrivacyPolicyPI, GetPrivacyPolicyAPI } from "../../../services/helpers/Dashboard";
import { AddInstructions, InstructionsDetails } from "../../../services/helpers/Instruction";
import { SelectInputField } from "../../../components/Forms";
import BreadcrumbsC from "../../../components/common/BreadcrumbsC";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import { HtmlOutlined } from "@mui/icons-material";
import { AddPolicy, GetPolicy } from "../../../services/helpers/policy";
import Policy from "@mui/icons-material/Policy";
import { CircularProgress } from "@mui/material";
const TermsCondition = () => {
    const [loading,setLoading] = useState(true);
    const [callapi, setCallApi] = useState(false);
    const [hasdata, setHasData] = useState(false);
    const [data, setData] = useState(false);
    const { id } = useParams();

    const [exam, setExam] = useState();
    // useEffect(() => {
    //     setCallApi(true)
    // }, [id])



    const initialValues =
    {
        examType: ""

    }

    const options = [

        {
            label: "Reading",
            option: "Reading",
        },
        {
            label: "Writing",
            option: "Writing",
        },
        {
            label: "Listening",
            option: "listening",
        },
        {
            label: "Speaking",
            option: "Speaking",
        }

    ];
    const [contentData, setContentData] = useState(null);
    console.log(contentData);
    const dispatch = useDispatch();
    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: (values) => {
            console.log(values)
        },
    });
    console.log(formik);
    const breadcrumbb = [
        {
            id: 1,
            title: "Dashboard",
            link: "/dashboard",
        },
        {
            id: 2,
            title: "Terms and Condition",
            link: "/exam instructions",
        },
    ];



    useQuery(
        ["GetPolicy"],
        () => {
            const queryParams = {
                type:"terms",
                addById: Cookies.get("userId")
            };
            const paramsData = {
                queryParams,
            };
            return GetPolicy(paramsData);
        },
        {
            retry: false,
            mutationKey: "allCaraTakerList",
            onSuccess: (res) => {
                console.log(res);
                if (res?.status === true) {
                    setData(res?.data);
                    setLoading(false);
                    setHasData(true);
                    console.log(res)
                    if (res?.data?.content) {
                        setContentData(res.data.content);
                    }
                } else {
                    setData(res?.data);
                    setLoading(false);
                    setHasData(true);
                    setContentData("");
                }
            },
        }
    );

    const { mutate, isLoading } = useMutation(AddPolicy, {
        mutationKey: "addpolicy",
        onSuccess: (data) => {
            if (data.status === true) {
                dispatch(
                    setToastMessage({
                        isActive: true,
                        message: data?.message,
                        type: "success",
                    })
                );
            }
        },
    });

    const { mutate: updateIns } = useMutation(AddPolicy, {
        mutationKey: "addpolicy",
        onSuccess: (data) => {
            if (data.status === true) {
                dispatch(
                    setToastMessage({
                        isActive: true,
                        message: data?.message,
                        type: "success",
                    })
                );
            }
        },
    });


    const submitHandle = () => {
        if (hasdata) {
            updateIns({ id: data?.id, content: contentData, addById: Cookies.get("userId"), type: "terms" });

        }
        else {
            mutate({ content: contentData, addById: Cookies.get("userId"), type: "terms" });
        }

    };

    return (
        <div className="relative px-2 sm:px-8 py-6 bg-[#F3F3F3] md:ml-64">

        {loading ? (
            <div className="flex justify-center min-h-[50vh] items-center">
                <CircularProgress className="mt-12" />
            </div>
        ) : <Suspense fallback={<div>Loading...</div>}>
            <AddCKEditor contentData={contentData} setContentData={setContentData} breadcrumbb={breadcrumbb} heading="Terms and Condition " submitHandle={submitHandle} />
        </Suspense>}
    </div>
    );
};

export default TermsCondition;