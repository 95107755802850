import SearchIcon from "@mui/icons-material/Search";
import Cookies from "js-cookie";
import { ButtonSwitch } from "../../components/common/ButtonC";
import BreadcrumbsC from "../../components/common/BreadcrumbsC";
import { CustomPagination, ResultsTable } from "../../components";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { useState } from "react";

const Results = () => {
  
  const handler = () => {};
  const breadcrumbb = [
    {
      id: 1,
      title: "Dashboard",
      link: "/dashboard",
    },
    {
      id: 1,
      title: "Results",
      link: "/message",
    },
  ];

  const data = [
    { id: 1, examName: "olympiad 2023", applyFor: "Study", country: "Astralia", appliedStudent: "152", appearedStudent: "132" },
    { id: 2, examName: "olympiad 2024", applyFor: "job", country: "Canada", appliedStudent: "200", appearedStudent: "167" },
    { id: 3, examName: "olympiad 2025", applyFor: "Migration", country: "US", appliedStudent: "500", appearedStudent: "431" },
  ];
  const examsTables = [];

  return (
    <div className={`relative  md:ml-64 px-8 py-4 bg-[#F3F3F3] `}>
      <div className="w-full">
        {/* <div className="flex justify-between items-center">
          <BreadcrumbsC data={breadcrumbb} />
         
        </div> */}
        <div className="flex justify-between items-center mt-4">
          <div className="flex">
            <ButtonSwitch handler={handler} text="View All" active={false} css="border-r-0 rounded-r-none rounded-b-none" />
            <ButtonSwitch handler={handler} text="Active" active={true} css="border-l-0 rounded-t-none rounded-l-none" />
          </div>
          <div className="flex items-center gap-4">
            <TextField
              id="outlined-start-adornment"
              className=""
              size="small"
              sx={{ backgroundColor: "white" }}
              placeholder="Search or Type Here"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton aria-label="toggle password visibility" edge="end">
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
      </div>

      {/* lower section */}
      <div className={`bg-white mt-8 rounded-lg p-5 mb-10 ${examsTables.length > 2 && examsTables.length <= 4 ? "mb-20" : "" || examsTables.length <= 2 ? "mb-20" : ""}`}>
        <div className="flex w-full">
          <ResultsTable data={data} />
        </div>
      </div>
      <CustomPagination data={data} flag={false} />
    </div>
  );
};

export default Results;
