import { useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { ExamDetailsAPI } from "../../../services/helpers/Users";
import { DashboadPagesLayout, LoaderSpiner } from "../../../components";

import UpdateExamForm from "./UpdateExamForm";

const UpdateExam = () => {
  const { id } = useParams();
  const [examDetails, setExamDetails] = useState(null);
  const [initialValue, setInitialValue] = useState(null);
  // const [examsId, setExamsId] = useState(null);

  useQuery(
    ["examdetail"],
    () => {
      const queryParams = {
        examId: id,
      };
      const paramsData = {
        queryParams,
      };
      return ExamDetailsAPI(paramsData);
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,

      mutationKey: "examdetail",
      enabled: true,
      onSuccess: (res) => {
        if (res?.status === true) {
          setExamDetails(res.data);
          console.log("res?.data?.durationres?.data?.durationres?.data?.duration", res?.data?.duration)
          // let spliting = res.data.duration
          // let [duration, second] = spliting.split(":")
          // setInitialValue({ duration: duration,second:second, examName: res.data.examName, examType: JSON.parse(res.data.examType), totalMarks: res.data.totalMarks });
          setInitialValue({ duration: res?.data?.duration, examName: res.data.examName, examType: JSON.parse(res.data.examType), totalMarks: res.data.totalMarks, instituteId: res?.data?.instituteId||"", });
        } else {
        }
      },
    }
  );

  return (
    <DashboadPagesLayout>
      <div className="w-full">
        <div className="p-8">
          <div className="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
            <h1 className="text-center font-semibold text-2xl mb-5 ">Update Exam</h1>

            {initialValue ? (
              <UpdateExamForm initialValue={initialValue} data={examDetails} examId={id} />
            ) : (
              <div className="w-full min-h-[50vh] flex justify-center items-center">
                <LoaderSpiner />
              </div>
            )}
          </div>
        </div>
      </div>
    </DashboadPagesLayout>
  );
};

export default UpdateExam;
