import Button from "@mui/material/Button";

const QueChangerButton = ({ change, setChange, last, examModuleList, selectedModule, ExamSubmitHandler, submitExam, isLoading, activeSection }) => {

  return (
    <div className="sm:flex justify-center mt-4 lg:mt-4 text-center sm:justify-between gap-4 items-center mb-6 xl:mb-0 flex-col">
      {/* {submitExam?.timerStatus !== 'stopped' && <p className="text-lg font-medium lg:text-base mt-0 sm:mt-4">
        Change Question : <span className="text-lg font-bold text-gray-700">{change + 1}</span>
      </p>} */}
      <div className="flex gap-4 justify-center sm:justify-end mt-4 sm:mt-0">
        {/* {change != 0 && */}
        {submitExam?.timerStatus !== 'stopped' &&
          <Button variant="contained" className={`!px-2 !py-1 ${change == 0 && "invisible"}`} onClick={() => {
            setChange(change - 1)
          }}>
            <span>
              Previous
            </span>
          </Button>
        }
        {/* // } */}
        {/* {(last - 1) != change &&  */}
        {submitExam?.timerStatus !== 'stopped' &&
          <Button disabled={isLoading} className={`!p-0 !py-1 ${(((examModuleList.length - 1) == selectedModule) && (last - 1) == change) ? ('!hidden') : (last - 1) == change && 'invisible'}`} variant="contained" onClick={() => {
            setChange(change + 1)
          }}>
            <span>
              Next
            </span>
          </Button>
        }
        {
          ((last - 1) == change && ((examModuleList[selectedModule]?.sections?.length - 1) == activeSection) || submitExam?.timerStatus == 'stopped') &&
          <Button className={`!p-0 !py-1 `} variant="contained" onClick={() => {
            ExamSubmitHandler("Listening");
          }}>
            <span>
              Submit
            </span>
          </Button>
        }
        {/* // } */}
        {/* {change !=0 && <DarkButton handler={()=>{
            setChange(change-1)
        }}  endIcon={<WestIcon style={{ fontSize: 'small' }} />} />} */}
        {/* {(last-1) != change && <DarkButton handler={()=>{
            setChange(change+1)
        }} endIcon={<EastIcon />} />} */}
      </div>
    </div>
  );
};

export default QueChangerButton;
