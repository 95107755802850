

  const MockUsers = [
    { username: "institute123@gmail.com", password: "institute123", role: "institute" },
    { username: "admin123@gmail.com", password: "admin123", role: "admin" },
    { username: "student123@gmail.com", password: "student123", role: "student" },
  ];

  export default MockUsers;

