import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { CircularProgress,Backdrop } from "@mui/material";

const DarkButton = ({ btnTitle, endIcon, handler, type, loading }) => {
  return (
    <Stack direction="row" spacing={2}>
      <Button variant="contained" onClick={handler} type={type} disabled={loading}>
        {loading && <Backdrop
                  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                  open={loading}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>}
        <span className={`${loading ? "invisible" : ""}`}>
          {btnTitle}
          {endIcon}
        </span>
      </Button>
    </Stack>
  );
};

export default DarkButton;
