import { useEffect, useState } from "react";
import { examSelectedId, examUpdateById } from "../../../../../store/exam";
import { useDispatch } from "react-redux";
import { Button } from "@mui/material";
import { examinationUpdateById, mediaQuestionSelected } from "../../../../../store/examination";

const ListeningQuestion = ({ change, selectedExam, time }) => {
    const [examD, setExamD] = useState()
    const dispatch = useDispatch()
    useEffect(() => {
        if (selectedExam.length > 0) {
            setExamD(selectedExam[change])
            dispatch(mediaQuestionSelected(selectedExam[change]))
            dispatch(examSelectedId(selectedExam[change].id))
            console.log("selectedExam[change] ==", selectedExam[change])
        }
    }, [change, selectedExam])

    const questionsAnswersHandle = (id, answer) => {
        // for both store dispatch exam and examination
        dispatch(examinationUpdateById({ id, answer }))
        dispatch(examUpdateById({ id, answer }))
    }

    const fillInBlankInputHandle = (id, answer) => {
        // for both store dispatch exam and examination
        dispatch(examinationUpdateById({ id, answer }))
        dispatch(examUpdateById({ id, answer }))
    }

    return (
        <>
            <ul className="">
                <li className="mt-2">
                    <div className="flex justify-end"><p>{time} min</p></div>
                    <div className="text-gray-500 flex mb-6">
                        <p className="mr-2 font-semibold text-gray-900">Ques.{change + 1}</p>
                        <p dangerouslySetInnerHTML={{__html: examD?.question}}></p>
                    </div>
                    <div className="mt-3">
                        {/* <RadioButtonExam questionsAnswersHandle={questionsAnswersHandle} id={examD.id} answers={examD.options} /> */}
                        {/* <Button
                            variant="contained"
                            className="mt-4 float-right"
                            onClick={() => {
                                questionsAnswersHandle(examD?.id, "selectedAnswer");
                            }}
                        >
                            Start
                        </Button> */}
                    </div>

                </li>

            </ul>
        </>
    );
};

export default ListeningQuestion;
