import { Box, Modal } from "@mui/material";
import React from "react";
import InstituteListCheckbox from "./InstituteListCheckbox";

const style = {
  bgcolor: "background.paper",
  height: "85vh",
  width: "80%",
};

const AssignExamInstitute = ({open, handleClose, examId}) => {
  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={style} className="min-w-[400px] top-[50%] left-[50%] absolute translate-x-[-50%] translate-y-[-50%] shadow-lg outline-none rounded p-4">
        <InstituteListCheckbox handleClose={handleClose} examId={examId} />
      </Box>
    </Modal>
  )
}

export default AssignExamInstitute