import { Alert, Portal, Snackbar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setToastMessage } from "../../../store/toast";


const CustomSnackBar = () => {
  const toast_data = useSelector((state) => state.toast_data);
  const dispatch = useDispatch();

  const handleClose = (_event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(
      setToastMessage({
        isActive: false,
        message: "",
      })
    );
  };

  return (
    <Portal>
    <Snackbar
      open={toast_data.isActive}
      autoHideDuration={4000}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Alert
        onClose={handleClose}
        severity={toast_data.type}
        sx={{ width: "100%" }}
      >
        {toast_data.message}
      </Alert>
    </Snackbar>
    </Portal>
  );
};
export default CustomSnackBar;
