import { getApiCall, postApiCall } from "../../utils/axios";
import { BASE_URL, API } from "../../utils/defines";

export const DashboardAPI = async (paramsData) => {
  const url = `${BASE_URL}${API.DASHBOARD}`;
  try {
    const response = await getApiCall(url, paramsData);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};

export const DashboardDetailsAPI = async (paramsData) => {
  const url = `${BASE_URL}${API.DASHBOARDDETAIL}`;
  try {
    const response = await getApiCall(url, paramsData);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};
export const GetTermsConditionAPI = async (paramsData) => {
  const url = `${BASE_URL}${API.TERMSCONDTIONDetail}`;
  try {
    const response = await getApiCall(url, paramsData);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};

export const AddTermsConditionAPI = async (payload, callback) => {
  const url = `${BASE_URL}${API.TERMSCONDTION}`;
  try {
    const response = await postApiCall(url, payload);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};

export const GetPrivacyPolicyAPI = async (paramsData) => {
  const url = `${BASE_URL}${API.PRIVACYPOLICYDetail}`;
  try {
    const response = await getApiCall(url, paramsData);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};

export const AddPrivacyPolicyPI = async (payload, callback) => {
  const url = `${BASE_URL}${API.PRIVACYPOLICY}`;
  try {
    const response = await postApiCall(url, payload);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};

export const AddFeedbackAPI = async (payload, callback) => {
  const url = `${BASE_URL}${API.FEEDBACKADD}`;
  try {
    const response = await postApiCall(url, payload);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};

export const FeedbackImageUploadAPI = async (payload, callback) => {
  const url = `${BASE_URL}${API.IMAGEUPLOAD}`;
  try {
    const response = await postApiCall(url, payload);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};
