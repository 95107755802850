import { useState,useEffect } from "react";
import { useQuery } from "react-query";
import StatusPills from "../common/StatusPills";
import { useNavigate, useParams } from "react-router-dom";
import DeleteModal from "../common/DeleteModal";
import SearchIcon from "@mui/icons-material/Search";
import AddSharpIcon from "@mui/icons-material/AddSharp";
import { InstituteStudentListHeading } from "../../mockData";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import TableHeading from "../common/Table/TableHeading";
import { CustomPagination, DarkButton, StudentTable } from "../../components";
import { Table, TableBody, TableRow, TableContainer, TableCell, MenuItem, Menu, Paper } from "@mui/material";
import { INSTITUTEUSERAPI, USERLISTAPI } from "../../services/helpers/Users";
import { CircularProgress, IconButton, InputAdornment, TextField } from "@mui/material";
import { setSelectedInstituteId } from "../../store/student";
import { useDispatch } from 'react-redux'

const InstituteStudentList = ({ instituteList }) => {
  const [stopList,setStopList] = useState(true);
  const[search,setSearch] = useState(null);
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [serial, setSerial] = useState(null);
  const [change, setChange] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isupdated, setIsupdated] = useState(false);
  const [paginationdata, setPaginationdata] = useState();
  const [searchflag,setSearchFlag] = useState(false);
  const dispatch = useDispatch()
  const {id} = useParams();

  const handleSearch = (e)=>{
    e.preventDefault();
      setSearchFlag(true)
  }

  const getStartingSerialNumber = () => {
    return setSerial((page - 1) * paginationdata?.numberPerPage + 1);
  };

  useEffect(() => {
    getStartingSerialNumber();
  }, [data]);

  useEffect(()=>{
     setStopList(true);
  },[isupdated])

  useEffect(()=>{
    if(search===""){
      setStopList(true)
    }

  },[search])

  useEffect(()=>{
    if(data.length===0){
      setStopList(true)

    }
  },[data])

  useQuery(
    ["USERLISTAPI", page, change, isupdated],
    () => {
      const queryParams = {
        institueId:id
      };
      const paramsData = {
        queryParams,
      };
      return INSTITUTEUSERAPI(paramsData);
    },
    {
      retry: false,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: 0,
      mutationKey: "USERLISTAPI",
      enabled: stopList,
      onSuccess: (res) => {
        setLoading(false);
        if (res?.status === true) {
          console.log(res);
          setChange(false);
          setIsupdated(false);
          setData(res?.data?.userList);
          setPaginationdata(res?.data.pagination);
        } else {
        }
      },
    }
  );

  // useQuery(
  //   ["USERLISTAPI", page, change, isupdated],
  //   () => {
  //     const queryParams = {
  //       pageNum: page,
  //       role: "student",
  //       search:search
        
  //     };
  //     const paramsData = {
  //       queryParams,
  //     };
  //     return USERSEARCH(paramsData);
  //   },
  //   {
  //     retry: false,
  //     keepPreviousData: true,
  //     refetchOnWindowFocus: false,
  //     staleTime: 0,
  //     mutationKey: "USERLISTAPI",
  //     enabled: searchflag,
  //     onSuccess: (res) => {
  //       setLoading(false);
  //       if (res?.status === true) {
  //         setStopList(false);
  //         setSearchFlag(false)
  //         console.log(res);
  //         setChange(false);
  //         setIsupdated(false);
  //         setData(res?.data?.userList);
  //         setPaginationdata(res?.data.pagination);
  //       } else {
  //       }
  //     },
  //   }
  // );

  const navigate = useNavigate();
  const handler = () => {
    dispatch(setSelectedInstituteId({id:id}))
    navigate("/student/add");
  };
  const breadcrumbb = [
    {
      id: 1,
      title: "Dashboard",
      link: "/dashboard",
    },
    {
      id: 1,
      title: "Students",
      link: "/students",
    },
  ];

  const examsTables = [];
  return (
    <div className="relative px-8 py-4 bg-[#F3F3F3] ">
      <div className="w-full">
        {/* <div className="flex justify-between items-center">
          <BreadcrumbsC data={breadcrumbb} />
        </div> */}
        <div className="flex flex-col-reverse sm:grid sm:grid-cols-2 justify-center sm:justify-between items-center mt-8 gap-5">
          <div className="flex items-center w-full gap-4">
            {/* <form onSubmit={(e)=>{
                handleSearch(e);
            }}>
            <TextField
              id="outlined-start-adornment"
              className="w-full sm:w-auto"
              size="small"
              sx={{ backgroundColor: "white" }}
              placeholder="Search or Type Here"
              onChange={(e)=>{
                setSearch(e.target.value);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton aria-label="toggle password visibility" edge="end">
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            </form> */}
          </div>
          <div className="flex w-full sm:justify-end">
            <DarkButton btnTitle="Add Student" endIcon={<AddSharpIcon />} handler={handler} />
          </div>
        </div>
      </div>

      {/* lower section */}
      <div
        className={`bg-white mt-4 rounded-lg p-5 min-h-[50vh] mb-10 ${examsTables.length > 2 && examsTables.length <= 4 ? "mb-20" : "" || examsTables.length <= 2 ? "mb-20" : ""}`}
      >
        {loading ? (
          <div className="flex justify-center items-center min-h-[50vh]">
            <CircularProgress className="mt-12 " />
          </div>
        ) : (
          <div className="flex w-full">
            <StudentTable data={data} serial={serial} setChange={setChange} isupdated={isupdated} setIsupdated={setIsupdated} />
          </div>
        )}
      </div>
      {/* <CustomPagination page={page} setPage={setPage} paginationData={paginationdata} /> */}
    </div>
  );
};

export default InstituteStudentList;



