import React from "react";
import BreadcrumbsC from "../../components/common/BreadcrumbsC";
import { useState } from "react";
import StudentResultListTab from "../../components/Students/StudentResultListTab";
import { useParams } from "react-router-dom";
import { STUDENTRESULTDETAIL } from "../../services/helpers/Student";
import { useQuery } from "react-query";
const StudentResultList = () => {
  const [result, setResult] = useState();
  const breadcrumbb = [
    {
      id: 1,
      title: "Dashboard",
      link: "/dashboard",
    },
    {
      id: 2,
      title: "Students",
      link: "/students",
    },
    {
      id: 3,
      title: "Result Details",
      link: "/students",
    },
  ];

  const { resultId } = useParams();

  useQuery(
    ["StudentResultDetail"],
    () => {
      const queryParams = {
        submitExamId: resultId,
      };
      const paramsData = {
        queryParams,
      };
      return STUDENTRESULTDETAIL(paramsData);
    },
    {
      retry: false,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: 0,
      mutationKey: "StudentResultDetail",
      enabled: true,
      onSuccess: (res) => {
        console.log("resposeno ", res);
        setResult(res?.data);
      },
    }
  );

  return (
    <div className="relative px-8 py-4 bg-[#F3F3F3] md:ml-64">
      <div className="w-full">
        <div className="flex justify-between items-center">
          <BreadcrumbsC data={breadcrumbb} />
        </div>
        <div className={`bg-white mt-4 rounded-lg p-5 min-h-[50vh] mb-10`}>
          <h2 className="mb-4 text-center font-medium text-2xl">Result Detail</h2>
          {result?.map((module) => {
            return (
              <>
                <h3 className="text-center">Exam Name: {module?.examName}</h3>
                <h3 className="text-center">Module Name: {module?.sectionName}</h3>
                <h3 className="text-center">Section Name: {module?.sectionName}</h3>
                <div className="border mb-8 mt-4">
                  <StudentResultListTab questions={module.questions} />
                </div>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default StudentResultList;
