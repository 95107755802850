import React, { useState } from "react";
// import { CameraAndMicComponent } from "../../components/Cameratest";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { examAttemptUpdate, examRefresh } from "../../store/exam";
import { Button } from "@mui/material";
import Cookies from "js-cookie";
import AppSideBarTest from "../../components/common/AppSideBar/AppSideBarTest";
import { DashboadPagesLayout } from "../../components";
import DeviceMediaTest from "../../components/DeviceMediaTest";
import RecordView from "../Videotest";
import { AudioTest } from "../../components/Audiotest";
import VideoTesting from "../Videotest/VideoTesting";
import { AudioTesting } from "../../components/Audiotest/AudioTesting";
import { CameraAndMicComponent } from "../../components/Cameratest/CameraAndMicComponent";
import AppSideBarExam from "../../components/common/AppSideBar/AppSideBarExam";

const initialStartExam = {
  startExam: false,
  showMediaAudio: false,
  showMediaVideo: false,
  showMedia: false,
  showWritten: false,
  mediaEnabled: false,
};

const DeviceTest = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [enableStartExam, setEnableStartExam] = useState(initialStartExam);
  const attemptExamHandler = () => {
    // dispatch(
    //   examAttemptUpdate({
    //     examId: Cookies.get("sExamId"),
    //     scheduleId: Cookies.get("sScheduleId"),
    //   })
    // );
    dispatch(examRefresh(true));
    setTimeout(() => {
      navigate(`/examination/${Cookies.get("sExamId")}`);
    }, 200);
  };
  return (
    <div>
      <AppSideBarExam type="dashboard"  />
      {/* <AppSideBarTest /> */}

      <DashboadPagesLayout>
        <div className="w-full">
          <div className="w-full relative px-8 py-4  bg-[#fff] min-h-[60px] ">
            <h3 className="text-center text-2xl ">
              <span className="font-semibold"></span>
            </h3>
          </div>
          <div className="relative px-8 py-4 lg:flex-row flex-col flex bg-[#F3F3F3] min-h-[80vh] gap-8">
            <div className="flex justify-around items-center min-h-[80vh] w-full bg-[#fff] flex-col mt-4 py-8">
              {/* <RecordView /> */}
              {/* <AudioTesting /> */}
              <CameraAndMicComponent />
              {/* <AudioTest /> */}
              {/* <DeviceMediaTest /> */}
              {/* <CameraAndMicComponent
                setEnableStartExam={setEnableStartExam}
              /> */}
              {/* <Button
                className="!mt-4"
                variant="contained"
                onClick={() => attemptExamHandler()}
              >
                Start Exam
              </Button> */}
            </div>
          </div>
        </div>
      </DashboadPagesLayout>
    </div>
  );
};

export default DeviceTest;
