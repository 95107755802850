import { useDispatch } from "react-redux";
import { useState, Suspense, useEffect } from "react";
import { useFormik } from "formik";
import ReactHtmlParser from 'react-html-parser';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { AddCKEditor } from "../../../components";
import { useMutation, useQuery } from "react-query";
import { setToastMessage } from "../../../store/toast";
import { AddPrivacyPolicyPI, GetPrivacyPolicyAPI } from "../../../services/helpers/Dashboard";
import { AddInstructions, InstructionsDetails } from "../../../services/helpers/Instruction";
import { SelectInputField } from "../../../components/Forms";
import BreadcrumbsC from "../../../components/common/BreadcrumbsC";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import { HtmlOutlined } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";

const InstructionDetails = () => {
    const [callapi, setCallApi] = useState(false);
    const [hasdata, setHasData] = useState(false);
    const [data, setData] = useState(false);
    const [loading ,setLoading ] = useState(true);
    const { id } = useParams();

    const [exam, setExam] = useState();
    useEffect(() => {
        setCallApi(true)
    }, [id])



    const initialValues =
    {
        examType: ""

    }

    const options = [

        {
            label: "Reading",
            option: "Reading",
        },
        {
            label: "Writing",
            option: "Writing",
        },
        {
            label: "Listening",
            option: "listening",
        },
        {
            label: "Speaking",
            option: "Speaking",
        }

    ];
    const [contentData, setContentData] = useState(null);
    console.log(contentData);
    const dispatch = useDispatch();
    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: (values) => {
            console.log(values)
        },
    });
    console.log(formik);
    const breadcrumbb = [
        {
            id: 1,
            title: "Dashboard",
            link: "/dashboard",
        },
        {
            id: 2,
            title: "Exam Instructions",
            link: "/exam instructions",
        },
    ];



    useQuery(
        ["InstructionDetail"],
        () => {
            const queryParams = {
                examTypeId: id,
                addById: Cookies.get("userId")

            };
            const paramsData = {
                queryParams,
            };
            return InstructionsDetails(paramsData);
        },
        {
            retry: false,
            // keepPreviousData: false,
            // refetchOnWindowFocus: false,
            // staleTime: 444440,
            mutationKey: "allCaraTakerList",
            // enabled: callapi,
            onSuccess: (res) => {
                console.log(res);
                setLoading(false);
                if (res?.status === true) {
                    setData(res?.data);
                    setCallApi(false);
                    setHasData(true);
                    console.log(res)
                    if (res?.data?.content) {
                        var htmlString = res?.data?.content;
                        const doc = new DOMParser().parseFromString(htmlString, 'text/html');
                        const newText = doc.body.textContent || '';
                        console.log(newText);

                        //    const htmlContent = ReactHtmlParser(res?.data?.content);
                        //    console.log(htmlContent);

                        // const tempElement = document.createElement('div');
                        // tempElement.innerHTML = res?.data?.content;
                        // <div dangerouslySetInnerHTML={{ __html: htmlContent }}></div>

                        // // Extract the text content
                        // const textContent = tempElement.textContent || tempElement.innerText;
                        // console.log(textContent);

                        setContentData(res.data.content);
                    }
                } else {
                }
            },
        }
    );

    const { mutate, isLoading } = useMutation(AddInstructions, {
        mutationKey: "AddInstruction",
        onSuccess: (data) => {
            if (data.status === true) {
                dispatch(
                    setToastMessage({
                        isActive: true,
                        message: data?.message,
                        type: "success",
                    })
                );
            }
        },
    });

    const { mutate: updateIns } = useMutation(AddInstructions, {
        mutationKey: "addInstructions",
        onSuccess: (data) => {
            if (data.status === true) {
                dispatch(
                    setToastMessage({
                        isActive: true,
                        message: data?.message,
                        type: "success",
                    })
                );
            }
        },
    });


    const submitHandle = () => {
        if (hasdata) {
            updateIns({ id: data?.id, content: contentData });

        }
        else {
            mutate({ content: contentData, addById: Cookies.get("userId"), examTypeId: id });
        }

    };

    return (
        <div className="relative px-2 sm:px-8 py-6 bg-[#F3F3F3] md:ml-64">
            {/* <div className="flex justify-between items-center">
                <BreadcrumbsC data={breadcrumbb} />
            </div> */}

            {/* <div class="[&>*:nth-child(odd)]:bg-gray-300 [&>*:nth-child(even)]:bg-blue-500  text-center items-center flex flex-col justify-center items ">
                <div className="h-16 text-2xl   w-full flex justify-around">
                    <div className="flex flex-col justify-center  ">Reading Exam</div>
                    <div className="flex flex-col justify-center"><ArrowForwardIcon style={{ cursor: "pointer" }} /></div>
                </div>
                <div className="h-16 text-2xl   w-full flex justify-around">
                    <div className="flex flex-col justify-center text-white  ">Writing Exam</div>
                    <div className="flex flex-col justify-center"><ArrowForwardIcon style={{ cursor: "pointer" }} /></div>
                </div>

                <div className="h-16 text-2xl   w-full flex justify-around">
                    <div className="flex flex-col justify-center  ">Listening Exam</div>
                    <div className="flex flex-col justify-center"><ArrowForwardIcon style={{ cursor: "pointer" }} /></div>
                </div>

                <div className="h-16 text-2xl   w-full flex justify-around">
                    <div className="flex flex-col justify-center text-white  ">Speaking Exam</div>
                    <div className="flex flex-col justify-center"><ArrowForwardIcon style={{ cursor: "pointer" }} /></div>
                </div>


            </div> */}
{loading ?(
            <div className="flex justify-center min-h-[50vh] items-center">
            <CircularProgress className="mt-12" />
          </div>
           ):
            <Suspense fallback={<div>Loading...</div>}>
                <AddCKEditor contentData={contentData} setContentData={setContentData} breadcrumbb={breadcrumbb} heading="Exam Instructions" submitHandle={submitHandle} />
            </Suspense>
}




        </div>
    );
};

export default InstructionDetails;