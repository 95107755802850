import {
  AnswerCardButton,
  ExamHeadingCard,
  QueChangerButton,
  NeonReadingQuiz,
  ExamFormHeader,
} from "../../../components";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Button from "@mui/material/Button";
import { SpeakerCard } from "../../../components";
import RecordView from "../../Videotest";
import VideoRecordExam from "./VideoRecordExam";
import AudioRecordExam from "./AudioRecordExam";

const ExamQuestionList = ({
  listQ,
  examModuleList,
  selectedModule,
  ExamSubmitHandler,
  submitExam,
  isLoading,
  examType,
  activeSection
}) => {
  console.log("listQlistQ",listQ)
  const [selectedExam, setSelecteExam] = useState(listQ);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const examState = useSelector((state) => state.exam);

  console.log("lskjdlfkjsldf",listQ,
  examModuleList,
  selectedModule,
  ExamSubmitHandler,
  submitExam,
  isLoading,
  examType,)

  const paginationHandler = (value) => {
    if (value >= 0 && listQ.length > value) {
      let findout = examState?.questions?.find(
        (quest) => quest.id === examState.questionSelectedId
      );
      //   if (findout) {
      //     if (findout?.answer) {
      //       setCurrentQuestion(value);
      //     } else {
      //       alert("Please enter answer");
      //     }
      //   } else {
      setCurrentQuestion(value);
      //   }
    }
  };

  useEffect(() => {
    setSelecteExam(listQ);
    setCurrentQuestion(0);
  }, [listQ]);

  return (
    <>
      {(examType == 1 || examType == 2) && (
        <div className="w-full mt-3">
          <ExamFormHeader />
          <div className="w-full ">
            <ExamHeadingCard title={listQ[currentQuestion]?.questionTypeId} />
            <div className="flex flex-col justify-center">
              <NeonReadingQuiz
                change={currentQuestion}
                selectedExam={selectedExam}
                setSelecteExam={setSelecteExam}
                time={listQ[currentQuestion]?.time}
              />
            </div>
          </div>
        </div>
      )}
      {examType == 4 && (
        <div className="w-full mt-3">
          <ExamFormHeader />
          <div className="w-full ">
            <ExamHeadingCard title={listQ[currentQuestion]?.questionTypeId} />
            <div className="flex flex-col justify-center">
              <VideoRecordExam change={currentQuestion}
                selectedExam={selectedExam}
                setSelecteExam={setSelecteExam}
                time={listQ[currentQuestion]?.time}
              />
            </div>
          </div>
        </div>
      )}
      {examType == 3 && (
        <>
        <div className="w-full mt-3">
          <ExamFormHeader />
          <div className="w-full ">
            <ExamHeadingCard title={listQ[currentQuestion]?.questionTypeId} />
            <div className="flex flex-col justify-center">
              <AudioRecordExam change={currentQuestion}
                selectedExam={selectedExam}
                setSelecteExam={setSelecteExam}
                time={listQ[currentQuestion]?.time}
              />
            </div>
          </div>
        </div>
          {/* <SpeakerCard /> */}
        </>
      )}
      <div>
        <div>
          <div className="mt-4 ">
            <QueChangerButton
              change={currentQuestion}
              setChange={paginationHandler}
              last={listQ.length}
              examModuleList={examModuleList}
              selectedModule={selectedModule}
              ExamSubmitHandler={ExamSubmitHandler}
              submitExam={submitExam}
              isLoading={isLoading}
              activeSection={activeSection}
            />
          </div>
          <div>
            {/* {
              (listQ.length - 1) == currentQuestion &&
              <Button size="small" className={`!p-0 !py-1 `} variant="outlined" onClick={() => {
                console.log("lksjdflkjlsdf")
              }}>
                <span>
                  Submit
                </span>
              </Button>
            } */}
          </div>
          {submitExam?.timerStatus !== "stopped" && (
            <div className="bg-slate-200 flex justify-evenly lg:mt-0 pt-4 xl:mt-4 py-4 mt-4 w-full rounded-md">
              <div className="">
                <AnswerCardButton
                  paginationLength={listQ.length}
                  change={currentQuestion}
                  setChange={paginationHandler}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ExamQuestionList;
