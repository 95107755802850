const AnswerCardButton = ({setChange,change,paginationLength}) => {
  // const list = [1, 2, 3, 4, 5, 6, 7, 8];
  const list = Array.from({ length: paginationLength }, (value, index) => index + 1);

  let n = paginationLength;

  return (
    <div className="flex justify-center">
      <div className="flex  flex-wrap gap-2 lg:gap-2 px-2  xl:grid-cols-8   lg:grid-cols-6">
        {list.splice(0, n).map((i) => (
          <button
          onClick={()=>{
              setChange(i-1)
          }}
            key={i}
            style={{backgroundColor:change == i-1?"#2962ff":"white",color:change == i-1?"white":"black"}}
            className="bg-white  w-6 h-6 sm:w-7 sm:h-7 md:w-7 md:h-7    lg:w-7  lg:h-7   xl:w-8 xl:h-8 flex justify-center items-center rounded text-l sm:text-xl xl:text-xl shadow-xl drop-shadow-xl font-medium hover:text-white hover: transition-all duration-300 cursor-pointer"
          >
            {i}
          </button>
        ))}
      </div>
    </div>
  );
};

export default AnswerCardButton;
