import Cookies from "js-cookie";
import { useFormik } from "formik";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setToastMessage } from "../../../store/toast";
import { DashboadPagesLayout } from "../../../components";
import { CircularProgress, TextareaAutosize, Button } from "@mui/material";
import { addStudentFeedbackAPI } from "../../../services/helpers/Feedback";
import AppSideBarTest from "../../../components/common/AppSideBar/AppSideBarTest";
import { initialValuesNewFeedback as initialValue, validationSchemaNewFeedback as validationSchema } from "../../../services/validate/AddFeedback";
import AppSideBarExam from "../../../components/common/AppSideBar/AppSideBarExam";

const AddFeedback = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/test");
  };

  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      submitHandler(values);
    },
  });

  const { mutate, isLoading } = useMutation(addStudentFeedbackAPI, {
    mutationKey: "addFeedback",
    onSuccess: (data) => {
      if (data.status === true) {
        dispatch(
          setToastMessage({
            isActive: true,
            message: data?.message,
            type: "success",
          })
        );
        setTimeout(() => {
          handleNavigate();
        }, 2000);
      }
    },
  });

  const submitHandler = (values) => {
    const newValues = {
      content: values.feedback,
      studentId: Cookies.get("userId"),
    };

    mutate(newValues);
  };

  return (
    <>
      {/* <AppSideBarTest /> */}
      <AppSideBarExam type="dashboard"  />
      <DashboadPagesLayout>
        <div className="w-full">
          <div className="w-full relative px-8 py-4  bg-[#fff] min-h-[60px] ">
            <h3 className="text-center text-2xl ">
              <span className="font-semibold"></span>
            </h3>
          </div>
          <div className={`flex justify-center items-start w-full p-12`}>
            <div className={`bg-[#fff] rounded-lg w-full flex justify-center items-start min-h-[80vh]`}>
              <div className={`rounded-lg p-5 w-[700px] min-w-[300px]`}>
                <h2 className="text-xl text-center font-semibold mt-5 mb-12">Add Feedback</h2>
                <form onSubmit={formik.handleSubmit}>
                  <TextareaAutosize
                    name="feedback"
                    minRows={6}
                    variant="red"
                    onChange={formik.handleChange}
                    value={formik.values.feedback}
                    placeholder="Type Your Feedback..."
                    className="w-full bg-gray-200 rounded-lg text-dark px-5 py-4 border-none outline-none"
                  />
                  <div>{formik.touched && formik.errors && <p className="text-red-500 text-sm pl-4">{formik.errors.feedback}</p>}</div>
                  <div className="w-full flex justify-center items-center my-12">
                    <Button size="small" variant="contained" type="submit" className="w-[200px] h-[38px]">
                      {isLoading ? <CircularProgress size="1rem" color="inherit" /> : <span className="text-[16px]">Submit Feedback</span>}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </DashboadPagesLayout>
    </>
  );
};

export default AddFeedback;
