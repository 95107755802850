import { postApiCall, getApiCall, deleteApiCall, putApiCall } from "../../utils/axios";
import { BASE_URL, API } from "../../utils/defines";
import { store } from "../../../store";
import { profileUpdate } from "../../../store/profile";

export const LoginAPI = async (payload) => {
  const url = `${BASE_URL}${API.LOGIN}`;
  try {
    const response = await postApiCall(url, payload);
    return response;
  } catch (err) {
    throw new Error(err?.data?.error?.message);
  }
};

export const ForgotAPI = async (payload) => {
  const url = `${BASE_URL}${API.FORGOT}`;
  try {
    const response = await postApiCall(url, payload);
    return response;
  } catch (err) {
    throw new Error(err?.data?.error?.message);
  }
};

export const ResetAPI = async (payload) => {
  const url = `${BASE_URL}${API.RESET}`;
  try {
    const response = await postApiCall(url, payload);
    return response;
  } catch (err) {
    throw new Error(err?.data?.error?.message);
  }
};

export const SIGNUP_API = async (payload) => {
  const url = `${BASE_URL}${API.SIGNUP}`;
  try {
    const response = await postApiCall(url, payload);
    return response;
  } catch (err) {
    throw new Error(err?.data?.error?.message);
  }
};

export const UserProfile = async (paramsData) => {
  const url = `${BASE_URL}${API.USER_DETAILS}`;
  store.dispatch(profileUpdate({ isLoading: true }));

  try {
    const response = await getApiCall(url, paramsData);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};

export const AddStudent = async (payload) => {
  const url = `${BASE_URL}${API.SIGNUP}`;
  try {
    const response = await postApiCall(url, payload);
    return response;
  } catch (err) {
    throw new Error(err?.data?.error?.message);
  }
};
export const AddInstitute = async (payload) => {
  const url = `${BASE_URL}${API.ADDINSTITUTE}`;
  try {
    const response = await postApiCall(url, payload);
    return response;
  } catch (err) {
    throw new Error(err?.data?.error?.message);
  }
};

export const AddSection = async (payload) => {
  const url = `${BASE_URL}${API.ADDSECTION}`;
  try {
    const response = await postApiCall(url, payload);
    return response;
  } catch (err) {
    throw new Error(err?.data?.error?.message);
  }
};

export const UploadSection = async (payload) => {
  const url = `${BASE_URL}${API.UPLOADSECTION}`;
  try {
    const response = await postApiCall(url, payload);
    return response;
  } catch (err) {
    throw new Error(err?.data?.error?.message);
  }
};
export const UpdateSection = async (payload) => {
  const url = `${BASE_URL}${API.UPDATESECTION}`;
  try {
    const response = await putApiCall(url, payload);
    return response;
  } catch (err) {
    throw new Error(err?.data?.error?.message);
  }
};

export const AddExamAPI = async (payload) => {
  const url = `${BASE_URL}${API.ADDEXAM}`;
  try {
    const response = await postApiCall(url, payload);
    return response;
  } catch (err) {
    throw new Error(err?.data?.error?.message);
  }
};
export const uploadImage = async (payload) => {
  const url = `${BASE_URL}${API.UPLOADIMAGE}`;
  try {
    const response = await postApiCall(url, payload);
    return response;
  } catch (err) {
    throw new Error(err?.data?.error?.message);
  }
};

export const USERLISTAPI = async (paramsData) => {
  const url = `${BASE_URL}${API.STUDENTLIST}`;
  try {
    const response = await getApiCall(url, paramsData);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};
export const INSTITUTEUSERAPI = async (paramsData) => {
  const url = `${BASE_URL}${API.INSTITUTEUSERAPI}`;
  try {
    const response = await getApiCall(url, paramsData);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};
export const USERSEARCH = async (paramsData) => {
  const url = `${BASE_URL}${API.USERSEARCH}`;
  try {
    const response = await getApiCall(url, paramsData);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};

export const MASTERLISTAPI = async (paramsData) => {
  const url = `${BASE_URL}${API.MASTERLISTAPI}`;
  try {
    const response = await getApiCall(url, paramsData);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};
export const STUDENTDETAILSAPI = async (paramsData) => {
  const url = `${BASE_URL}${API.STUDENTDETAILSAPI}`;
  try {
    const response = await getApiCall(url, paramsData);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};
export const SelectInstituteAPI = async (paramsData) => {
  const url = `${BASE_URL}${API.SELECTLIST}`;
  try {
    const response = await getApiCall(url, paramsData);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};
export const ExamDetailsAPI = async (paramsData) => {
  const url = `${BASE_URL}${API.EXAMDETAILS}`;
  try {
    const response = await getApiCall(url, paramsData);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};

export const SECTIONDETAILSAPI = async (paramsData) => {
  const url = `${BASE_URL}${API.SECTIONDETAILS}`;
  try {
    const response = await getApiCall(url, paramsData);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};

export const deleteStudentAPI = async (userid) => {
  const url = `${BASE_URL}${API.USERDELETE}?userId=${userid}`;
  try {
    const response = await deleteApiCall(url);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};

export const deleteAssignStudentAPI = async (userid) => {
  const url = `${BASE_URL}${API.REMOVEASSIGNSTUDENT}?assignId=${userid}`;
  try {
    const response = await deleteApiCall(url);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};

export const deleteInstituteAPI = async (instituteid) => {
  const url = `${BASE_URL}${API.INSTITUTEDELETE}?instituteId=${instituteid}`;
  try {
    const response = await deleteApiCall(url);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};
export const deleteExamAPI = async (userid) => {
  const url = `${BASE_URL}${API.EXAMDELETE}?id=${userid}`;
  try {
    const response = await deleteApiCall(url);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};

export const deleteSectionAPI = async (id) => {
  const url = `${BASE_URL}${API.SECTIONDELETE}?sectionId=${id}`;
  try {
    const response = await deleteApiCall(url);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};



// export const StudentExamStatusAPI = async (paramsData) => {
//   const url = `${BASE_URL}${API.UPDATEEXAM}`;
//   try {
//     const response = await putApiCall(url,paramsData);
//     return response;
//   } catch ({ response }) {
//     throw new Error(response?.data?.error?.message);
//   }
// };

export const StudentExamStatusAPI = async (payload) => {
  const url = `${BASE_URL}${API.UPDATEEXAM}`;
  try {
    const response = await postApiCall(url, payload);
    return response;
  } catch (err) {
    throw new Error(err?.data?.error?.message);
  }
};

export const ExamStatusAPI = async (payload) => {
  const url = `${BASE_URL}${API.UPDATEINSTITUTESTATUS}`;
  try {
    const response = await postApiCall(url, payload);
    return response;
  } catch (err) {
    throw new Error(err?.data?.error?.message);
  }
};

export const InstituteStatusAPI = async (payload) => {
  const url = `${BASE_URL}${API.INSTITUTEUPDATESTATUS}`;
  try {
    const response = await postApiCall(url, payload);
    return response;
  } catch (err) {
    throw new Error(err?.data?.error?.message);
  }
};


export const SECTIONLIST = async (paramsData) => {
  const url = `${BASE_URL}${API.SECTIONLIST}`;
  try {
    const response = await getApiCall(url, paramsData);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};


export const SECTIONDETAIL = async (paramsData) => {
  const url = `${BASE_URL}${API.SECTIONDETAIL}`;
  try {
    const response = await getApiCall(url, paramsData);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};







