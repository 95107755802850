import React from "react";
import { AudioCard } from "../../../../components";
import ReadingCard from "../../../../components/common/ExamForm/ReadingCard";

const ExamType = ({ examDetails }) => {
  console.log("examDetailsexamDetails", examDetails);
  return (
    <>
      {examDetails?.sectionType === "paragraph" && (
        <div className="flex bg-white justify-center lg:w-full md:w-12/12 md:justify-center w-[100%] md:pb-4 px-8 sm:pb-4 pb-4">
          <ReadingCard
            data={examDetails?.sectionTypeValue}
            instruction={examDetails?.sectionTitle}
            sectionName={examDetails?.sectionName}
            url={examDetails?.url}
            sectionImage={examDetails?.sectionImage}
          />
        </div>
      )}
      {examDetails?.sectionType === "audio" && (
        <div className="flex bg-white justify-center items-center lg:w-full md:w-12/12 md:justify-center w-[100%] md:pb-4 px-8 sm:pb-4 pb-4">
          <p className="mb-4 px-4 mt-4 text-justify">
            <div
              dangerouslySetInnerHTML={{
                __html: examDetails?.sectionTitle,
              }}
            />
          </p>
          <hr />
          <AudioCard data={examDetails} instruction={examDetails?.sectionTitle} />
        </div>
      )}
      {examDetails?.sectionType === "video" && (
        <div className="flex bg-white justify-center lg:w-full md:w-12/12 md:justify-center w-[100%] md:pb-4 px-8 sm:pb-4 pb-4">
          <div className="bg-white p-4 rounded-lg shadow">
            <p className="mb-4 px-4 mt-4 text-justify">
              <div
                dangerouslySetInnerHTML={{
                  __html: examDetails?.sectionTitle,
                }}
              />
            </p>
            <hr />
            <video width="320" height="240" controls>
              <source src={examDetails?.url + examDetails?.sectionTypeValue} type="video/mp4" />
              <source src={examDetails?.url + examDetails?.sectionTypeValue} type="video/ogg" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      )}
    </>
  );
};

export default ExamType;
