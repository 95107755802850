import { DashboadPagesLayout } from "../../components";
import AddInstituteForm from "./AddInstituteForm";

const AddInstitute = () => {
  return (
    <DashboadPagesLayout>
      <AddInstituteForm />
    </DashboadPagesLayout>
  );
};

export default AddInstitute;
