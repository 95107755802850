import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useNavigate } from "react-router";

export default function BreadcrumbsC({ data }) {
  const navigate = useNavigate();

  function handleClick(e, link) {
    e.preventDefault();
    navigate(link);
  }
  const breadcrumbs = data.map((bread, i) => {
    const isLastItem = i === data.length - 1;

    return (
      <div key={i}>
        {!isLastItem ? (
          <Link
            underline="hover"
            key="1"
            color="inherit"
            href={bread.link}
            onClick={(e) => {
              handleClick(e, bread.link);
            }}
          >
            {bread.title}
          </Link>
        ) : (
          <Typography key="3" color="text.primary">
            {bread.title}
          </Typography>
        )}
      </div>
    );
  });

  return (
    <Stack spacing={2}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>
    </Stack>
  );
}
