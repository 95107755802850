import QuizIcon from "@mui/icons-material/Quiz";
import PersonIcon from "@mui/icons-material/Person";
import SchoolIcon from "@mui/icons-material/School";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

const dashboardCard = {
  totalStudents: {
    id: 1,
    counts: 3280,
    barvalue: 80,
    title: "Students",
    icons: <PeopleAltIcon />,
    description: "80% increase in 20 days",
  },
  newStudents: {
    id: 2,
    counts: 254,
    barvalue: 50,
    title: "Selected Students",
    icons: <PersonIcon />,
    description: "50% increase in 25 days",
  },
  totalCourse: {
    id: 3,
    counts: 76,
    barvalue: 28,
    title: "Institute",
    icons: <SchoolIcon />,
    description: "76% increase in 20 days",
  },
  feeCollection: {
    id: 4,
    barvalue: 80,
    counts: "256",
    title: "Exam",
    icons: <QuizIcon />,
    description: "30% increase in 30 days",
  },
};

export default dashboardCard;

export const studentsData = [
  {
    id: 1,
    studentName: "Samual",
    qualification: "Graduation",
    course: "English",
    phone: "+91-8768565689",
    address: "New Delhi",
  },
  {
    id: 1,
    studentName: "Samual",
    qualification: "Graduation",
    course: "English",
    phone: "+91-8768565689",
    address: "New Delhi",
  },
  {
    id: 1,
    studentName: "Samual",
    qualification: "Graduation",
    course: "English",
    phone: "+91-8768565689",
    address: "New Delhi",
  },
];

export const courseTable = [
  {
    id: 1,
    course: "English",
    university: "Abroad",
    examType: "Reading",
  },
  {
    id: 2,
    course: "English",
    university: "Abroad",
    examType: "Reading",
  },
  {
    id: 3,
    course: "English",
    university: "Abroad",
    examType: "Reading",
  },
  {
    id: 4,
    course: "English",
    university: "Abroad",
    examType: "Writting",
  },
];

export const feedbacks = [
  {
    id: 1,
    feedback: "Strong Understanding: You demonstrated a solid grasp of the core concepts we covered in this unit",
  },
  {
    id: 2,
    feedback: "Your answers were detailed and well-explained, showcasing your understanding of the material",
  },
  {
    id: 3,
    feedback: " I noticed you were able to apply theoretical concepts to practical scenarios, which is a crucial skill",
  },
];
