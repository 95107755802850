import { useState } from "react";

import { useParams } from "react-router-dom";
import { LoaderSpiner, TableHeading } from "../../../components";
import { TestTableHeading } from "../../../mockData";
import {
  Table,
  TableBody,
  TableRow,
  TableContainer,
  TableCell,
  Button,
} from "@mui/material";
import { GETEXAMLISTSTUDENTAPI } from "../../../services/helpers/Exam";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";


const StudentExamListInstitute = () => {
  const navigate = useNavigate();
  const { id } = useParams()
  const exam = useSelector((state) => state.exam);
  const queryParams = {
    studentId: id,
  };
  const paramsData = {
    queryParams,
  };

  const {
    data: StudentExamList,
    isLoading,
    isFetching,
    isError,
  } = useQuery(
    ["getStudentExamList"],
    () => GETEXAMLISTSTUDENTAPI(paramsData),
    {
    //   keepPreviousData: false,
      // staleTime: Infinity,
    }
  );

  const attemptExamHandler = (item) => {
    console.log("item ====> ", item)
    if(item?.submitExamId){
      navigate(`/student/${id}/list/${item?.submitExamId}`)
    } else {
      alert("not working")
    }
  };

  return (
    // <div className="mt-20 overflow-auto lg:px-8  ">
    <>
      <div className="w-full ">
        <div className="py-8">
          <div className="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6 min-h-[60vh]">
        <h3 className="text-lg font-semibold text-center mb-6">Student Exam List</h3>
            {isLoading || isFetching ? (
              <div className="w-full flex justify-center items-center min-h-[60vh]">
                <LoaderSpiner />
              </div>
            ) : (
              exam.SwitchtoExam == false && (
                <TableContainer>
                  <Table style={{ overflow: "auto" }} aria-label="simple table">
                    <TableHeading tableHeading={TestTableHeading} />
                    <TableBody>
                      {StudentExamList?.data?.examList?.map((item, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell>{index + 1}</TableCell>
                          <TableCell align="left">{item.examName}</TableCell>
                          <TableCell align="left">
                            {item.instituteName}
                          </TableCell>
                          <TableCell align="left">
                            <ExamType item={item.examType} />
                          </TableCell>
                          {/* <TableCell align="left">{"item.country"}</TableCell> */}
                          <TableCell align="left">
                            {item.examScheduleTime.slice(0, 5)}
                          </TableCell>
                          <TableCell align="left">
                            <Button
                              variant="contained"
                              onClick={() => attemptExamHandler(item)}
                            >
                              Result
                            </Button>
                            {/* <Button
                              variant="contained"
                              onClick={(event) => {
                                (function disableButtonForAMinute() {
                                  attemptExamHandler(item);
                                  event.currentTarget.disabled = true;
                                  setTimeout(
                                    () =>
                                      (event.currentTarget.disabled = false),
                                    6000
                                  ); // 60 seconds
                                })();
                              }}
                            >
                              Take Exam
                            </Button> */}
                            {/* <LinkButton btnTitle="Take Exam" linkUrl={'/present-exam'} type="button" /> */}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )
            )}
          </div>
        </div>
        
      </div>
    </>
  );
};

let examType = [
  {
    id: "1",
    Name: "Reading Exam",
  },
  {
    id: "2",
    Name: "Writing Exam",
  },
  {
    id: "3",
    Name: "Listening Exam",
  },
  {
    id: "4",
    Name: "Speaking Exam",
  },
  {
    id: "11",
    Name: "Academic Reading",
  },
  {
    id: "12",
    Name: "General Reading",
  }
];

export const ExamType = ({ item }) => {
  const [Item, setItem] = useState(JSON.parse(item));
  return (
    <div className="flex flex-col">
      {Item.map((id) => {
        return <span>{examType.find((type) => type?.id === id)?.Name}</span>;
      })}
    </div>
  );
};

export default StudentExamListInstitute