export const tableHeading = [
  {
    title: "S.No.",
  },
  {
    title: "Student Name",
  },
  {
    title: "Roll Number",
  },
  {
    title: "Email",
  },
  {
    title: "username",
  },
  {
    title: "Exam Status",
  },
  {
    title: "Apply For",
  },

  {
    title: "Action",
  },
];
export const ScheduleExamListHeading = [
  {
    title: "S.No.",
  },
  {
    title: "S. Exam Title",
  },
  {
    title: "Exam Name",
  },
  {
    title: "Schedule Date",
  },
  {
    title: "Schedule Time",
  },
  // {
  //   title: "status",
  // },
  {
    title: "Action",
  },
];
export const ScheduleStudentListHeading = [
  {
    title: "S.No.",
  },
  {
    title: "Student Name",
  },
  {
    title: "Contact Number",
  },
  {
    title: "Action",
  },
];

export const StudenttableHeading = [
  {
    title: "S.No",
  },
  {
    title: "Full Name",
  },
  {
    title: "Institute Name",
  },
  {
    title: "Phone",
  },

  {
    title: "Email",
  },
  {
    title: "Status",
  },

  {
    title: "Nationality",
  },

  {
    title: "Action",
  },
];
export const StudenttableHeadingInsttitute = [
  {
    title: "S.No",
  },
  {
    title: "Full Name",
  },
  {
    title: "Phone",
  },

  {
    title: "Email",
  },
  {
    title: "Status",
  },

  {
    title: "Nationality",
  },

  {
    title: "Action",
  },
];
export const examTableHeading = [
  {
    title: "S.No",
  },
  {
    title: "Exam Name",
  },
  {
    title: "Added by",
  },
  {
    title: "Module Type",
  },

  {
    title: "Duration(In min.)",
  },
  {
    title: "Status",
  },
  {
    title: "Section",
  },
  {
    title: "Action",
  },
];
export const institutetableHeading = [
  {
    title: "S.No",
  },
  {
    title: "Institute's Name",
  },
  {
    title: "Email",
  },
  {
    title: "Phone",
  },
  {
    title: "Status",
  },
  {
    title: "Action",
  },
];

export const sectionTableHeading = [
  {
    title: "S.No",
  },
  // {
  //   title: "Section",
  // },
  {
    title: "Title",
  },
  {
    title: "Time",
  },
  {
    title: "SectionType",
  },
  {
    title: "Action",
  },
];
export const InstituteStudentListHeading = [
  {
    title: "S.No",
  },
  {
    title: "Student's Name",
  },
  {
    title: "Email",
  },
  {
    title: "Phone",
  },
  {
    title: "Exam",
  },
  {
    title: "Action",
  },
];

export const InstitutetableHeading = [
  {
    title: "S.No",
  },
  {
    title: "Full Name",
  },
  {
    title: "Phone",
  },

  {
    title: "Email",
  },
  {
    title: "State",
  },

  {
    title: "Action",
  },
];
export const studentTableHeading = [
  {
    title: "S.No.",
  },
  {
    title: "Student Name",
  },
  {
    title: "Father's Name",
  },
  {
    title: "Mobile",
  },
  {
    title: "Email",
  },
  {
    title: "country",
  },

  {
    title: "Apply For",
  },

  {
    title: "Action",
  },
];
export const TestTableHeading = [
  {
    title: "S.No.",
  },
  {
    title: "Exam Name",
  },
  {
    title: "Institute Name",
  },

  {
    title: "Module Type",
  },
  {
    title: "Time",
  },

  {
    title: "Start",
  },
];
export const ResultTableHeading = [
  {
    title: "S.No.",
  },
  {
    title: "Exam Name",
  },
  // {
  //   title: "Institute Name",
  // },
  // {
  //   title: "Module Type",
  // },
  {
    title: "Status",
  },
  {
    title: "Action",
  },
];

export const resultsTableHeading = [
  {
    title: "S.No.",
  },
  {
    title: "Exam Name",
  },
  {
    title: "Institute Name",
  },
  {
    title: "Country",
  },

  {
    title: "Start",
  },
  {
    title: "Time",
  },
  {
    title: "Action",
  },
];

export const resultsStdTableHeading = [
  {
    title: "S.No.",
  },
  {
    title: "Applicant Name",
  },
  {
    title: "Band Score",
  },
  {
    title: "Result Status",
  },
  {
    title: "Action",
  },
];

export const resultsAllResults = [
  {
    title: "S.No.",
  },

  {
    title: "Exam Name",
  },
  {
    title: "Reading Exam",
  },
  {
    title: "Writing  Exam",
  },
  {
    title: "Listening  Exam",
  },
  {
    title: "Band Score",
  },
  {
    title: "Result Status",
  },
  {
    title: "Action",
  },
];
