import { useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import LoaderSpiner from "../common/LoaderSpiner";
import PhoneIcon from "@mui/icons-material/Phone";
import InstituteStudentList from "./InstituteStudentList";
import MarkunreadIcon from "@mui/icons-material/Markunread";
import InstituteDetailsTable from "./InstituteDetailsTable";
import { instituteDetailsAPI } from "../../services/helpers/Institute";

const InstituteDetails = () => {
  const [instituteDetail, setInstituteDetail] = useState(null);

  const { id } = useParams();

  useQuery(
    ["INSTITUTERDETAILS"],
    () => {
      const queryParams = {
        instituteId: id,
      };
      const paramsData = {
        queryParams,
      };
      return instituteDetailsAPI(paramsData);
    },
    {
      retry: false,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: 0,
      mutationKey: "INSTITUTEDETAILS",
      enabled: true,
      onSuccess: (res) => {
        if (res?.status === true) {
          setInstituteDetail(res?.data);
        } else {
        }
      },
    }
  );

  return (
    <div className="w-full">
      <h1 className="text-2xl text-center font-semibold pb-4">Institute Details</h1>
      <hr />
      {instituteDetail ? (
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-8">
          <div className="bg-blue-50 shadow-sm rounded">
            <div className="bg-gray-500 rounded-t px-4 py-2 text-white gap-2 flex">
              <h2>ID:</h2>
              {instituteDetail.instituteId}
            </div>
            <div className="p-10 rounded-b">
              <h2 className="text-center text-2xl md:text-4xl font-medium capitalize">{instituteDetail.instituteName}</h2>
              <div className="flex flex-col justify-center items-center mt-5 gap-2">
                <div className="flex flex-wrap justify-center gap-1 text-blue-800 font-medium text-lg items-center">
                  <PhoneIcon />
                  <p> {instituteDetail.phone}</p>
                </div>
                <div className="flex justify-center flex-wrap gap-1 text-blue-800 font-medium text-lg items-center">
                  <MarkunreadIcon />
                  <p>{instituteDetail.email}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="shadow-sm rounded bg-teal-50 ">
            <strong>
              <InstituteDetailsTable data={instituteDetail} />
            </strong>
          </div>
        </div>
      ) : (
        <div className="w-full min-h-[50vh] flex justify-center items-center">
          <LoaderSpiner />
        </div>
      )}

      <div className="mt-10">
        <InstituteStudentList />
      </div>
    </div>
  );
};

export default InstituteDetails;
