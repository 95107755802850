import * as yup from "yup";

export const initialValuesExamSchedule = {
    examId:"",
    description:"",
    examScheduleDate:"",
    examScheduleTime:""
}

export const validationSchemaExamSchedule = yup.object().shape({
    examId:yup.string()
        .required("Exam id is required"),
    examScheduleDate:yup.string()
        .required("Schedule date is required"),
    examScheduleTime:yup.string()
        .required("Schedule time is required"),
    description:yup.string()
    .required("Exam Title is required"),
})