import React, { useEffect, useState } from 'react'
import { GETEXAMSUBMITLISTAPI } from '../../services/helpers/Exam';
import Cookies from 'js-cookie';

import { useQuery } from "react-query";
import LoaderSpiner from '../common/LoaderSpiner';
import { ResultTableHeading } from "../../mockData";
import { CustomPagination, DarkButton, ExamListTable } from "../../components";
import {
    Table,
    TableBody,
    TableRow,
    TableContainer,
    TableCell,
    Button,
} from "@mui/material";
import {useNavigate} from 'react-router-dom'
import TableHeading from '../common/Table/TableHeading';

const ResultListComp = () => {
    const [serial, setSerial] = useState();
    const [submitExamList, setSubmitExamList] = useState({
        list: null,
        pagination: null,
        notList: false
    })

    const getStartingSerialNumber = () => {
        return setSerial((page - 1) * submitExamList.pagination?.numberPerPage + 1);
      };
    
      useEffect(() => {
        getStartingSerialNumber();
      }, [submitExamList?.list]);

    const [page,setPage] = useState(1);
    const navigate = useNavigate()
    const queryParams = {
        studentId: Cookies.get("userId"),
        pageNum: page,
    };
    const paramsData = {
        queryParams,
    };
    const {
        data,
        isLoading,
        isFetching,
        isError,
    } = useQuery(
        ["getSubmitExamList",page],
        () => GETEXAMSUBMITLISTAPI(paramsData),
        {
            keepPreviousData: false,
            // staleTime: Infinity,
        }
    );

    useEffect(() => {
        if (data?.data?.examList?.length > 0) {
            setSubmitExamList((prev) => {
                return {
                    list: data?.data?.examList,
                    pagination: data?.data?.pagination,
                    notList: false,
                }
            })
        } else {
            setSubmitExamList((prev) => {
                return {
                    ...prev,
                    notList: true,
                }
            })
        }
    }, [data])


    const resultHandler = (item) => {
        navigate(`/result-list/${item?.id}`)
    }

    return (
        <div className="w-full ">
            <div className="p-8">
                <div className="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6 min-h-[60vh]">{isError ? <h1 className='text-center text-red-400'>Something went wrong!</h1> :
                    isLoading || isFetching ? (
                        <div className="w-full flex justify-center items-center min-h-[60vh]">
                            <LoaderSpiner />
                        </div>
                    ) : (
                        <>
                            {submitExamList?.notList ? <h3 className='text-center text-red-400'>List not found</h3> :
                                submitExamList?.list?.length > 0 && <TableContainer>
                                    <Table style={{ overflow: "auto" }} aria-label="simple table">
                                        <TableHeading tableHeading={ResultTableHeading} />
                                        <TableBody>
                                            {submitExamList?.list?.map((list, index) => {
                                                return (
                                                    <TableRow
                                                        key={index}
                                                        sx={{
                                                            "&:last-child td, &:last-child th": { border: 0 },
                                                        }}
                                                    >
                                                        <TableCell>{serial+ index}</TableCell>
                                                        <TableCell align="left">{list.examName}</TableCell>
                                                        <TableCell align="left">{list.result}</TableCell>
                                                        <TableCell align="left"><Button
                                                            variant="contained"
                                                            onClick={() => resultHandler(list)}
                                                        >
                                                            View Result
                                                        </Button></TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            }
                        </>
                    )

                }
                </div>
            </div>
            {submitExamList?.list?.length!==0?<CustomPagination data={submitExamList?.list} page={page} setPage={setPage} paginationData={submitExamList?.pagination}  />:null}
        </div>
    )
}

export default ResultListComp