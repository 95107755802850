import Button from "@mui/material/Button";
import { useSelector } from "react-redux";

const QuestionButtonChanger = ({
  change,
  setChange,
  last,
  ExamSubmitHandler,
  submitExam,
  isLoading,
  submitable,
}) => {
  const { mediaUploading } = useSelector((state) => state.examination);
  console.log("submitExamsubmitExamsubmitExamsubmitExam=> ", last - 1 == change);
  return (
    <div className="sm:flex justify-center mt-4 lg:mt-4 text-center sm:justify-between gap-4 items-center mb-6 xl:mb-0 flex-col">
      <div className="flex gap-4 justify-center sm:justify-end mt-4 sm:mt-0">
        {submitExam?.moduleTimerEnd !== true && (
          <Button
            variant="contained"
            className={`!px-2 !py-1 ${change == 0 && "invisible"}`}
            onClick={() => {
              setChange(change - 1);
            }}
          >
            <span>Previous</span>
          </Button>
        )}

        {submitExam?.moduleTimerEnd !== true && (
          <Button
            disabled={isLoading}
            className={`!p-0 !py-1 ${
              last - 1 == change ? "!hidden" : last - 1 == change && "invisible"
            }`}
            variant="contained"
            onClick={() => {
              setChange(change + 1);
            }}
          >
            <span>Next</span>
          </Button>
        )}
        {(last - 1 == change || submitExam?.moduleTimerEnd == true) &&
          submitable && (
            <Button
              className={`!p-0 !py-1 `}
              variant="contained"
              onClick={() => {
                ExamSubmitHandler("Listening");
              }}
              disabled={mediaUploading || isLoading}
            >
              <span>Submit</span>
            </Button>
          )}
      </div>
    </div>
  );
};

export default QuestionButtonChanger;
