import { useDispatch } from "react-redux";
import { useState, Suspense } from "react";
import { useFormik } from "formik";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { AddCKEditor } from "../../../components";
import { useMutation, useQuery } from "react-query";
import { setToastMessage } from "../../../store/toast";
import { AddPrivacyPolicyPI, GetPrivacyPolicyAPI } from "../../../services/helpers/Dashboard";
import { SelectInputField } from "../../../components/Forms";
import BreadcrumbsC from "../../../components/common/BreadcrumbsC";
import { useNavigate } from "react-router-dom";
// import reading from "../../../assets/img/readingImage.png"
import { MASTERLISTAPI } from "../../../services/helpers/Users";
import { CircularProgress } from "@mui/material";
import reading from "../../../assets/img/readingImage.png"
import writing from "../../../assets/img/writingImage.png"
import listening from "../../../assets/img/listeningExam.png"
import speaking from "../../../assets/img/SpeakingExam.gif";
const Instructions = () => {

  // const items = ['Explore', 'Create', 'Connect', 'Share'];
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  // const imagedata = [
  //   {image:"https://ieltsacademy.org/wp/wp-content/uploads/2017/07/67974633_s.jpg.webp"},
  //   {image:"https://cdn2.iconfinder.com/data/icons/back-to-school-flat/64/School-28-512.png"},
  //   {image:"https://static.vecteezy.com/system/resources/previews/003/133/649/original/listening-test-and-exams-vector.jpg"},
  //   {image:"https://www.eklavvya.com/wp-content/uploads/2022/12/Viva-Oral-Test.gif"}
  // ]

  // const combinedData = data.map((item, index) => ({
  //   ...item,
  //   ...imagedata[index]
  // }));
  // console.log(combinedData)
 
  // const updatedData = data.map(item => {
  //   // Assuming you want to set a default difficulty for each exam
  //   const image = "Medium";
  
  //   // Add the new field to the existing object
  //   return {
  //     ...item,
  //     "Difficulty": difficulty
  //   };
  // });

  useQuery(
    ["MASTERLISTAPI"],
    () => {
      const queryParams = {};
      const paramsData = {
        queryParams,
      };
      return MASTERLISTAPI(paramsData);
    },
    {
      retry: false,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: 0,
      mutationKey: "MASTERLISTAPI",
      enabled: true,
      onSuccess: (res) => {
        if (res?.status === true) {
          setData(res?.data.slice(0,4));
          setLoading(false);
        } else {
        }
      },
    }
  );

  const navigate = useNavigate();

   
  const [exam, setExam] = useState();
  const initialValues =
  {
    examType: ""

  }

 

  // const updatedData = data.map(item => {
  //   // Find the corresponding option in the options array
  //   const correspondingOption = options.find(option => option.option === item.Name);
  
  //   // Add the "label" field to the existing object
  //   return {
  //     ...item,
  //     "label": correspondingOption ? correspondingOption.label : "Unknown"
  //   };
  // });
  
  // console.log(updatedData);
  const [contentData, setContentData] = useState(null);
  console.log(contentData);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => {
      console.log(values)
    },
  });
  console.log(formik);
  const breadcrumbb = [
    {
      id: 1,
      title: "Dashboard",
      link: "/dashboard",
    },
    {
      id: 2,
      title: "Exam Instructions",
      link: "/exam instructions",
    },
  ];

  const { mutate, isLoading } = useMutation(AddPrivacyPolicyPI, {
    mutationKey: "addPrivacyPolicy",
    onSuccess: (data) => {
      if (data.status === true) {
        dispatch(
          setToastMessage({
            isActive: true,
            message: data?.message,
            type: "success",
          })
        );
      }
    },
  });

  useQuery(
    ["privacyPolicyDetail"],
    () => {
      const queryParams = {};
      const paramsData = {
        queryParams,
      };
      // return GetPrivacyPolicyAPI(paramsData);
    },
    {
      retry: true,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: 0,
      mutationKey: "allCaraTakerList",
      enabled: true,
      onSuccess: (res) => {
        if (res?.status === true) {
          if (res?.data?.content) {
            setContentData(res.data.content);
          }
        } else {
        }
      },
    }
  );

  const submitHandle = () => {
    // mutate({ content: contentData });
  };

  return (
    <div className="relative px-2 sm:px-8 py-6 bg-[#F3F3F3] md:ml-64">
      <div className="flex justify-between items-center">
        <BreadcrumbsC data={breadcrumbb} />
      </div>

      {loading ? (
            <div className="flex justify-center min-h-[50vh] items-center">
            <CircularProgress className="mt-12" />
          </div>
           ): <div className="grid grid-cols-1 md:grid-cols-2 gap-3 py-8" >
        {/* {data.map((e) => {
          return (
            <div className="h-16 text-2xl   w-full flex justify-around transition ease-in-out delay-100 bg-blue-500 hover:-translate-y-1 hover:scale-103 hover:bg-indigo-500 duration-300 ...">
              <div className="flex flex-col justify-center  ">{e.Name}</div>
              <div className="flex flex-col justify-center"><ArrowForwardIcon onClick={() => {
                navigate(`/instructions/${e.id}`)
              }} style={{ cursor: "pointer" }} /></div>
            </div>
          )

        })} */}
        {data?.map((e, index) => {
          return (

            <div className="max-w-sm mx-auto bg-white rounded-md overflow-hidden shadow-lg">
              <div className="flex justify-center"><img className="w-8/12  object-cover object-center" src={index === 0 ? reading : index === 1 ? writing : index === 2 ? listening : speaking} alt="Card" /></div>
              <div className="p-6">
                <h2 className="text-xl font-semibold text-gray-800 mb-1">{e.Name}</h2>
                {/* <p className="text-gray-600">A beautiful card description goes here. It can be as long as you need.</p> */}
                <div className="mt-4">
                  <button
                    onClick={() => {
                      navigate(`/instructions/${e.id}`)
                    }}
                    className="text-blue-500 hover:underline focus:outline-none focus:shadow-outline-blue"
                  >
                    Add/Modify Instructions
                  </button>
                </div>
              </div>
            </div>

          )
        })}
      </div>}





      {/* <div className="bg-gray-100 min-h-screen flex items-center justify-center">
          <div className="bg-white p-8 rounded-md shadow-md w-80">
            <h1 className="text-2xl font-bold mb-4">Features</h1>
            <ul>
              {items.map((item, index) => (
                <li key={index} className="flex items-center py-2">
                  <span className="bg-green-500 rounded-full h-4 w-4 mr-2"></span>
                  <span className="text-gray-700">{item}</span>
                </li>
              ))}
            </ul>
          </div>
        </div> */}

      {/* <div className="h-16 text-2xl   w-full flex justify-around">
          <div className="flex flex-col justify-center text-white  ">Writing Exam</div>
          <div className="flex flex-col justify-center"><ArrowForwardIcon onClick={()=>{
            navigate("/instructions/1")
          }} style={{cursor:"pointer"}} /></div>
        </div>

        <div className="h-16 text-2xl   w-full flex justify-around">
          <div className="flex flex-col justify-center  ">Listening Exam</div>
          <div className="flex flex-col justify-center"><ArrowForwardIcon onClick={()=>{
            navigate("/instructions/1")
          }} style={{cursor:"pointer"}} /></div>
        </div>

        <div className="h-16 text-2xl   w-full flex justify-around">
          <div className="flex flex-col justify-center text-white  ">Speaking Exam</div>
          <div className="flex flex-col justify-center"><ArrowForwardIcon onClick={()=>{
            navigate("/instructions/1");
          }}  style={{cursor:"pointer"}} /></div>
        </div> */}

      {/* <div class="h-16 text-2xl   w-full  "><div className=""><p className="text-center ">Reading Exam</p></div>
        <ArrowForwardIcon/></div> */}

      {/* <div class="h-16 text-2xl flex flex-col justify-center mt-4 w-full"><p className="text-center">Listening Exam</p></div>
        <div class="h-16 text-2xl flex flex-col justify-center mt-4 w-full text-white"><p className="text-center">Speaking Exam</p></div> */}


      {/* <div class="[&>*:nth-child(odd)]:bg-gray-500 [&>*:nth-child(even)]:bg-blue-500 text-center items-center ">
        <div class="h-16 text-2xl flex flex-col justify-center">
          <p class="text-center">Reading Exam</p>
        </div>
        <div class="h-16 text-2xl flex flex-col justify-center">
          <p class="text-center">Writing Exam</p>
        </div>
        <div class="h-16 text-2xl flex flex-col justify-center">
          <p class="text-center">Listening Exam</p>
        </div>
        <div class="h-16 text-2xl flex flex-col justify-center">
          <p class="text-center">Speaking Exam</p>
        </div>
      </div> */}



      {/* <Suspense fallback={<div>Loading...</div>}>
        <AddCKEditor  contentData={contentData} flag={true} exam={exam} setExam={setExam} setContentData={setContentData} breadcrumbb={breadcrumbb} heading="Exam Instructions" submitHandle={submitHandle} options={options} formik={formik} />
      </Suspense> */}
    </div>
  );
};

export default Instructions;