import React, { useState, useEffect } from "react";
import AppSideBarExam from "../../../components/common/AppSideBar/AppSideBarExam";
import { DashboadPagesLayout } from "../../../components";
import ExamAttemp from "./ExamAttemp";
import { examDetailmock } from "./Exam";
import DrawIcon from "@mui/icons-material/Draw";
import { useDispatch, useSelector } from "react-redux";
import { LoaderSpiner } from "../../../components";
import { examRemove, examUpdate, examUpdateQuestion } from "../../../store/exam";
import { GETEXAMDETAILSTUDENTAPI } from "../../../services/helpers/Exam";
import { useQuery } from "react-query";
import ListeningExamChild from "./ListeningExamChild";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import AppSideBarTest from "../../../components/common/AppSideBar/AppSideBarTest";

const ListeningExamC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const state = useSelector((state) => state.exam);
  const { examAttempt } = useSelector((state) => state.exam);
  const [historyState, setHistoryState] = useState(null);
  const queryParams = {
    studentId: Cookies.get("userId"),
    // examId: examAttempt?.examId,
    // scheduleId: examAttempt?.scheduleId ,
    examId: examAttempt?.examId || Cookies.get("sExamId"),
    scheduleId: examAttempt?.scheduleId || Cookies.get("sScheduleId"),
    // examId: "1564ec23-012e-4290-bb41-6ec5f3e148bc",
    // scheduleId: "59a5b616-33c6-410e-882f-6455b384d95b",
  };
  const paramsData = {
    queryParams,
  };

  const {
    data: StudentExamDetails,
    isLoading,
    isFetching,
    isError,
  } = useQuery(["getStudentExamDetails"], () => GETEXAMDETAILSTUDENTAPI(paramsData), {
    enabled: ((examAttempt?.examId && examAttempt?.scheduleId) || (Cookies.get("sExamId") && Cookies.get("sScheduleId"))) ? true : false,
    // enabled: (examAttempt?.examId && examAttempt?.scheduleId) ? true : false,
    // enabled: true,
    keepPreviousData: false,
    staleTime: Infinity,
  });

  useEffect(() => {
    return () => {
      dispatch(examRemove({}));
      Cookies.remove("sExamId")
      Cookies.remove("sScheduleId")
    };
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const message = 'You have unsaved changes. Are you sure you want to leave?';
      event.preventDefault();
      event.returnValue = message;
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  // useEffect(() => {
  //   // Push a new state object to history to lock current page
  //   setHistoryState(window.history.pushState({}, '', window.location.href));

  //   // Add popstate listener to prevent back navigation
  //   window.addEventListener('popstate', () => {
  //     // Replace state with the saved history state, effectively blocking back navigation
  //     window.history.replaceState(historyState, '', window.location.href);
  //   });

  //   // Clean up listener on unmount
  //   return () => window.removeEventListener('popstate');
  // }, [historyState]);

  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     const message = 'You have unsaved changes. Are you sure you want to leave?';
  //     event.preventDefault();
  //     event.returnValue = message;
  //   };
  //   window.addEventListener('beforeunload', handleBeforeUnload);

  //   // Push a new state object to history to lock current page
  //   setHistoryState(window.history.pushState({}, '', window.location.href));

  //   // Add popstate listener to prevent back navigation
  //   window.addEventListener('popstate', () => {
  //     if (window.history.state !== historyState) {
  //       window.history.replaceState(historyState, '', window.location.href);
  //     }
  //   });

  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //     window.removeEventListener('popstate', () => window.history.replaceState(historyState, '', window.location.href));
  //   };
  // }, [historyState]);
  

  return (
    <>
      {/* {true ? ( */}

      {isLoading || isFetching ? (
        <>
          <AppSideBarTest />
          <DashboadPagesLayout>
            <div className="w-full flex justify-center items-center min-h-[60vh]">
              <LoaderSpiner />
            </div>
          </DashboadPagesLayout>
        </>
      ) : (
        <>
          {((examAttempt?.examId && examAttempt?.scheduleId) || (Cookies.get("sExamId") && Cookies.get("sScheduleId"))) ? (
            // {(examAttempt?.examId && examAttempt?.scheduleId) ? (
            <div>{StudentExamDetails && !isLoading && !isFetching && <ListeningExamChild StudentExamDetails={StudentExamDetails} />}</div>
          ) : (
            <>
              <AppSideBarTest />
              <DashboadPagesLayout>
                <div className="w-full">
                  <div className="w-full relative px-8 py-4  bg-[#fff] min-h-[60px] ">
                    <h3 className="text-center text-2xl ">
                      <span className="font-semibold"></span>
                    </h3>
                  </div>
                  <div className="relative px-8 py-4 lg:flex-row flex-col flex bg-[#F3F3F3] min-h-[80vh] gap-8">
                    <div className="flex justify-around items-center min-h-[80vh] w-full bg-[#fff] flex-col mt-4 py-8">
                      <div className="h-[80vh] flex justify-center items-center flex-col">
                        <h3 className="text-2xl text-center mb-6">Something went wrong</h3>
                        <Button
                          variant="contained"
                          className="text-xl text-center mt-12 cursor-pointer border-b px-4 py-2 rounded-xl opacity-80"
                          onClick={() => {
                            navigate("/test");
                          }}
                        >
                          Back to Exam List
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </DashboadPagesLayout>
            </>
          )}
        </>
      )}
    </>
  );
};

export default ListeningExamC;
