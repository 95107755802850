import { postApiCall, getApiCall, putApiCall, deleteApiCall } from "../../utils/axios";
import { BASE_URL, API } from "../../utils/defines";

export const AddScheduleExamAPI = async (payload) => {
  const url = `${BASE_URL}${API.SCHEDULEADD}`;
  try {
    const response = await postApiCall(url, payload);
    return response;
  } catch (err) {
    throw new Error(err?.data?.error?.message);
  }
};

export const SCHEDULELISTAPI = async (paramsData) => {
  const url = `${BASE_URL}${API.SCHEDULELIST}`;
  try {
    const response = await getApiCall(url, paramsData);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};

export const SCHEDULESTUDENTLISTAPI = async (paramsData) => {
  const url = `${BASE_URL}${API.SCHEDULESTUDENTLIST}`;
  try {
    const response = await getApiCall(url, paramsData);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};

export const ASSIGNSTUDENTLISTAPI = async (paramsData) => {
  const url = `${BASE_URL}${API.ASSIGNSTUDENTLIST}`;
  try {
    const response = await getApiCall(url, paramsData);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};



export const AddScheduleStudentAPI = async (payload) => {
  const url = `${BASE_URL}${API.SCHEDULESTUDENTADD}`;
  try {
    const response = await postApiCall(url, payload);
    return response;
  } catch (err) {
    throw new Error(err?.data?.error?.message);
  }
};

export const AddAssignStudentAPI = async (payload) => {
  const url = `${BASE_URL}${API.ASSIGNSTUDENTADD}`;
  try {
    const response = await postApiCall(url, payload);
    return response;
  } catch (err) {
    throw new Error(err?.data?.error?.message);
  }
};