import { TextField, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import DarkButton from "../../common/CustomButtons/DarkButton";
import { InputTextField, SelectInputField, TextAreaField } from "../../Forms";
import { useState } from "react";
import { CircularProgress,Backdrop } from "@mui/material";
import QuillEdit from "../../QuillEditor";
import QuillEditor from "../../QuillEditor";

const McqForm = ({ formik, btnText, isLoading, mcqQList, setMcqQList, initialValue }) => {
  const [loading, setLoading] = useState(false);
  console.log("testingItis", loading)
  const [contentdata, setContentData] = useState();
  const [selectedExam, setSelectedExam] = useState("");
  const handleChange = (event) => {
    setSelectedExam(event.target.value);
  };
  const minutes_arr = [];
  for (var i = 0; i < 60; i++) {
    let obj = { label: i, option: i };
    minutes_arr.push(obj);
  }
  const rightOption = [
    {
      option: "1",
      label: "Option 1",
    },
    {
      option: "2",
      label: "Option 2",
    },
    {
      option: "3",
      label: "Option 3",
    },
    {
      option: "4",
      label: "Option 4",
    },
  ];
  const questions = mcqQList;
  // console.log("fomrik332123 --> error -->  ", formik?.errors, formik)
  return (
    <form onSubmit={formik.handleSubmit}>
      {questions?.map((questionNumber, index) => (
        <div key={questionNumber}>
          {/* {console.log("fomrik332123 --> ", !!formik.errors[`questions`][index]['question'] )} */}
          <fieldset className="fieldsetinit mb-8">
            {btnText=="Update Question"?null:<legend className="my-4">Question {++questionNumber}</legend>}
            <div className="grid grid-cols-1  gap-8">
              {loading ? <div className="flex justify-center min-h-[5vh] items-center">
                <Backdrop
                  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                  open={loading}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
              </div> : null}
              
              <QuillEditor setContentData={setContentData} formik={formik} index={`questions[${index}]question`} initialValue={initialValue?.question} setLoading={setLoading} loading={loading}  />
              <p className="text-red-600 text-sm mt-8">{(!!formik.errors[`questions`] && !!formik.errors[`questions`][index] && !!formik.errors[`questions`][index]['question'] && formik.errors[`questions`][index]['question'])}</p>
              {/* <TextField
                variant="outlined"
                fullWidth
                type="text"
                id={`questions[${index}]question`}
                label={"Add Question"}
                multiline
                rows={4}
                placeholder={"Add Question"}
                value={formik.values[`questions[${index}]question`]}
                onChange={formik.handleChange}
                {...formik.getFieldProps(`questions[${index}].question`)}
                error={(!!formik.errors[`questions`] && !!formik.errors[`questions`][index] && !!formik.errors[`questions`][index]['question'])}
                helperText={(!!formik.errors[`questions`] && !!formik.errors[`questions`][index] && !!formik.errors[`questions`][index]['question'] && formik.errors[`questions`][index]['question'])}
              /> */}
              {/* <TextAreaField type="text" id={`questions[${index}]question`} label="Add Question" formik={formik} placeholder="Add Question" /> */}
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                {[1, 2, 3, 4].map((optionNumber) => (
                  <>
                    {/* <InputTextField
                  key={optionNumber}
                  type="text"
                  id={`questions[${index}]opt${optionNumber}`}
                  label={`Option ${optionNumber}`}
                  formik={formik}
                  placeholder={`Option ${optionNumber}`}
                /> */}
                    <TextField
                      variant="outlined"
                      fullWidth
                      type="text"
                      id={`questions[${index}]opt${optionNumber}`}
                      label={`Option ${optionNumber}`}
                      placeholder={`Option ${optionNumber}`}
                      value={formik.values[`questions[${index}]opt${optionNumber}`]}
                      onChange={formik.handleChange}
                      {...formik.getFieldProps(`questions[${index}]opt${optionNumber}`)}
                      error={(!!formik.errors[`questions`] && !!formik.errors[`questions`][index] && !!formik.errors[`questions`][index]['opt' + optionNumber])}
                      helperText={(!!formik.errors[`questions`] && !!formik.errors[`questions`][index] && !!formik.errors[`questions`][index]['opt' + optionNumber] && formik.errors[`questions`][index]['opt' + optionNumber])}
                    />
                    {/* <InputText
              key={optionNumber}
              type="text"
              id={`questions[${index}]opt${optionNumber}`}
              label={`Option ${optionNumber}`}
              formik={formik}
              placeholder={`Option ${optionNumber}`}
              error={formik.touched[`questions`][index] && formik.errors[`questions`][index]['opt' + optionNumber]}
              helperText={formik.touched[`questions`][index] && formik.errors[`questions`][index]['opt' + optionNumber]}
            /> */}

                  </>
                ))}
              </div>
              <div className="mt-2 grid grid-cols-1 sm:grid-cols-2 gap-4">

                {/* <InputTextField type="text" id={`questions[${index}]time`} label="Time in minute" formik={formik} placeholder="Time in minute" /> */}
                {/* <FormControl className="w-full" size="small"> */}
                {/* <InputLabel id="demo-simple-select-autowidth-label">Choose Minutes</InputLabel> */}
                {/* <Select
                  width={"100%"}
                  // value={selectedExam}
                  value={formik.values[`questions[${index}]time`]}
                  defaultValue={formik.values[`questions[${index}]time`]}
                  labelId="demo-simple-select-autowidth-label"
                  name={`questions[${index}]time`}
                  {...formik.getFieldProps(`questions[${index}]time`)}
                  error={formik.touched[`questions[${index}]time`] && formik.errors[`questions[${index}]time`]}
                  onChange={(e) => {
                    formik.handleChange(e);
                    handleChange(e);
                  }}
                  helperText={formik.touched[`questions[${index}]time`] && formik.errors[`questions[${index}]time`]}
                  label={"Choose Minutes"}
                >
                  {[...minutes_arr, { label: 60, option: 60 }].slice(1).map((item, index) => (
                    <MenuItem style={{ width: "100%" }} key={index} value={item.option} className="">
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
                {!!formik.errors[`questions`] &&
                !!formik.errors[`questions`][index] &&
                !!formik.errors[`questions`][index]["time"]  && (
                  <p className="text-red-500 text-sm pl-4">{formik.errors[`questions`][index]["time"]}</p>
                )} */}
              {/* </FormControl> */}
                {/* <TextField
                  variant="outlined"
                  fullWidth
                  type="text"
                  id={`questions[${index}]time`}
                  label={`Time in minute`}
                  placeholder={`Time in minute`}
                  value={formik.values[`questions[${index}]time`]}
                  onChange={formik.handleChange}
                  {...formik.getFieldProps(`questions[${index}]time`)}
                  error={(!!formik.errors[`questions`] && !!formik.errors[`questions`][index] && !!formik.errors[`questions`][index]['time'])}
                  helperText={(!!formik.errors[`questions`] && !!formik.errors[`questions`][index] && !!formik.errors[`questions`][index]['time'] && formik.errors[`questions`][index]['time'])}
                /> */}
                {/* <TextField
                  variant="outlined"
                  fullWidth
                  type="number"
                  id={`questions[${index}]marks`}
                  label={`Marks`}
                  placeholder={`Marks`}
                  value={formik.values[`questions[${index}]marks`]}
                  onChange={formik.handleChange}
                  {...formik.getFieldProps(`questions[${index}]marks`)}
                  error={(!!formik.errors[`questions`] && !!formik.errors[`questions`][index] && !!formik.errors[`questions`][index]['marks'])}
                  helperText={(!!formik.errors[`questions`] && !!formik.errors[`questions`][index] && !!formik.errors[`questions`][index]['marks'] && formik.errors[`questions`][index]['marks'])}
                /> */}
                <div>
                <SelectInputField
                  id={`questions[${index}]answer`}
                  label="Select Right Answer"
                  defaultValue="Select Right Answer"
                  options={rightOption}
                  formik={formik}
                  />
                <div className="flex justify-start items-center"><p className="text-[12px] text-red-500 ml-4 mt-1">{(!!formik.errors[`questions`] && !!formik.errors[`questions`][index] && !!formik.errors[`questions`][index]['answer'] && formik.errors[`questions`][index]['answer'])}</p></div>
                  </div>
              </div>
            </div>
          </fieldset>
        </div>
      ))}
      <div className="mt-10  flex justify-center">
        <DarkButton type="submit" btnTitle={btnText} loading={isLoading} />
      </div>
    </form>
  );
};
export default McqForm;