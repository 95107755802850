import { FaBars } from "react-icons/fa";
import toast from "react-simple-toasts";
import "react-simple-toasts/dist/theme/dark.css";
import { TfiClose } from "react-icons/tfi";
import GridViewIcon from "@mui/icons-material/GridView";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import PolicyIcon from "@mui/icons-material/Policy";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import LogoutSharpIcon from "@mui/icons-material/LogoutSharp";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import CookieStorage from "../../../services/cookieStorage";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import QuizIcon from "@mui/icons-material/Quiz";
import DrawIcon from "@mui/icons-material/Draw";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import DescriptionIcon from "@mui/icons-material/Description";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import MarkEmailUnreadIcon from "@mui/icons-material/MarkEmailUnread";
import RuleIcon from '@mui/icons-material/Rule';
import ieltsLogo from '../../../assets/img/ilteslogo.png'
import { Avatar } from "@mui/material";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";

const AppSideBar = () => {
  const [mobileMenu, setMobildMenu] = useState(false);
  const loginData = useSelector((state) => state?.caretaker?.login);
  // console.loge(loginData);

  const navigate = useNavigate();
  const location = useLocation();
  const cookie = new CookieStorage();

  const clickLink = (pathname) => {
    navigate(pathname);
  };

  const checkHyperLink = (pathname) => {
    if (typeof pathname == "string") {
      return location.pathname === pathname ? "bg-blue-200 border-r-2 border-blue-600 transition-all ease-in delay-100	" : "hover:hover:bg-gray-200";
    } else {
      if (pathname[1].split("/")[1] === location.pathname.split("/")[1]) {
        return "bg-blue-200 border-r-2 border-blue-600";
      }
      for (let i = 0; i < pathname.length; i++) {
        if (location.pathname === pathname[i]) {
          return "bg-gray-200";
        }
      }
      return "hover:hover:bg-gray-200";
    }
  };

  useEffect(() => {
    setMobildMenu(false);
  }, [location.pathname]);

  return (
    <>
      <nav
        id="customScrollbar"
        className="md:left-0 md:block md:fixed md:top-0  md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-20 py-4 px-6"
      >
        <div
          style={{ transition: "all 1.5s" }}
          className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto"
        >
          <button
            className="bg-white m-2  cursor-pointer text-black opacity-50 md:hidden px-3 py-2 text-xl leading-none bg-transparent rounded border border-solid border-transparent border-[#e4e4e7]"
            type="button"
            onClick={() => setMobildMenu(!mobileMenu)}
          >
            {!mobileMenu ? <FaBars /> : <TfiClose />}
          </button>
          {/* <div className="md:block text-left md:pb-2 text-blueGray-600 mr-0 bg-purple-500 inline-block whitespace-nowrap text-md uppercase font-bold md:p-4 px-0 py-0 cursor-pointer">
            <div className="w-[34%] md:w-[60%] mb-2 mx-auto float-right md:float-none bg-red-500">
              <img
                src={adminlogo}
                className="-md:w-[152px]"
                alt="navbarlogo"
                onClick={() => {
                  clickLink("/");
                }}
              />
            </div>
          </div> */}
          {/* <div className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-md uppercase font-bold md:p-4 px-0 py-0 cursor-pointer"> */}
          <div className="md:block flex-1 md:flex-none flex justify-center md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-md uppercase font-bold md:p-4 px-0 py-0 cursor-pointer">
            {/* <h1 className="text-dark font-bold text-5xl text-center hover:text-purple-800 transition-all duration-700 drop-shadow-lg shadow-black">IELTS</h1> */}
            <img src={ieltsLogo} alt="ieltslogo" className="w-[120px] " />
            </div>
          <div className="flex md:hidden items-center justify-end gap-2">
            <Avatar alt="Developer" src="/static/images/avatar/1.jpg" sx={{ width: 36, height: 36 }} />
          </div>
          {mobileMenu && (
            <div className="top-0; h-auto w-full md:hidden">
              <ul className="flex flex-col p-4 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
                {Cookies.get("id") === "institute" || Cookies.get("id") === "super_admin" ? (
                  <li className="items-center ">
                    <div
                      className={`text-xs uppercase py-3 font-bold cursor-pointer flex items-center justify-center hover:bg-gray-100 ${checkHyperLink("/dashboard")}`}
                      onClick={() => {
                        clickLink("/dashboard");
                      }}
                    >
                      <GridViewIcon className="mr-1" /> Dashboard
                    </div>
                  </li>
                ) : null}
                {Cookies.get("id") === "institute" || Cookies.get("id") === "super_admin" ? (
                  <li className="items-center ">
                    <div
                      className={`text-xs uppercase py-3 font-bold cursor-pointer flex items-center justify-center hover:bg-gray-100 ${checkHyperLink("/exams")}`}
                      onClick={() => {
                        clickLink("/exams");
                      }}
                    >
                      <QuizIcon className="mr-1" /> Exams
                    </div>
                  </li>
                ) : null}
                {Cookies.get("id") === "institute" || Cookies.get("id") === "super_admin" ? (
                  <li className="items-center ">
                    <div
                      className={`text-xs uppercase py-3 font-bold cursor-pointer flex items-center justify-center hover:bg-gray-100 ${checkHyperLink("/students")}`}
                      onClick={() => {
                        clickLink("/students");
                      }}
                    >
                      <SupervisorAccountIcon fontSize="small" className="mr-1" /> Students
                    </div>
                  </li>
                ) : null}
                {Cookies.get("id") === "super_admin" ? (
                  <li className="items-center ">
                    <div
                      className={`text-xs uppercase py-3 font-bold cursor-pointer flex items-center justify-center hover:bg-gray-100 ${checkHyperLink("/institute")}`}
                      onClick={() => {
                        clickLink("/institute");
                      }}
                    >
                      <AccountBalanceIcon fontSize="small" className="mr-1" /> Institute
                    </div>
                  </li>
                ) : null}
                {/* {Cookies.get("id") === "institute" || Cookies.get("id") === "super_admin" ? (
                <li className="items-center ">
                  <div
                    className={`pl-2 text-sm uppercase py-3 font-medium cursor-pointer flex items-center justify-center ${checkHyperLink("/examschedule")}`}
                    onClick={() => {
                      clickLink("/examschedule");
                    }}
                  >
                    <QuizIcon fontSize="small" className="mr-1" /> <span className="ml-1 ">Exam Schedule</span>
                  </div>
                </li>
              ) : null} */}
                {/* {Cookies.get("id") === "institute" || Cookies.get("id") === "super_admin" ? (
                <li className="items-center ">
                  <div
                    className={`pl-2 text-sm uppercase py-3 font-medium cursor-pointer flex items-center ${checkHyperLink("/students")}`}
                    onClick={() => {
                      clickLink("/students");
                    }}
                  >
                    <SupervisorAccountIcon fontSize="small" className="mr-1" /> <span className="ml-1 ">Students</span>
                  </div>
                </li>
              ) : null} */}

                {/* <li className="items-center ">
                  <div
                    className={`text-xs uppercase py-3 font-bold cursor-pointer flex items-center justify-center hover:bg-gray-100 ${checkHyperLink("/speaking-exam")}`}
                    onClick={() => {
                      clickLink("/speaking-exam");
                    }}
                  >
                    <RecordVoiceOverIcon className="mr-1" />
                    Speaking Exam
                  </div>
                </li> */}

                {Cookies.get("id") === "institute" || Cookies.get("id") === "super_admin" ? (
                  <li className="items-center">
                  <div
                    className={`text-xs uppercase py-3 font-bold cursor-pointer flex items-center justify-center hover:bg-gray-100 ${checkHyperLink("/instructions")}`}
                    onClick={() => {
                      clickLink("/instructions");
                    }}
                  >
                    <RuleIcon fontSize="small" className="mr-1" /> <span className="ml-1">Instructions</span>
                  </div>
                </li>
                ) : null}
                {Cookies.get("id") === "institute" || Cookies.get("id") === "super_admin" ? (
                  <li className="items-center">
                    <div
                      className={`text-xs uppercase py-3 font-bold cursor-pointer flex items-center justify-center hover:bg-gray-100 ${checkHyperLink("/feedback")}`}
                      onClick={() => {
                        clickLink("/feedback");
                      }}
                    >
                      <ThumbUpAltIcon className="mr-1" /> Feedback
                    </div>
                  </li>
                ) : null}
                {Cookies.get("id") === "super_admin" || Cookies.get("id") === "institute" ? (
                  <li className="items-center">
                    <div
                      className={`text-xs uppercase py-3 font-bold cursor-pointer flex items-center justify-center hover:bg-gray-100 ${checkHyperLink("/privacypolicy")}`}
                      onClick={() => {
                        clickLink("/privacypolicy");
                      }}
                    >
                      <PolicyIcon className="mr-1" /> Privacy Policy
                    </div>
                  </li>
                ) : null}
                {Cookies.get("id") === "super_admin" || Cookies.get("id") === "institute" ? (
                  <li className="items-center">
                    <div
                      className={`text-xs uppercase py-3 font-bold cursor-pointer flex items-center justify-center hover:bg-gray-100 ${checkHyperLink("/termscondition")}`}
                      onClick={() => {
                        clickLink("/termscondition");
                      }}
                    >
                      <IndeterminateCheckBoxIcon className="mr-1" /> Terms & Condition
                    </div>
                  </li>
                ) : null}

                <li className="items-center">
                  <div
                    className={`text-xs uppercase py-3 font-bold cursor-pointer flex items-center justify-center hover:bg-gray-100 ${checkHyperLink("/logout")}`}
                    onClick={() => {
                      cookie.logout();
                      navigate("/login");
                      window.location.reload();
                    }}
                  >
                    <LogoutSharpIcon className="mr-1" /> Logout
                  </div>
                </li>
              </ul>
            </div>
          )}

          <div className="md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded hidden">
            <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200">
              <div className="flex flex-wrap">
                <div className="w-6/12">
                  <div className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"> GST Sahayak </div>
                </div>
                <div className="w-6/12 flex justify-end">
                  <button
                    type="button"
                    className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                  >
                    button
                  </button>
                </div>
              </div>
            </div>

            {/* <h6 className="md:min-w-full text-blueGray-500 uppercase font-bold block pt-1 pb-4 no-underline text-xl"> Dashboard </h6> */}
            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              {Cookies.get("id") === "institute" || Cookies.get("id") === "super_admin" ? (
                <li className="items-center ">
                  <div
                    className={`pl-2 text-sm uppercase py-3 font-medium cursor-pointer flex items-center ${checkHyperLink("/dashboard")}`}
                    onClick={() => {
                      clickLink("/dashboard");
                    }}
                  >
                    <GridViewIcon fontSize="small" className="mr-1" /> <span className="ml-1 ">Dashboard</span>
                  </div>
                </li>
              ) : null}
              {/* {Cookies.get("id") === "super_admin" ? (
                <li className="items-center ">
                  <div
                    className={`pl-2 text-sm uppercase py-3 font-medium cursor-pointer flex items-center ${checkHyperLink("/addCollege")}`}
                    onClick={() => {
                      clickLink("/addCollege");
                    }}
                  >
                    <GridViewIcon fontSize="small" className="mr-1" /> <span className="ml-1 ">Add Institute</span>
                  </div>
                </li>
              ) : null} */}
              {Cookies.get("id") === "institute" || Cookies.get("id") === "super_admin" ? (
                <li className="items-center ">
                  <div
                    className={`pl-2 text-sm uppercase py-3 font-medium cursor-pointer flex items-center ${checkHyperLink("/exams")}`}
                    onClick={() => {
                      clickLink("/exams");
                    }}
                  >
                    <QuizIcon fontSize="small" className="mr-1" /> <span className="ml-1 ">Exams</span>
                  </div>
                </li>
              ) : null}

              {/* <li className="items-center ">
                <div
                  className={`pl-2 text-sm uppercase py-3 font-medium cursor-pointer flex items-center ${checkHyperLink("/routine")}`}
                  onClick={() => {
                    clickLink("/routine");
                  }}
                >
                  <CalendarMonthIcon fontSize="small" className="mr-1" /> <span className="ml-1 ">Routine</span>
                </div>
              </li>
              <li className="items-center ">
                <div
                  className={`pl-2 text-sm uppercase py-3 font-medium cursor-pointer flex items-center ${checkHyperLink("/course")}`}
                  onClick={() => {
                    clickLink("/course");
                  }}
                >
                  <ImportContactsIcon fontSize="small" className="mr-1" /> <span className="ml-1 ">Course</span>
                </div>
              </li> */}
              {Cookies.get("role") === "institute" || Cookies.get("role") === "super_admin" ? (
                <li className="items-center ">
                  <div
                    className={`pl-2 text-sm uppercase py-3 font-medium cursor-pointer flex items-center ${checkHyperLink("/results")}`}
                    onClick={() => {
                      clickLink("/results");
                    }}
                  >
                    <DescriptionIcon fontSize="small" className="mr-1" /> <span className="ml-1 ">Results</span>
                  </div>
                </li>
              ) : null}
              {Cookies.get("id") === "institute" || Cookies.get("id") === "super_admin" ? (
                <li className="items-center ">
                  <div
                    className={`pl-2 text-sm uppercase py-3 font-medium cursor-pointer flex items-center ${checkHyperLink("/students")}`}
                    onClick={() => {
                      clickLink("/students");
                    }}
                  >
                    <SupervisorAccountIcon fontSize="small" className="mr-1" /> <span className="ml-1 ">Students</span>
                  </div>
                </li>
              ) : null}

              {Cookies.get("id") === "super_admin" ? (
                <li className="items-center ">
                  <div
                    className={`pl-2 text-sm uppercase py-3 font-medium cursor-pointer flex items-center ${checkHyperLink("/institute")}`}
                    onClick={() => {
                      clickLink("/institute");
                    }}
                  >
                    <AccountBalanceIcon fontSize="small" className="mr-1" /> <span className="ml-1 ">Institute</span>
                  </div>
                </li>
              ) : null}

              {/* {Cookies.get("id") === "institute" || Cookies.get("id") === "super_admin" ? (
                <li className="items-center ">
                  <div
                    className={`pl-2 text-sm uppercase py-3 font-medium cursor-pointer flex items-center ${checkHyperLink("/examschedule")}`}
                    onClick={() => {
                      clickLink("/examschedule");
                    }}
                  >
                    <QuizIcon fontSize="small" className="mr-1" /> <span className="ml-1 ">Exam Schedule</span>
                  </div>
                </li>
              ) : null} */}

              {/* {Cookies.get("id") === "super_admin" ? (
                <li className="items-center ">
                  <div
                    className={`pl-2 text-sm uppercase py-3 font-medium cursor-pointer flex items-center ${checkHyperLink("/userList")}`}
                    onClick={() => {
                      clickLink("/institute");
                    }}
                  >
                    <AccountBalanceIcon fontSize="small" className="mr-1" /> <span className="ml-1 ">Institute</span>
                  </div>
                </li>
              ) : null} */}
              {/* <li className="items-center ">
                <div
                  className={`pl-2 text-sm uppercase py-3 font-medium cursor-pointer flex items-center ${checkHyperLink("/message")}`}
                  onClick={() => {
                    clickLink("/message");
                  }}
                >
                  <MarkEmailUnreadIcon fontSize="small" className="mr-1" /> <span className="ml-1 ">Message</span>
                </div>
              </li> */}
              {Cookies.get("id") === "institute" || Cookies.get("id") === "super_admin" ? (
                <li className="items-center">
                  <div
                    className={`pl-2 text-sm uppercase py-3 font-medium cursor-pointer flex items-center ${checkHyperLink("/feedback")}`}
                    onClick={() => {
                      clickLink("/feedback");
                    }}
                  >
                    <ThumbUpAltIcon fontSize="small" className="mr-1" /> <span className="ml-1">Feedback</span>
                  </div>
                </li>
              ) : null}

              {Cookies.get("id") === "super_admin" || Cookies.get("id") === "institute" ? (
                <li className="items-center">
                  <div
                    className={`pl-2 text-sm uppercase py-3 font-medium cursor-pointer flex items-center ${checkHyperLink("/privacypolicy")}`}
                    onClick={() => {
                      clickLink("/privacypolicy");
                    }}
                  >
                    <PolicyIcon fontSize="small" className="mr-1" /> <span className="ml-1">Privacy Policy</span>
                  </div>
                </li>
              ) : (
                ""
              )}
               {Cookies.get("id") === "super_admin" || Cookies.get("id") === "institute" ? (
                <li className="items-center">
                  <div
                    className={`pl-2 text-sm uppercase py-3 font-medium cursor-pointer flex items-center ${checkHyperLink("/instructions")}`}
                    onClick={() => {
                      clickLink("/instructions");
                    }}
                  >
                    <RuleIcon fontSize="small" className="mr-1" /> <span className="ml-1">Instructions</span>
                  </div>
                </li>
              ) : (
                ""
              )}
              {Cookies.get("id") === "super_admin" || Cookies.get("id") === "institute"  ? (
                <li className="items-center">
                  <div
                    className={`pl-2 text-sm uppercase py-3 font-medium cursor-pointer flex items-center ${checkHyperLink("/termscondition")}`}
                    onClick={() => {
                      clickLink("/termscondition");
                    }}
                  >
                    {/* <IndeterminateCheckBoxIcon fontSize="small" className="mr-1" /> <span className="ml-1">Term Condition</span> */}
                    <IndeterminateCheckBoxIcon fontSize="small" className="mr-1" /> <span className="ml-1">Terms & Condition</span>
                  </div>
                </li>
              ) : (
                ""
              )}

              <li className="items-center">
                <div
                  className={`pl-2 text-sm uppercase py-3 font-medium cursor-pointer flex items-center ${checkHyperLink("/logout")}`}
                  onClick={() => {
                   
                    cookie.logout();
                    // window.location.reload();
                    // navigate("/login");
                    
                    toast("Logged out Successfully", {
                      theme: "dark",
                      position: "top-center",
                    });
                    //  navigate("/login");
                    window.location.reload();
                    
                  }}
                >
                  <LogoutSharpIcon fontSize="small" className="mr-1" /> <span className="ml-1">Logout</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default AppSideBar;
