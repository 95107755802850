import * as yup from "yup";
import { v4 as uuidv4 } from "uuid";

export const initialValuesAddFeedback = {
  name: "",
  email: "",
  phone: "",
  feedback: "",
};

export const validationSchemaAddFeedback = yup.object().shape({
  name: yup.string().required("Name is required"),
  phone: yup.string().required("Phone Number is required"),
  feedback: yup.string().required("Feedback message is required"),
  email: yup.string().required("Email is  required").email("Email is invalid"),
});

export const initialValuesNewFeedback = {
  // name: "",
  // email: "",
  // phone: "",
  feedback: "",
};

export const validationSchemaNewFeedback = yup.object().shape({
  // name: yup.string().required("Name is required"),
  // phone: yup.string().required("Phone Number is required"),
  feedback: yup.string().required("Feedback message is required"),
  // email: yup.string().required("Email is  required").email("Email is invalid"),
});
