import { TableHead, TableCell, TableRow } from "@mui/material";

const TableHeading = ({ tableHeading }) => {
  return (
    <TableHead style={{ backgroundColor: "#E5E7EB" }}>
      <TableRow className="font-extrabold">
        {tableHeading.map((item, index) => (
          <TableCell align="left" key={index}>
            <p className="font-bold">{item.title}</p>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default TableHeading;
