import { Login, UpdateStudent } from "../../../components";
import { Test } from "../../../pages/takeTest";
import UsersList from "../../../pages/usersList";
import Cookies from "js-cookie";
import {
  Exams,
  Course,
  Routine,
  Message,
  AddExam,
  Results,
  Students,
  FeedBack,
  Dashboard,
  BasicForm,
  Institute,
  AddStudent,
  UpdateExam,
  WritingExam,
  NotFound404,
  ExamDetails,
  ReadingExam,
  ResultsCard,
  SpeakingExam,
  AddInstitute,
  AddQuestions,
  ListeningExam,
  PrivacyPolicy,
  ResultDetails,
  StudentUpdate,
  TermsCondition,
  StudentDetails,
  UpdateInstitute,
  InstituteDetail,
  ApplicantDetails,
  AddFeedback,
} from "../../../pages";
import CreateUser from "../../../components/createUser";
import { AddSection } from "../../../pages/AddSection";
import { UpdateSection } from "../../../pages/updateSection";
import PrevExamDetail from "../../../pages/prevExam";
import ScheduleExam from "../../../pages/ScheduleExam";
import AddScheduleExam from "../../../pages/ScheduleExam/AddScheduleExam";
import ScheduleStudent from "../../../pages/ScheduleExam/ScheduleStudent";
import StudentSection from "../../../components/StudentSection/index"
import ExamNavSide from "../../../pages/takeTest/ExamNavSide";
import Instructions from "../../../pages/common/Instructions";
import { CameraAndMicComponent } from "../../../components/Cameratest";
import WebcamRecorder from "../../../pages/Videotest";
import InstructionDetails from "../../../pages/common/instructionDetails";
import Livetest from "../../../pages/livetest";
import DeviceTest from "../../../pages/DeviceTest";
// import { CameraAndMicComponent } from "../../../components/Cameratest";
import Examination from '../../../pages/Exams/Examination'
import TermsCondtion from "../../../pages/StudentPrivacy/TermsCondtion";
import StudentPrivacy from "../../../pages/StudentPrivacy";
import Section from "../../../pages/Exams/Section";
import ResultList from "../../../pages/ResultList";
import ResultDetail from "../../../pages/ResultList/ResultDetail";
import StudentExamList from "../../../pages/Students/StudentExamList";
import StudentResultList from "../../../pages/StudentResultList";
import ForgotPassword from "../../../components/Login/ForgotPassword";
import ResetPassword from "../../../components/Login/ResetPassword";

const routes = [
  {
    id: "login",
    path: "/login",
    component: <Login />,
  },
  {
    id: "login",
    path: "/createUser",
    component: <CreateUser />,
  },
  {
    id: "forgot-password",
    path: "/forgot-password",
    component: <ForgotPassword />,
  },
  {
    id: "reset-password",
    path: "/reset-password/:hash",
    component: <ResetPassword />,
  },
  {
    id: "userList",
    path: "/userList",
    component: <UsersList />,
    protectedRoute: true,
  },
  {
    id: "Institute",
    path: "Institute",
    component: <Institute />,
    protectedRoute: true,
  },
  {
    id: "videotest",
    path: "/videotest",
    component: <WebcamRecorder/>,
    protectedRoute: true,
  },
  {
    id: "cameratest",
    path: "/cameratest",
    component: <CameraAndMicComponent/>,
    protectedRoute: true,
  },
  {
    id: "addInstitute",
    path: "institute/add",
    component: <AddInstitute />,
    protectedRoute: true,
  },
  {
    id: "instituteDetails",
    path: "institute/:id",
    component: <InstituteDetail />,
    protectedRoute: true,
  },
  {
    id: "instituteUpdate",
    path: "institute/update/:id",
    component: <UpdateInstitute />,
    protectedRoute: true,
  },

  {
    id: "addStudent",
    path: "/student/add",
    component: <AddStudent />,
    protectedRoute: true,
  },
  {
    id: "updateStudent",
    path: "/student/update/:id",
    component: <StudentUpdate />,
    protectedRoute: true,
  },

  {
    id: "test",
    path: "/test",
    component: <ExamNavSide />,
    protectedRoute: true,
  },
  {
    id: "resultList",
    path: "/result-list",
    component: <ResultList />,
    protectedRoute: true,
  },
  {
    id: "resultList",
    path: "/result-list/:id",
    component: <ResultDetail />,
    protectedRoute: true,
  },
  {
    id: "dashboard",
    path: "/",
    component: <Dashboard />,
    protectedRoute: true,
    student: true,
  },
  {
    id: "exams",
    path: "/exams",
    component: <Exams />,
    protectedRoute: true,
  },
  {
    id: "addExam",
    path: "/exams/add",
    component: <AddExam />,
    protectedRoute: true,
  },
  {
    id: "addExam",
    path: "/StudentSection",
    component: <StudentSection/>,
    protectedRoute: true,
  },
  {
    id: "updateExam",
    path: "/exam/update/:id",
    component: <UpdateExam />,
    protectedRoute: true,
  },
  {
    id: "examDetails",
    path: "/exams/:id",
    component: <ExamDetails />,
    protectedRoute: true,
  },
  {
    id: "examDetails",
    path: "/exams/:id/:sectionId",
    component: <Section />,
    protectedRoute: true,
  },
  {
    id: "reading",
    path: "/reading-exam",
    component: <ReadingExam />,
    protectedRoute: true,
  },
  {
    id: "writing",
    path: "/writing-exam",
    component: <WritingExam />,
    protectedRoute: true,
  },
  {
    id: "listening",
    path: "/listening-exam",
    component: <ListeningExam />,
    protectedRoute: true,
  },
  {
    id: "listening",
    path: "/examination2/:examId",
    component: <ListeningExam />,
    protectedRoute: true,
  },
  {
    id: "examination2",
    path: "/examination/:examId",
    component: <Examination />,
    protectedRoute: true,
  },
  {
    id: "speaking",
    path: "/speaking-exam",
    component: <SpeakingExam />,
    protectedRoute: true,
  },
  {
    id: "termsconditionstudent",
    path: "/stermscondition",
    component: <TermsCondtion />,
    protectedRoute: true,
  },
  {
    id: "termscondition",
    path: "/sprivacypolicy",
    component: <StudentPrivacy />,
    protectedRoute: true,
  },
  {
    id: "termscondition",
    path: "/termscondition",
    component: <TermsCondition />,
    protectedRoute: true,
  },

  {
    id: "privacypolicy",
    path: "/privacypolicy",
    component: <PrivacyPolicy />,
    protectedRoute: true,
  },
  {
    id: "privacypolicy",
    path: "/cameratest",
    component: <CameraAndMicComponent/>,
    protectedRoute: true,
  },
  {
    id: "devicetest",
    path: "/device-test",
    component: <DeviceTest />,
    protectedRoute: true,
  },
  {
    id: "livetest",
    path: "/livetest",
    component: <Livetest/>,
    protectedRoute: true,
  },

  {
    id: "instructions",
    path: "/instructions",
    component: <Instructions />,
    protectedRoute: true,
  },
  {
    id: "instructions",
    path: "/instructions/:id",
    component: <InstructionDetails />,
    protectedRoute: true,
  },
  {
    id: "feedback",
    path: "/feedback",
    component: <FeedBack />,
    protectedRoute: true,
  },
  {
    id: "basicform",
    path: "/feedback/:id",
    component: <BasicForm />,
  },
  {
    id: "login",
    path: "/login",
    component: <Login />,
  },
  {
    id: "forgot_password",
    path: "/dashboard",
    component: <Dashboard />,
    protectedRoute: true,
  },
  {
    id: "forgot_password",
    path: "/dashboard",
    component: <Dashboard />,
    protectedRoute: true,
  },
  {
    id: "message",
    path: "/message",
    component: <Message />,
    protectedRoute: true,
  },
  {
    id: "routine",
    path: "/routine",
    component: <Routine />,
    protectedRoute: true,
  },
  {
    id: "course",
    path: "/course",
    component: <Course />,
    protectedRoute: true,
  },
  {
    id: "results",
    path: "/results",
    component: <Results />,
    protectedRoute: true,
  },
  {
    id: "resultDetails",
    path: "/result/details",
    component: <ResultDetails />,
    protectedRoute: true,
  },
  {
    id: "students",
    path: "/students",
    component: <Students />,
    protectedRoute: true,
  },
  {
    id: "applicantDetails",
    path: "/result/details/applicant-details",
    component: <ApplicantDetails />,
    protectedRoute: true,
  },
  {
    id: "studentDetails",
    path: "/student/:id",
    component: <StudentDetails />,
    protectedRoute: true,
  },
  {
    id: "studentDetails",
    path: "/student/:id/list/:resultId",
    component: <StudentResultList />,
    protectedRoute: true,
  },
  {
    id: "studentDetails",
    path: "/student/:id/list",
    component: <StudentExamList />,
    protectedRoute: true,
  },
  {
    id: "studentDetails",
    path: "/student/:id/prev_exam/:id",
    component: <PrevExamDetail/>,
    protectedRoute: true,
  },
  {
    id: "allResults",
    path: "/all-exam/all-results",
    component: <ResultsCard />,
    protectedRoute: true,
  },
  {
    id: "addQuestions",
    path: "/:id/:sectionId/add-questions",
    component: <AddQuestions />,
    protectedRoute: true,
  },
  {
    id: "addQuestions",
    path: "/:id/add-section",
    component: <AddSection/>,
    protectedRoute: true,
  },
  {
    id: "addQuestions",
    path: "/:id/update-section/:id",
    component: <UpdateSection/>,
    protectedRoute: true,
  },
  {
    id: "addExamSchedule",
    path: "/examschedule/:scheduleId",
    component: <ScheduleStudent />,
    protectedRoute: true,
  },
  {
    id: "examSchedule",
    path: "/examschedule",
    component: <ScheduleExam />,
    protectedRoute: true,
  },
  {
    id: "addExamSchedule",
    path: "/examschedule/add",
    component: <AddScheduleExam />,
    protectedRoute: true,
  },
  {
    id: "feedbackAdd",
    path: "/feedback/add",
    component: <AddFeedback />,
    protectedRoute: true,
  },
  {
    id: "astric",
    path: "*",
    component: <NotFound404 />,
  },
];

export default routes;
