import React, { useState, useEffect } from "react";
import { DashboadPagesLayout } from "../../../components";
import ExamAttemp from "./ExamAttemp";
import { examDetailmock } from "./Exam";
import DrawIcon from "@mui/icons-material/Draw";
import { useDispatch, useSelector } from "react-redux";
import { examUpdate, examUpdateQuestion } from "../../../store/exam";
import SidebarExam from "./SidebarExam";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

import InstructionExam from './InstructionExam'
import AppSideBarTest from "../../../components/common/AppSideBar/AppSideBarTest";

const ListeningExamChild = ({ StudentExamDetails }) => {
  const navigate = useNavigate();
  const [selectedModule, setSelectedModule] = useState()
  const [startModuleExam, setStartModuleExam] = useState(false)
  const [submitExam, setSubmitExam] = useState({
    userStatus: 'pending',
    timerStatus: 'running',
  })
  const [examSidebar, setExamSidebar] = useState();
  const [examStudentSidebar, setExamStudentSidebar] = useState();
  const [activeSection, setActiveSection] = useState(0);
  const state = useSelector((state) => state.exam)
  const dispatch = useDispatch()

  useEffect(() => {
    let questionList = []
    let sectionDetail = StudentExamDetails?.data?.module?.map((module, index) => {
      if (!module.sections.length > 0) {
        return undefined
      }
      let newModule = module?.sections?.map((section, index) => {
        let { questions, ...rest } = section
        if (!questions.length > 0) {
          return undefined
        }

        // let allQuestionList = []
        let qA = questions.map((data) => {
          return { id: data?.id, answer: null, attempt: null, questionTypeId: data?.questionTypeId }
        })
        questionList.push(...qA)

        // questionList.push(...questions)
        let sectionTime = (rest && rest?.time) ? rest?.time?.split(':') : "00:00"?.split(':')
        let secTime = ((sectionTime[0] * 1) * 60) + (sectionTime[1] * 1)
        return {
          ...rest,
          questions,
          time: 12,
          // time: secTime,
          totalTime: section.time,
          set: section.sectionName,
          link: '/',
          icon: <DrawIcon fontSize="small" className="mr-1" />
        }
      }).filter((element) => element != undefined)
      if (!newModule.length > 0) {
        return undefined
      }
      module.sections = newModule
      return module
    }).filter((element) => element != undefined)
    dispatch(examUpdateQuestion(questionList))
    setExamStudentSidebar(sectionDetail)
  }, [])

  useEffect(() => {
    if (startModuleExam && selectedModule >= 0) {

    }
  }, [startModuleExam])

  const stopExamModule = () => {
    if (selectedModule < (examStudentSidebar.length - 1)) {
      setStartModuleExam(false);
    } else {
      setSubmitExam((prev) => {
        return {
          userStatus: prev.userStatus,
          timerStatus: 'stopped',
        }
      })
    }
  }
  const startExamModule = () => {
    if (selectedModule >= 0) {
      setSelectedModule(selectedModule + 1)
      setActiveSection(0)
    } else {
      setSelectedModule(0)
    }
    setStartModuleExam(true);
  }
  console.log("submitExamsubmitExamsubmitExam=", examStudentSidebar, StudentExamDetails, state)
  return (
    <>

      {!startModuleExam ?
        <>
          <AppSideBarTest />

          <DashboadPagesLayout>
            <div className="w-full">
              <div className="w-full relative px-8 py-4  bg-[#fff] min-h-[60px] ">
                <h3 className="text-center text-2xl "><span className="font-semibold"></span></h3>
              </div>
              <div className="relative px-8 py-4 lg:flex-row flex-col flex bg-[#F3F3F3] min-h-[80vh] gap-8">
                <div className="flex justify-around items-center min-h-[80vh] w-full bg-[#fff] flex-col mt-4 py-8">
                  <InstructionExam startExamModule={startExamModule} selectedModule={selectedModule} examStudentSidebar={examStudentSidebar} />
                </div>
              </div>
            </div>
          </DashboadPagesLayout>

        </>
        :
        submitExam.userSubmit === "submitted" ? <>
          <AppSideBarTest />

          <DashboadPagesLayout>
            <div className="w-full">
              <div className="w-full relative px-8 py-4  bg-[#fff] min-h-[60px] ">
                <h3 className="text-center text-2xl "><span className="font-semibold"></span></h3>
              </div>
              <div className="relative px-8 py-4 lg:flex-row flex-col flex bg-[#F3F3F3] min-h-[80vh] gap-8">
                <div className="flex justify-center items-center min-h-[80vh] w-full bg-[#fff] flex-col mt-4 py-8">
                  <h3 className="mb-8">
                    Exam submitted
                  </h3>
                  <Button
                    variant="contained"
                      className="text-xl !text-gray-600 text-center mt-12 cursor-pointer border-b px-4 py-2 rounded-xl opacity-80"
                      onClick={() => {
                        navigate("/test");
                      }}
                    >
                      Back to Exam List
                    </Button>
                </div>
              </div>
            </div>
          </DashboadPagesLayout>


        </>
          :
          <div>
            {examStudentSidebar && <SidebarExam type="exam" data={examSidebar} activeSection={activeSection} setActiveSection={setActiveSection} examStudentSidebar={examStudentSidebar[selectedModule]} stopExamModule={stopExamModule} />}
            {/* <AppSideBarExam type="exam" data={examSection} activeSection={activeSection} setActiveSection={setActiveSection} /> */}

            <DashboadPagesLayout>
              {examStudentSidebar && <ExamAttemp setSubmitExam={setSubmitExam} examName={StudentExamDetails?.data?.examName} examTime={examStudentSidebar[selectedModule]?.moduleTime} examModuleList={examStudentSidebar} selectedModule={selectedModule} examModule={examStudentSidebar[selectedModule]?.moduleName} examDetails={examStudentSidebar[selectedModule].sections[activeSection]} examType={examStudentSidebar[selectedModule]?.moduleTypeId} submitExam={submitExam} activeSection={activeSection} />}
            </DashboadPagesLayout>

          </div>
      }
    </>
  );
};

export default ListeningExamChild;
