import AddExamFrom from "./AddExamFrom";
import { DashboadPagesLayout } from "../../../components";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from "react-router-dom";
const AddExam = () => {
  const navigate = useNavigate();
  return (
    <DashboadPagesLayout>
      <div className="w-full">
        <div className="p-8">
          <div className="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
            <div className="">
              <ArrowBackIcon className="cursor-pointer" onClick={()=>{navigate('/exams')}} />
            <h1 className="text-center font-semibold text-2xl mb-5 ">Add Exam</h1>
            </div>
            <AddExamFrom />
          </div>
        </div>
      </div>
    </DashboadPagesLayout>
  );
};

export default AddExam;
