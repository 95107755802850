import { useEffect, useState } from "react";
import Module from "./Module";
import ExamType from "../../../pages/Exams/ListeningExam/ExamType";
import ReadingWritingExam from "./Module/ReadingWritingExam";
import ListeningExam from "./Module/ListeningExam";
import SpeakingExam from "./Module/SpeakingExam";

const ExamModule = ({ selectedModule, activeSection, children, examName }) => {
  const [selectedSection, setSelectedSection] = useState();
  useEffect(() => {
    if (selectedModule?.sections?.length > activeSection) {
      setSelectedSection(selectedModule?.sections[activeSection]);
    }
  }, [activeSection]);
  console.log(
    "StudentExamDetailsStudentExamDetailsStudentExamDetails selectedModule",
    selectedModule, activeSection, selectedModule?.moduleTypeId
  );
  return (
    <>
      <div className="w-full">
        <div className="w-full relative px-8 py-4 bg-[#fff] min-h-[40px] ">
          <h3 className="text-center text-2xl ">
            <span className="font-semibold">Exam:</span>
            {examName?.examName} <span>{` (${examName?.duration} min)`}</span>
          </h3>
          <h3 className="text-center text-xl ">
            <span className="font-semibold">Module:</span>
            {selectedModule?.moduleName}
          </h3>
        </div>
        <div className="relative px-8 py-4 lg:flex-row flex-col flex bg-[#F3F3F3] min-h-[80vh] gap-8">
          {selectedSection && <ExamType examDetails={selectedSection} />}
          <div
            className={`bg-white  rounded-lg shadow py-4 relative px-8 w-full pb-4 flex flex-col justify-between`}
          >
            {/* Login for show and hide media recorder and upload */}

            {selectedSection && (
              <>
                {(selectedModule?.moduleTypeId == 1 || selectedModule?.moduleTypeId == 11 || selectedModule?.moduleTypeId == 12 ||
                  selectedModule?.moduleTypeId == 2) && (
                    <>
                      <ReadingWritingExam
                        Questions={selectedSection?.questions}
                        examType={selectedModule?.moduleTypeId}
                        submitable={selectedModule?.sections?.length == ((activeSection*1)+1)}
                      />
                    </>
                  )}
                {selectedModule?.moduleTypeId == 3 && (
                  <>
                    <ListeningExam
                      Questions={selectedSection?.questions}
                      examType={selectedModule?.moduleTypeId}
                      newchildren={children} 
                      submitable={selectedModule?.sections?.length == ((activeSection*1)+1)}
                    />
                  </>
                )}
                {selectedModule?.moduleTypeId == 4 && (
                  <>
                    <SpeakingExam
                      Questions={selectedSection?.questions}
                      examType={selectedModule?.moduleTypeId}
                      newchildren={children} 
                      submitable={selectedModule?.sections?.length == ((activeSection*1)+1)}
                    >
                      {/* <div className="">{children}</div> */}
                    </SpeakingExam>
                  </>
                )}
              </>
            )}
            {/* <Module selectedSection={selectedSection} examType={selectedModule?.moduleTypeId} /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ExamModule;
