import QuestionNavigator from "./QuestionNavigator";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
const AddQuestion = () => {
  const navigate = useNavigate();
  const { id, sectionId } = useParams();
  return (
    <div className="w-full">
      <div className=" justify-center mb-4 items-center">
        <ArrowBackIcon className="cursor-pointer ml-8 mt-8" onClick={() => { navigate(`/exams/${id}/${sectionId}`) }} />
        <h1 className="text-xl text-center font-semibold py-2">Add Questions</h1>
      </div>

      <div className="w-11/12 mx-auto">
        <QuestionNavigator />
      </div>
    </div>
  );
};

export default AddQuestion;
