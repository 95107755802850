import React, { useEffect, useState } from 'react';
import information from "../../../../../assets/img/information.png"
import AuidoTest from '../../../../../pages/Videotest/AuidoTest';


export const AuidoRecordComp = ({setEnableStartExam=()=>{},setStatus,setmicStatus,test, questionsAnswersHandle, id}) => {
  console.log("audiotuploaddsk", questionsAnswersHandle, id )
  const [cameraStatus, setCameraStatus] = useState('pending');
  console.log(cameraStatus);
  const [video,setVideo] = useState(false);
  const [cameraswitch,setCameraSwitch] = useState(false);

  const [micStatus, setMicStatus] = useState('on');
  console.log(micStatus);
  const [flag,setFlag] = useState(false);

  useEffect(()=>{
    setFlag(true)
  },[video])
  

  const uploadSubmitCallback = (answer) => {
    questionsAnswersHandle(id, answer)
  }

  const handleMicPermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      setMicStatus('on');
      setEnableStartExam((prev)=>{
        return {
          ...prev,
          mediaEnabled: true,
        }
      })
      // Stop the stream to release the microphone when not in use
      stream.getTracks().forEach(track => track.stop());
    } catch (error) {
      setMicStatus('off');
      setEnableStartExam((prev)=>{  
        return {       
          ...prev,
          mediaEnabled: false,
        }
      })
    }
  };
  return (
    <div className=''>
      <div className='flex justify-around'>
        
        <div>
          {/* <p>Mic status: {micStatus}</p> */}

            
          {micStatus == "off" || micStatus == "pending" ? (
            <img onClick={handleMicPermission} className="w-20 h-24 mt-10" src="https://cdn1.iconfinder.com/data/icons/material-audio-video/21/mic-off-512.png" />
          ) : <div>
            {/* <p>Mic status: {micStatus}</p> */}
            {micStatus == "on" ? (
              <img onClick={handleMicPermission} className="w-20 h-[90px] mt-8" src="https://svgsilh.com/svg/312421.svg" />
            ) : null}
          </div>}

        </div>
      </div>
      {!flag?<div className='flex justify-center  '><button onClick={()=>{
        setFlag(true);
      }} className='px-6 mt-4  py-3 bg-blue-500 rounded-lg text-white'>Test Recording </button></div>:null}
      {flag?<div ><AuidoTest id={id} uploadSubmitCallback={uploadSubmitCallback} video={false} setCameraSwitch={setCameraSwitch} micStatus={micStatus}  /></div>:null}
      { micStatus == "off"?<div className="flex justify-center mt-6"><img  src = {information} /></div>:null}
    </div>
  );
};






