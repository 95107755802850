import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useQuery } from "react-query";
import Modal from "@mui/material/Modal";
import { Table, TableBody, TableRow, TableContainer, TableCell, CircularProgress, Card, Tooltip } from "@mui/material";
import { ScheduleStudentListHeading } from "../../../mockData";
import TableHeading from "../../common/Table/TableHeading";
import { useNavigate, useParams } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { GrClose } from "react-icons/gr";
import { ASSIGNSTUDENTLISTAPI } from "../../../services/helpers/ScheduleExam";
import { deleteAssignStudentAPI } from "../../../services/helpers/Users";
import LoaderSpiner from "../../common/LoaderSpiner";
import toast from "react-simple-toasts";
import { useDispatch } from 'react-redux'
import { setToastMessage } from "../../../store/toast";
const studentList = [
  {
    id: "c375b930-3258-40bd-8f1f-68060e16f4b1",
    scheduleId: "aba06c8b-389a-4bbb-9294-e06c884d84be",
    examName: "exam one",
    studentId: "5c85d440-509d-4ac9-b65f-2fc04de71848",
    studentName: "sanjay kumar",
    studentEmail: "sanjay@gmail.com",
    studentPhone: "8739874898",
  },
];
const style = {
  display: "block",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const ExamStudentList = ({ open, handleClose }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [delete1, setDelete1] = useState({
    status: false
  });
  const [assignStatus, setAssignStatus] = useState(true)
const dispatch = useDispatch()
  useQuery(
    ["ASSIGNSTUDENTLIST"],
    () => {
      const queryParams = {
        examId: id,
      };
      const paramsData = {
        queryParams,
      };
      return ASSIGNSTUDENTLISTAPI(paramsData);
    },
    {
      retry: false,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: 0,
      mutationKey: "ASSIGNSTUDENTLIST",
      enabled: assignStatus,
      onSuccess: (res) => {
        console.log("klsdjlfjksdjfs kdflk", res?.data?.studentList);
        setIsLoading(false);
        setAssignStatus(false)
        if (res?.status === true) {
          setData(res?.data?.studentList);
        } else {
        }
      },
    }
  );

  useQuery(
    ["STUDENTASSIGNDELETEAPI"],
    () => {
      return deleteAssignStudentAPI(delete1?.id);
    },
    {
      retry: false,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: 0,
      mutationKey: "STUDENTASSIGNDELETEAPI",
      enabled: delete1.status,
      onSuccess: (res) => {
        // setLoading(false);
        if (res?.status === true) {
          // handleCloseModal();
          
          setAssignStatus(true)
          // toast(res.message, {
          //   theme: "dark",
          //   position: "top-center",
          // });
          dispatch(
            setToastMessage({
              isActive: true,
              message: res?.message,
              type: "success",
            })
          );
          // setChange(true);
          setDelete1({
            status: false
          });
          console.log(res);
        } else {
          dispatch(
            setToastMessage({
              isActive: true,
              message: res?.message,
            })
          );
          setDelete1({
            status: false
          });
          setIsLoading(false);
        }
      },
    }
  );

  return (
    <div>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box className="block w-full h-full p-4 bg-white">
          <div className="flex justify-end">
            <GrClose className="cursor-pointer" onClick={handleClose} />
          </div>
          {isLoading ? (
            <div className="w-full flex justify-center items-center h-[50vh]">
              <LoaderSpiner />
            </div>
          ) : (
            <>
              <h4 className="text-center text-2xl mt-4 mb-6">Assigned Student List</h4>
              {data?.length > 0 ? (
                <TableContainer>
                  <Table aria-label="simple table">
                    <TableHeading tableHeading={ScheduleStudentListHeading} />
                    <TableBody>
                      {data?.length > 0 ? (
                        data?.map((exam, index) => {
                          return (
                            <TableRow
                              key={index}
                              sx={{
                                "&:last-child td, &:last-child th": { border: 0 },
                              }}
                            >
                              <TableCell>{1 + index}</TableCell>
                              <TableCell>{exam?.studentName}</TableCell>
                              <TableCell>{exam?.studentNumber ? "8472865342" : "8748294323"}</TableCell>
                              <TableCell>
                                <CustomMenu
                                  menus={listRef(<MoreVertIcon />, [
                                    [
                                      "View",
                                      () => {
                                        navigate(`/student/${exam?.studentId}`);
                                      },
                                      <></>,
                                    ],
                                    [
                                      "Delete",
                                      () => {
                                        setDelete1({
                                          id: exam?.id,
                                          studentId: exam?.studentId,
                                          status: true
                                        });
                                        setIsLoading(true);
                                      },
                                      <></>,
                                    ],
                                  ])}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })
                      ) : (
                        <TableRow>
                          <TableCell colSpan={4}>
                            <h1 className="text-center">Student List not found</h1>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <div>
                  <h3>Student not assign</h3>
                </div>
              )}
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
};

const listRef = (btnName, list) => {
  return {
    btnName: btnName,
    list: list.map((list) => {
      return {
        itemName: list[0],
        itemClick: list[1],
        itemIcon: list[2],
      };
    }),
  };
};

const CustomMenu = ({ menus }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className="!text-black"
      >
        {menus?.btnName}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        // MenuListProps={{
        //   'aria-labelledby': 'basic-button',
        // }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {menus?.list?.map((item, i) => {
          return (
            <MenuItem onClick={item?.itemClick}>
              {item?.itemName} {item?.itemIcon}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};
export { CustomMenu, listRef };
export default ExamStudentList;
