import React, { useEffect } from 'react'
import { MASTERLISTAPI, SECTIONDETAIL } from '../../services/helpers/Users';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { UpdateSectionForm } from '.';
import { useState } from 'react';

const UpdateFormSection = () => {
    const { id } = useParams();
    const [newValue,setnewValue] = useState(null)
    const [flag,setFlag] = useState(false);
    useQuery(
        ["Section"],
        () => {
          const queryParams = {
            sectionId:id
          };
          const paramsData = {
            queryParams,
          };
          return SECTIONDETAIL(paramsData);
        },
        {
          retry: false,
          keepPreviousData: true,
          refetchOnWindowFocus: false,
          staleTime: 0,
          mutationKey: "SectionList",
          enabled: true,
          onSuccess: (res) => {
            // setLoading(false);
            if (res?.status === true) {
                console.log(res?.data);
                let {time, ...resp} = res?.data;
                let [newTime, second] = time.split(":")
                setnewValue({time:newTime,second:second,...resp});
                setFlag(true);
                
            //   setChange(false);
            //   setIsupdated(false)
              
            //   setInstituteList(res?.data);
            //   setPagination(res?.data.pagination);
            } else {
            }
          },
        }
      );

      const [data, setData] = useState();
    const [arrD, setArrD] = useState([]);

    useQuery(
        ["MASTERLISTAPI"],
        () => {
            const queryParams = {};
            const paramsData = {
                queryParams,
            };
            return MASTERLISTAPI(paramsData);
        },
        {
            retry: false,
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            staleTime: 0,
            mutationKey: "MASTERLISTAPI",
            enabled: true,
            onSuccess: (res) => {
                if (res?.status === true) {
                    console.log("test", res.data);
                    setData(res?.data);
                } else {
                }
            },
        }
    );

    useEffect(() => {
      let arr = [];
      if (newValue?.examTypeIdList) {
          let EType = JSON.parse(newValue?.examTypeIdList)
          for (var i = 0; i < data?.length; i++) {
              if (EType.includes(data[i].id)) {
                  let check = { label: data[i].Name, option: data[i].id };
                  arr.push(check);
              }
          }
          setArrD(arr)
      }
  }, [data, newValue])

  return (
    <div>{newValue && arrD?.length>0 && <UpdateSectionForm setnewValue={setnewValue} newValue={newValue} setFlag={setFlag} flag={flag} arrD={arrD} />}</div>
  )
}

export default UpdateFormSection