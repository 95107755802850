const ReadingCard = ({ data, sectionName, url, sectionImage, instruction }) => {
  return (
    <div className=" p-4 rounded-lg  w-full">
      <h1 className="text-2xl text-center font-semibold mt-2">{sectionName}</h1>
      <p className="mb-4 px-4 mt-4 text-justify">
        <div
          dangerouslySetInnerHTML={{
            __html: instruction,
          }}
        />
      </p>
      <hr />
      <p className="mb-4 px-4 mt-4 text-justify">
        <div
          dangerouslySetInnerHTML={{
            __html: data,
          }}
        />
      </p>
      {sectionImage && <img src={url + sectionImage} alt="laskj" className="max-w-[240px] max-h-[200px] w-[240px] h-[200px]" />}
    </div>
  );
};

export default ReadingCard;
