import { useFormik } from "formik";
import { DashboadPagesLayout } from "../../../components";
import { initialValuesExamSchedule, validationSchemaExamSchedule } from "../../../services/validate/ScheduleExam";
import ScheduleExamForm from "./ScheduleExamForm";
import { useMutation, useQuery } from "react-query";
import { AllExam } from "../../../services/helpers/Institute";
import { useState } from "react";
import { AddScheduleExamAPI } from "../../../services/helpers/ScheduleExam";
import Cookies from "js-cookie";
import { setToastMessage } from "../../../store/toast";
import { useDispatch } from "react-redux";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const AddScheduleExam = () => {
  const navigate = useNavigate();
  const [options, setOptions] = useState();
  const [optionLoading, setOptionLoading] = useState(true);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: initialValuesExamSchedule,
    validationSchema: validationSchemaExamSchedule,
    onSubmit: (values) => {
      let scheduleDate = new Date(values.examScheduleDate);
      const formattedDate = scheduleDate
        .toLocaleDateString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
        .replace(/\//g, "-");
      const payload = {
        ...values,
        instituteId: Cookies.get("userId"),
        examScheduleDate: formattedDate,
      }
      if(Cookies.get("id") != 'institute'){
        let findExam = options?.find((option)=>option?.option == values?.examId)
        payload.instituteId = findExam.addById
      }
      mutate(payload);
    },
  });

  useQuery(
    ["EXAMLISTAPI"],
    () => {
      const queryParams = {};
      if(Cookies.get("id") == 'institute'){
        queryParams.addById = Cookies.get("userId");
      }
      const paramsData = {
        queryParams,
      };
      return AllExam(paramsData);
    },
    {
      retry: false,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: 0,
      mutationKey: "EXAMLISTAPI",
      enabled: true,
      onSuccess: (res) => {
        if (res?.status === true) {
          setOptionLoading(false);
          setOptions(
            res.data.map((user) => {
              return {
                option: user.id,
                label: user.examName,
                addById: user.addById,
              };
            })
          );
        } else {
        }
      },
    }
  );
  const { mutate, isLoading } = useMutation(AddScheduleExamAPI, {
    mutationKey: "addScheduleExam",
    onSuccess: (res) => {
      if (res?.status === false) {
      } else if (res?.status === true) {
        dispatch(
          setToastMessage({
            isActive: true,
            message: res?.message,
            type: "success",
          })
        );
        formik.resetForm();
        navigate("/examschedule")
      }
    },
    onError: () => {},
    onSettled: () => {},
  });
  return (
    <DashboadPagesLayout>
      <div className="w-full">
        <div className="p-8">
          <div className="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
            {optionLoading ? (
              <div className="flex justify-center min-h-[50vh] items-center">
                <CircularProgress className="mt-12" />
              </div>
            ) : (
              <>
              <div>
              <ArrowBackIcon className="cursor-pointer" onClick={()=>{navigate('/examschedule')}} />
                <h1 className="text-center font-semibold text-2xl mb-5 ">Add Exam Schedule</h1>
              </div>
                {options && <ScheduleExamForm formik={formik} options={options} />}
              </>
            )}
          </div>
        </div>
      </div>
    </DashboadPagesLayout>
  );
};

export default AddScheduleExam;
