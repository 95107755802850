import { BASE_URL, API } from "../../utils/defines";
import { putApiCall, getApiCall, postApiCall } from "../../utils/axios";

export const instituteListAPI = async (paramsData) => {
  const url = `${BASE_URL}${API.INSTITUTELIST}`;
  try {
    const response = await getApiCall(url, paramsData);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};


export const instituteDetailsAPI = async (paramsData) => {
  const url = `${BASE_URL}${API.INSTITUTEDETAILS}`;
  try {
    const response = await getApiCall(url, paramsData);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};

export const ExamList = async (paramsData) => {
  const url = `${BASE_URL}${API.EXAMLIST}`;
  try {
    const response = await getApiCall(url, paramsData);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};

export const AllExam = async (paramsData) => {
  const url = `${BASE_URL}${API.ALLEXAM}`;
  try {
    const response = await getApiCall(url, paramsData);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};

export const ExamSearchList = async (paramsData) => {
  const url = `${BASE_URL}${API.ExamSearch}`;
  try {
    const response = await getApiCall(url, paramsData);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};

export const updateInstituteAPI = async (paramsData) => {
  const url = `${BASE_URL}${API.UPDATE_INSTITUTE}`;
  try {
    const response = await putApiCall(url, paramsData);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};

export const AddAssignInstituteAPI = async (payload) => {
  const url = `${BASE_URL}${API.INSTITUTEASSIGNEXAM}`;
  try {
    const response = await postApiCall(url, payload);
    return response;
  } catch (err) {
    throw new Error(err?.data?.error?.message);
  }
};