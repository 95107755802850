import React, { useState, useRef, useEffect } from 'react';
import { ReactMediaRecorder } from 'react-media-recorder';
import { UploadSection } from '../../services/helpers/Users';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
const VideoPreview = ({ stream }) => {
  const videoRef = useRef(null);
  useEffect(() => {
    if (videoRef.current && stream) {
      videoRef.current.srcObject = stream;
    }
  }, [stream]);

  if (!stream) {
    return null;
  }
  return <video ref={videoRef} width={500} height={500} autoPlay controls />;
};
const AuidoTest = ({ video,setCameraSwitch,micStatus, uploadSubmitCallback, id }) => {
  const [show, setShow] = useState();
  console.log("test", video);

  useEffect(() => {
    setShow(video);
  }, [video])
  const formdata = new FormData();
  const [path, setPath] = useState(null);
  const [flag, setFlag] = useState(false);
  const [blob, setBlob] = useState(null);
  const [myState, setMyState] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [videoStatus, setVideoStatus] = useState("idle"); 
  const [uploadedAudio, setUploadedAudio] = useState(false)
  const examination = useSelector((state) => state.examination);
  const onStop = async (stopRecording, status) => {
    console.log("onClick status ::", status);
    await stopRecording();
    console.log("onClick status after ::", status);
    setMyState(true);
  };

  console.log("examinationexamination", examination)

  useEffect(()=>{
    let filterQuestion = examination.questions.find((question)=>(question.id == id && question.answer))
    if(filterQuestion){
      setUploadedAudio(true)
      setPath("https://eacademyeducation.com:8099/section/"+filterQuestion.answer)
    } else {
      setUploadedAudio(false)
    }
    console.log("useeeffectsd", filterQuestion, id)
  },[examination.questions,id])

  const handleStopRecording = (blobUrl, status) => {
    console.log("handleStopRecording", status);
    if (status === "stopped") {
      fetch(blobUrl)
        .then((response) => response.blob())
        .then((blob) => {
          console.log(blob);
          const fileName = 'myFile.mp3';
          const newFile = new File([blob], fileName, { type: blob.type });
          formdata.append("file", newFile);
          setIsLoading(true)
          check(formdata);
        });
    }
  };
  const { mutate: check } = useMutation(UploadSection, {
    mutationKey: "uploadaudiofile",
    onSuccess: (res) => {
      if (res?.status === false) {
      } else if (res?.status === true) {
        uploadSubmitCallback(res?.data?.fileName)
        setPath(res?.data?.path);
        setFlag(true);
        setIsLoading(false)
        setVideoStatus("idle");
      }
    },
    onError: () => { },
    onSettled: () => { },
    onError: () => { },
    onSettled: () => { },
  });
  const resetAudioStatus = () => {
    setVideoStatus("uploaded");
  };
  return (
    <div>
      <div className="max-w-md mx-auto mt-8 p-8 bg-white rounded-lg shadow-md">
        {<ReactMediaRecorder
          video={video}
          render={({ status, startRecording, stopRecording, mediaBlobUrl, previewStream }) => (
            <div>
              {isLoading && <p className="text-center mb-4">Uploading...</p>}
              {/* <p className="text-center mb-4">{status}</p> */}
              {status !== "stopped" || videoStatus == "idle" ? (
                <div className="flex justify-center">
                  {videoStatus!='recording' && <button
                    onClick={()=>{
                      if(micStatus !== "off"){
                        startRecording();
                        setCameraSwitch(true);
                        setVideoStatus('recording')
                        setUploadedAudio(false)
                      }
                      
                    }}
                    disabled={status === 'recording'}
                    className="bg-blue-500 text-white py-2 px-4 rounded mr-2"
                  >
                    {uploadedAudio? "Again Start Recording": "Start Recording"}
                  </button>}
                  {videoStatus == 'recording' && <button

                    onClick={() => {
                      stopRecording();
                      setVideoStatus('recorded')
                    }}
                    disabled={status === 'idle'}
                    className="bg-red-500 text-white py-2 px-4 rounded"
                  >
                    Stop Recording
                  </button>}
                </div>
              ) : null}
              {video ? <div>
              {status == "recording" && (
                <div className="mt-4">
                  {/* <h2 className="text-lg font-semibold mb-2">Live Preview:</h2> */}
                  <VideoPreview stream={previewStream} />
                </div>
              )}
              </div>:null}
              <div className='flex justify-center'>
              {status === "stopped" && videoStatus == "recorded" && (
                <button
                  className="bg-blue-500 text-white py-2 px-4 rounded mr-2"
                  onClick={() => {handleStopRecording(mediaBlobUrl, status);resetAudioStatus();}}
                >
                  Upload
                </button>
              )}
            </div>
            {/** before uploaded video preview */}
              {status === "stopped" && videoStatus == 'recorded' && (
                <div className="mt-4">
                  <h2 className="text-lg font-semibold mb-2">{}</h2>
                  <video
                    src={mediaBlobUrl}
                    controls
                    autoPlay
                    loop
                    className="w-full rounded shadow-md"
                  />
                </div>
              ) }
              {/** after uploaded video preview */}
              {(status === "stopped" && uploadedAudio) ? (
                  <div className="mt-4">
                    <h2 className="text-lg font-semibold mb-2">Recorded Audio:</h2>
                    <video
                      src={path}
                      controls
                      autoPlay
                      loop
                      className="w-full rounded shadow-md"
                    />
                  </div>
                ) : null}
            </div>
          )}
        />}
      </div>
    </div>
  );
};
export default AuidoTest;