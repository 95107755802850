export const readingQuiz = [
  {
    id: 1,
    question: "Participants in the Learner Persistence study were all drawn from the same ?",
    answers: [
      { value: "value1", option: "age group." },
      { value: "value2", option: "geographical area." },
      { value: "value3", option: "socio-economic level." },
    ],
  },
  {
    id: 2,
    question: "The study showed that when starting their course, older students were most concerned about ?",
    answers: [
      { value: "value1", option: "age group." },
      { value: "value2", option: "geographical area." },
      { value: "value3", option: "socio-economic level." },
    ],
  },
  {
    id: 3,
    question: "What do you mean by older students always focused about ?",
    answers: [
      { value: "value1", option: "Taking Challanges." },
      { value: "value2", option: " Playing Always" },
      { value: "value3", option: "Hill Climbing" },
    ],
  },
  {
    id: 4,
    question: "What do you mean by word epidemic ?",
    answers: [
      { value: "value1", option: "Catching." },
      { value: "value2", option: " Pandemic" },
      { value: "value3", option: "Sleeping" },
    ],
  },
  {
    id: 5,
    question: "What do you mean by older students always focused about ?",
    answers: [
      { value: "value1", option: "Taking Challanges." },
      { value: "value2", option: " Playing Always" },
      { value: "value3", option: "Hill Climbing" },
    ],
  },
  {
    id: 6,
    question: "The study showed that when starting their course, older students were most concerned about ?",
    answers: [
      { value: "value1", option: "age group." },
      { value: "value2", option: "geographical area." },
      { value: "value3", option: "socio-economic level." },
    ],
  },
  {
    id: 7,
    question: "What do you mean by word epidemic ?",
    answers: [
      { value: "value1", option: "Catching." },
      { value: "value2", option: " Pandemic" },
      { value: "value3", option: "Sleeping" },
    ],
  },
  {
    id: 8,
    question: "The study showed that when starting their course, older students were most concerned about ?",
    answers: [
      { value: "value1", option: "age group." },
      { value: "value2", option: "geographical area." },
      { value: "value3", option: "socio-economic level." },
    ],
  }
];

export const writingQuiz = [
  {
    id: 1,
    question: "Participants in the Learner Persistence study were all drawn from the same ?",
    answers: [
      { value: "value1", option: "age group." },
      { value: "value2", option: "geographical area." },
      { value: "value3", option: "socio-economic level." },
    ],
  },
  {
    id: 2,
    question: "The study showed that when starting their course, older students were most concerned about ?",
    answers: [
      { value: "value1", option: "age group." },
      { value: "value2", option: "geographical area." },
      { value: "value3", option: "socio-economic level." },
    ],
  },
  {
    id: 3,
    question: "Participants in the Learner Persistence study were all drawn from the same ?",
    answers: [
      { value: "value1", option: "age group." },
      { value: "value2", option: "geographical area." },
      { value: "value3", option: "socio-economic level." },
    ],
  },
  {
    id: 4,
    question: "The study showed that when starting their course, older students were most concerned about ?",
    answers: [
      { value: "value1", option: "age group." },
      { value: "value2", option: "geographical area." },
      { value: "value3", option: "socio-economic level." },
    ],
  },
  {
    id: 5,
    question: "Participants in the Learner Persistence study were all drawn from the same ?",
    answers: [
      { value: "value1", option: "age group." },
      { value: "value2", option: "geographical area." },
      { value: "value3", option: "socio-economic level." },
    ],
  },
  {
    id: 6,
    question: "The study showed that when starting their course, older students were most concerned about ?",
    answers: [
      { value: "value1", option: "age group." },
      { value: "value2", option: "geographical area." },
      { value: "value3", option: "socio-economic level." },
    ],
  },
  {
    id: 7,
    question: "Participants in the Learner Persistence study were all drawn from the same ?",
    answers: [
      { value: "value1", option: "age group." },
      { value: "value2", option: "geographical area." },
      { value: "value3", option: "socio-economic level." },
    ],
  },
  {
    id: 8,
    question: "The study showed that when starting their course, older students were most concerned about ?",
    answers: [
      { value: "value1", option: "age group." },
      { value: "value2", option: "geographical area." },
      { value: "value3", option: "socio-economic level." },
    ],
  },
  
 
];
