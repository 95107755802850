import { useFormik } from "formik";
import { useMutation } from "react-query";
import { InstituteForm } from "../../components";
import { setToastMessage } from "../../store/toast";
import { useDispatch, useSelector } from "react-redux";
import { AddInstitute } from "../../services/helpers/Users";
import { initialValuesInstitute, validationSchemaInstitute } from "../../services/validate/Institute";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from "react-router-dom";

const AddInstituteForm = () => {
  const { role, userId } = useSelector((state) => state.profile);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { mutate, isLoading } = useMutation(AddInstitute, {
    mutationKey: "addInstitute",
    onSuccess: (res) => {
      if (res?.status === false) {
      } else if (res?.status === true) {
        dispatch(
          setToastMessage({
            isActive: true,
            message: res?.message,
            type: "success",
          })
        );
        navigate('/institute')
        formik.resetForm();
      }
    },
    onError: () => {},
    onSettled: () => {},
  });
  const formik = useFormik({
    initialValues: {...initialValuesInstitute,country:"IN"},
    validationSchema: validationSchemaInstitute,
    onSubmit: (values) => {
      const payload = {
        ...values,
        addById: userId,
        role: "institute",
      };
      mutate(payload);
    },
  });

  return (
    <div className="w-full ">
      <div className="p-8">
        <div className="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6 ">
          <div>
          <ArrowBackIcon className="cursor-pointer" onClick={()=>{navigate('/institute')}} />
          <h1 className="text-xl text-center font-semibold mb-6 pb-4">Add Institute</h1>
          </div>
          <InstituteForm formik={formik} isLoading={isLoading} />
        </div>
      </div>
    </div>
  );
};

export default AddInstituteForm;
