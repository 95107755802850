import React, { useState, useRef, useEffect } from "react";
import { ReactMediaRecorder } from "react-media-recorder";
import { UploadSection } from "../../services/helpers/Users";
import { useMutation } from "react-query";
import { mediaUploadingToggle } from "../../store/examination";
import { useDispatch, useSelector } from "react-redux";

const VideoPreview = ({ stream }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current && stream) {
      videoRef.current.srcObject = stream;
    }
  }, [stream]);

  if (!stream) {
    return null;
  }

  return <video ref={videoRef} width={500} height={500} autoPlay controls />;
};

const VideoTest = ({ questionsAnswersHandle, id, mediaType=true }) => {
  const dispatch = useDispatch()
  const formdata = new FormData();
  const [isLoading, setIsLoading] = useState(false)
  const [path, setPath] = useState(null);
  const [flag, setFlag] = useState(false);
  const [blob, setBlob] = useState(null);
  const [myState, setMyState] = useState(false);
  const [videoStatus, setVideoStatus] = useState("idle"); // Added videoStatus state

  const [uploadedVideo, setUploadedVideo] = useState(false)
  const examination = useSelector((state) => state.examination);
  useEffect(()=>{
    let filterQuestion = examination.questions.find((question)=>(question.id == id && question.answer))
    if(filterQuestion){
      setUploadedVideo(true)
      setPath("https://eacademyeducation.com:8099/section/"+filterQuestion.answer)
    } else {
      setUploadedVideo(false)
    }
    console.log("useeeffectsd", filterQuestion, id)
  },[examination.questions,id])

  const onStop = async (stopRecording, status) => {
    console.log("onClick status ::", status);
    await stopRecording();
    console.log("onClick status after ::", status);
    setMyState(true);
    setVideoStatus('recorded')
  };

  const handleStopRecording = (blobUrl, status) => {
    console.log("handleStopRecording", status);
    if (status === "stopped") {
      fetch(blobUrl)
        .then((response) => response.blob())
        .then((blob) => {
          dispatch(mediaUploadingToggle(true))
          const fileName = "myFile.mp4";
          const newFile = new File([blob], fileName, { type: blob.type });
          formdata.append("file", newFile);
          setIsLoading(true)
          check(formdata);
        });
    }
  };

  const { mutate: check } = useMutation(UploadSection, {
    mutationKey: "uploadvideofile",
    onSuccess: (res) => {
      dispatch(mediaUploadingToggle(false))
      if (res?.status === false) {
      } else if (res?.status === true) {
        questionsAnswersHandle(id, res?.data?.fileName);
        setPath(res?.data?.path);
        setFlag(true);
        setIsLoading(false)
        setVideoStatus("idle"); // Set videoStatus to 'uploaded' after successful upload
      }
    },
    onError: () => {},
    onSettled: () => {},
  });

  // Function to reset video status
  const resetVideoStatus = () => {
    setVideoStatus("uploaded");
  };

  return (
    <div className="max-w-md mx-auto mt-8 p-8 bg-white rounded-lg shadow-md">
      <ReactMediaRecorder
        video={mediaType}
        render={({
          status,
          startRecording,
          stopRecording,
          mediaBlobUrl,
          previewStream,
          clearBlobUrl,
        }) => (
          <>
            {isLoading && <p className="text-center mb-4">Uploading...</p>}
            {status !== "stopped" || videoStatus == "idle" ? (
              <div className="flex justify-center">
                {videoStatus!='recording' &&<button
                  onClick={() => {
                    startRecording();
                    clearBlobUrl();
                    setPath();
                    setVideoStatus('recording')
                    setUploadedVideo(false)
                  }}
                  disabled={status === "recording"}
                  className="bg-blue-500 text-white py-2 px-4 rounded mr-2"
                >
                  {uploadedVideo? "Again Start Recording": "Start Recording"}
                </button>}
                {videoStatus == 'recording' && <button
                  onClick={() => onStop(stopRecording, status)}
                  disabled={status === "idle"}
                  className="bg-red-500 text-white py-2 px-4 rounded"
                >
                  Stop Recording
                </button>}
              </div>
            ) : null}
            {status === "recording" && (
              <div className="mt-4">
                <h2 className="text-lg font-semibold mb-2">Live Preview:</h2>
                <VideoPreview stream={previewStream} />
              </div>
            )}
            <div className="flex justify-center">
              {status === "stopped" && videoStatus == "recorded" && (
                <button
                  className="bg-blue-500 text-white py-2 px-4 rounded mr-2"
                  onClick={() => {
                    handleStopRecording(mediaBlobUrl, status);
                    resetVideoStatus(); // Reset video status after clicking the Upload button
                  }}
                >
                  Upload
                </button>
              )}
            </div>
            {/** before uploaded video preview */}
            {(status === "stopped" && videoStatus == "recorded") && (
              <div className="mt-4">
              <h2 className="text-lg font-semibold mb-2">Recorded Video:</h2>
              <video
                src={mediaBlobUrl}
                controls
                autoPlay
                loop
                className="w-full rounded shadow-md"
                />
            </div>
            ) }
            {/** after uploaded video preview */}
            {(status === "stopped" && uploadedVideo) ? (
              <div className="mt-4">
                <h2 className="text-lg font-semibold mb-2">Recorded Video:</h2>
                <video
                  src={path}
                  controls
                  autoPlay
                  loop
                  className="w-full rounded shadow-md"
                />
              </div>
            ) : null}
          </>
        )}
      />
    </div>
  );
};

export default VideoTest;
