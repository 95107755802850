import { DashboadPagesLayout, LoaderSpiner, UpdateStudent } from "../../../components";
import BreadcrumbsC from "../../../components/common/BreadcrumbsC";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { useQuery } from "react-query";
import { instituteDetailsAPI } from "../../../services/helpers/Institute";
import { STUDENTDETAILSAPI } from "../../../services/helpers/Users";

const breadcrumbb = [
  {
    id: 1,
    title: "Dashboard",
    link: "/dashboard",
  },
  {
    id: 2,
    title: "Student",
    link: "/students",
  },
  {
    id: 3,
    title: "Student Update",
    link: "/exams/details",
  },
];

const StudentUpdate = () => {
  const [studentDetail, setStudentDetail] = useState(null);
  const [initialValue, setInitialValue] = useState(null);
  const [studentId, setStudentId] = useState(null);

  const { id } = useParams();

  useQuery(
    ["STUDENTDETAILS"],
    () => {
      const queryParams = {
        userId: id,
      };
      const paramsData = {
        queryParams,
      };
      return STUDENTDETAILSAPI(paramsData);
    },
    {
      retry: false,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: 0,
      mutationKey: "STUDENTDETAILS",
      enabled: true,
      onSuccess: (res) => {
        if (res?.status === true) {
          setStudentDetail(res?.data);
          // setStudentId(res?.data?.instituteId);
          setInitialValue({
            userId: res.data.userId,
            firstName: res.data.firstName,
            lastName: res.data.lastName,
            gender: res.data.genderId,
            fatherName: res.data.fatherName,
            phone: res.data.phone,
            email: res.data.email,
            role: res.data.role,
            course: res.data.course,
            degree: res.data.degree,
            university: res.data.university,
            applyFor: res.data.applyFor,
            address: res.data.address,
            city: res.data.city,
            state: res.data.state,
            country: res.data.country,
            pincode: res.data.pincode,
            profileImg: res.data.profileImg,
            instituteId: res.data.instituteId,
          });
        } else {
        }
      },
    }
  );

  return (
    <DashboadPagesLayout>
      <div className="w-full px-8 py-4">
        <div className="flex justify-start items-center">
          <BreadcrumbsC data={breadcrumbb} />
        </div>
        <div className={`bg-white mt-8 rounded-lg p-5 mb-10 min-h-[60vh] `}>
          <div className="flex w-full">
            {studentDetail ? (
              <UpdateStudent initialValue={initialValue} />
            ) : (
              <div className="w-full flex justify-center items-center h-[50vh]">
                <LoaderSpiner />
              </div>
            )}
          </div>
        </div>
      </div>
    </DashboadPagesLayout>
  );
};

export default StudentUpdate;
