import { RadioButton } from "../../Forms";
import { useNavigate } from "react-router-dom";
import { MyTimer } from "../../Timer";
import { useState } from "react";
import { readingQuiz } from "./mockQuiz";

const ReadingQuiz = ({ change }) => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  // const navigate = useNavigate();
  // const [running,setRunning] = useState(true)
  
  // const time = new Date();
  // time.setSeconds(time.getSeconds() + 8); 
  // if(!running){
  //   return  navigate("/writing-exam")
   
  // }
  console.log(change)
  

  const handleChangeQuestion = (event) => {
    setCurrentQuestion(parseInt(event.target.value, 10));
  };

  const handleNextQuestion = () => {
    setCurrentQuestion(currentQuestion + 1);
  };

  const handlePrevQuestion = () => {
    setCurrentQuestion(currentQuestion - 1);
  };

  const currentQuizItem = readingQuiz[change];

  if (!currentQuizItem) {
    return <div className="mt-16">No more questions.</div>;
  }
  return (
    <div className="mt-16">

      {/* <MyTimer running={setRunning} expiryTimestamp={time} /> */}

      <ul className="">
        {
          <li key={currentQuizItem.id} className="mt-2">
            <div className="text-gray-500 flex">
              <p className="mr-4 font-semibold text-gray-900">Ques.{currentQuizItem.id}</p>
              <p>{currentQuizItem.question}</p>
            </div>
            <div className="mt-6"><RadioButton id={currentQuizItem.id} answers={currentQuizItem.answers} /></div>
          </li>
        }
      </ul>
    </div>
  );
};

export default ReadingQuiz;
