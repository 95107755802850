import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setToastMessage } from "../../store/toast";

const RadioButton = ({ answers }) => {
  return (
    <div>
      <FormControl className="w-full">
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group"
        >
          <div className="md:grid md:grid-cols-2 pl-10 flex flex-col">
            {answers.map((item, index) => (
              <FormControlLabel
                key={index}
                value={item.value}
                control={<Radio />}
                label={item.option}
              />
            ))}
          </div>
        </RadioGroup>
      </FormControl>
    </div>
  );
};

export const RadioButtonExam = ({ answers, questionsAnswersHandle, id }) => {
  const dispatch = useDispatch()
  const [selectedAnswer, setSelectedAnswer] = useState(0);
  const examDetails = useSelector((state) => state.examination.questions);
  console.log("examlislksdi", examDetails)
  useEffect(() => {
    let findQuestion = examDetails.find((question) => question.id == id);
    if (findQuestion) {
      setSelectedAnswer(findQuestion.answer);
    }
  }, []);
  return (
    <div className="">
      <FormControl className="w-full">
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group"
          onChange={(event) => {
            setSelectedAnswer(parseInt(event.target.value));

            // Set selectedAnswer to the ID of the selected radio button
          }}
        >
          <div className="md:grid md:grid-cols-2 pl-10 grid grid-cols-2 sm:grid sm:grid-cols-2 flex-col">
            {answers.map((item, index) => (
              <FormControlLabel
                key={index}
                value={item.id}
                control={<Radio />}
                label={item.title}
                checked={selectedAnswer == item.id}
              />
            ))}
          </div>
        </RadioGroup>
      </FormControl>
        <Button
          variant="contained"
          className="mt-4 float-right"
          onClick={() => {
            console.log("selectedAnswerselectedAnswer",selectedAnswer)
              questionsAnswersHandle(id, selectedAnswer);
          }}
        >
          Save
        </Button>
    </div>
  );
};

export default RadioButton;
