import { useState } from "react";
import { useDispatch } from "react-redux";
import ModeIcon from "@mui/icons-material/Mode";
import DeleteModal from "../../common/DeleteModal";
import DeleteIcon from "@mui/icons-material/Delete";
import { setToastMessage } from "../../../store/toast";
import { useMutation, useQueryClient } from "react-query";
import { deleteQuestionAPI } from "../../../services/helpers/Question";
import WrittenUpdateModal from "../../common/UpdateQuestionModal/WrittenUpdateModal";

const WrittenExamDetails = ({ questions, examDetails, index }) => {
  const { question } = questions;
  const [openModal, setOpenModal] = useState(false);
  const [questionId, setQuestionId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [initialValue, setInitialValue] = useState(null);
  const [questionDetails, setQuestionDetails] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleCloseDeleteModal = () => setOpenDeleteModal(false);

  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleCloseModal = () => setOpenModal(false);

  const handleClick = (event, id) => {
    setOpenModal(true);
    setQuestionId(questions.id);
    setQuestionDetails(examDetails);
    setInitialValue({ questions: [{ question: questions.question, time: questions.time, questionType: questions.questionTypeId }]});
  };

  const { mutate: mutateDeleteQuestion, isLoading } = useMutation(deleteQuestionAPI, {
    mutationKey: "EXAMDETAILSAPI",
    onSuccess: (data) => {
      setOpenDeleteModal(false);
      if (data.status === true) {
        dispatch(
          setToastMessage({
            isActive: true,
            message: data?.message,
            type: "success",
          })
        );
        queryClient.invalidateQueries("EXAMDETAILSAPI");
      }
    },
    onError: () => {},
    onSettled: () => {},
  });

  const handleDelete = () => {
    const queryParams = {
      questionId: questionId,
    };
    const paramsData = {
      queryParams,
    };
    mutateDeleteQuestion(paramsData);
  };

  return (
    <>
      <h2 className="text-gray-700 text-lg font-medium m-0 p-0 items-center flex-wrap mt-8 mb-4">
        <span>Question. {index}</span>
        {/* {question} */}
        <div className="mb-4 text-base" dangerouslySetInnerHTML={{__html: question}} />
        <span className="p-1  shadow bg-green-100 ml-2 rounded cursor-pointer text-blue-500">
          <ModeIcon fontSize="25px" onClick={handleClick} />
        </span>
        {/* <span className="p-1 shadow bg-red-100 ml-2 rounded cursor-pointer text-red-500">
          <DeleteIcon
            fontSize="25px"
            onClick={() => {
              setAnchorEl(null);
              setOpenDeleteModal(true);
              setQuestionId(questions.id);
            }}
          />
        </span> */}
      </h2>
      <DeleteModal
        open={openDeleteModal}
        loading={isLoading}
        deleteHandle={() => {
          handleDelete();
          setLoading(true);
        }}
        handleClose={handleCloseDeleteModal}
        title="Are you sure to delete!!"
        deleteText="Delete Question"
        className="w-100 h-100 bg-slate-800"
      />
      {initialValue && (
        <WrittenUpdateModal
          open={openModal}
          questionId={questionId}
          initialValue={initialValue}
          handleClose={handleCloseModal}
          questionDetails={questionDetails}
          headingText="Update Written Question"
          className="w-100 h-100 bg-slate-800"
        />
      )}
    </>
  );
};

export default WrittenExamDetails;
