// MainComponent.js
import React, { useState } from "react";
import { Table, TableBody, TableRow, TableContainer, TableCell, CircularProgress, Card, Tooltip } from "@mui/material";
import { ScheduleStudentListHeading } from "../../../mockData";
import { CustomPagination, TableHeading } from "../../../components";
import { useNavigate } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from "@mui/icons-material/MoreVert";

const ScheduleStudentTable = ({ data, isLoading, hasNextPage, page, setPage, handlePrevClick, handleNextClick ,pagination}) => {
  const navigate = useNavigate();
  console.log("Testing it is",data);
  console.log("isLoadingisLoadingisLoading==> ", isLoading)
  if (isLoading) {
    return (
      <div className="flex justify-center min-h-[50vh] items-center w-full">
        <CircularProgress className="mt-12" />
      </div>
    );
  }

  return (
    <>
      <div className="mb-4">
        <div className=" justify-center">
          <div className="bg-blue-50 shadow-sm rounded">
            <div className="py-4 px-6 rounded-b">
              <div className="flex justify-center mb-2 items-center gap-2 border-b pb-3">
                <h2 className="text-center text-xl md:text-2xl font-medium capitalize truncate">
                  <span>{data?.data?.examDetails?.examName}</span>
                </h2>
                <Tooltip title="Exam Details ">
                  <InfoIcon className="cursor-pointer" onClick={() => {
                    navigate(`/exams/${data?.data?.examDetails?.id}`)
                  }} />
                </Tooltip>
              </div>
              <div className="flex flex-col md:flex-row">
                <div className="flex flex-1 flex-col  p-2">
                  <p>
                    Time Duration:
                  </p>
                  <p className="text-gray-700">
                    {data?.data?.examDetails?.duration} min
                  </p>
                </div>
                <div className="flex flex-1 flex-col md:border-x flexBorderY p-2">
                  <p>
                    Exam Module:
                  </p>
                  <p className="text-gray-700">
                    {data?.data?.examDetails?.examType.includes("[") ?
                      JSON.parse(data?.data?.examDetails?.examType).map((examType) => {
                        return (
                          <>
                            {(examType == "1"
                              ? "Reading"
                              : examType == "2"
                                ? "Writing"
                                : examType == "3"
                                  ? "Listening"
                                  : examType == "4"
                                    ? "Speaking"
                                    : examType == "11"
                                    ? "Academic Reading"
                                    : examType == "12"
                                    ? "General Reading"
                                    : null)}
                            <br />
                          </>
                        )
                      })
                      : <>{data?.data?.examDetails?.examType == "1"
                        ? "Reading"
                        : data?.data?.examDetails?.examType == "2"
                          ? "Writing"
                          : data?.data?.examDetails?.examType == "3"
                            ? "Listening"
                            : data?.data?.examDetails?.examType == "4"
                              ? "Speaking"
                              : null}</>}
                  </p>
                </div>
                {/* <div className="flex flex-1 flex-col p-2">
                  <p>
                    Total band:
                  </p>
                  <p className="text-gray-700">
                    {data?.data?.examDetails?.totalMarks}
                  </p>
                </div> */}
              </div>

            </div>
          </div>
        </div>
      </div>
      <h4 className="text-center text-2xl my-4">Student List</h4>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHeading tableHeading={ScheduleStudentListHeading} />
          <TableBody>
            {data?.data?.studentList?.length > 0 ?
              data?.data?.studentList.map((exam, index) => {
                return (
                  <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell>{1 + index}</TableCell>
                    <TableCell>{exam?.studentName}</TableCell>
                    <TableCell>{exam?.studentNumber ? "8472865342" : "8748294323"}</TableCell>
                    <TableCell>
                      <CustomMenu menus={listRef(<MoreVertIcon />, [["View", () => { navigate(`/student/${exam?.studentId}`) }, <></>]])} />
                    </TableCell>
                  </TableRow>
                );
              })
              :
              <TableRow>
                <TableCell colSpan={4}><h1 className="text-center">Student List not found</h1></TableCell>
              </TableRow>

            }
          </TableBody>
        </Table>
      </TableContainer>
      {/* <div>
        <CustomPagination data={data} page={page} setPage={setPage} paginationData={pagination} />
      </div> */}
    </>
  );
};


const listRef = (btnName, list) => {
  return {
    btnName: btnName,
    list: list.map((list) => {
      return {
        itemName: list[0],
        itemClick: list[1],
        itemIcon: list[2]
      }
    })
  }
}


const CustomMenu = ({ menus }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        className="!text-black"
      >
        {menus?.btnName}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        // MenuListProps={{
        //   'aria-labelledby': 'basic-button',
        // }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {menus?.list?.map((item, i) => {
          return (
            <MenuItem onClick={item?.itemClick}>{item?.itemName}{" "}{item?.itemIcon}</MenuItem>
          )
        })}

      </Menu>
    </>
  )
}
export { CustomMenu, listRef }
export default ScheduleStudentTable;
