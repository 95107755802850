import Badge from "@mui/material/Badge";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import ModeIcon from "@mui/icons-material/Mode";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { useState, useEffect } from "react";

const StudentResultListTab = ({ questions }) => {
  let questionCount = {
    mcq: 0,
    fill: 0,
    written: 0,
    boolean: 0,
  };
  let questionCounts = {
    mcq: 0,
    fill: 0,
    written: 0,
    boolean: 0,
  };
  const [value, setValue] = useState("1");
  const [badgeCount, setBadgeCount] = useState();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    questions?.forEach((question, i) => {
      if (question.questionTypeId === 1) {
        questionCounts.mcq += 1;
      } else if (question.questionTypeId === 2) {
        questionCounts.fill += 1;
      } else if (question.questionTypeId === 3) {
        questionCounts.written += 1;
      } else if (question.questionTypeId === 4) {
        questionCounts.boolean += 1;
      }
    });
    setBadgeCount(questionCounts);
  }, []);

  return (
    <>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          {/* <TabList onChange={handleChange} aria-label="lab API tabs example" indicatorColor="primary" centered> */}
          <TabList onChange={handleChange} aria-label="lab API tabs example" indicatorColor="primary" variant="fullWidth">
            <Tab
              label={
                <Badge badgeContent={badgeCount?.mcq} color="primary">
                  <span className="pr-3">MCQ</span>
                </Badge>
              }
              value="1"
            />
            <Tab
              label={
                <Badge badgeContent={badgeCount?.fill} color="primary">
                  <span className="pr-3">Fill In The Blank</span>
                </Badge>
              }
              value="2"
            />
            <Tab
              label={
                <Badge badgeContent={badgeCount?.written} color="primary">
                  <span className="pr-3">written</span>
                </Badge>
              }
              value="3"
            />
            <Tab
              label={
                <Badge badgeContent={badgeCount?.boolean} color="primary">
                  <span className="pr-3">Boolean</span>
                </Badge>
              }
              value="4"
            />
          </TabList>
        </Box>
        <TabPanel value="1">
          <div className="mb-2"></div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {questions?.map((question, i) => {
              if (question.questionTypeId === 1) {
                questionCount.mcq += 1;
              }
              const { submittedAnswer, submittedAttempt, options, correctAnswer, id } = question;
              return (
                <>
                  {question?.questionTypeId == 1 && (
                    <div key={i}>
                      {/* <McqQuestionDetails questions={question} examDetails={examDetails} index={questionCount.mcq} /> */}
                      <h2 className="text-gray-700 text-lg font-medium m-0 p-0 items-center flex-wrap mt-8 mb-4">
                        <span className="">Question. {questionCount.mcq}</span>

                        <div className="mb-5 text-base" dangerouslySetInnerHTML={{ __html: question?.question }} />
                      </h2>

                      <ul className="text-gray-700 list-aplpha list-inside ml-4 mt-2">
                        {options.map((option, i) => {
                          return <li key={i}>{option.title}</li>;
                        })}
                      </ul>
                      <h2 className="flex text-gray-700 text-lg font-normal mt-1">
                        {submittedAttempt ? (
                          <>
                            Student Answer: <span className="text-green-500 ml-2"> {submittedAnswer}</span>
                          </>
                        ) : (
                          <span>Not Attempt</span>
                        )}
                      </h2>
                      <h2 className="flex text-gray-700 text-lg font-normal mt-1">
                        Answer: <span className="text-green-500 ml-2"> {correctAnswer}</span>
                      </h2>
                    </div>
                  )}
                </>
              );
            })}
          </div>
        </TabPanel>
        <TabPanel value="2">
          <div className="mb-2"></div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {questions.map((question, i) => {
              if (question.questionTypeId === 2) {
                questionCount.fill += 1;
              }
              const { submittedAnswer, submittedAttempt, correctAnswer, id } = question;
              return (
                question.questionTypeId === 2 && (
                  <div key={i}>
                    <h2 className="text-gray-700 text-lg font-medium m-0 p-0 items-center flex-wrap mt-8 mb-4">
                      <span className="">Question. {questionCount.fill}</span>

                      <div className="mb-5 text-base" dangerouslySetInnerHTML={{ __html: question?.question }} />
                    </h2>

                    <h2 className="flex text-gray-700 text-lg font-normal mt-1">
                      {submittedAttempt ? (
                        <>
                          Student Answer: <span className="text-green-500 ml-2"> {submittedAnswer}</span>
                        </>
                      ) : (
                        <span>Not Attempt</span>
                      )}
                    </h2>
                    <h2 className="flex text-gray-700 text-lg font-normal mt-1">
                      Answer: <span className="text-green-500 ml-2"> {correctAnswer}</span>
                    </h2>
                  </div>
                )
              );
            })}
          </div>
        </TabPanel>
        <TabPanel value="3">
          <div className="mb-2"></div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {questions.map((question, i) => {
              if (question.questionTypeId === 3) {
                questionCount.written += 1;
              }
              const { submittedAnswer, submittedAttempt, correctAnswer, id } = question;
              return (
                question.questionTypeId === 3 && (
                  <div key={i}>
                    <h2 className="text-gray-700 text-lg font-medium m-0 p-0 items-center flex-wrap mt-8 mb-4">
                      <span>Question. {questionCount?.written}</span>
                      <div className="mb-4 text-base" dangerouslySetInnerHTML={{ __html: question?.question }} />
                    </h2>
                    <h2 className="flex text-gray-700 text-lg font-normal mt-1">
                      {submittedAttempt ? (
                        <>
                          Student Answer: <span className="text-green-500 ml-2"> {submittedAnswer}</span>
                        </>
                      ) : (
                        <span>Not Attempt</span>
                      )}
                    </h2>
                    <h2 className="flex text-gray-700 text-lg font-normal mt-1">
                      Answer: <span className="text-green-500 ml-2"> {correctAnswer}</span>
                    </h2>
                  </div>
                )
              );
            })}
          </div>
        </TabPanel>
        <TabPanel value="4">
          <div className="mb-2"></div>
          {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {questions.map((question, i) => {
              if (question.questionTypeId === 4) {
                questionCount.boolean += 1;
              }
              const { submittedAnswer, submittedAttempt, options, correctAnswer, id } = question;
              return (
                question.questionTypeId === 4 && (
                  <div key={i}>
                    <h2 className="text-gray-700 text-lg font-medium m-0 p-0 items-center flex-wrap mt-8 mb-4">
                      <span>Question. {questionCount?.boolean}</span>
                      <div className="mb-4 text-base" dangerouslySetInnerHTML={{ __html: question?.question }} />
                    </h2>
                    <ul className="text-gray-700 list-aplpha list-inside ml-4 mt-2">
                      {options.map((option, i) => {
                        return <li key={i}>{option.title}</li>;
                      })}
                    </ul>
                    <h2 className="flex text-gray-700 text-lg font-normal mt-1">
                      {submittedAttempt ? (
                        <>
                          Student Answer: <span className="text-green-500 ml-2"> {submittedAnswer == "1" ? "a" : "b"}</span>
                        </>
                      ) : (
                        <span>Not Attempt</span>
                      )}
                    </h2>
                    <h2 className="flex text-gray-700 text-lg font-normal mt-1">
                      Answer: <span className="text-green-500 ml-2"> {correctAnswer == "1" ? "a" : "b"}</span>
                    </h2>
                  </div>
                )
              );
            })}
          </div> */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {questions?.map((question, i) => {
              if (question.questionTypeId === 4) {
                questionCount.boolean += 1;
              }
              const { submittedAnswer, submittedAttempt, options, correctAnswer, id } = question;
              return (
                <>
                  {question?.questionTypeId == 4 && (
                    <div key={i}>
                      {/* <McqQuestionDetails questions={question} examDetails={examDetails} index={questionCount.mcq} /> */}
                      <h2 className="text-gray-700 text-lg font-medium m-0 p-0 items-center flex-wrap mt-8 mb-4">
                        <span className="">Question. {questionCount.boolean}</span>

                        <div className="mb-5 text-base" dangerouslySetInnerHTML={{ __html: question?.question }} />
                      </h2>

                      <ul className="text-gray-700 list-aplpha list-inside ml-4 mt-2">
                        {options.map((option, i) => {
                          return <li key={i}>{option.title}</li>;
                        })}
                      </ul>
                      <h2 className="flex text-gray-700 text-lg font-normal mt-1">
                        {submittedAttempt ? (
                          <>
                            Student Answer: <span className="text-green-500 ml-2"> {submittedAnswer}</span>
                          </>
                        ) : (
                          <span>Not Attempt</span>
                        )}
                      </h2>
                      <h2 className="flex text-gray-700 text-lg font-normal mt-1">
                        Answer: <span className="text-green-500 ml-2"> {correctAnswer}</span>
                      </h2>
                    </div>
                  )}
                </>
              );
            })}
          </div>
        </TabPanel>
      </TabContext>
    </>
  );
};

export default StudentResultListTab;
