import { configureStore } from "@reduxjs/toolkit";
import profileReducer from "./profile";
import studentReducer from "./student";
import toastReducer from "./toast";
import examReducer from "./exam";
import examinationReducer from "./examination";

export const store = configureStore({
  reducer: {
    profile: profileReducer,
    student: studentReducer,
    toast_data: toastReducer,
    exam: examReducer,
    examination: examinationReducer,
  },
});
