import React, { useEffect, useState } from "react";
import { Test } from ".";
import ExamHeader from "../../components/ExamHeader";
import { DashboadPagesLayout, Header, LoaderSpiner } from "../../components";
import AppSideBarExam from "../../components/common/AppSideBar/AppSideBarExam";
import { FaBars } from "react-icons/fa";
import { TfiClose } from "react-icons/tfi";
import DrawIcon from "@mui/icons-material/Draw";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import LogoutSharpIcon from "@mui/icons-material/LogoutSharp";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import { useSelector } from 'react-redux'
import { useQuery } from "react-query";
import Cookies from "js-cookie";
import { GetPolicy } from "../../services/helpers/policy";

const TermsCondtion = () => {
    const [content, setContent] = useState();
    const { data, isLoading, isFetching } = useQuery(
      ["GetPolicy"],
      () => {
        const queryParams = {
          type: "terms",
          addById: Cookies.get("InsId"),
        };
        const paramsData = {
          queryParams,
        };
        return GetPolicy(paramsData);
      },
      {
        retry: false,
        // keepPreviousData: false,
        // refetchOnWindowFocus: false,
        // staleTime: 444440,
        mutationKey: "studentPrivacyPolicy",
        // enabled: callapi,
        onSuccess: (res) => {
          console.log(res);
          if (res?.status === true) {
            if (res?.data?.content) {
              setContent(res?.data?.content);
            }
          } else {
          }
        },
      }
    );
  
  return (
    <div>
      <AppSideBarExam type="dashboard" />
      {/* <ExamHeader /> */}
      <Header />

      <DashboadPagesLayout>
      {isLoading || isFetching ? (
          <>
            <div className="w-full flex justify-center items-center min-h-[60vh]">
              <LoaderSpiner />
            </div>
          </>
        ) : (
          <>
        {content ? (
          <div className="flex justify-center items-start w-full min-h-[70vh] p-4 pt-8 bg-[#fff]">
            <div className="w-full relative px-8 py-4 min-h-[60px] ">

            <div
              dangerouslySetInnerHTML={{
                __html: content,
              }}
              ></div>
              </div>
          </div>
        ) : (
          <div className="flex justify-center items-center w-full h-[70vh] p-4 pt-8 bg-[#fff]">
            <h1 className="text-3xl">Not Found</h1>
          </div>
        )}
        </>
        )}
      </DashboadPagesLayout>
    </div>
  );
};

export default TermsCondtion;