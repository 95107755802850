import { useQuery } from "react-query";
import { useState, useEffect, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import AddSharpIcon from "@mui/icons-material/AddSharp";
import DataNotFoundImg from "../../assets/no_data_found.png"
import { USERLISTAPI, USERSEARCH } from "../../services/helpers/Users";
import BreadcrumbsC from "../../components/common/BreadcrumbsC";
import { CustomPagination, DarkButton, StudentTable } from "../../components";
import { CircularProgress, IconButton, InputAdornment, TextField } from "@mui/material";
import Cookies from "js-cookie";
// import SearchIcon from "@mui/icons-material/Search";


const Results = () => {
  const [stopList,setStopList] = useState(true);
  const[search,setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  console.log(data?.length);
  const [serial, setSerial] = useState(null);
  const [change, setChange] = useState(false);
  const [loading, setLoading] = useState(true);
  console.log("loadertest",loading);
  const [isupdated, setIsupdated] = useState(false);
  const [paginationdata, setPaginationdata] = useState();
  const [searchflag,setSearchFlag] = useState(false);
  console.log(searchflag)

  const handleSearch = (e)=>{
    e.preventDefault();
      // setSearchFlag(true);
      setLoading(true);
  }
  

  const getStartingSerialNumber = () => {
    return setSerial((page - 1) * paginationdata?.numberPerPage + 1);
  };

  useLayoutEffect(() => {
    if(search.length>0){
      setSearchFlag(true);
    }
    
    
  }, [search]);

  
  useEffect(() => {
    getStartingSerialNumber();
  }, [data]);

 

  useEffect(()=>{
     setStopList(true);
  },[isupdated])

  useEffect(()=>{
    if(search===""){
      setStopList(true);
      setLoading(true);
    }

  },[search])

  // useEffect(()=>{
  //   if(data.length===0){
  //     setStopList(true)
  //     // setLoading(true)
  //   }
    
  // },[data])



  useQuery(
    ["USERLISTAPI", page, change, isupdated],
    () => {
      const queryParams = {
        pageNum: page,
        role: "student",
      };
      if(Cookies.get("id") === "institute"){
        queryParams.addById = Cookies.get("userId");
      }
      const paramsData = {
        queryParams,
      };
      return USERLISTAPI(paramsData);
    },
    {
      retry: false,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: 0,
      mutationKey: "USERLISTAPI",
      enabled: stopList,
      onSuccess: (res) => {
        
        if (res?.status === true) {
          console.log(res);
          setLoading(false);
          setChange(false);
          setIsupdated(false);
          setData(res?.data?.userList);
          setPaginationdata(res?.data.pagination);
        } else {
        }
      },
    }
  );
useEffect(()=>{
  console.log("testingData",searchflag);
  
},[])
  useQuery(
    ["USERSEARCH", page, change, isupdated,search],
    () => {
      const queryParams = {
        pageNum: page,
        role: "student",
        search:search
        
      };
      if(Cookies.get("id") === "institute"){
        queryParams.addById = Cookies.get("userId");
      }
      const paramsData = {
        queryParams,
      };
      return USERSEARCH(paramsData);
    },
    {
      retry: false,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: 0,
      mutationKey: "USERSEARCH",
      enabled: searchflag,
      onSuccess: (res) => {
        if (res?.status === true) {
          setLoading(false);
          setStopList(false);
          setSearchFlag(false)
          console.log(res);
          setChange(false);
          setIsupdated(false);
          setData(res?.data?.userList);
          setPaginationdata(res?.data?.pagination);
        } else {
        }
      },
    }
  );

  const navigate = useNavigate();
  const handler = () => {
    navigate("/student/add");
  };
  const breadcrumbb = [
    {
      id: 1,
      title: "Dashboard",
      link: "/dashboard",
    },
    {
      id: 1,
      title: "Students",
      link: "/students",
    },
  ];

  const examsTables = [];
 console.log(examsTables);
  return (
    <div className="relative px-8 py-4 bg-[#F3F3F3] md:ml-64">
      <div className="w-full">
        <div className="flex justify-between items-center">
          <BreadcrumbsC data={breadcrumbb} />
        </div>
        <div className="flex flex-col-reverse sm:grid sm:grid-cols-2 justify-center sm:justify-between items-center mt-8 gap-5">
          <div className="flex items-center w-full gap-4">
            <form onSubmit={(e)=>{
                handleSearch(e);
            }}>
            <TextField
              id="outlined-start-adornment"
              className="w-full sm:w-auto"
              size="small"
              sx={{ backgroundColor: "white" }}
              placeholder="Search or Type Here"
              onChange={(e)=>{
                setSearch(e.target.value);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton aria-label="toggle password visibility" edge="end">
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            </form>
          </div>
          <div className="flex w-full sm:justify-end">
            <DarkButton btnTitle="Add Student" endIcon={<AddSharpIcon />} handler={handler} />
          </div>
        </div>
      </div>

      {/* lower section */}
      <div
        className={`bg-white mt-4 rounded-lg p-5 min-h-[50vh] mb-10 ${examsTables.length > 2 && examsTables.length <= 4 ? "mb-20" : "" || examsTables.length <= 2 ? "mb-20" : ""}`}
      >
        {loading ? (
          <div className="flex justify-center items-center min-h-[50vh]">
            <CircularProgress className="mt-12 " />
          </div>
        ) : (
          <div className="flex w-full">
            {data?.length !== 0? (
              <StudentTable
                data={data}
                serial={serial}
                setChange={setChange}
                isupdated={isupdated}
                setIsupdated={setIsupdated}
              />
            ) : (
              <div className="text-center h-1/12 w-1/2  mx-auto">
                <h3 className="text-2xl">No Students Found</h3>
                <img className="w-[300px] mx-auto" src={DataNotFoundImg} />
              </div>
            )}
          </div>
        )}
      </div>
      {data?.length !==0 ?<CustomPagination page={page} setPage={setPage} paginationData={paginationdata} />:null}
    </div>
  );
};

export default Results;
