import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link, Typography } from "@material-ui/core";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const CustomBreadcrums = ({ data }) => {
  const navigate = useNavigate();

  function handleClick(e, link) {
    e.preventDefault();
    navigate(link);
  }
  const breadcrumbs = data.map((bread, i) => {
    const isLastItem = i === data.length - 1;

    return (
      <div key={i}>
        {!isLastItem ? (
          <Link
            underline="hover"
            key="1"
            color="inherit"
            href={bread.link}
            onClick={(e) => {
              handleClick(e, bread.link);
            }}
          >
            {bread.title}
          </Link>
        ) : (
          <Typography key="3" color="text.primary">
            {bread.title}
          </Typography>
        )}
      </div>
    );
  });

  return (
    <Stack spacing={2}>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        {breadcrumbs}
      </Breadcrumbs>
    </Stack>
  );
};

export default CustomBreadcrums;
