import { useState } from "react";
import Writting from "./Writting";
import MenuItem from "@mui/material/MenuItem";
import { useQuery } from "react-query";
import McqQuestion from "./McqQuestion";
import { Box, Tab, TextField, Select } from "@mui/material";
import { useParams } from "react-router-dom";
import FillInTheBlanks from "./FillInTheBlanks";
import { initialValuestest, validationSchematest } from "../../services/validate/AddSection";
import DarkButton from "../common/CustomButtons/DarkButton";
import LoaderSpiner from "../common/LoaderSpiner";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { QuestionTypeListAPI } from "../../services/helpers/Question";
import { SelectInputField, TextArea } from "../Forms";
import { useFormik } from "formik";
import { SECTIONLIST } from "../../services/helpers/Users";
import * as yup from "yup";
import Boolean from "./Boolean";

const QuestionNavigator = () => {
  const { id, sectionId } = useParams();
  const [value, setValue] = useState("1");
  const [questionType, setQuestionType] = useState(null);
  const [sectionList, setSectionList] = useState(null);
  const [booleanQList, setBooleanQList] = useState([0]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useQuery(
    ["questionType"],
    () => {
      const queryParams = {
        examId: id,
      };
      const paramsData = {
        queryParams,
      };
      return QuestionTypeListAPI(paramsData);
    },
    {
      retry: false,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: 0,
      mutationKey: "questionType",
      enabled: true,
      onSuccess: (res) => {
        if (res?.status === true) {
          setQuestionType(res?.data);
        } else {
        }
      },
    }
  );

  useQuery(
    ["sectionlistquestion"],
    () => {
      const queryParams = {
        examId: id,
      };
      const paramsData = {
        queryParams,
      };
      return SECTIONLIST(paramsData);
    },
    {
      retry: false,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: 0,
      mutationKey: "sectionlistquestion",
      enabled: true,
      onSuccess: (res) => {
        if (res?.status === true) {
          let options = res?.data?.map((option) => {
            return {
              label: option?.sectionName,
              option: option?.id,
            };
          });
          setSectionList(options);
        }
      },
    }
  );

  const [mcqQList, setMcqQList] = useState([0]);
  const [fillQList, setFillQList] = useState([0]);
  const [writtenQList, setWrittenQList] = useState([0]);

  const formik = useFormik({
    initialValues: { sectionId: sectionId || "" },
    validationSchema: yup.object().shape({}),
    onSubmit: (values) => {
      console.log(values);
    },
  });

  return (
    <>
      <div className="mt-8 rounded-xl mb-10  ">
        {questionType ? (
          <Box sx={{ width: "100%", typography: "body1", marginTop: "3%" }}>
            <div className="w-1/6 ml-5">{sectionList && <SelectInputField options={sectionList} formik={formik} label="Select Section" id="sectionId" />} </div>
            <div className="mt-3   ">

              <TabContext value={value}>
                <div className="overflow-x-scroll">
                  <Box sx={{ borderColor: "divider", borderBottom: 1, marginLeft: "2rem", marginRight: "2rem", }}>
                    <div className="block md:hidden">
                      <TabList orientation="vertical"
                        variant="scrollable" onChange={handleChange} aria-label="question Tabs" indicatorColor="primary" >
                        <Tab label="MCQ" value="1" />
                        <Tab label="Fill in the Blanks" value="2" />
                        <Tab label="Written" value="3" />
                        <Tab label="Boolean" value="4" />
                      </TabList>

                    </div>
                    <div className="hidden md:block">
                      <TabList onChange={handleChange} aria-label="question Tabs" indicatorColor="primary" variant="fullWidth">
                        <Tab label="MCQ" value="1" />
                        <Tab label="Fill in the Blanks" value="2" />
                        <Tab label="Written" value="3" />
                        <Tab label="Boolean" value="4" />
                      
                      </TabList>

                    </div>

                  </Box>
                </div>

                <TabPanel value="1">
                  <McqQuestion sectionId={formik.values.sectionId} questionType={questionType[0].id} examId={id} setMcqQList={setMcqQList} mcqQList={mcqQList} />
                </TabPanel>
                <TabPanel value="2">
                  <FillInTheBlanks sectionId={formik.values.sectionId} questionType={questionType[1].id} examId={id} fillQList={fillQList} setFillQList={setFillQList} />
                </TabPanel>
                <TabPanel value="3">
                  <Writting sectionId={formik.values.sectionId} questionType={questionType[2].id} examId={id} writtenQList={writtenQList} setWrittenQList={setWrittenQList} />
                </TabPanel>
                <TabPanel value="4">
                    <Boolean sectionId={formik.values.sectionId} questionType={questionType[3].id} examId={id} booleanQList={booleanQList} setBooleanQList={setBooleanQList}  />
                  </TabPanel>
              </TabContext>
            </div>
          </Box>
        ) : (
          <div className="w-full flex justify-center items-center h-[50vh]">
            <LoaderSpiner />
          </div>
        )}

      </div>
    </>
  );
};

export default QuestionNavigator;
