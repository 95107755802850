import { TextareaAutosize, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const FillInBlankTextArea = ({ fillInBlankInputHandle, id }) => {
  const [selectedAnswer, setSelectedAnswer] = useState("");
  const examDetails = useSelector((state) => state.examination.questions);
  console.log(
    "findQuestionfindQuestionfindQuestion=> ",
    
    examDetails
  );
  useEffect(() => {
    let findQuestion = examDetails.find((question) => question.id == id);
    if (findQuestion) {
      setSelectedAnswer(findQuestion.answer);
    }
  }, []);

  return (
    <>
      <TextareaAutosize
        onChange={(event) => {
          setSelectedAnswer(event.target.value);
        }}
        defaultValue={selectedAnswer}
        name="Solid"
        minRows={4}
        maxRows={4}
        variant="red"
        placeholder="Type You Answer..."
        className="w-full bgColor bg-gray-500 rounded-lg text-white  px-5 py-4   overflow-auto   border-none outline-none"
      />
        <Button
          className="mt-4 float-right"
          variant="contained"
          onClick={() => {
            fillInBlankInputHandle(id, selectedAnswer);
          }}
        >
          Save
        </Button>
    </>
  );
};
export const WrittenTextArea = ({ fillInBlankInputHandle, id }) => {
  const [selectedAnswer, setSelectedAnswer] = useState("");
  const examDetails = useSelector((state) => state.examination.questions);
  useEffect(() => {
    let findQuestion = examDetails.find((question) => question.id == id);
    console.log(
      "findQuestionfindQuestionfindQuestion=> ",
      findQuestion,
      examDetails
    );
    if (findQuestion) {
      setSelectedAnswer(findQuestion.answer);
    }
  }, []);

  return (
    <>
      <TextareaAutosize
        onChange={(event) => {
          setSelectedAnswer(event.target.value);
        }}
        defaultValue={selectedAnswer}
        name="Solid"
        minRows={4}
        maxRows={4}
        variant="red"
        placeholder="Type You Answer..."
        className="w-full bgColor bg-gray-500 rounded-lg text-white px-5 py-4  border-none outline-none"
      />
      {/* <div className="flex justify-center items-center mt-12"> */}
        <Button
          variant="contained"
          className="mt-4 float-right"
          onClick={() => {
            fillInBlankInputHandle(id, selectedAnswer);
          }}
        >
          Save
        </Button>
      {/* </div> */}
    </>
  );
};

export default FillInBlankTextArea;
