import Table from "@mui/material/Table";
import { styled } from "@mui/material/styles";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },

  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const InstituteDetailsTable = ({ data }) => {
  console.log(data?.isDeleted);
  return (
    <TableContainer>
      <Table aria-label="customized table">
        <TableBody>
          <StyledTableRow>
            <StyledTableCell align="left">
              <strong>Role:</strong>
            </StyledTableCell>
            <StyledTableCell align="left">
              <p className="flex capitalize">{data.role}</p>
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell align="left">
              <strong>Address:</strong>
            </StyledTableCell>
            <StyledTableCell align="left">
              <p className="flex capitalize">
                {data.address}, {data.city}, {data.state}, {data.country} - {data.pincode}
              </p>
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell align="left">
              <strong>State:</strong>
            </StyledTableCell>
            <StyledTableCell align="left">
              <p className="flex capitalize">{data.state}</p>
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell align="left">
              <strong>Country:</strong>
            </StyledTableCell>
            <StyledTableCell align="left">
              <p className="flex capitalize">{data.country}</p>
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell align="left">
              <strong>Active Status:</strong>
            </StyledTableCell>
            <StyledTableCell align="left">
              <p className="flex capitalize">{data.isActive == "1" ? "Active" : "Inactive"}</p>
            </StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InstituteDetailsTable;
