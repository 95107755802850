import DarkButton from "../common/CustomButtons/DarkButton";
import { SelectInputField, InputTextField } from "../../components/Forms";
import { useState } from "react";
import { Country,State,City } from 'country-state-city';
import { useEffect } from "react";

const country = [
  {
    label: "Astralia",
    option: "astralia",
  },
  {
    label: "Canada",
    option: "canada",
  },
  {
    label: "United Kingdom",
    option: "unitedKingdom",
  },
  {
    label: "United States",
    option: "unitedStates",
  },
];

const InstituteForm = ({ formik, isLoading, initialValueFormik={
  country: "",
  state: "",
  city: "",
}, setInitialValueFormik=()=>{} }) => {
  console.log(formik)
  const [country, setCountry] = useState([]);
  const [states, setStates] = useState([]);
  console.log("showing states",states);
  const [cities, setCities] = useState([]);
  // console.log("test",countries);

  const [selectedCountry, setSelectedCountry] = useState("IN");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  console.log(selectedCountry);

  console.log("selectedCountry",selectedCountry, formik);
  console.log("state",selectedState);
  console.log("city",cities);
  
  


  useEffect(() => {
    
    const fetchCountries = async () => {
      try {
        const countries = Country.getAllCountries();
        let allCountries = [];
        allCountries = countries?.map(({ isoCode, name }) => ({
          isoCode,
          name,
        }));
        const [{ isoCode: firstCountry = "" } = {}] = allCountries;
        setCountry(allCountries)
        if (!selectedCountry) {
          // setSelectedCountry("IN");
        }
        if(initialValueFormik?.country?.length>0){
          setSelectedCountry(initialValueFormik?.country)
          setInitialValueFormik((prev)=>{
            return {
              ...prev,
              country:''
            }
          })
        }
      } catch (error) {
        setCountry([]);
        setStates([]);
        setSelectedCity("");
      }
    };

    fetchCountries();
  }, []); // The empty dependency array ensures this runs once on component mount


  let arr = [];
  for (var i = 0; i < country?.length; i++) {
    let check = { label: country[i].name, option: country[i].isoCode };
    arr.push(check);
  }

  useEffect(() => {
    const getStates = async () => {
      try {
        console.log("wjdjsdjasjdasjdajsjjasd")
        const result = await State.getStatesOfCountry(selectedCountry);
        let allStates = [];
        allStates = result?.map(({ isoCode, name }) => ({
          isoCode,
          name,
        }));
        console.log("states9847",allStates)
        const [{ isoCode: firstState = "" } = {}] = allStates;
        setStates(allStates);
        
        // if (!selectedState) {
        //   setSelectedState(firstState);
        // }
        if(initialValueFormik?.state?.length>0){
          setSelectedState(initialValueFormik?.state)
          setInitialValueFormik((prev)=>{
            return {
              ...prev,
              state:''
            }
          })
        }
      } catch (error) {
        setStates([]);
        setCities([]);
        // setCities([]);
        setSelectedCity("");
      }
    };

    getStates();
  }, [selectedCountry]);

  let state_arr = [];
  for (var i = 0; i < states?.length; i++) {
    let check = { label: states[i].name, option: states[i].isoCode};
    state_arr.push(check);
  }
  console.log(selectedState)

  useEffect(() => {
    const getCities = async () => {
      console.log("Component Mounted ", cities);
      try {
        const result = await City.getCitiesOfState(selectedCountry,selectedState);
        console.log("testing_state",result);
        let allCities = [];
        allCities = result?.map(({ name }) => ({
          name,
        }));
        console.log("Cities",allCities);
        const [{ name: firstCity = "" } = {}] = allCities;
        // if (!selectedCity) {
        //   setSelectedCity(firstCity);
        // }
        setCities(allCities);
        // setSelectedCity(firstCity);
      } catch (error) {
        setCities([]);
      }
    };
    getCities();
    
  }, [selectedState]);

 
  

  let city_arr = [];
  for (var i = 0; i < cities?.length; i++) {
    let check = { label: cities[i].name, option: cities[i].name };
    city_arr.push(check);
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="grid grid-cols-1 sm:grid-cols-2  gap-8">
        <InputTextField type="text" id="instituteName" label="Institute's Name" formik={formik} placeholder="Institute's Name" />
        <InputTextField type="email" id="email" label="Email" formik={formik} placeholder="Email" />
        <InputTextField type="number" id="phone" label="Phone" formik={formik} placeholder="Phone" />
        {/* <InputTextField type="text" id="country" label="Country" formik={formik} placeholder="Country" defaultValue="India" /> */}
        <InputTextField type="text" id="address" label="Street Address" formik={formik} placeholder="Street Address" />
        <SelectInputField id="country" label="Select Country" defaultValue="Select" options={arr} formik={formik} exam={setSelectedCountry} flag={true} />
          <SelectInputField id="state" label="Select States" defaultValue="Select" options={state_arr} formik={formik} exam={setSelectedState} flag={true}   />
          <SelectInputField id="city" label="Select Cities" defaultValue="Select" options={city_arr} formik={formik} exam={setSelectedCity} flag={true}   />
        {/* <InputTextField type="text" id="city" label="City" formik={formik} placeholder="City" /> */}
        {/* <InputTextField type="text" id="state" label="State" formik={formik} placeholder="State" /> */}
        <InputTextField type="number" id="pincode" label="Zip Code" formik={formik} placeholder="Zip Code" />
      </div>
      <div className="mt-10  flex justify-center">
        <DarkButton type="submit" btnTitle="Submit" loading={isLoading} />
      </div>
    </form>
  );
};

export default InstituteForm;
