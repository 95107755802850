import { useFormik } from "formik";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { GrClose } from "react-icons/gr";
import { useDispatch } from "react-redux";
import { setToastMessage } from "../../../store/toast";
import { useMutation, useQueryClient } from "react-query";
import { updateQuestionAPI } from "../../../services/helpers/Question";
import FillInTheBlanksForm from "../../AddQuestion/FillInTheBlanks/FillInTheBlanks";
import { validationSchemaFillInTheBlanks as validationSchema } from "../../../services/validate/AddQuestion";
import {useParams} from 'react-router-dom'
const style = {
  bgcolor: "background.paper",
};

const FtbUpdateModal = ({ handleClose, open, headingText, questionId, initialValue, questionDetails }) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const{id} = useParams()
  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      submitHandler(values);
    },
  });
  
  const submitHandler = (values) => {
    const newValues = {
      questionId: questionId,
      questionType: values.questions[0].questionType,
      question: values.questions[0].question,
      examId: id,
      writtenAnswer: values.questions[0].writtenAnswer,
      time: values.questions[0].time,
    };
    mutate(newValues);
  };
  
  const { mutate, isLoading } = useMutation(updateQuestionAPI, {
    mutationKey: "updateExam",
    onSuccess: (data) => {
      if (data.status === true) {
        dispatch(
          setToastMessage({
            isActive: true,
            message: data?.message,
            type: "success",
          })
          );
          
          handleClose();
          queryClient.invalidateQueries("EXAMDETAILSAPI");
          window.location.reload();
        }
      },
    });

    console.log("squetiion questionDetails ==> ", formik)
    
    return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={style} className="min-w-[400px] top-[50%] left-[50%] absolute translate-x-[-50%] translate-y-[-50%] shadow-lg outline-none rounded p-4">
        <div className="pt-4 px-5 flex justify-between">
          <h2 className="text-bold font-bold text-lg">{headingText}</h2>
          <GrClose className="cursor-pointer" onClick={handleClose} />
        </div>
        {/* <div className="w-100 bg-slate-200 h-[.25px] mt-2 mb-4 shadow-lg" /> */}
        <div className="overflow-y-auto h-[72vh] mt-4">
        <FillInTheBlanksForm formik={formik} btnTitle="Update Question" isLoading={isLoading} initialValue={initialValue} fillQList={[0]} />

        {/* <div className="w-100 bg-slate-200 h-[.25px] mt-6 mb-2 shadow-lg" /> */}
        <div className="w-full p-5 pb-4 flex justify-between">
          <button onClick={handleClose} className="w-full py-2 rounded border">
            Cancel
          </button>
        </div>
        </div>
      </Box>
    </Modal>
  );
};

export default FtbUpdateModal;
