import { TextField } from "@mui/material";
import { useMutation, useQuery } from "react-query";
import { setToastMessage } from "../../store/toast";
import { InputTextField, SelectInputField } from "../../components/Forms";
import { initialValues, validationSchema } from "../../services/validate/AddSection";
import { useFormik } from "formik";
import { CircularProgress,Backdrop } from "@mui/material";
import { AddCKEditor, DarkButton } from "../../components";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Navigate, useParams } from "react-router-dom";
import { AddSection, ExamDetailsAPI, MASTERLISTAPI, UploadSection } from "../../services/helpers/Users";
import { useDispatch } from "react-redux";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SelectMultipleInput, { SelectSingleInput } from "../../components/Forms/SelectMultipleInput";
import AddParagraph from "./AddParagraph";
import CkEditorForParagraph from "./CkEditorParagraph";
import QuillEditor from "../../components/editortest";
import QuillNew from "../../components/common/EditorNew";

export const AddSectionForm = () => {
  const [instructions,setInstructions] = useState(null);
  const [instructionsImage,setInstructionsImage] = useState();
  console.log(instructions);
  const[imageloading,setImageLoading] = useState(false);
  console.log(imageloading)
  const [contentData,setContentData] = useState();
  const [selectedImage, setSelectedImage] = useState({
    file: null,
    preview: null,
  });
  const [isImageLoading, setIsImageLoading] = useState(false)

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      formdata.append("file", file);
      checkImage(formdata);
      setIsImageLoading(true)
      setSelectedImage((prev) => {
        return {
          file: file,
          preview: URL.createObjectURL(file),
        };
      });
      // setSelectedImage(URL.createObjectURL(file));
    }
  };
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formdata = new FormData();
  const [flag, setFlag] = useState(false);
  const { id } = useParams();
  const [uploadedName, setUploadedName] = useState();
  const [type, setType] = useState();
  const [error, setError] = useState();
  console.log("type", type);
  const [examVal, setExamVal] = useState("");
  const [audioVal, setAudioVal] = useState("");
  const [videoVal, setVideoVal] = useState("");
  const [selectedExam, setSelectedExam] = useState();
  console.log("test", examVal);
  const reload = () => {
    return navigate(`/exams/${id}`);
  };

  const minutes_arr = [];
  for (var i = 0; i < 60; i++) {
    let obj = { label: i, option: i };
    minutes_arr.push(obj);
  }

  const { mutate, isLoading } = useMutation(AddSection, {
    mutationKey: "addSection",
    onSuccess: (res) => {
      if (res?.status === false) {
      } else if (res?.status === true) {
        setLoading(false);

        dispatch(
          setToastMessage({
            isActive: true,
            message: res?.message,
            type: "success",
          })
        );

        setTimeout(() => {
          reload();
        }, 2000);
      }
    },
    onError: () => {},
    onSettled: () => {},
  });

  const { mutate: checkImage } = useMutation(UploadSection, {
    mutationKey: "uploadSection",
    onSuccess: (res) => {
      if (res?.status === false) {
      } else if (res?.status === true) {
        setSelectedImage((prev) => {
          return {
            ...prev,
            fileName: res?.data?.fileName,
          };
        });
        setIsImageLoading(false)
        // setFlag(true)
        // setToastMessage({
        //     isActive: true,
        //     message: res?.message,
        //     type: "success",
        // })

        // formik.resetForm();
      }
    },
    onError: () => {},
    onSettled: () => {},
  });

  const { mutate: check } = useMutation(UploadSection, {
    mutationKey: "uploadSection",
    onSuccess: (res) => {
      if (res?.status === false) {
      } else if (res?.status === true) {
        console.log("sdkf;lksd;fk", res?.data?.fileName);
        setUploadedName(res?.data?.fileName);
        let { time, second, ...payload } = formik.values;
        time = time + ":" + second;
        mutate({ ...payload, time: time, sectionTypeValue: res?.data?.fileName, examId: id, sectionImage: selectedImage?.fileName });
        // setFlag(true)
        // setToastMessage({
        //     isActive: true,
        //     message: res?.message,
        //     type: "success",
        // })

        // formik.resetForm();
      }
    },
    onError: () => {},
    onSettled: () => {},
  });

  const options = [
    {
      label: "Paragraph",
      option: "paragraph",
    },
    {
      label: "Audio",
      option: "audio",
    },
    {
      label: "Video",
      option: "video",
    },
  ];

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      if (type === "paragraph") {
        if (examVal) {
          setError("");
          let { time, second, ...payload } = values;
          time = time + ":" + second;
          mutate({ ...payload, time: time, sectionTypeValue: examVal, examId: id, sectionImage: selectedImage?.fileName });
          setLoading(true);
        } else {
          setError("Data is required");
        }
      } else if (type === "audio") {
        if(audioVal){
          formdata.append("file", audioVal);
          check(formdata);
          setLoading(true);
          // console.log("newwef---> ", newwe)
          // if(flag){
            //     console.log("jdjdjsjsjdjdjsjj");
            //     await mutate({ ...values, sectionTypeValue: uploadedName, examId: id });
            // }
        }else {
          dispatch(
            setToastMessage({
              isActive: true,
              message: "Please select a file",
              type: "error",
            })
          );
        }
        } else if (type === "video") {
          if(videoVal){
            formdata.append("file", videoVal);
            check(formdata);
            setLoading(true);
          }else {
            dispatch(
              setToastMessage({
                isActive: true,
                message: "Please select a file",
                type: "error",
              })
            );
          }
        }
        
        // else{
          //     setError("Data is required")
          // }
          
      //   mutate(values);
      //   setLoading(true);
    },
  });
  console.log("Test", formik);
  console.log(formik);
  console.log(examVal);

  const [examDetails, setExamDetails] = useState();
  const [data, setData] = useState();
  const [arrD, setArrD] = useState([]);

  useQuery(
    ["MASTERLISTAPI"],
    () => {
      const queryParams = {};
      const paramsData = {
        queryParams,
      };
      return MASTERLISTAPI(paramsData);
    },
    {
      retry: false,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: 0,
      mutationKey: "MASTERLISTAPI",
      enabled: true,
      onSuccess: (res) => {
        if (res?.status === true) {
          console.log("test", res.data);
          setData(res?.data);
        } else {
        }
      },
    }
  );

  useQuery(
    ["EXAMDETAILSAPI"],
    () => {
      const queryParams = {
        examId: id,
      };
      const paramsData = {
        queryParams,
      };
      return ExamDetailsAPI(paramsData);
    },
    {
      retry: false,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: 0,
      mutationKey: "EXAMDETAILSAPI",
      enabled: true,
      onSuccess: (res) => {
        if (res?.status === true) {
          setExamDetails(res.data);
        } else {
        }
      },
    }
  );

  useEffect(() => {
    let arr = [];
    if (examDetails?.examType) {
      let EType = JSON.parse(examDetails?.examType);
      for (var i = 0; i < data?.length; i++) {
        if (EType.includes(data[i].id)) {
          let check = { label: data[i].Name, option: data[i].id };
          arr.push(check);
        }
      }
      setArrD(arr);
    }
  }, [data, examDetails?.examType]);

  return (
    <>
    <div className="">
        
      <div>
        <ArrowBackIcon
          className="cursor-pointer"
          onClick={() => {
            navigate(`/exams/${id}`);
          }}
        />
        <h1 className="text-3xl text-center mb-12">Add Section</h1>
      </div>
      <form className="" onSubmit={formik.handleSubmit}>
        <div className="grid grid-cols-1 gap-8 mt-4 ">
          {/* <div className="flex ">
                        <div className="max-w-sm  p-8 bg-white rounded-lg shadow-md ">
                            <label className="block text-gray-700 text-sm font-bold mb-2">
                                Choose Image
                            </label>
                            <label className="w-full flex items-center justify-center px-4 py-2 bg-blue-500 text-white rounded cursor-pointer">
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={handleImageChange}
                                    className="hidden"
                                />
                                Select File
                            </label>

                        </div>
                        <div className="ml-4">
                          
                           
                        </div>
                    </div> */}
          {/* <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your email</label> */}
          <div className="flex gap-4">
            <div className="flex flex-1">
              <InputTextField type="text" formik={formik} id="sectionName" placeholder="Add Title" />
            </div>
            <div className="flex flex-1">
              <SelectSingleInput
                flag={true}
                id="examTypeId"
                label="Select Module Type"
                exam={(selectedExams) => formik.setFieldValue("examTypeId", selectedExams)}
                options={arrD}
                formik={formik}
              />
            </div>
          </div>
          {/* <InputTextField type="text" formik={formik} id="sectionTitle" placeholder="Add Title" /> */}
          {/* <InputTextField type="text" formik={formik} id="time" placeholder="Add Time " /> */}
          <div>
            <p className="mb-2">Duration*</p>
            <div className="flex justify-between gap-4 ">
              <div className="w-full">
                <SelectInputField
                  flag={true}
                  id="time"
                  label="Choose Minutes"
                  exam={setSelectedExam}
                  options={[...minutes_arr, { label: 60, option: 60 }].slice(1)}
                  formik={formik}
                />
              </div>
              <div className="w-full">
                <SelectInputField flag={true} id="second" label="Choose Seconds" exam={setSelectedExam} options={minutes_arr} formik={formik} />
              </div>
            </div>
          </div>
          <div >
            <p className="mb-2">Add Instructions</p>
            <QuillEditor paddingBottom={20} setContentData={setInstructions} formik={formik} setLoading={setInstructionsImage} loading={instructionsImage} fieldName={"sectionTitle"}/>
            {instructionsImage && 
                <Backdrop
                  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                  open={instructionsImage}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
             }
            <p className="text-red-500 mt-8 text-sm">{formik.errors["sectionTitle"]}</p>
          </div>
          <div className="grid grid-cols-2 gap-2">
            <div className="mt-1">
              <SelectInputField formik={formik} id="sectionType" label="Select Section Type " options={options} flag={true} exam={setType} />
              
            </div>

            {/* <div className=" ">
              <div className="  p-1 bg-white rounded-lg shadow-md ">
                <label className="block text-gray-700 text-sm font-bold mb-2">Choose Image</label>
                <label className="w-full flex items-center justify-center px-4 py-2 bg-blue-500 text-white rounded cursor-pointer">
                  <input type="file" accept="image/*" onChange={handleImageChange} className="hidden" />
                  Upload Image
                </label>
              </div>
              <div className="ml-4 flex justify-center">
                <div>
                  {selectedImage?.preview && (
                    <div>
                      <div className="mt-2 max-w-sm ">
                        <img src={selectedImage?.preview} alt="Selected" className="w-full h-20 object-cover rounded shadow-md" />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div> */}

          </div>
          <div className="w-full  mx-auto mt-2 col-start-1 col-end-2 ">
          
                <div className="">{type === "paragraph" && <Paragraph formik={formik} setExamVal={setExamVal} examVal={examVal} error={error} setContentData={setContentData} imageloading={imageloading} setImageLoading={setImageLoading} />}</div>

                <div className="flex justify-start">{type === "audio" && <Audio setAudioVal={setAudioVal} examVal={examVal} error={error} />}</div>
                <div className="flex justify-start">{type === "video" && <Video setExamVal={setVideoVal} examVal={examVal} error={error} />}</div>
              </div>
        </div>
        <div className="flex justify-center mt-4">
          <DarkButton type="submit" btnTitle="Submit" loading={loading||isImageLoading} />
        </div>
      </form>
    </div>
    {imageloading && 
                <Backdrop
                  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                  open={imageloading}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
             }
    </>
  );
};

const Paragraph = ({ examVal, setExamVal, error, setContentData,imageloading,setImageLoading,formik }) => {
  console.log("Component Mounted");
  // const handleChange = (e) => {
  //   console.log(e.target.value);
  //   setExamVal(e.target.value);
  // };
  return (
    <div className=" ">
      {/* <h1>Upload Paragraph</h1> */}
      
      {/* <AddParagraph data={examVal} /> */}
      {/* <CkEditorForParagraph /> */}
      {/* <TextField name="test123" style={{ width: "100%" }} multiline rows={4} onChange={handleChange} placeholder="Upload Paragraph" defaultValue={examVal} /> */}
      {/* <QuillEditor paddingBottom={20}setContentData={setExamVal} loading={imageloading} setLoading={setImageLoading} fieldName={"sectionTypeValue"} /> */}
      <QuillNew paddingBottom={80} setContentData={setExamVal}  loading={imageloading} setLoading={setImageLoading} formik={formik} fieldName={"sectionTypeValue"}/>
      <p className="text-red-600">{error ? error : null}</p>
    </div>
  );
};
const Audio = ({ setAudioVal, audioVal, error }) => {
  console.log("Component Mounted");
  const handleChange = (e) => {
    console.log("targetsdfs", e.target.files);
    setAudioVal(e.target.files[0]);
  };
  return (
    <div>
      <h1> Upload Audio</h1>

      <input type="file" accept=".wav, .mp3, .aac" onChange={handleChange} />
      <p className="text-red-600">{error ? error : null}</p>

      {/* <textarea/> */}
    </div>
  );
};

const Video = ({ setExamVal, examVal, error }) => {
  const handleChange = (e) => {
    console.log(e.target.value);
    setExamVal(e.target.files[0]);
  };
  console.log("Component Mounted");
  return (
    <div>
      <h1> Upload Video</h1>

      <input type="file" accept=".mp4, .mov, .wmv, .avi, .avchd" onChange={handleChange} />

      {/* <textarea/> */}
    </div>
  );
};
