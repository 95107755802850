import { useState } from "react";
import { useQuery } from "react-query";
import toast from "react-simple-toasts";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../common/DeleteModal";
import StatusPills from "../common/StatusPills";
import { StudenttableHeading, StudenttableHeadingInsttitute } from "../../mockData";
import StudentStatusModal from "./StudentStatusModal";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import TableHeading from "../common/Table/TableHeading";
import { deleteStudentAPI } from "../../services/helpers/Users";
import { Table, TableBody, TableRow, TableContainer, TableCell, MenuItem, Menu } from "@mui/material";
import Cookies from "js-cookie";

const StudentTable = ({ data, serial, setChange, isupdated, setIsupdated }) => {
  const option = [
    { label: "Active", option: 1 },
    { label: "Inactive", option: 0 },
  ];
  let usersId = Cookies.get("id")
  const [userId, setUserId] = useState();
  const [delete1, setDelete1] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModalStatus, setopenModalStatus] = useState(false);
  console.log(openModalStatus);
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const handleCloseModal1 = () => setopenModalStatus(false);
  const [isactive, setIsactive] = useState(false);
  const handleData = (item) => {
    setIsactive(item);
  };
  const open = Boolean(anchorEl);

  const handleClick = (event, userId) => {
    setAnchorEl(event.currentTarget);
    console.log(userId);
    setUserId(userId);
  };

  useQuery(
    ["USERDELETEAPI"],
    () => {
      const queryParams = {
        userId: userId,
      };
      const paramsData = {
        queryParams,
      };
      return deleteStudentAPI(userId);
    },
    {
      retry: false,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: 0,
      mutationKey: "USERDELETEAPI",
      enabled: delete1,
      onSuccess: (res) => {
        setLoading(false);
        if (res?.status === true) {
          handleCloseModal();
          toast(res.message, {
            theme: "dark",
            position: "top-center",
          });
          setChange(true);
          setDelete1(false);
          console.log(res);
        } else {
        }
      },
    }
  );

  const handleDelete = () => {
    setDelete1(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHeading tableHeading={usersId == "super_admin" ? StudenttableHeading : StudenttableHeadingInsttitute } />
          <TableBody>
            {data.map((item, index) => (
              <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell>{serial + index}</TableCell>
                <TableCell align="left">
                  {item.firstName} {item.lastName}
                </TableCell>
                {usersId == "super_admin" && 
                <TableCell align="left">
                  {item?.instituteName}
                </TableCell>}
                <TableCell align="left">{item.phone}</TableCell>
                <TableCell align="left">{item.email}</TableCell>
                <TableCell align="left">
                  <StatusPills
                    status={item.isActive}
                    clickHandle={() => {
                      setopenModalStatus(true);
                      handleData(item);
                    }}
                  />
                </TableCell>
                <TableCell align="left">{item.country}</TableCell>
                <TableCell align="left">
                  <MoreVertIcon
                    onClick={(e) => {
                      // setOpen(true)
                      handleClick(e, item.userId);
                    }}
                    className="cursor-pointer"
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem
          onClick={(e) => {
            navigate(`/student/${userId}`);
            handleClose();
          }}
        >
          View Details
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            navigate(`/student/update/${userId}`);
            handleClose();
          }}
        >
          Edit Student
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenModal(true);
            setAnchorEl(null);
          }}
        >
          Delete Student
        </MenuItem>
      </Menu>
      <DeleteModal
        open={openModal}
        deleteHandle={() => {
          handleDelete();
          setLoading(true);
          setIsupdated(true);
        }}
        loading={loading}
        handleClose={handleCloseModal}
        title="Are you sure want  to delete !!"
        deleteText="Delete Student"
        className="w-100 h-100 bg-slate-800"
      />
      {openModalStatus && (
        <StudentStatusModal
          defaultValue={isactive}
          open={openModalStatus}
          isupdated={isupdated}
          setIsupdated={setIsupdated}
          // formik={formik}
          id="action"
          option={option}
          handleClose={handleCloseModal1}
          deleteText="Update Exam"
          title="Are you sure want to update the  exam...!!!"
          className="w-100 h-100 bg-slate-800"
        />
      )}
    </>
  );
};

export default StudentTable;
