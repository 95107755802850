import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import information from "../../../assets/img/information.png";
import { CameraAndMicComponent } from "../../../components/Cameratest";

const initialStartExam = {
  startExam: false,
  showMediaAudio: false,
  showMediaVideo: false,
  showMedia: false,
  showWritten: false,
  mediaEnabled: false,
};

const InstructionExam = ({
  selectedModule,
  examStudentSidebar,
  startExamModule,
}) => {
  // const [enableStartExam, setEnableStartExam] = useState(initialStartExam);
  // const [camerastatus,setStatus] = useState();
  // const [micstatus,setMicStatus] = useState();

  // useEffect(() => {
  //   let findMedia = examStudentSidebar?.filter(
  //     (module) => module.moduleTypeId == 4 || module.moduleTypeId == 3
  //   );
  //   console.log("findMediafindMedia", findMedia, examStudentSidebar, selectedModule);
  //   if (examStudentSidebar) {
  //     if (selectedModule == undefined) {
  //       if (examStudentSidebar[0]?.moduleTypeId == 4) {
  //         setEnableStartExam((prev) => {
  //           return {
  //             ...prev,
  //             showMediaVideo: true,
  //           };
  //         });
  //         console.log("findMediafindMedia222", examStudentSidebar[0]);
  //       } else if (examStudentSidebar[0]?.moduleTypeId == 3) {
  //         console.log("findMediafindMedia222", examStudentSidebar[0]);
  //         setEnableStartExam((prev) => {
  //           return {
  //             ...prev,
  //             showMediaAudio: true,
  //           };
  //         });
  //       } else {
  //         setEnableStartExam((prev) => {
  //           return {
  //             ...prev,
  //             showWritten: true,
  //             startExam: true,
  //           };
  //         });
  //       }
  //     } else if (selectedModule >= 0) {
  //       if (examStudentSidebar[(selectedModule * 1) + 1]?.moduleTypeId == 4) {
  //         console.log(
  //           "findMediafindMedia222",
  //           examStudentSidebar[(selectedModule * 1) + 1]
  //         );
  //         setEnableStartExam((prev) => {
  //           return {
  //             ...prev,
  //             showMediaVideo: true,
  //           };
  //         });
  //       } else if (
  //         examStudentSidebar[(selectedModule * 1) + 1]?.moduleTypeId == 3
  //       ) {
  //         console.log(
  //           "findMediafindMedia222",
  //           examStudentSidebar[(selectedModule * 1) + 1]
  //         );
  //         setEnableStartExam((prev) => {
  //           return {
  //             ...prev,
  //             showMediaAudio: true,
  //           };
  //         });
  //       } else {
  //         setEnableStartExam((prev) => {
  //           return {
  //             ...prev,
  //             showWritten: true,
  //             startExam: true,
  //           };
  //         });
  //       }
  //     }
  //   }
  // }, [examStudentSidebar]);

  // useEffect(() => {
  //   return () => {
  //     setEnableStartExam(initialStartExam)
  //   }
  // }, [])

  // console.log(
  //   "examStudentSidebarexamStudentSidebar",
  //   examStudentSidebar,
  //   enableStartExam
  // );

  return (
    <>
      <div className="w-[90%] mb-20">
        {/* {(enableStartExam?.showMediaVideo || enableStartExam?.showMediaAudio) &&
          enableStartExam?.showMedia ? (
          enableStartExam?.showMediaVideo ? (
            <>
              <p>
                <CameraAndMicComponent setEnableStartExam={setEnableStartExam} setStatus={setStatus} setmicStatus={setMicStatus} />
              </p>
            </>
          ) : (
            <>Media Audio Testing</>
          )
        ) : null} */}

        <>
          {selectedModule == undefined ? (
            <>
              <br />
              <br />
              {examStudentSidebar && (
                <p>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: examStudentSidebar[0]?.moduleInstruction,
                    }}
                  />
                </p>
              )}
              <br />
              <br />
            </>
          ) : (
            <>
              <p>
                <br />
                <br />
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      examStudentSidebar[selectedModule * 1 + 1]
                        ?.moduleInstruction,
                  }}
                />
                <br />
                <br />
              </p>
            </>
          )}
        </>
      </div>
      {/* {enableStartExam?.startExam ? (

        (enableStartExam?.showMediaAudio ||
          enableStartExam?.showMediaVideo) ?

          <Button
            variant="contained"
            onClick={() => {
              if (enableStartExam.mediaEnabled) {
                startExamModule();
              } else {
                alert("Please Enable Mic or webcam before starting the examination")
              }
            }}
          >
            Start Exam
          </Button>
          :
          <Button
            variant="contained"
            onClick={() => {
              startExamModule();
            }}
          >
            Start Exam
          </Button>
      ) 
      : (
        (enableStartExam?.showMediaAudio ||
          enableStartExam?.showMediaVideo) && (
          <Button
            variant="contained"
            onClick={() => {
              setEnableStartExam((prev) => {
                return {
                  ...prev,
                  showMedia: true,
                  startExam: true,
                };
              });
            }}
          >
            Check Device
          </Button>
        )
      )
      } */}
      <Button
        variant="contained"
        onClick={() => {
          startExamModule();
        }}
      >
        Start Exam
      </Button>
    </>
  );
};

export default InstructionExam;
