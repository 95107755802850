import { useState } from "react";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import StatusPills from "../common/StatusPills";
import DeleteModal from "../common/DeleteModal";
import { setToastMessage } from "../../store/toast";
import { institutetableHeading } from "../../mockData";
import InstituteUpdateModal from "./InstuteStatusModal";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import TableHeading from "../common/Table/TableHeading";
import { deleteInstituteAPI } from "../../services/helpers/Users";
import { Table, TableBody, TableRow, TableContainer, TableCell, MenuItem, Menu } from "@mui/material";

const InstituteTable = ({ instituteList, serial, setChange, isupdated, setIsupdated }) => {
  // console.log(isupdated,serial);
  const option = [
    { label: "Active", option: 1 },
    { label: "Inactive", option: 0 },
  ];
  const [openmodal, setOpenmodal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isactive, setIsactive] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [instituteId, setInstituteId] = useState(null);
  const [delete1, setDelete1] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleData = (item) => {
    setIsactive(item);
  };

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const handleCloseModal1 = () => setOpenmodal(false);

  useQuery(
    ["INSTITUTEDELETEAPI"],
    () => {
      return deleteInstituteAPI(instituteId);
    },
    {
      retry: false,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: 0,
      mutationKey: "INSTITUTEDELETEAPI",
      enabled: delete1,
      onSuccess: (res) => {
        setLoading(false);
        if (res?.status === true) {
          handleCloseModal();
          dispatch(
            setToastMessage({
              isActive: true,
              message: res?.message,
              type: "success",
            })
          );
          setChange(true);
          setDelete1(false);
        } else {
        }
      },
    }
  );
  const handleDelete = () => {
    setDelete1(true);
    setLoading(true);
  };

  const open = Boolean(anchorEl);

  const handleClick = (event, item) => {
    setInstituteId(item.instituteId);
    // setIsactive()
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHeading tableHeading={institutetableHeading} />
          <TableBody>
            {instituteList.map((item, index) => (
              <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell>{index + serial}</TableCell>
                <TableCell align="left">{item.instituteName}</TableCell>
                <TableCell align="left">{item.email}</TableCell>
                <TableCell align="left">{item.phone}</TableCell>
                <TableCell align="left">
                  <StatusPills
                    status={item.isActive}
                    clickHandle={() => {
                      setOpenmodal(true);
                      handleData(item);
                    }}
                  />
                </TableCell>
                <TableCell align="left">
                  <MoreVertIcon
                    onClick={(e) => {
                      handleClick(e, item);
                    }}
                    className="cursor-pointer"
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem
          onClick={(e) => {
            navigate(`/institute/${instituteId}`);
            handleClose();
          }}
        >
          View Details
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            navigate(`/institute/update/${instituteId}`);
            handleClose();
          }}
        >
          Edit Institute
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenModal(true);
            setAnchorEl(null);
          }}
        >
          Delete Institute
        </MenuItem>
      </Menu>
      <DeleteModal
        open={openModal}
        loading={loading}
        deleteHandle={()=>{
          handleDelete();
          setIsupdated(true);
        }}
        handleClose={handleCloseModal}
        title="Are you sure want  to delete !!"
        deleteText="Delete Institute"
        className="w-100 h-100 bg-slate-800"
      />
      {openmodal && (
        <InstituteUpdateModal
          defaultValue={isactive}
          open={openmodal}
          isupdated={isupdated}
          setIsupdated={setIsupdated}
          // formik={formik}
          id="action"
          option={option}
          loading={loading}
          deleteHandle={() => {
            // handleUpdate();
            // handleCloseModal()
            //  handleClose()
            //   // handleUpdateClose();
            //   setLoading(true);
          }}
          handleClose={handleCloseModal1}
          deleteText="Update Exam"
          title="Are you sure want to update the  exam...!!!"
          className="w-100 h-100 bg-slate-800"
        />
      )}
      {/* {openUpdateModal && <InstituteUpdateModal/>
      defaultValue={isactive}
        open={openUpdateModal}
        isupdated={isupdated}
        setIsupdated={setIsupdated}
        // formik={formik}
        id="action"
        option={option}
        loading={loading}
        deleteHandle={() => {
          // handleUpdate();
          CloseEditModal();
         
        //  handleClose()
        //   // handleUpdateClose();
        //   setLoading(true);
        }}
        handleClose={handleUpdateClose}
        deleteText="Update Exam"
        title="Are you sure want to update the  exam...!!!"
        className="w-100 h-100 bg-slate-800"
      />} */}
    </>
  );
};

export default InstituteTable;
