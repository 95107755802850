import Cookies from "js-cookie";
import DashboardP from "./DashboardP";
import { useQuery } from "react-query";
import { useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { DashboadPagesLayout } from "../../components";
import { DashboardDetailsAPI } from "../../services/helpers/Dashboard";

const Dashboard = () => {
  const [examList, setExamList] = useState(null);
  const [totalExam, setTotalExam] = useState(null);
  const [studentList, setStudentList] = useState(null);
  const [totalStudent, setTotalStudent] = useState(null);
  const [instituteList, setInstituteList] = useState(null);
  const [dashboardData, setDashboardData] = useState(null);
  const [totalInstitute, setTotalInstitute] = useState(null);

  useEffect(() => {
    if (Cookies.get("id") === "student") {
      if ((window.location.href = "/")) {
        window.location.href = "/test";
      }
    }
  }, []);

  useQuery(
    ["DashboardDetails"],
    () => {
      const queryParams = {};
      if (Cookies.get("id") == "institute") {
        queryParams.instituteId = Cookies.get("userId");
      }
      const paramsData = {
        queryParams,
      };
      return DashboardDetailsAPI(paramsData);
    },
    {
      retry: true,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: 0,
      mutationKey: "DashboardDetails",
      enabled: true,
      onSuccess: (res) => {
        if (res?.status === true) {
          setDashboardData(res.data);
          setExamList(res.data.examList);
          setTotalExam(res.data.totalExam);
          setStudentList(res.data.studentList);
          setTotalStudent(res.data.totalStudent);
          setInstituteList(res.data.instituteList);

          if (Cookies.get("id") != "institute") {
            setTotalInstitute(res.data.totalInstitute);
          }
        } else {
        }
      },
    }
  );

  return (
    <>
      {dashboardData ? (
        <DashboardP examList={examList} studentList={studentList} instituteList={instituteList} totalInstitute={totalInstitute} totalStudent={totalStudent} totalExam={totalExam} />
      ) : (
        <DashboadPagesLayout>
          <div className="w-full h-[75vh] flex justify-center items-center">
            <Box sx={{ display: "flex" }}>
              <CircularProgress />
            </Box>
          </div>
        </DashboadPagesLayout>
      )}
    </>
  );
};

export default Dashboard;
