import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    instituteId: ''
};

export const studentSlice = createSlice({
    name: "student",
    initialState,
    reducers: {
        setSelectedInstituteId: (state, action) => {
            return { ...state, instituteId: action.payload.id };
        },
        removeSelectedInstituteId: (state, action) => {
            return {
                ...state, instituteId: ""
            };
        },
    },
});

export const { setSelectedInstituteId, removeSelectedInstituteId } = studentSlice.actions;

export default studentSlice.reducer;
