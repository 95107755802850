import axios from "axios";
import { BASE_URL } from "../utils/defines";
import { store } from "../../store";
import { setToastMessage } from "../../store/toast";
// import { store } from "../../store";
// import { setToastMessage } from "store/toast";

const axiosInstance = axios.create({
  baseURL: BASE_URL,
});
axiosInstance.interceptors.request.use((config) => {
  return config;
});
axiosInstance.interceptors.response.use(
  (response) => {
    if (response.data?.status === false) {
      // store.dispatch(
      //   setToastMessage({
      //     isActive: true,
      //     message: response?.data?.message || "Something went Wrong",
      //   })
      // );  
    }

    return response.data;
  },
  (error) => {
    // alert(error?.response?.data?.message || "Something went Wrong")
    store.dispatch(
      setToastMessage({
        isActive: true,
        message: error?.response?.data?.message || "Something went Wrong",
      })
    );
    return Promise.reject(error?.response?.data);
  }
);
export default axiosInstance;
