import * as yup from "yup";
import Cookies from "js-cookie";

export const initialValuesInstitute = {
  city: "",
  email: "",
  state: "",
  phone: "",
  country: "",
  address: "",
  pincode: "",
  instituteName: "",
  //   profileImg: "",
  // role: "institute",
  // addById: Cookies.get("userId"),
};
export const validationSchemaInstitute = yup.object().shape({
  instituteName: yup.string().required("Institute's Name is required"),
  email: yup.string().required("Email is required").email("Email is invalid"),
  country: yup.string().required("Country  is required"),
  city: yup.string().required("City is required"),
  state: yup.string().required("State is required"),
  address: yup.string().required("Address is required"),
  pincode: yup.string().required("Pin code is required"),
  // pincode: yup.string().required("Pin code is required").matches(/^\d{6}$/, 'Must be  6-digit  number'),
  phone: yup
    .string()
    .required('Phone number is required')
    .matches(/^\d{10}$/, 'Must be  10-digit  number'),
  // phone: yup.string().required("Phone code is required"),
});
