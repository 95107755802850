import { useState } from "react";
import { useFormik } from "formik";
import ExamForm from "../AddExam/ExamForm";
import { useQuery, useMutation } from "react-query";
import { setToastMessage } from "../../../store/toast";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateExamAPI } from "../../../services/helpers/Exam";
import { MASTERLISTAPI } from "../../../services/helpers/Users";
import { validationSchemaExam } from "../../../services/validate/course";

const UpdateExamForm = ({ data, initialValue, examId }) => {
  console.log("initslisdlfsdf  =============================", initialValue)
  const [selectedExam, setSelectedExam] = useState();
  const [examType, setExamType] = useState(null);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  useQuery(
    ["MASTERLISTAPI"],
    () => {
      const queryParams = {};
      const paramsData = {
        queryParams,
      };
      return MASTERLISTAPI(paramsData);
    },
    {
      retry: false,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: 0,
      mutationKey: "MASTERLISTAPI",
      enabled: true,
      onSuccess: (res) => {
        if (res?.status === true) {
          setExamType(res?.data);
        } else {
        }
      },
    }
  );

  let arr = [];
  for (var i = 0; i < examType?.length; i++) {
    let check = { label: examType[i].Name, option: examType[i].id };
    arr.push(check);
  }

  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: validationSchemaExam,
    onSubmit: (values) => {
      submitHandler(values);
    },
  });

  const submitHandler = () => {
    // let duration = formik.values.duration+":"+formik.values.second
    const newValues = {
      ...formik.values,
      id: examId,
      // duration:duration
    };
    mutate(newValues);
  };

  const { mutate, isLoading } = useMutation(updateExamAPI, {
    mutationKey: "updateExam",
    onSuccess: (data) => {
      if (data.status === true) {
        dispatch(
          setToastMessage({
            isActive: true,
            message: data?.message,
            type: "success",
          })
        );

        navigate("/exams");
        formik.resetForm();
      }
    },
  });



  return (examType && <ExamForm formik={formik} setSelectedExam={setSelectedExam} arr={arr} isLoading={isLoading} data={examType} />);
};

export default UpdateExamForm;
