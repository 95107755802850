import { useEffect, useState } from "react";
import { RadioButtonExam } from "../../../Forms/RadioButton";
import { examSelectedId, examUpdateById } from "../../../../store/exam";
import { useDispatch } from "react-redux";
import FillInBlankTextArea, { WrittenTextArea } from "./FillInBlankTextArea";
import { Button } from "@mui/material";
import { examinationUpdateById } from "../../../../store/examination";
import { setToastMessage } from "../../../../store/toast";

const ReadingQuiz = ({ change, selectedExam, time }) => {
  const [examD, setExamD] = useState();
  const dispatch = useDispatch();
  useEffect(() => {
    if (selectedExam.length > 0) {
      setExamD(selectedExam[change]);
      dispatch(examSelectedId(selectedExam[change].id));
      console.log("selectedExam[change] ==", selectedExam[change]);
    }
  }, [change, selectedExam]);

  const questionsAnswersHandle = (id, answer) => {
    if (answer) {
      // for both store dispatch exam and examination
      dispatch(examinationUpdateById({ id, answer }));
      dispatch(examUpdateById({ id, answer }));
      dispatch(
        setToastMessage({
          isActive: true,
          message: "Saved",
          type: "success",
        })
      );
    } else {
      dispatch(
        setToastMessage({
          isActive: true,
          message: "Invalid",
        })
      );
    }
  };

  const fillInBlankInputHandle = (id, answer) => {
    if (answer) {
      // for both store dispatch exam and examination
      dispatch(examinationUpdateById({ id, answer }));
      dispatch(examUpdateById({ id, answer }));
      dispatch(
        setToastMessage({
          isActive: true,
          message: "Saved",
          type: "success",
        })
      );
    } else {
      dispatch(
        setToastMessage({
          isActive: true,
          message: "Invalid",
        })
      );
    }
  };

  return (
    <>
      <ul className="">
        {examD && (examD?.questionTypeId == 1 || examD?.questionTypeId == 4) && (
          <li
            key={examD.id}
            className="mt-2 min-h-[270px]  sm:min-h-[230px] sm:max-h-[230px] md:min-h-[230px] md:max-h-[230px] lg:min-h-[260px] lg:max-h-[230px] xl:min-h-[230px] xl:max-h-[230px]"
          >
            <div className="flex justify-end">
              <p>{time} min</p>
            </div>
            <div className="text-gray-500 flex mb-6">
              <p className="mr-2 font-semibold text-gray-900">
                Ques.{change + 1}
              </p>
              <p dangerouslySetInnerHTML={{__html: examD?.question}}></p>
            </div>
            <div className="mt-3">
              <RadioButtonExam
                questionsAnswersHandle={questionsAnswersHandle}
                id={examD.id}
                answers={examD.options}
              />
            </div>
          </li>
        )}
        {examD && examD?.questionTypeId == 2 && (
          <li
            key={examD.id}
            className="mt-2 min-h-[270px]   sm:min-h-[230px] sm:max-h-[230px] md:min-h-[230px] md: lg:min-h-[260px] lg: xl:min-h-[230px] "
          >
            <div className="flex justify-end">
              <p>{time} min</p>
            </div>
            <div className="text-gray-500 flex mb-6">
              <p className="mr-4 font-semibold text-gray-900">
                Ques.{change + 1}
              </p>
              <p dangerouslySetInnerHTML={{__html: examD?.question}}></p>
            </div>
            <div className="mt-3">
              <FillInBlankTextArea
                fillInBlankInputHandle={fillInBlankInputHandle}
                id={examD.id}
              />
            </div>
          </li>
        )}
        {examD && examD?.questionTypeId == 3 && (
          <li
            key={examD.id}
            className=""
          >
            <div className="flex justify-end">
              <p>{time} min</p>
            </div>
            <div className="text-gray-500 flex mb-6 ">
              <p className="mr-4 font-semibold text-gray-900">
                Ques.{change + 1}
              </p>
              <p dangerouslySetInnerHTML={{__html: examD?.question}}></p>
            </div>
            <WrittenTextArea
              fillInBlankInputHandle={fillInBlankInputHandle}
              id={examD.id}
            />
            {/* <div className="mt-6"><RadioButtonExam id={examD.id} answers={examD.options} /></div> */}
          </li>
        )}
        {/* {examD && examD?.questionTypeId == 4 && (
          <li
            key={examD.id}
            className=""
          >
            <div className="flex justify-end">
              <p>{time} min</p>
            </div>
            <div className="text-gray-500 flex mb-6 ">
              <p className="mr-4 font-semibold text-gray-900">
                Ques.{change + 1}
              </p>
              <p dangerouslySetInnerHTML={{__html: examD?.question}}></p>
            </div>
            <div className="mt-3">
              <RadioButtonExam
                questionsAnswersHandle={questionsAnswersHandle}
                id={examD.id}
                answers={examD.options}
              />
            </div>
          </li>
        )} */}
      </ul>
    </>
  );
};

export default ReadingQuiz;
