import * as yup from 'yup';

export const initialValuesSignUp = {
  fullName: '',
  role: '',
  email: '',
  password: '',
};
export const initialValuesLogin = {
  email: '',
  password: '',
};
export const initialValuesReset = {
  password: '',
};
export const initialValuesForgot = {
  email: '',
};
export  const validationSchemaLogin = yup.object().shape({
  email: yup.string()
    .required('Email is required')
    .email('Email is invalid'),
  password: yup.string()
    .required('Password is required')
    .min(6, 'Password must be at least 6 characters')
    .max(30, 'Password must not exceed 40 characters'),
});

export  const validationSchemaReset = yup.object().shape({
  password: yup.string()
    .required('New Password is required')
    .min(6, 'New Password must be at least 6 characters')
    .max(30, 'New Password must not exceed 40 characters'),
  confirmpassword: yup.string()
    .required('Confirm Password is required')
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .min(6, 'Confirm Password must be at least 6 characters')
    .max(30, 'Confirm Password must not exceed 40 characters'),
});

export  const validationSchemaForgot = yup.object().shape({
  email: yup.string()
    .required('Email is required')
    .email('Email is invalid'),
});

export  const validationSchemaSignUp = yup.object().shape({

  fullName: yup.string()
  .required('Full Name  is required'),
  phone: yup.string()
  .required('Phone  is required'),
  email: yup.string()
    .required('Email is required')
    .email('Email is invalid'),
  password: yup.string()
    .required('Password is required')
    .min(6, 'Password must be at least 6 characters')
    .max(30, 'Password must not exceed 40 characters'),
    role: yup.string()
    .required('Role  is required'),
    
});