import { useState } from "react";
import { useDispatch } from "react-redux";
import ModeIcon from "@mui/icons-material/Mode";
import DeleteModal from "../../common/DeleteModal";
import DeleteIcon from "@mui/icons-material/Delete";
import { setToastMessage } from "../../../store/toast";
import { useMutation, useQueryClient } from "react-query";
import { deleteQuestionAPI } from "../../../services/helpers/Question";
import McqUpdateModal from "../../common/UpdateQuestionModal/McqUpdateModal";

const McqQuestionDetails = ({ questions, examDetails,index=1 }) => {
  const { correctAnswer, options, question, id } = questions;
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [initialValue, setInitialValue] = useState(null);
  const [questionDetails, setQuestionDetails] = useState(null);
  const [questionId, setQuestionId] = useState(null);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleCloseModal = () => setOpenModal(false);
  const handleCloseDeleteModal = () => setOpenDeleteModal(false);

  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setOpenModal(true);
    setQuestionDetails(examDetails);
    setQuestionId(questions.id);
    let option = {};
    option[`opt${1}`] = questions.options[0].title;
    option[`opt${2}`] = questions.options[1].title;
    option[`opt${3}`] = questions.options[2].title;
    option[`opt${4}`] = questions.options[3].title;
    setInitialValue({ question: questions.question, answer: questions.correctAnswer[0], time: questions.time, marks: questions.marks, ...option });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  /** Delete question */

  const { mutate: mutateDeleteQuestion, isLoading } = useMutation(deleteQuestionAPI, {
    mutationKey: "EXAMDETAILSAPI",
    onSuccess: (data) => {
      setOpenModal(false);
      setOpenDeleteModal(false);
      if (data.status === true) {
        dispatch(
          setToastMessage({
            isActive: true,
            message: data?.message,
            type: "success",
          })
        );
        queryClient.invalidateQueries("EXAMDETAILSAPI");
      }
    },
    onError: () => {},
    onSettled: () => {},
  });

  const handleDelete = () => {
    const queryParams = {
      questionId: questionId,
    };
    const paramsData = {
      queryParams,
    };
    mutateDeleteQuestion(paramsData);
  };

  return (
    <>
      <h2 className="text-gray-700 text-lg font-medium m-0 p-0 items-center flex-wrap mt-8 mb-4">
        <span className="">Question. {index}</span>
        {/* {question} */}
        <div className="mb-5 text-base" dangerouslySetInnerHTML={{__html: question}} />
        <span className="p-1 shadow bg-green-100 ml-2 rounded cursor-pointer text-blue-500">
          <ModeIcon fontSize="25px" onClick={handleClick} />
        </span>
        {/* <span className="p-1 shadow bg-red-100 ml-2 rounded cursor-pointer text-red-500">
          <DeleteIcon
            fontSize="25px"
            onClick={() => {
              setOpenDeleteModal(true);
              setAnchorEl(null);
              setQuestionId(questions.id);
            }}
          />
        </span> */}
      </h2>

      <ul className="text-gray-700 list-aplpha list-inside ml-4 mt-2">
        {options.map((option, i) => {
          return <li key={i}>{option.title}</li>;
        })}
      </ul>
      <h2 className="flex text-gray-700 text-lg font-normal mt-1">
        Answer: <span className="text-green-500">{correctAnswer}</span>
      </h2>
      <DeleteModal
        open={openDeleteModal}
        loading={isLoading}
        deleteHandle={() => {
          handleDelete();
          setLoading(true);
        }}
        handleClose={handleCloseDeleteModal}
        title="Are you sure to delete!!"
        deleteText="Delete Question"
        className="w-100 h-100 bg-slate-800"
      />
      {initialValue && (
        <McqUpdateModal
          open={openModal}
          questionId={questionId}
          initialValue={initialValue}
          handleClose={handleCloseModal}
          questionDetails={questionDetails}
          headingText="Update MCQ Question"
          className="w-100 h-100 bg-slate-800"
        />
      )}
    </>
  );
};

export default McqQuestionDetails;
