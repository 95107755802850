import React from "react";
import BreadcrumbsC from "../../components/common/BreadcrumbsC";
import { CircularProgress } from "@mui/material";
import { SCHEDULELISTAPI } from "../../services/helpers/ScheduleExam";
import { useQuery } from "react-query";
import SearchIcon from "@mui/icons-material/Search";
import AddSharpIcon from "@mui/icons-material/AddSharp";
import MainComponentWithPagination from "./ScheduleExamTable";
import { DarkButton } from "../../components";
import { useNavigate } from "react-router-dom";
import { IconButton, InputAdornment, TextField } from "@mui/material";

const ScheduleExam = () => {
  const navigate = useNavigate();
  const handleSearch = (e)=>{}
  const breadcrumbb = [
    {
      id: 1,
      title: "Dashboard",
      link: "/dashboard",
    },
    {
      id: 1,
      title: "Exam Schedule",
      link: "/examschedule",
    },
  ];

//   useQuery(
//     ["INSTITUTEAPI"],
//     () => {
//       const queryParams = {
//         instituteId: "7aac9699-d843-407b-9cce-57161eba2217",
//       };
//       const paramsData = {
//         queryParams,
//       };
//       return SCHEDULELISTAPI(paramsData);
//     },
//     {
//       retry: false,
//       keepPreviousData: true,
//       refetchOnWindowFocus: false,
//       staleTime: 0,
//       mutationKey: "INSTITUTEAPI",
//       enabled: true,
//       onSuccess: (res) => {
//         if (res?.status === true) {
//           console.log("resposn--> ", res)
//         } else {
//         }
//       },
//     }
//   );

  return (
    <div className="relative px-8 py-4 bg-[#F3F3F3] md:ml-64">
      <div className="w-full">
        <div className="flex justify-between items-center">
          <BreadcrumbsC data={breadcrumbb} />
        </div>
        <div className="flex flex-col-reverse sm:grid sm:grid-cols-2 justify-center sm:justify-between items-center mt-8 gap-5">
        <form onSubmit={handleSearch}>
          <div className="flex items-center w-full gap-4">
            <TextField
              id="outlined-start-adornment"
              className="w-full sm:w-auto"
              size="small"
              onChange={(e)=>{
              }}
              sx={{ backgroundColor: "white" }}
              placeholder="Search or Type Here"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton aria-label="toggle password visibility" edge="end">
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          
          </form>
        <div className="flex w-full sm:justify-end">
            <DarkButton btnTitle="Add Exam Schedule" endIcon={<AddSharpIcon />} handler={()=> {navigate("/examschedule/add");}} />
          </div>
        </div>
      </div>
      <div className={`bg-white mt-4 rounded-lg p-5 mb-10 `}>


        <div className="flex w-full">
          <MainComponentWithPagination />
        </div>
      </div>
    </div>
  );
};

export default ScheduleExam;
