import { DashboadPagesLayout, StudentDetail } from "../../../components";
import StudentExamListInstitute from "../../../components/Students/StudentExamListInstitute";
import BreadcrumbsC from "../../../components/common/BreadcrumbsC";

const breadcrumbb = [
  {
    id: 1,
    title: "Dashboard",
    link: "/dashboard",
  },
  {
    id: 2,
    title: "Student",
    link: "/students",
  },
  {
    id: 3,
    title: "Student Details",
    link: "/exams/details",
  },
];

const StudentExamList = () => {
  return (
    <DashboadPagesLayout>
      <div className="w-full px-8 py-4">
        <div className="flex justify-start items-center">
          <BreadcrumbsC data={breadcrumbb} />
        </div>
        
            <StudentExamListInstitute />
         
      </div>
    </DashboadPagesLayout>
  );
};

export default StudentExamList;
