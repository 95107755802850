import BreadcrumbsC from "../../components/common/BreadcrumbsC";
import { AddQuestion, DashboadPagesLayout } from "../../components";

const breadcrumbb = [
  {
    id: 1,
    title: "Dashboard",
    link: "/dashboard",
  },
  {
    id: 2,
    title: "Add Exam",
    link: "/exams/add",
  },
  {
    id: 3,
    title: "Add Questions",
    link: "/add-questions",
  },
];

const AddQuestions = () => {
  return (
    <DashboadPagesLayout>
      <div className="w-full px-8 py-4">
        <div className="flex justify-start items-center">
          <BreadcrumbsC data={breadcrumbb} />
        </div>
        <div className={`bg-white mt-8 rounded-lg p-5 mb-10 min-h-[60vh] `}>
          <div style={{boxShadow: "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px"}} className="flex w-full ">
            <AddQuestion />
          </div>
        </div>
      </div>
    </DashboadPagesLayout>
  );
};

export default AddQuestions;
