import { useFormik } from "formik";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import FillInTheBlanks from "./FillInTheBlanks";
import { setToastMessage } from "../../../store/toast";
import { AddQuestionAPI } from "../../../services/helpers/Question";
import { initialValueFillInTheBlanks as initialValues, validationSchemaFillInTheBlanks as validationSchema } from "../../../services/validate/AddQuestion";
import { Button, Stack } from "@mui/material";
import AddSharpIcon from "@mui/icons-material/AddSharp";
import RemoveIcon from '@mui/icons-material/Remove';
import { useNavigate, useParams } from "react-router-dom";
const Speaking = ({ questionType, examId, fillQList, setFillQList, sectionId }) => {
  const dispatch = useDispatch();
  // const {sectionId} = useParams()
  const navigate = useNavigate();
  const { mutate, isLoading } = useMutation(AddQuestionAPI, {
    mutationKey: "addFillQuestion",
    onSuccess: (res) => {
      if (res?.status === false) {
      } else if (res?.status === true) {
        dispatch(
          setToastMessage({
            isActive: true,
            message: res?.message,
            type: "success",
          })
        );
        formik.resetForm();
        navigate(`/exams/${examId}/${sectionId}`)
      }
    },
    onError: () => { },
    onSettled: () => { },
  });

  const submitHandler = (values) => {
    console.log("formik values ==> ", values)
    const newValues = {
      // ...formik.values,
      sectionId: sectionId,
      questionType: questionType,
      examId: examId,
      fillBlank: values.questions.map((question, index) => ({
        question: question[`question`],
        writtenAnswer: question[`writtenAnswer`],
        time: question[`time`],
      })),
    };
    if(sectionId){
      mutate(newValues);
    } else {
      alert("Select Section of question")
    }
  };

  const formik = useFormik({
    initialValues: {
      questions: Array.from({ length: fillQList.length }, (_, index) => ({
        question: "",
        writtenAnswer: "",
        time: "1",
      })),
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      submitHandler(values);
    },
  });

  console.log("formik written ==> ", formik.values)

  return (
    <div className="w-full mt-4">
      <div className="flex justify-end gap-4">
        {/* <Stack> */}

        <Button variant="contained" onClick={() => {
          setFillQList((prevState) => {
            return [...prevState, prevState.length]
          })
          formik.values.questions.push({
            question: "",
            writtenAnswer: "",
            time: "1",
          })
        }}>Add Question <AddSharpIcon className="ml-2" /></Button>
        {/* </Stack> */}
        {fillQList.length !== 1 && <Button variant="contained" onClick={() => {
          if (fillQList.length !== 1) {
            let questions = formik.values.questions
            questions.pop()
            formik.values.questions = questions
            setFillQList((prevState) => {
              prevState.pop()
              return [...prevState]
            })
          }
        }}>Remove Question <RemoveIcon className="ml-2"/></Button>}
      </div>
      <FillInTheBlanks formik={formik} btnTitle="Add Question" isLoading={isLoading} fillQList={fillQList} />
    </div>
  );
};

export default Speaking;
