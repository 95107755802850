import { useState } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import StudentForm from "../StudentForm";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { setToastMessage } from "../../../store/toast";
import { SelectInstituteAPI } from "../../../services/helpers/Users";
import { updateStudentAPI } from "../../../services/helpers/Student";
import { validationSchemaStudent } from "../../../services/validate/student";

const UpdateStudent = ({ initialValue }) => {
  const [imgFile, setImgFile] = useState();
  console.log("initialValue",initialValue);
  const [initialValueFormik, setInitialValueFormik] = useState({
    country: initialValue?.country,
    state: initialValue?.state,
    city: initialValue?.city,
  })

  const dispatch = useDispatch();
  const handleNavigate = ()=>{
    navigate("/students")
  }

  const [institutelist, setInstituteList] = useState([]);
  const [previewImage, setPreviewImage] = useState(imgFile || initialValue.profileImg);

  const navigate = useNavigate();

  useQuery(
    ["InstituteAPI"],
    () => {
      const queryParams = {};
      const paramsData = {
        queryParams,
      };
      return SelectInstituteAPI(paramsData);
    },
    {
      retry: false,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: 0,
      mutationKey: "InstituteAPI",
      enabled: true,
      onSuccess: (res) => {
        if (res?.status === true) {
          let institute = res.data.map((data) => {
            return {
              label: data.instituteName,
              option: data.institueId,
            };
          });
          setInstituteList(institute);
        } else {
        }
      },
    }
  );

  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: validationSchemaStudent,
    onSubmit: (values) => {
      submitHandler(values);
    },
  });

  const submitHandler = () => {
    const newValues = {
      ...formik.values,
      // instituteId: instituteId,
    };
    mutate(newValues);
  };

  const { mutate, isLoading } = useMutation(updateStudentAPI, {
    mutationKey: "updateStudent",
    onSuccess: (data) => {
      if (data.status === true) {
        dispatch(
        setToastMessage({
          isActive: true,
          message: data?.message,
          type: "success",
        })
        )
        setTimeout(()=>{
          handleNavigate();
        },2000)

      }
    },
  });

  function pathImage(name) {
    formik.setFieldValue("profileImg", name);
  }

  console.log("initialValue==>", initialValue);
  return (
    <div className="w-full">
      <h1 className="text-xl text-center font-semibold ">Update Student</h1>
      <StudentForm
        formik={formik}
        previewImage={previewImage}
        isLoading={isLoading}
        setPreviewImage={setPreviewImage}
        pathImage={pathImage}
        institue={institutelist}
        setImgFile={setImgFile}
        initialValueFormik={initialValueFormik} setInitialValueFormik={setInitialValueFormik}
      />
    </div>
  );
};

export default UpdateStudent;
