import React, { useEffect, useState } from 'react';
import WebcamRecorder from "../../pages/Videotest/index";
import Livetest from '../../pages/livetest';
import information from "../../assets/img/information.png"
import AudioLivetest from '../AudiotestLive';


export const AudioUpload = ({setEnableStartExam=()=>{},setStatus,setmicStatus,test, questionsAnswersHandle, id}) => {
  console.log("audiotuploaddsk", questionsAnswersHandle, id )
  const [cameraStatus, setCameraStatus] = useState('pending');
  console.log(cameraStatus);
  const [video,setVideo] = useState(false);
  const [cameraswitch,setCameraSwitch] = useState(false);

  const [micStatus, setMicStatus] = useState('pending');
  console.log(micStatus);
  const [flag,setFlag] = useState(false);

  useEffect(()=>{
    setFlag(true)
  },[video])
  
  useEffect(() => {
    // handleCameraPermission();
    handleMicPermission();
  }, [])
  const handleCameraPermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      setCameraStatus('on');
    //   if(test){
    //     setStatus("on");
    //   }
      
    //   if(test){
    //     setStatus("on");
    //   }
      stream.getTracks().forEach(track => track.stop());
    } catch (error) {
      setCameraStatus('off');
    //   if(test){
    //     setStatus("off")
    //   }
    }
  };

  const uploadSubmitCallback = (answer) => {
    questionsAnswersHandle(id, answer)
  }


  const toggleCameraPermission = async () => {
    if (cameraStatus === 'on') {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        setCameraStatus('off');
        setVideo(false);
        // Stop the stream to release the camera when not in use
        stream.getTracks().forEach(track => track.stop());
      } catch (error) {
        console.error("Error turning off camera:", error);
      }
    } else {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        setCameraStatus('on');
        setVideo(true)
        stream.getTracks().forEach(track => track.stop());
      } catch (error) {
        setCameraStatus('off');
        console.error("Error turning on camera:", error);
      }
    }
  };
  const handleMicPermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      setMicStatus('on');
    //   if(test){
    //   setmicStatus("on");
    //   }
      
      
      setEnableStartExam((prev)=>{
        return {
          ...prev,
          mediaEnabled: true,
        }
      })
      // Stop the stream to release the microphone when not in use
      stream.getTracks().forEach(track => track.stop());
    } catch (error) {
      setMicStatus('off');
    //   if(test){
    //     setmicStatus("off");
    //   }
      
      setEnableStartExam((prev)=>{
             
        return {       
          ...prev,
          mediaEnabled: false,
        }
      })
    }
  };
  return (
    <div className=''>
    {/* <div className='md:ml-64 mt-10'> */}
      <div className='flex justify-around'>
        {/* <div className=''>
          <p>Camera status: {cameraStatus}</p><br />
          {cameraStatus == "on" ? <div className='max-w-sm'>
            <img onClick={()=>{
              if(!cameraswitch){
                setCameraStatus("on");
                toggleCameraPermission();
              }
            }} className="w-32" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzqDXclQqnkSvb6QcI5WtqHoo05Rfj23Tpeg&usqp=CAU" />
          </div> : null}
          {cameraStatus == "pending" || cameraStatus == "off" ? <div className='max-w-sm'>
            <img onClick={()=>{
              if(!cameraswitch){
                toggleCameraPermission();
              }
            }}  className="w-32" src="https://static.thenounproject.com/png/4600752-200.png" />
          </div> : null}

        </div> */}
        <div>
          <p>Mic status: {micStatus}</p>

            
          {micStatus == "off" || micStatus == "pending" ? (
            <img onClick={handleMicPermission} className="w-20 h-24 mt-10" src="https://cdn1.iconfinder.com/data/icons/material-audio-video/21/mic-off-512.png" />
          ) : <div>
            {/* <p>Mic status: {micStatus}</p> */}
            {micStatus == "on" ? (
              <img onClick={handleMicPermission} className="w-20 h-[90px] mt-8" src="https://svgsilh.com/svg/312421.svg" />
            ) : null}
          </div>}

        </div>
      </div>
      {!flag?<div className='flex justify-center  '><button onClick={()=>{
        setFlag(true);
      }} className='px-6 mt-4  py-3 bg-blue-500 rounded-lg text-white'>Test Recording </button></div>:null}
      {flag?<div ><AudioLivetest uploadSubmitCallback={uploadSubmitCallback} video={video} setCameraSwitch={setCameraSwitch} micStatus={micStatus}  /></div>:null}
      { micStatus == "off"?<div className="flex justify-center mt-6"><img  src = {information} /></div>:null}
      {/* <div><img src={}/></div> */}
    </div>
  );
};






