import { TextField } from "@mui/material";

const InputTextField = ({ type, id, label, placeholder, formik, defaultValue }) => {
  console.log("id", id, "id", formik);
  return (
    <TextField
    fullWidth
      size="small"
      defaultValue={defaultValue}
      id={id}
      type={type}
      label={label}
      placeholder={placeholder}
      // multiline
      {...formik.getFieldProps(id)}
      error={formik.touched[id] && formik.errors[id]}
      helperText={formik.touched[id] && formik.errors[id]}
    />
  );
};

export default InputTextField;
