import * as yup from "yup";
import Cookies from "js-cookie";

export const initialValuesExam = {
  action:""
  //   profileImg: "",


};
export const validationSchemaExam = yup.object().shape({
  action: yup.string().required("Status is required "),
//   email: yup.string().required("Email is required").email("Email is invalid"),
//   country: yup.string().required("Country  is required"),
//   city: yup.string().required("City is required"),
//   state: yup.string().required("State is required"),
//   address: yup.string().required("Address is required"),
//   pincode: yup.string().required("Pin code is required"),
//   phone: yup
//     .number()
//     .typeError("That doesn't look like a phone number")
//     .positive("A phone number can't start with a minus")
//     .integer("A phone number can't include a decimal point")
//     .min(8)
//     .required("A phone number is required"),
  // phone: yup.string().required("Phone code is required"),
});
