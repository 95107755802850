import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  personal:{
  },
  education:{
  },
  banking:{
  },
  document:{
  },
  profile:{
  },
  login:{

  }
}

export const caretakerSlice = createSlice({
  name: 'caretaker',
  initialState,
  reducers: {

    caretakerForm1: ( state, action) => {
      return {...state, personal: action.payload}
    },
    caretakerForm2: ( state, action) => {
      return {...state, education: action.payload}
    },
    caretakerForm3: ( state, action) => {
      return {...state, banking: action.payload}
    },
    caretakerForm4: ( state, action) => {
      return {...state, document: {...state.document,[action.payload.field]:action.payload.img,id:action.payload.id}}
    },
    caretakerForm5: ( state, action) => {
      return {...state, profile: {...state.profile,[action.payload.field]:action.payload.img}}
    },
    caretakerReset: (state, action) => {
      return initialState
    },
    Logindata:( state, action) => {
      return {...state, login: action.payload}
    }

    

  },
})

export const { caretakerForm1,caretakerForm2,caretakerForm3,caretakerForm4,caretakerForm5, caretakerReset,Logindata } = caretakerSlice.actions

export default caretakerSlice.reducer