import React, { useState } from "react";
import { SelectInputField, TextAreaField, InputTextField } from "../../../components/Forms";
import { DarkButton } from "../../../components";
const testType = [
  {
    option: "multiple",
    label: "Multiple choice",
  },
  {
    option: "matching",
    label: "Matching",
  },
  {
    option: "diagram",
    label: "Plan, map, diagram labelling",
  },
  {
    option: "flowChart",
    label: "Form, note, table, flow-chart, summary completion",
  },

  {
    option: "sentenceCompletion",
    label: "Sentence completion",
  },
  {
    option: "shortAnswer",
    label: "Short-answer questions",
  },
];
const ScheduleExamForm = ({ formik, options }) => {
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const handleTimeChange = (e) => {
    setSelectedTime(e.target.value);
  };
  return (
    <div>
      <form className="" onSubmit={formik.handleSubmit}>
      <div className="grid my-10">
          <InputTextField type="text" id="description" label="Exam Title" formik={formik} placeholder="Exam Title" />
        </div>
        <div className="flex gap-4 items-start min-h-[100px]">
          <div className="flex-1">
            <SelectInputField id="examId" label="Exam" defaultValue="Select Exam" options={options} formik={formik} />
          </div>
          <div className="flex-1">
            <div className="flex items-start gap-4">
              <div className="flex-1">
                <input
                  name="examScheduleDate"
                  type="date"
                  value={formik.values.examScheduleDate}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="border rounded p-2 w-full"
                />
                {formik.touched.examScheduleDate && formik.errors.examScheduleDate ? <div className="text-red-500 text-sm ml-2">{formik.errors.examScheduleDate}</div> : null}
              </div>
              <div className="flex-1">
                <input
                  name="examScheduleTime"
                  type="time"
                  value={formik.values.examScheduleTime}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="border rounded p-2 w-full"
                />
                {formik.touched.examScheduleTime && formik.errors.examScheduleTime ? <div className="text-red-500 text-sm ml-2">{formik.errors.examScheduleTime}</div> : null}
              </div>
            </div>
          </div>
        </div>

        <div className="mt-10 flex justify-center">
          <DarkButton type="submit" btnTitle="Submit" loading={false} />
        </div>
      </form>
    </div>
  );
};

export default ScheduleExamForm;
