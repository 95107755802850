import { useState } from "react";
import DeleteModal from "../common/DeleteModal";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Table, TableBody, TableRow, TableContainer, TableCell, Menu, MenuItem } from "@mui/material";
import TableHeading from "../common/Table/TableHeading";
import { examTableHeading } from "../../mockData";
import StatusPills from "../common/StatusPills";
import { deleteExamAPI } from "../../services/helpers/Users";
import ExamUpdateModal from "./ExamDetail/ExamUpdateModal";
import { setToastMessage } from "../../store/toast";
import { useDispatch } from "react-redux";
import AssignExamInstitute from "./SectionDetail/AssignExamInstitute";
import Cookies from 'js-cookie'

const ExamListTable = ({ data, serial, setChange, isupdated, setIsupdated }) => {
  const [examid, setExamId] = useState();
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isactive, setIsactive] = useState(null);
  const [delete1, setDelete1] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [anchorUpdate, setAnchorUpdate] = useState(null);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [assignExamModal, setAssignExamModal] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleCloseModal = () => setOpenModal(false);
  const handleData = (item) => {
    setIsactive(item);
  };

  const option = [
    { label: "Active", option: 1 },
    { label: "Inactive", option: 0 },
  ];

  console.log("ExamId", examid);
  useQuery(
    ["USERDELETEAPI"],
    () => {
      return deleteExamAPI(examid);
    },
    {
      retry: false,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: 0,
      mutationKey: "USERDELETEAPI",
      enabled: delete1,
      onSuccess: (res) => {
        if (res?.status === true) {
          setLoading(false);
          handleCloseModal();

          dispatch(
            setToastMessage({
              isActive: true,
              message: res?.message,
              type: "success",
            })
          );
          setChange(true);
          setDelete1(false);
          console.log(res);
        } else {
        }
      },
    }
  );

  /**========= delete modal ========== */
  const open = Boolean(anchorEl);
  const handleClick = (event, id) => {
    setExamId(id);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    setDelete1(true);
  };

  const CloseEditModal = () => {
    setOpenUpdateModal(false);
  };

  /**========= update modal ========== */
  const update = Boolean(anchorUpdate);


  const handleUpdateClose = () => {
    setOpenUpdateModal(false);
  };
  const handleAssignExamClose = () => {
    setAssignExamModal(false);
  };

  return (
    <>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHeading tableHeading={examTableHeading} />
          <TableBody>
            {data?.map((item, index) => (
              <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell>{serial + index}</TableCell>
                <TableCell align="left">{item.examName}</TableCell>
                <TableCell align="left">{item?.addByName}</TableCell>
                <TableCell align="left">
                  {" "}
                  {item?.examType.includes("[")
                    ? JSON.parse(item?.examType).map((examType) => {
                        return (
                          <>
                            {" "}
                            {examType == "1"
                              ? "Reading"
                              : examType == "2"
                              ? "Writing"
                              : examType == "3"
                              ? "Listening"
                              : examType == "4"
                              ? "Speaking"
                              : examType == "12"
                              ? "General Reading"
                              : examType == "11"
                              ? "Academic Reading"
                              : null}{" "}
                            <br />
                          </>
                        );
                      })
                    : item.examType == "1"
                    ? "Reading"
                    : item.examType == "2"
                    ? "Writing"
                    : item.examType == "3"
                    ? "Listening"
                    : item.examType == "4"
                    ? "Speaking"
                    : null}
                </TableCell>
                <TableCell align="left">{item.duration}</TableCell>
                {/* <TableCell align="left">{item.totalMarks}</TableCell> */}
                <TableCell align="left">
                  <StatusPills
                    status={item.isActive}
                    clickHandle={() => {
                      setOpenUpdateModal(true);
                      handleData(item);
                      // setOpenModal(true);
                      // setAnchorEl(null);
                    }}
                  />
                </TableCell>
                <TableCell align="left">
                  <button
                    type="button"
                    class="text-white bg-[#1e78fe] focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                    onClick={() => {
                      navigate(`/${item.id}/add-section`);
                    }}
                  >
                    Add Section
                  </button>
                </TableCell>
                <TableCell align="left">
                  <MoreVertIcon
                    onClick={(e) => {
                      handleClick(e, item.id);
                    }}
                    className="flex cursor-pointer"
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {Cookies.get("id") === "super_admin" && <MenuItem
          onClick={(e) => {
            setAssignExamModal(true)
            handleClose();
          }}
        >
          Exam Assign to Institute
        </MenuItem> }
        <MenuItem
          onClick={(e) => {
            navigate(`/exams/${examid}`);
            handleClose();
          }}
        >
          View Details
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            navigate(`/exam/update/${examid}`);
            handleClose();
          }}
        >
          Edit Exam
        </MenuItem>
      </Menu>
      <DeleteModal
        open={openModal}
        loading={loading}
        deleteHandle={() => {
          handleDelete();
          setLoading(true);
          setIsupdated(true)
        }}
        handleClose={handleCloseModal}
        title="Are you sure to delete!!"
        deleteText="Delete Exam"
        className="w-100 h-100 bg-slate-800"
      />

      {openUpdateModal && (
        <ExamUpdateModal
          defaultValue={isactive}
          open={openUpdateModal}
          isupdated={isupdated}
          setIsupdated={setIsupdated}
          id="action"
          option={option}
          loading={loading}
          deleteHandle={() => {
            CloseEditModal();
          }}
          handleClose={handleUpdateClose}
          deleteText="Update Exam"
          title="Are you sure want to update the  exam...!!!"
          className="w-100 h-100 bg-slate-800"
        />
      )}
      {assignExamModal && <AssignExamInstitute open={assignExamModal} handleClose={handleAssignExamClose} examId={examid} />}
    </>
  );
};

export default ExamListTable;
