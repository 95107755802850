import { SpeakerCard } from "../../../components";
import { STUDENTEXAMSUBMITAPI } from "../../../services/helpers/Exam";
import ExamQuestionList from "./ExamQuestionList";
import ExamType from "./ExamType";
import { useSelector } from 'react-redux';
import { useMutation } from 'react-query';
import Cookies from "js-cookie";
import { CameraAndMicComponent } from "../../../components/Cameratest";
import RecordView from "../../Videotest";

const ExamAttemp = ({ examDetails, examType, examName, examTime, examModule, examModuleList, selectedModule, submitExam, setSubmitExam,activeSection }) => {
  const {exam, exam:{
    examAttempt,questions
  }} = useSelector((state)=>state)

  const ExamSubmitHandler = (type) => {
    let payload = {
      studentId: Cookies.get('userId'),
      examId:examAttempt?.examId,
      scheduleId:examAttempt?.scheduleId,
      examTypeId:examDetails?.examTypeId,
      qusAnsData: questions,
    }
    mutate(payload)
  }

  const { mutate, isLoading } = useMutation(STUDENTEXAMSUBMITAPI, {
    mutationKey: "STUDENTEXAMSUBMIT",
    onSuccess: (res) => {
      if (res?.status === false) {
      } else if (res?.status === true) {
        setSubmitExam((prev)=>{
          return {
            userStatus: prev.userStatus,
            timerStatus: prev.timerStatus,
            userSubmit: "submitted"
          }
        })
      }
    },
    onError: () => {},
    onSettled: () => {},
  });

  return (
    <>
    <div className="w-full">
    <div className="w-full relative px-8 py-4 bg-[#fff] min-h-[40px] ">
      <h3 className="text-center text-2xl "><span className="font-semibold">Exam:</span>{examName} <span>{` (${examTime} min)`}</span></h3>
      <h3 className="text-center text-xl "><span className="font-semibold">Module:</span>{examModule}</h3>
    </div>
      <div className="relative px-8 py-4 lg:flex-row flex-col flex bg-[#F3F3F3] min-h-[80vh] gap-8">
          {/** Question Sidebar */}
          <ExamType examDetails={examDetails} />
        {/* <div className={``}> */}
          <div
            className={`bg-white  rounded-lg shadow py-4 relative px-8 w-full pb-4 flex flex-col justify-between`}
            >
            {
              (examType == 1 || examType == 2 || examType == 3 || examType == 4 ) && <>
                {/**Reading, writing, listening Exam*/}
                <ExamQuestionList listQ={examDetails?.questions} examModuleList={examModuleList} selectedModule={selectedModule} ExamSubmitHandler={ExamSubmitHandler} submitExam={submitExam} isLoading={isLoading} examType={examType} activeSection={activeSection} />
              </>
            }
            {/* {examType == 3 && <>
              <SpeakerCard />
            </>} */}
            {/* {examType == 4 && <> */}
              {/**Speaking Exam */}
              {/* <SpeakerCard /> */}
              {/* <RecordView />
            </>
            } */}
          </div>
          {/* <ExamPagination /> */}
        {/* </div> */}
      </div>
    </div>
            </>
  );
};

export default ExamAttemp;
