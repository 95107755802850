import * as yup from "yup";
import Cookies from "js-cookie";

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const initialValuesStudent = {
  firstName:"",
  lastName:"",
  gender:"",
  fatherName:"",
  phone:"",
  email:"",
  password:"",
  role:"student",
  addById:Cookies.get("userId"),
  course:"",
  degree:"",
  university:"",
  applyFor:"",
  address:"",
  city:"",
  state:"",
  country:"India",
  pincode:"",
  profileImg:"",
  instituteId:"",
  
};
export const validationSchemaStudent = yup.object().shape({
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  fatherName: yup.string().required("Father's Name is required"),
 
  gender:yup.string().required("Gender  is required"),
  phone: yup.string()
        .required('Phone number is required')
        .matches(/^\d{10}$/, 'Must be  10-digit  number'),
  // phone: yup.number().min(10, "Number must be at least 10 digits").required("Phone number is required"),
  email: yup.string().required("Email is required").email("Email is invalid"),
  // course: yup.string().required("Course is required"),
  // country: yup.string().required("Country  is required"),
  applyFor: yup.string().required("Apply For  is required"),
  // university: yup.string().required("University  is required"),
  // degree: yup.string().required("Degree is required"),
  // // testType: yup.string().required("Test Type  is required"),
  city: yup.string().required("City is required"),
   state: yup.string().required("State is required"),
   address: yup.string().required("Address is required"),
  //  pincode: yup.string().required("Pin code is required").matches(/^\d{6}$/, 'Must be  6-digit  number'),
   pincode: yup.string().required("Pin code is required"),
   instituteId: yup.string().required("Institute  is required"),
});


export const initialValuesInstitute = {
  firstName:"",
  lastName:"",
  gender:"",
  fatherName:"",
  phone:"",
  email:"",
  password:"",
  role:"student",
  addById:Cookies.get("userId"),
  applyFor:"",
  address:"",
  city:"",
  state:"",
  country:"India",
  pincode:"",
  profileImg:"",
  instituteId:Cookies.get("userId"),
  
};
export const validationSchemaInstitute = yup.object().shape({
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  fatherName: yup.string().required("Father's Name is required"),
 
  gender:yup.string().required("Gender  is required"),
  phone: yup.number().min(10, "Number must be at least 10 digits").required("Phone number is required"),
  email: yup.string().required("Email is required").email("Email is invalid"),
  country: yup.string().required("Country  is required"),
  applyFor: yup.string().required("Apply For  is required"),
  // testType: yup.string().required("Test Type  is required"),
  city: yup.string().required("City is required"),
  state: yup.string().required("State is required"),
  address: yup.string().required("Address is required"),
  pincode: yup.string().required("Pin code is required"),
  
  // instituteId: yup.string().required("Institute  is required"),
});
//Different validation and initialValues 