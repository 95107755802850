import React from 'react'
import ResultDetailComp from '../../../components/ResultListComp/ResultDetailComp'
import { useParams } from 'react-router-dom'
import AppSideBarExam from '../../../components/common/AppSideBar/AppSideBarExam'
import ExamHeader from '../../../components/ExamHeader'
import { DashboadPagesLayout, Header } from '../../../components'
const ResultDetail = () => {
    const { id } = useParams()
    return (
        <div>
            <AppSideBarExam type="dashboard" />
            <Header />

            <DashboadPagesLayout>
                <ResultDetailComp />
            </DashboadPagesLayout>
        </div>
    )
}

export default ResultDetail