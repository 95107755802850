import React, { useState, useRef, useEffect } from "react";
import { ReactMediaRecorder } from "react-media-recorder";
import { UploadSection } from "../../services/helpers/Users";
import { useMutation } from "react-query";
const VideoPreview = ({ stream }) => {
  const videoRef = useRef(null);
  useEffect(() => {
    if (videoRef.current && stream) {
      videoRef.current.srcObject = stream;
    }
  }, [stream]);
  if (!stream) {
    return null;
  }
  return <video ref={videoRef} width={500} height={500} autoPlay controls />;
};
const RecordView = ({ questionsAnswersHandle=()=>{}, id="lksjdlfkjs" }) => {
  const formdata = new FormData();
  const [path, setPath] = useState(null);
  const [flag, setFlag] = useState(false);
  const [blob, setBlob] = useState(null);
  const [myState, setMyState] = useState(false);
  const onStop = async (stopRecording, status) => {
    console.log("onClick status ::", status);
    await stopRecording();
    console.log("onClick status after ::", status);
    setMyState(true);
  };
  const handleStopRecording = (blobUrl, status) => {
    console.log("handleStopRecording", status);
    if (status === "stopped") {
      fetch(blobUrl)
        .then((response) => response.blob())
        .then((blob) => {
          console.log("blobblobblobblob", blob);
          const fileName = "myFile.mp4";
          const newFile = new File([blob], fileName, { type: blob.type });
          formdata.append("file", newFile);
          check(formdata);
        });
    }
  };
  const { mutate: check } = useMutation(UploadSection, {
    mutationKey: "uploadSection",
    onSuccess: (res) => {
      if (res?.status === false) {
      } else if (res?.status === true) {
        questionsAnswersHandle(id, res?.data?.fileName);
        setPath(res?.data?.path);
        setFlag(true);
      }
    },
    onError: () => {},
    onSettled: () => {},
  });
  return (
    <div className="max-w-md mx-auto mt-8 p-8 bg-white rounded-lg shadow-md">
      <ReactMediaRecorder
        video={true}
        render={({
          status,
          startRecording,
          stopRecording,
          mediaBlobUrl,
          previewStream,
        }) => (
          <>
            <p className="text-center mb-4">{status}</p>
            {status !== "stopped" ? (
              <div className="flex justify-center">
                <button
                  onClick={startRecording}
                  disabled={status === "recording"}
                  className="bg-blue-500 text-white py-2 px-4 rounded mr-2"
                >
                  Start Recording
                </button>
                <button
                  onClick={() => onStop(stopRecording, status)}
                  disabled={status === "idle"}
                  className="bg-red-500 text-white py-2 px-4 rounded"
                >
                  Stop Recording
                </button>
              </div>
            ) : null}
            {status === "recording" && (
              <div className="mt-4">
                <h2 className="text-lg font-semibold mb-2">Live Preview:</h2>
                <VideoPreview stream={previewStream} />
              </div>
            )}
            <div className="flex justify-center">
              {status === "stopped" && (
                <button
                  className="bg-blue-500 text-white py-2 px-4 rounded mr-2"
                  onClick={() => handleStopRecording(mediaBlobUrl, status)}
                >
                  Upload
                </button>
              )}
            </div>
            {status === "stopped" ? (
              <div className="mt-4">
                <h2 className="text-lg font-semibold mb-2">Recorded Video:</h2>
                <video
                  src={path}
                  controls
                  autoPlay
                  loop
                  className="w-full rounded shadow-md"
                />
              </div>
            ) : null}
          </>
        )}
      />
    </div>
  );
};
export default RecordView;
