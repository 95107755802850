import React from "react";
import { useSelector } from "react-redux";
import ExamSuccess from "./ExamSuccess";
import ExamInstruction from "./ExamInstruction";
import DashboadPagesLayout from "../common/Layouts/DashboadPagesLayout";
import AppSideBarTest from "../common/AppSideBar/AppSideBarTest";

const ExaminationC = ({ StudentExamDetails }) => {
  const { examStatus } = useSelector((state) => state.examination);

  return (
    <>
      {examStatus?.submittedStatus ? (
        <>
          <AppSideBarTest />
          <DashboadPagesLayout>
            <ExamSuccess />
          </DashboadPagesLayout>
        </>
      ) : (
        <ExamInstruction StudentExamDetails={StudentExamDetails} />
      )}
    </>
  );
};

export default ExaminationC;
