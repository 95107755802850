import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { submitExamStatusReset } from '../../store/examination'
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { GETEXAMRESULTAPI } from '../../services/helpers/Exam';
import { Card } from '@mui/material'
import LoaderSpiner from '../common/LoaderSpiner';
import { useQuery } from "react-query";
import Cookies from 'js-cookie';

const ExamSuccess = () => {
  const { examinationSubmitStatus } = useSelector((state) => state.examination)
  console.log("examinationSubmitStatusexaminationSubmitStatusexaminationSubmitStatusexaminationSubmitStatus", examinationSubmitStatus)
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const [result, setResult] = useState()
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    return () => {
      dispatch(submitExamStatusReset())
    }
  }, [])

  useQuery(
    ["examimationResult"],
    () => {
      const queryParams = {
        submitExamId: examinationSubmitStatus.id,
      };
      const paramsData = {
        queryParams,
      };
      return GETEXAMRESULTAPI(paramsData);
    },
    {
      retry: false,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      mutationKey: "examimationResult",
      enabled: true,
      // enabled: examinationSubmitStatus.id.length > 0 ? true : false,
      onSuccess: (res) => {
        if (res?.status === true) {
          setResult(res.data);
          setIsLoading(false)
          Cookies.remove("sfirstExamId")
          Cookies.remove("sresultExamId")

        }
      },
    }
  );

  console.log("resultstljl", result)

  return (
    <div className="w-full">
      <div className="w-full relative px-8 py-4  bg-[#fff] min-h-[60px] ">
        <h3 className="text-center text-2xl ">
          {/* <div>ExamSuccess</div> */}
        </h3>
      </div>
      {isLoading ? <div className="w-full flex justify-center items-center min-h-[60vh]">
        <LoaderSpiner />
      </div> :
        <>
          {result &&
            <div className="w-full relative px-8 my-4">
              <div className="text-center flex justify-center items-center text-2xl bg-[#fff] min-h-[60vh] flex-col gap-4">
                {/* <h3>Exam Submitted Successfully</h3> */}
                <div className='flex flex-col w-full mb-20'>
                  <h3 className='pb-6'>Result</h3>
                  {result.map((examResult,index)=>{
                    return (
                      <Card className='w-[90%] md:w-1/2 m-auto py-12 my-2'>
                    <h1 className='text-center text-base mb-2 '><span className='font-semibold'>Module Name: </span> <span>{examResult?.examTypeName}</span></h1>
                    <div className='w-full m-auto flex text-base font-semibold mb-2'>
                      <div className='w-1/2 text-right'>Total Question:</div>
                      <div className='w-1/2 text-left pl-8'>{examResult?.result?.totalQuestion}</div>
                    </div>

                    <div className='w-full m-auto flex text-base font-semibold mb-2'>
                      <div className='w-1/2 text-right'>Right Answer:</div>
                      <div className='w-1/2 text-left pl-8'>{examResult?.result?.correctAnswer}</div>
                    </div>

                    <div className='w-full m-auto flex text-base font-semibold mb-2'>
                      <div className='w-1/2 text-right'>Wrong Answer:</div>
                      <div className='w-1/2 text-left pl-8'>{examResult?.result?.incorrectAnswer}</div>
                    </div>

                    <div className='w-full m-auto flex text-base font-semibold mb-2'>
                      <div className='w-1/2 text-right'>Review Answer:</div>
                      <div className='w-1/2 text-left pl-8'>{examResult?.result?.reviewAnswer}</div>
                    </div>

                    <div className='w-full m-auto flex text-base font-semibold mb-2'>
                      <div className='w-1/2 text-right'>Not Attempt:</div>
                      <div className='w-1/2 text-left pl-8'>{examResult?.result?.notAttempt}</div>
                    </div>
                    <div className='w-full m-auto flex text-base font-semibold mb-2'>
                      <div className='w-1/2 text-right'>Band score:</div>
                      <div className='w-1/2 text-left pl-8'>{examResult?.result?.bandScore}</div>
                    </div>
                  </Card>
                    )
                  })}
                  {/* <Card className='w-[90%] md:w-1/2 m-auto py-12'>
                    <h1 className='text-center text-base mb-2 '><span className='font-semibold'>Module Name: </span> <span></span></h1>
                    <div className='w-full m-auto flex text-base font-semibold mb-2'>
                      <div className='w-1/2 text-right'>Total Question:</div>
                      <div className='w-1/2 text-left pl-8'>{result.totalQuesion}</div>
                    </div>

                    <div className='w-full m-auto flex text-base font-semibold mb-2'>
                      <div className='w-1/2 text-right'>Right Answer:</div>
                      <div className='w-1/2 text-left pl-8'>{result.correctAnswer}</div>
                    </div>

                    <div className='w-full m-auto flex text-base font-semibold mb-2'>
                      <div className='w-1/2 text-right'>Wrong Answer:</div>
                      <div className='w-1/2 text-left pl-8'>{result.incorrectAnswer}</div>
                    </div>

                    <div className='w-full m-auto flex text-base font-semibold mb-2'>
                      <div className='w-1/2 text-right'>Review Answer:</div>
                      <div className='w-1/2 text-left pl-8'>{result.reviewAnswer}</div>
                    </div>

                    <div className='w-full m-auto flex text-base font-semibold mb-2'>
                      <div className='w-1/2 text-right'>Not Attempt:</div>
                      <div className='w-1/2 text-left pl-8'>{result.notAttempt}</div>
                    </div>
                  </Card> */}

                </div>
                <Button variant='contained' onClick={() => {
                  navigate('/test')
                }}>Home</Button>
              </div>
            </div>}
        </>
      }
    </div>
  )
}

export default ExamSuccess