import { useState } from "react";
import { Pagination, Stack } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import DarkButton from "../CustomButtons/DarkButton";

const ExamPagination = ({ data, paginationdata, event, newPage }) => {
  const [page, setPage] = useState(1);
  const handleChange = (event, newPage) => {
    // setPage(newPage);
  };
  return (
    <div>
      {" "}
      <div className={` flex  flex-wrap gap-4 justify-between px-3 md:px-10 mt-8`}>
        {/* <div className="bg-grey-300 text-sm pt-2">{data?.length > 0 ? <Typography>Page: {page}</Typography> : null} </div> */}
        <div className="bg-grey-300 text-sm pt-2">Part:1</div>
        <Stack spacing={2}>
          <Pagination count={paginationdata?.last_page} page={page} onChange={handleChange} shape="rounded" />
        </Stack>
        {data?.length !== 0 ? (
          <div className="bg-grey-400 text-sm">
            <div className="flex justify-around gap-4">
              <button className="bg-white text-sm text-slate-600 hover:bg-indigo-50 py-2 px-4 rounded">Previous</button>
              <button className="bg-white text-sm text-slate-600 hover:bg-indigo-50 py-2 px-4 rounded">Next</button>
              <DarkButton className="ml-2" endIcon={<CheckIcon />} />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ExamPagination;
