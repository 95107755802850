import { AnswerCardButton, NeonReadingQuiz, QueChangerButton } from "../../../..";
import ExamFormHeader from "../../../../common/ExamForm/ExamFormHeader";
import ExamHeadingCard from "../../../../common/ExamForm/ExamHeadingCard";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import QuestionButtonChanger from "../common/QuestionButtonChanger";
import { examStatusSubmitted, examinationSubmittedStatus, submitExamStatusReset } from "../../../../../store/examination";
import { STUDENTEXAMSUBMITAPI } from "../../../../../services/helpers/Exam";
import { useMutation } from 'react-query';
import Cookies from "js-cookie";

const ReadingWritingExam = ({ Questions, examType, submitable }) => {
  const dispatch = useDispatch()
  const {submitExamStatus,questions} = useSelector((state)=> state.examination)
  const [selectedExam, setSelecteExam] = useState(Questions);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  useEffect(() => {
    setSelecteExam(Questions);
    setCurrentQuestion(0);
  }, [Questions]);

  const paginationHandler = (value) => {
    if (value >= 0 && Questions.length > value) {
      setCurrentQuestion(value);
    }
  };

  const ExamSubmitHandler = (type) => {
    let payload = {
      studentId: Cookies.get('userId'),
      examId: Cookies.get('sExamId'),
      scheduleId: Cookies.get('sScheduleId') ,
      examTypeId: examType,
      qusAnsData: questions,
    }
    if(Cookies.get("sfirstExamId")){
      payload.submitExamId = Cookies.get("sfirstExamId")
    }
    mutate(payload)
  }

  const { mutate, isLoading } = useMutation(STUDENTEXAMSUBMITAPI, {
    mutationKey: "STUDENTEXAMSUBMIT",
    onSuccess: (res) => {
      if (res?.status === false) {
      } else if (res?.status === true) {
        const expires = Date.now() + 4 * 60 * 60 * 1000;
        Cookies.set("sfirstExamId", res.data.submitExamId, { expires });
        Cookies.set("sresultExamId", res.data.id, { expires });
        if(submitExamStatus?.lastModule){
          dispatch(examinationSubmittedStatus({key:'id', value: res?.data?.submitExamId}))
          dispatch(examStatusSubmitted())
        } else {
          dispatch(submitExamStatusReset())
        }
      }
    },
    onError: () => {},
    onSettled: () => {},
  });

  return (
    <>
      <div className="w-full mt-3">
        <ExamFormHeader />
        <div className="w-full ">
          <ExamHeadingCard title={1} />
          <div className="flex flex-col justify-center">
            <NeonReadingQuiz
              change={currentQuestion}
              selectedExam={selectedExam}
              setSelecteExam={setSelecteExam}
              time={Questions[currentQuestion]?.time}
            />
          </div>
        </div>
      </div>
      <div>
        <div>
          <div className="mt-4 ">
            <QuestionButtonChanger
              change={currentQuestion}
              setChange={paginationHandler}
              last={Questions.length}
              ExamSubmitHandler={ExamSubmitHandler}
              submitExam={submitExamStatus}
              isLoading={isLoading}
              submitable={submitable}
            />
          </div>
          <div>
            
          </div>
          {submitExamStatus?.moduleTimerEnd !== true && (
            <div className="bg-slate-200 flex justify-evenly lg:mt-0 pt-4 xl:mt-4 py-4 mt-4 w-full rounded-md">
              <div className="">
                <AnswerCardButton
                  paginationLength={Questions.length}
                  change={currentQuestion}
                  setChange={paginationHandler}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ReadingWritingExam;
