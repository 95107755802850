import { TextField, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import DarkButton from "../../common/CustomButtons/DarkButton";
import QuillEdit from "../../QuillEditor";
import React, { useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { CircularProgress, Backdrop } from "@mui/material";
import { SelectInputField } from "../../Forms";

const BooleanForm = ({ formik, btnTitle, isLoading, booleanQList, initialValue }) => {
  const [contentdata, setContentData] = useState();
  const [loading, setLoading] = useState(false);

  const [value, setValue] = useState("");
  console.log("initialValue->>>>>>", formik);

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const minutes_arr = [];
  for (var i = 0; i < 60; i++) {
    let obj = { label: i, option: i };
    minutes_arr.push(obj);
  }
  const rightOption = [
    {
      option: "1",
      label: "Option 1",
    },
    {
      option: "2",
      label: "Option 2",
    },
    {
      option: "3",
      label: "Option 3",
    },
    {
      option: "4",
      label: "Option 4",
    },
  ];
  const questions = booleanQList;
  return (
    <form onSubmit={formik.handleSubmit}>
      {booleanQList?.map((questionNumber, index) => (
        <div key={questionNumber} className="grid grid-cols-1  gap-8">
          <fieldset className="fieldsetinit mb-8">
            {btnTitle == "Update Question" ? null : <legend className="my-4">Question {++questionNumber}</legend>}
            <div className="grid grid-cols-1  gap-8">
              {loading ? (
                <div className="flex justify-center min-h-[5vh] items-center">
                  <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                    <CircularProgress color="inherit" />
                  </Backdrop>
                </div>
              ) : null}

              <QuillEdit setContentData={setContentData} formik={formik} index={`questions[${index}]question`} initialValue={initialValue?.questions[0]?.question} />
              <p className="text-red-600 text-sm mt-16 min-h-[32px]">
                {!!formik.errors[`questions`] &&
                  !!formik.errors[`questions`][index] &&
                  !!formik.errors[`questions`][index]["question"] &&
                  formik.errors[`questions`][index]["question"]}
              </p>

              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                {[1, 2, 3, 4].map((optionNumber) => (
                  <>
                    {/* <InputTextField
                  key={optionNumber}
                  type="text"
                  id={`questions[${index}]opt${optionNumber}`}
                  label={`Option ${optionNumber}`}
                  formik={formik}
                  placeholder={`Option ${optionNumber}`}
                /> */}
                    <TextField
                      variant="outlined"
                      fullWidth
                      type="text"
                      id={`questions[${index}]opt${optionNumber}`}
                      label={`Option ${optionNumber}`}
                      placeholder={`Option ${optionNumber}`}
                      value={formik.values[`questions[${index}]opt${optionNumber}`]}
                      onChange={formik.handleChange}
                      {...formik.getFieldProps(`questions[${index}]opt${optionNumber}`)}
                      error={!!formik.errors[`questions`] && !!formik.errors[`questions`][index] && !!formik.errors[`questions`][index]["opt" + optionNumber]}
                      helperText={
                        !!formik.errors[`questions`] &&
                        !!formik.errors[`questions`][index] &&
                        !!formik.errors[`questions`][index]["opt" + optionNumber] &&
                        formik.errors[`questions`][index]["opt" + optionNumber]
                      }
                    />
                  </>
                ))}
              </div>
              <div className="mt-2 grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div>
                  <SelectInputField id={`questions[${index}]answer`} label="Select Right Answer" defaultValue="Select Right Answer" options={rightOption} formik={formik} />
                  <div className="flex justify-start items-center">
                    <p className="text-[12px] text-red-500 ml-4 mt-1">
                      {!!formik.errors[`questions`] &&
                        !!formik.errors[`questions`][index] &&
                        !!formik.errors[`questions`][index]["answer"] &&
                        formik.errors[`questions`][index]["answer"]}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="grid grid-cols-1 gap-4 my-8">
                
                  <RadioGroup
                    name={`questions[${index}][correctAnswer]`}
                    id={`questions[${index}][correctAnswer]`}
                    value={formik.values[`questions[${index}][correctAnswer]`]}
                    onChange={handleChange}
                    {...formik.getFieldProps(`questions[${index}[correctAnswer]`)}
                   
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="True"
                    onChange={formik.handleChange}
  
                    />
                    <FormControlLabel
                      value="2"
                      control={<Radio />}
                      label="False"
                    onChange={formik.handleChange}
  
                    />
                  </RadioGroup>
                  <p className="text-red-600 text-sm mt-16 min-h-[32px]">
                {!!formik.errors[`questions`] &&
                  !!formik.errors[`questions`][index] &&
                  !!formik.errors[`questions`][index]["answer"] &&
                  formik.errors[`questions`][index]["answer"]}
              </p>
               
              </div> */}
          </fieldset>
        </div>
      ))}
      <div className="mt-10  flex justify-center">
        <DarkButton type="submit" btnTitle={btnTitle} loading={isLoading} />
      </div>
    </form>
  );
};

export default BooleanForm;
