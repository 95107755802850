import React from "react";
import { Avatar } from "@mui/material";
import LinkButton from "../common/CustomButtons/LinkButton";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { CircularProgress } from "@mui/material";
import { Link } from "react-router-dom";
import {useDispatch} from "react-redux"
import { examRefresh } from "../../store/exam";

const ExamHeader = () => {
  const dispatch = useDispatch()
  return (
    <div className="flex justify-end items-center border-b-[1px] p-4 md:ml-64">
      <div className="flex items-center justify-center gap-2">
        <Stack direction="row" spacing={2}>
          <Link to="/device-test" onClick={()=>{
            dispatch(examRefresh(true));
          }}>
            <Button variant="contained" type={"button"} disabled={false}>
              <span>
                {"Video/Audio Test"}
              </span>
            </Button>
          </Link>
        </Stack>
        {/* <LinkButton linkUrl="/device-test" btnTitle="Video/Audio Test" /> */}
        <LinkButton linkUrl="/feedback/add" btnTitle="Add FeedBack" />
        
        <Avatar alt="Developer" src="/static/images/avatar/1.jpg" sx={{ width: 36, height: 36 }} />
      </div>
    </div>
  );
};

export default ExamHeader;
