import { postApiCall, getApiCall, putApiCall, deleteApiCall } from "../../utils/axios";
import { BASE_URL, API } from "../../utils/defines";


export const InstructionsDetails = async (paramsData) => {
    const url = `${BASE_URL}${API.Instruction}`;
    try {
      const response = await getApiCall(url, paramsData);
      return response;
    } catch ({ response }) {
      throw new Error(response?.data?.error?.message);
    }
  };

  export const AddInstructions = async (payload, callback) => {
    const url = `${BASE_URL}${API.AddInstruction}`;
    try {
      const response = await postApiCall(url, payload);
      return response;
    } catch ({ response }) {
      throw new Error(response?.data?.error?.message);
    }
  };

  export const UpdateInstructions = async (payload, callback) => {
    const url = `${BASE_URL}${API.AddInstruction}`;
    try {
      const response = await postApiCall(url, payload);
      return response;
    } catch ({ response }) {
      throw new Error(response?.data?.error?.message);
    }
  };