import { useState } from "react";
import Cookies from "js-cookie";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import AddSharpIcon from "@mui/icons-material/AddSharp";
import BreadcrumbsC from "../../components/common/BreadcrumbsC";
import LoaderSpiner from "../../components/common/LoaderSpiner";
import { instituteListAPI } from "../../services/helpers/Institute";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import CircularProgress from "@mui/material";
import notfound from "../../assets/no_data_found.png"
import { CustomPagination, DarkButton, InstituteTable } from "../../components";
import { useEffect } from "react";
import { USERSEARCH } from "../../services/helpers/Users";

const Institute = () => {
  const [search, setSearch] = useState(null);
  console.log("testing search",search );
  const [stopList, setStopList] = useState(true)
  const [searchflag, setSearchFlag] = useState(false);
  console.log(search);
  const handleSearch = (e) => {
    e.preventDefault();
    setSearchFlag(true);
    setLoading(true)

  }
  const [isupdated, setIsupdated] = useState(false);
  const [instituteList, setInstituteList] = useState([]);
  // console.log(instituteList);
  const [change, setChange] = useState(false);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState(null);
  const [page, setPage] = useState(1);
  const [serial, setSerial] = useState(null);
  const navigate = useNavigate();

  const handler = () => {
    navigate("/institute/add");
  };

  const breadcrumbb = [
    {
      id: 1,
      title: "Dashboard",
      link: "/dashboard",
    },
    {
      id: 1,
      title: "Institute",
      link: "/institute",
    },
  ];


  // useEffect(() => {
  //   console.log("search changed:", search);
  //   setSearchFlag(true)
  // }, [search])

  const getStartingSerialNumber = () => {
    return setSerial((page - 1) * pagination?.numberPerPage + 1);
  };


  useEffect(() => {
    if (search?.length>0) {
      setSearchFlag(true);
      // setLoading(true);
    }

  }, [search])

 

  useEffect(() => {
    getStartingSerialNumber();
  }, [instituteList]);



  useEffect(() => {
    
    if(search?.length>0){
      setSearchFlag(true)
    }
    else{
      setStopList(true);
    }
  }, [isupdated,page]);

  useEffect(() => {
    if (search === "") {
      setStopList(true);
      // setLoading(true);
    }
  }, [search]);

  

  // useEffect(() => {
  //   if (instituteList.length === 0) {
  //     setStopList(true);
  //     // setLoading(true)
  //   }
  // }, [instituteList])

  useQuery(
    ["INSTITUTELISTAPI", page, change, isupdated],
    () => {
      const queryParams = {
        pageNum: page,
        role: "institute",
      };
      const paramsData = {
        queryParams,
      };
      return instituteListAPI(paramsData);
    },
    {
      retry: false,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: 0,
      mutationKey: "INSTITUTELISTAPI",
      enabled: stopList,
      onSuccess: (res) => {
        setLoading(false);
        if (res?.status === true) {
          setChange(false);
          setIsupdated(false);
          console.log(res);
          setInstituteList(res?.data?.userList);
          setPagination(res?.data?.pagination);
        } else {
        }
      },
    }
  );

  useQuery(
    ["INSTITUTEAPI", page, change, isupdated,search],
    () => {
      const queryParams = {
        pageNum: page,
        role: "institute",
        search: search
      };
      const paramsData = {
        queryParams,
      };
      return USERSEARCH(paramsData);
    },
    {
      retry: false,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: 0,
      mutationKey: "INSTITUTEAPI",
      enabled: searchflag,
      onSuccess: (res) => {
        setLoading(false);
        if (res?.status === true) {
          setSearchFlag(false);
          setStopList(false)
          setChange(false);
          setIsupdated(false)
          console.log(res);
          setInstituteList(res?.data?.userList);
          setPagination(res?.data.pagination);
        } else {
        }
      },
    }
  );

  return (
    <div className="relative px-8 py-4 bg-[#F3F3F3] md:ml-64">
      <div className="w-full">
        <div className="flex justify-between items-center">
          <BreadcrumbsC data={breadcrumbb} />
        </div>
        <div className="flex flex-col-reverse sm:grid sm:grid-cols-2 justify-center sm:justify-between items-center mt-8 gap-5">
          <div className="flex items-center w-full gap-4">
            <form onSubmit={handleSearch} >
              <TextField
                id="outlined-start-adornment"
                className="w-full sm:w-auto"
                size="small"
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                sx={{ backgroundColor: "white" }}
                placeholder="Search or Type Here"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton aria-label="toggle password visibility" edge="end">
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>

                  ),
                }}
              />
            </form>
          </div>
          <div className="flex w-full sm:justify-end">
            <DarkButton btnTitle="Add Institute" endIcon={<AddSharpIcon />} handler={handler} />
          </div>
        </div>
      </div>

      {/* lower section */}
      <div className={`bg-white mt-8 rounded-lg p-5 mb-10 ${instituteList.length > 2 && instituteList.length <= 4 ? "mb-20" : "" || instituteList.length <= 2 ? "mb-40" : ""}`}>
      {loading ? (
          <div className="flex justify-center min-h-[50vh] items-center">
            <LoaderSpiner className="mt-12" />
          </div>
        ) : (
        <div className="">
            {instituteList.length!==0?(<InstituteTable instituteList={instituteList} serial={serial} setChange={setChange} setIsupdated={setIsupdated} isupdated={isupdated} />):(<div className=" "><span className="text-2xl text-center flex justify-center">No Institute Found </span><div className="flex justify-center"><img style={{width:"300px"}} src={notfound}/></div>
            </div>)}
          </div>
        )}
      </div>
      {instituteList?.length !==0?<CustomPagination page={page} setPage={setPage} paginationData={pagination} />:null}
    </div>
  );
};

export default Institute;

//{instituteList.length===0?(<InstituteTable instituteList={instituteList} serial={serial} setChange={setChange} setIsupdated={setIsupdated} isupdated={isupdated} />