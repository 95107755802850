import BreadcrumbsC from "../../components/common/BreadcrumbsC";
import { DashboadPagesLayout, SingleResultCard } from "../../components";

const breadcrumbb = [
  {
    id: 1,
    title: "Dashboard",
    link: "/dashboard",
  },
  {
    id: 2,
    title: "Result",
    link: "/results",
  },
  {
    id: 3,
    title: "Results Details",
    link: "/result/details",
  },
  {
    id: 4,
    title: "Applicant Details",
    link: "applicant/details",
  },
];

const ResultsCard = () => {
  return (
    <DashboadPagesLayout>
      <div className="w-full px-8 py-4">
        <div className="flex justify-start items-center">
          <BreadcrumbsC data={breadcrumbb} />
        </div>
        <div className={`bg-white mt-8 rounded-lg p-5 mb-10 min-h-[60vh] `}>
          <div className="flex w-full">
            <SingleResultCard />
          </div>
        </div>
      </div>
    </DashboadPagesLayout>
  );
};

export default ResultsCard;
