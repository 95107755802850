import ProfileImage from "../../UploadIcon";
import { useEffect,useState } from "react";
import Cookies from "js-cookie";
import { Country,State,City } from 'country-state-city';
import DarkButton from "../../common/CustomButtons/DarkButton";
import { InputTextField, SelectInputField } from "../../Forms";

const gender = [
  {
    label: "Male",
    option: 1,
  },
  {
    label: "Female",
    option: 2,
  },
  {
    label: "Others",
    option: 3,
  },
];
const universityName = [
  {
    label: "Astralia",
    option: "astralia",
  },
  {
    label: "Canada",
    option: "canada",
  },
  {
    label: "United Kingdom",
    option: "unitedKingdom",
  },
  {
    label: "United States",
    option: "unitedStates",
  },
];
const degree = [
  {
    label: "Master",
    option: "master",
  },
  {
    label: "Ph. D",
    option: "phD",
  },
  {
    label: "Bachelor",
    option: "bachelor",
  },
];

const applyFor = [
  {
    label: "IELTS For Study",
    option: "study",
  },
  {
    label: "IELTS For Migration",
    option: "migration",
  },
  {
    label: "IELTS for work",
    option: "work",
  },
];

const StudentForm = ({formik, setImgFile, previewImage, setPreviewImage, pathImage, isLoading, institue,flag,setFlag,loading, initialValueFormik={
  country: "",
  state: "",
  city: "",
}, setInitialValueFormik=()=>{}}) => {




  const [country, setCountry] = useState([]);
  const [states, setStates] = useState([]);
  console.log("showing states",states);
  const [cities, setCities] = useState([]);
  // console.log("test",countries);

  const [selectedCountry, setSelectedCountry] = useState("IN");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  console.log(selectedCountry);

  console.log("selectedCountry",selectedCountry, formik);
  console.log("state",selectedState);
  console.log("city",cities);
  
  


  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const countries = Country.getAllCountries();
        let allCountries = [];
        allCountries = countries?.map(({ isoCode, name }) => ({
          isoCode,
          name,
        }));
        const [{ isoCode: firstCountry = "" } = {}] = allCountries;
        setCountry(allCountries)
        if (!selectedCountry) {
          // setSelectedCountry("IN");
        }
        if(initialValueFormik?.country?.length>0){
          setSelectedCountry(initialValueFormik?.country)
          setInitialValueFormik((prev)=>{
            return {
              ...prev,
              country:''
            }
          })
        }
      } catch (error) {
        setCountry([]);
        setStates([]);
        setSelectedCity("");
      }
    };

    fetchCountries();
  }, []); 


  let arr = [];
  for (var i = 0; i < country?.length; i++) {
    let check = { label: country[i].name, option: country[i].isoCode };
    arr.push(check);
  }

  useEffect(() => {
    const getStates = async () => {
      try {
        console.log("wjdjsdjasjdasjdajsjjasd")
        const result = await State.getStatesOfCountry(selectedCountry);
        let allStates = [];
        allStates = result?.map(({ isoCode, name }) => ({
          isoCode,
          name,
        }));
        console.log("states9847",allStates)
        const [{ isoCode: firstState = "" } = {}] = allStates;
        setStates(allStates);
        
        // if (!selectedState) {
        //   setSelectedState(firstState);
        // }
        if(initialValueFormik?.state?.length>0){
          setSelectedState(initialValueFormik?.state)
          setInitialValueFormik((prev)=>{
            return {
              ...prev,
              state:''
            }
          })
        }
      } catch (error) {
        setStates([]);
        setCities([]);
        // setCities([]);
        setSelectedCity("");
      }
    };

    getStates();
  }, [selectedCountry]);

  let state_arr = [];
  for (var i = 0; i < states?.length; i++) {
    let check = { label: states[i].name, option: states[i].isoCode};
    state_arr.push(check);
  }
  console.log(selectedState)

  useEffect(() => {
    const getCities = async () => {
      console.log("Component Mounted ", cities);
      try {
        const result = await City.getCitiesOfState(selectedCountry,selectedState);
        console.log("testing_state",result);
        let allCities = [];
        allCities = result?.map(({ name }) => ({
          name,
        }));
        console.log("Cities",allCities);
        const [{ name: firstCity = "" } = {}] = allCities;
        // if (!selectedCity) {
        //   setSelectedCity(firstCity);
        // }
        setCities(allCities);
        // setSelectedCity(firstCity);
      } catch (error) {
        setCities([]);
      }
    };
    getCities();
    
  }, [selectedState]);

 
  

  let city_arr = [];
  for (var i = 0; i < cities?.length; i++) {
    let check = { label: cities[i].name, option: cities[i].name };
    city_arr.push(check);
  }



  console.log("new update value formik==>", formik);
  return (
    <div className="w-full">
      <div className="flex mb-8 mt-4 w-full">
        <div className="flex grow flex-col justify-center">
          <ProfileImage setImgFile={setImgFile} previewImage={previewImage} setPreviewImage={setPreviewImage} pathImage={pathImage} setFlag={setFlag} />
          {flag == false ? <div className="text-red-700 text-center mt-4">Image Upload is required</div> : null}
        </div>
      </div>

      <form onSubmit={formik.handleSubmit}>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3  gap-8">
          <InputTextField type="text" id="firstName" label="First Name" formik={formik} placeholder="First Name" />
          <InputTextField type="text" id="lastName" label="Last Name" formik={formik} placeholder="Last Name" />
          <InputTextField type="text" id="fatherName" label="Father's Name" formik={formik} placeholder="Father's Name" />
          <SelectInputField id="gender" label="Gender" defaultValue="Select" options={gender} formik={formik} />

          <InputTextField type="number" id="phone" label="Phone Number" formik={formik} placeholder="Phone Number" />
          <InputTextField type="email" id="email" label="Email" formik={formik} placeholder="Email" />
          {/* <InputTextField type="text" id="course" label="Course" formik={formik} placeholder="Enter Course" />

          <InputTextField id="degree" label="Degree" defaultValue="select Degree" options={degree} formik={formik} />
          <InputTextField id="university" label="University" defaultValue="select Uinversity" options={universityName} formik={formik} /> */}

          <SelectInputField id="applyFor" label="Apply For" defaultValue="Select" options={applyFor} formik={formik} />
          <SelectInputField id="country" label="Select Country" defaultValue="Select" options={arr} formik={formik} exam={setSelectedCountry} flag={true} />
          <SelectInputField id="state" label="Select States" defaultValue="Select" options={state_arr} formik={formik} exam={setSelectedState} flag={true}   />
          <SelectInputField id="city" label="Select Cities" defaultValue="Select" options={city_arr} formik={formik} exam={setSelectedCity} flag={true}   />

          <InputTextField type="text" id="address" label="Address" formik={formik} placeholder="Address" />
          {/* <InputTextField type="text" id="country" label="Country" formik={formik} placeholder="Country" defaultValue="India" /> */}
          {/* <InputTextField type="text" id="state" label="Your State" formik={formik} placeholder="Your State" />
          <InputTextField type="text" id="city" label="Your City" formik={formik} placeholder="Your City" /> */}
          
          <InputTextField type="number" id="pincode" label="Pincode" formik={formik} placeholder="PinCode" />
         
          {Cookies.get("id")=="super_admin"?<SelectInputField id="instituteId" label="Select Institute " defaultValue="Select" options={institue} formik={formik} />:null}
        </div>
        <div className="flex justify-center mt-10">
          <DarkButton type="submit" btnTitle="Submit" loading={loading} />
        </div>
      </form>
    </div>
  );
};

export default StudentForm;
