import { Pagination, Stack } from "@mui/material";

const CustomPagination = ({ paginationData, page, setPage,flag }) => {
  console.log(paginationData)
  const handleChange = (e, value) => {
    
      setPage(value);
    
   
    console.log(page);
  };
  return (
    <div>
    <div className={` px-3 md:px-10 mt-8 `}>
      <div className="bg-grey-300 text-sm pt-2 flex justify-center">Page: {page}</div>
      {paginationData?.length !== 0 ? (
        <div className="bg-grey-400 text-sm">
          <div className="flex justify-between ">
            <button
              onClick={() => {
                if (page === 1) {
                  return;
                }
                setPage(page - 1);
              }}
              className="bg-white text-sm text-slate-600 hover:bg-indigo-50 py-2 px-4 rounded"
            >
              Previous
            </button>
            <button
              onClick={() => {
                if (page === paginationData.last_page) {
                  return;
                }
                setPage(page + 1);
              }}
              className="bg-white text-sm text-slate-600 hover:bg-indigo-50 py-2 px-4 rounded ml-2"
            >
              Next
            </button>
          </div>
        </div>
      ) : null}
      <div className="flex justify-center"><Stack spacing={2}>
        <Pagination count={paginationData?.last_page} page={page} onChange={handleChange} />
      </Stack></div>
      
    
    </div>
  </div>
  );
};

export default CustomPagination;
