import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { GrClose } from "react-icons/gr";
import Typography from "@mui/material/Typography";
import { CircularProgress } from "@mui/material";

const style = {
  bgcolor: "background.paper",
};

const DeleteModal = ({ title, deleteText, deleteHandle, handleClose, open, loading }) => {
  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={style} className="w-[400px] top-[50%] left-[50%] absolute translate-x-[-50%] translate-y-[-50%] shadow-lg rounded outline-none">
        <div className="pt-4 px-5 flex justify-between">
          <h2 className="text-bold font-bold text-lg">{deleteText}</h2>
          <GrClose className="cursor-pointer" onClick={handleClose} />
        </div>
        <div className="w-100 bg-slate-200 h-[.25px] mt-2 mb-4 shadow-lg" />
        <Typography id="modal-modal-title" variant="h6" component="h2" className="w-full text-center pt-1 rounded px-5">
          {title}
        </Typography>
        <div className="w-100 bg-slate-200 h-[.25px] mt-6 mb-2 shadow-lg" />
        <div className="w-full p-5 pb-4 flex justify-between">
          <button onClick={handleClose} className="w-2/5 py-2 rounded border">
            Cancel
          </button>
          <button
            onClick={() => {
              deleteHandle();
            }}
            className="w-2/5 rounded bg-red-500 hover:bg-red-400 text-white py-2"
            disabled={loading}
          >
            {loading ? <CircularProgress size="1rem" className="top-[30%] left-[40%] " /> : "Delete"}
          </button>
        </div>
      </Box>
    </Modal>
  );
};

export default DeleteModal;
