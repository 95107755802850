import { useParams } from "react-router-dom";
import { DashboadPagesLayout, UpdateInstitute } from "../../../components";
import { useState } from "react";
import { useQuery } from "react-query";
import { instituteDetailsAPI } from "../../../services/helpers/Institute";

const UpdateInstitutes = () => {
  const [instituteDetail, setInstituteDetail] = useState(null);
  const [initialValue, setInitialValue] = useState(null);
  const [instituteId, setInstituteId] = useState(null);

  const { id } = useParams();

  useQuery(
    ["INSTITUTERDETAILS"],
    () => {
      const queryParams = {
        instituteId: id,
      };
      const paramsData = {
        queryParams,
      };
      return instituteDetailsAPI(paramsData);
    },
    {
      retry: false,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: 0,
      mutationKey: "INSTITUTERDETAILS",
      enabled: true,
      onSuccess: (res) => {
        if (res?.status === true) {
          setInstituteDetail(res?.data);
          setInstituteId(res?.data?.instituteId);
          setInitialValue({
            city: res.data.city,
            email: res.data.email,
            phone: res.data.phone,
            state: res.data.state,
            country: res.data.country,
            address: res.data.address,
            pincode: res.data.pincode,
            instituteName: res.data.instituteName,
          });
        } else {
        }
      },
    }
  );

  return <DashboadPagesLayout>{instituteDetail && <UpdateInstitute initialValue={initialValue} instituteId={instituteId} />}</DashboadPagesLayout>;
};

export default UpdateInstitutes;
