import { useDispatch } from "react-redux";
import { useState, Suspense, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import ReactHtmlParser from 'react-html-parser';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { AddCKEditor } from "../../../components";
import { useMutation, useQuery } from "react-query";
import { setToastMessage } from "../../../store/toast";
import { AddPrivacyPolicyPI, GetPrivacyPolicyAPI } from "../../../services/helpers/Dashboard";
import { AddInstructions, InstructionsDetails } from "../../../services/helpers/Instruction";
import { SelectInputField } from "../../../components/Forms";
import BreadcrumbsC from "../../../components/common/BreadcrumbsC";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import { HtmlOutlined } from "@mui/icons-material";
import { AddPolicy, GetPolicy } from "../../../services/helpers/policy";
import Policy from "@mui/icons-material/Policy";

const PrivacyPolicy = () => {
    const [hasdata, setHasData] = useState(false);
    const [data, setData] = useState(false);
    const [loading,setLoading] = useState(true);
    const [contentData, setContentData] = useState(null);
    console.log(contentData);
    const dispatch = useDispatch();
    const breadcrumbb = [
        {
            id: 1,
            title: "Dashboard",
            link: "/dashboard",
        },
        {
            id: 2,
            title: "Privacy Policy",
            link: "/exam instructions",
        },
    ];

    useQuery(
        ["GetPolicy"],
        () => {
            const queryParams = {
                type:"privacy",
                addById: Cookies.get("userId")
            };
            const paramsData = {
                queryParams,
            };
            return GetPolicy(paramsData);
        },
        {
            retry: false,
            mutationKey: "policydetails",
            onSuccess: (res) => {
                if (res?.status === true) {
                    setData(res?.data);
                    setLoading(false);
                    setHasData(true);
                    if (res?.data?.content) {
                        setContentData(res.data.content);
                    } 
                } else {
                    setData(res?.data);
                    setLoading(false);
                    setHasData(true);
                    setContentData("");
                }
            },
        }
    );

    const { mutate, isLoading } = useMutation(AddPolicy, {
        mutationKey: "addpolicy",
        onSuccess: (data) => {
            if (data.status === true) {
                dispatch(
                    setToastMessage({
                        isActive: true,
                        message: data?.message,
                        type: "success",
                    })
                );
            }
        },
    });

    const { mutate: updateIns } = useMutation(AddPolicy, {
        mutationKey: "addpolicy",
        onSuccess: (data) => {
            if (data.status === true) {
                dispatch(
                    setToastMessage({
                        isActive: true,
                        message: data?.message,
                        type: "success",
                    })
                );
            }
        },
    });


    const submitHandle = () => {
        if (hasdata) {
            updateIns({ id: data?.id, content: contentData,addById:Cookies.get("userId"),type:"privacy" });

        }
        else {
            mutate({ content: contentData, addById: Cookies.get("userId"), type:"privacy" });
        }

    };

    return (
        <div className="relative px-2 sm:px-8 py-6 bg-[#F3F3F3] md:ml-64">
            {/* <div className="flex justify-between items-center">
                <BreadcrumbsC data={breadcrumbb} />
            </div> */}

            {/* <div class="[&>*:nth-child(odd)]:bg-gray-300 [&>*:nth-child(even)]:bg-blue-500  text-center items-center flex flex-col justify-center items ">
                <div className="h-16 text-2xl   w-full flex justify-around">
                    <div className="flex flex-col justify-center  ">Reading Exam</div>
                    <div className="flex flex-col justify-center"><ArrowForwardIcon style={{ cursor: "pointer" }} /></div>
                </div>
                <div className="h-16 text-2xl   w-full flex justify-around">
                    <div className="flex flex-col justify-center text-white  ">Writing Exam</div>
                    <div className="flex flex-col justify-center"><ArrowForwardIcon style={{ cursor: "pointer" }} /></div>
                </div>

                <div className="h-16 text-2xl   w-full flex justify-around">
                    <div className="flex flex-col justify-center  ">Listening Exam</div>
                    <div className="flex flex-col justify-center"><ArrowForwardIcon style={{ cursor: "pointer" }} /></div>
                </div>

                <div className="h-16 text-2xl   w-full flex justify-around">
                    <div className="flex flex-col justify-center text-white  ">Speaking Exam</div>
                    <div className="flex flex-col justify-center"><ArrowForwardIcon style={{ cursor: "pointer" }} /></div>
                </div>


            </div> */}

           {loading ? (
            <div className="flex justify-center min-h-[50vh] items-center">
            <CircularProgress className="mt-12" />
          </div>
           ): <Suspense fallback={<div>Loading...</div>}>
                <AddCKEditor contentData={contentData} setContentData={setContentData} breadcrumbb={breadcrumbb} heading="Privacy Policy " submitHandle={submitHandle} />
            </Suspense>}
        </div>
    );
};

export default PrivacyPolicy;