import { useDispatch } from "react-redux";
import { AddFeedbackAPI, FeedbackImageUploadAPI } from "../../services/helpers/Dashboard";
import { setToastMessage } from "../../store/toast";
import { useMutation } from "react-query";
import { Button, CircularProgress } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import pdfImage from "../../assets/img/pdfimage.png";
import docImage from "../../assets/img/docimage.png";
import Avatarlogo from "../../assets/img/avatar.png";
import { useFormik } from "formik";
import { initialValuesAddFeedback as initialValues, validationSchemaAddFeedback as validationSchema } from "../../services/validate/AddFeedback";
import { useNavigate, useParams } from "react-router-dom";

const BasicForm = () => {
  const { id } = useParams();
  // console.log("id --> ", id);
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const [document, setDocument] = useState({
    img: "",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const allowedFileExtensions = [".jpg", ".jpeg", ".pdf", ".png", ".doc", ".docx"];

  const handleInputChange = (e) => {
    setDocument({
      img: e.target.files[0],
      imgPreview: URL.createObjectURL(e.target.files[0]),
    });
  };

  const submitHandler = (values) => {
    let checked = true;

    if (checked) {
      if (document.img) {
        const file = document.img;
        const fileExtension = file.name.substring(file.name.lastIndexOf(".")).toLowerCase();

        if (allowedFileExtensions.includes(fileExtension)) {
          const form = new FormData();
          setIsLoadingBtn(true);
          form.append("file", document.img);
          imageMutate(form);
        } else {
          dispatch(
            setToastMessage({
              isActive: true,
              message: "Invalid file format! Allowed formats: JPG, JPEG, PDF, Word",
            })
          );
        }
      } else {
        dispatch(
          setToastMessage({
            isActive: true,
            message: "Please select Feedback Doc",
          })
        );
      }
    } else {
      dispatch(
        setToastMessage({
          isActive: true,
          message: "Please select at least one detail",
        })
      );
    }
  };

  const { mutate: imageMutate, isLoading: imageIsLoadin } = useMutation(FeedbackImageUploadAPI, {
    mutationKey: "imageUpload",
    onSuccess: (res) => {
      if (res.status === true) {
        let { name, ...rest } = formik.values;
        const newValues = {
          ...rest,
          centerId: id,
          full_name: formik.values.name,
          feedback_images: [res.data.fileName],
          phone: `${formik.values.phone}`,
        };
        mutateFeedback(newValues);
      }
    },
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      submitHandler(values);
      // console.log("value", values);
    },
  });

  const { mutate: mutateFeedback, isLoading: feedbackloading } = useMutation(AddFeedbackAPI, {
    mutationKey: "addfeedback",
    onSuccess: (data) => {
      if (data.status === true) {
        dispatch(
          setToastMessage({
            isActive: true,
            message: data?.message,
            type: "success",
          })
        );
        formik.resetForm();
        setIsLoadingBtn(false);
        setDocument({
          img: "",
        });
      }
    },
  });

  // console.log("isloading", document);

  return (
    <>
      <div className="relative bg-[#F3F3F3] ">
        <div className="w-full pb-40 pt-24 ">
          <div className="px-16">
            <div className="bg-white rounded shadow-lg p-4 px-4 md:px-16 md:pt-8 md:pb-16 ">
              <h1 className="text-center font-bold text-2xl mb-4">{t("feedbackForm.feedback")}</h1>
              <div className="grid gap-4 gap-y-2 text-sm grid-cols-1">
                <form onSubmit={formik.handleSubmit}>
                  <div className="lg:col-span-2">
                    <div className="grid gap-4 gap-y-2  text-sm grid-cols-1 md:grid-cols-5">
                      <div className="md:col-span-5 mb-2">
                        <label htmlFor="full_name" className="mb-3">
                          {t("feedbackForm.name")}
                        </label>
                        <input
                          id="name"
                          type="text"
                          name="name"
                          value={formik.values.name}
                          onChange={formik.handleChange}
                          className="h-10 border mt-2  rounded px-4 w-full bg-gray-50 focus:outline-none focus:border-Primary"
                        />
                      </div>

                      <div className="md:col-span-5 mb-2">
                        <label htmlFor="email">{t("feedbackForm.email")}</label>
                        <input
                          id="email"
                          type="text"
                          name="email"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          className="h-10 border mt-2 rounded px-4 w-full bg-gray-50 focus:outline-none focus:border-Primary"
                          placeholder="email@domain.com"
                        />
                      </div>

                      <div className="md:col-span-5 mb-2">
                        <label htmlFor="address">{t("feedbackForm.phoneNumber")}</label>
                        <input
                          id="phone"
                          name="phone"
                          type="number"
                          value={formik.values.phone}
                          onChange={formik.handleChange}
                          className="h-10 border mt-2 rounded px-4 w-full bg-gray-50 focus:outline-none focus:border-Primary"
                          placeholder=""
                        />
                      </div>

                      <div className="md:col-span-5 mb-2">
                        <label htmlFor="address">{t("feedbackForm.feedback")}</label>
                        <textarea
                          type="number"
                          id="feedback"
                          name="feedback"
                          value={formik.values.feedback}
                          onChange={formik.handleChange}
                          className="border mt-2 rounded px-4 w-full bg-gray-50 pt-2 focus:outline-none focus:border-Primary"
                          rows={3}
                        />
                      </div>

                      <div className="md:col-span-5">
                        <div className="flex justify-center flex-col">
                          <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" htmlFor="file_input">
                            {t("feedbackForm.uploadFile")}
                          </label>
                          <div className="w-full m-auto">
                            <div className="flex justify-start gap-10 items-start h-full">
                              {document?.img?.name?.split(".").pop().toLowerCase() === "pdf" ? (
                                <>
                                  <img src={pdfImage} alt="file" className={`min-h-[50px] max-h-[50px] border`} />
                                  <p className="">{document?.img?.name}</p>
                                </>
                              ) : document?.img?.name?.split(".").pop().toLowerCase() === "docx" ? (
                                <>
                                  <img src={docImage} alt="file" className={`min-h-[50px] max-h-[50px] border`} />
                                  <p className="">{document?.img?.name}</p>
                                </>
                              ) : (
                                <div style={{ display: "block" }}>
                                  <img src={`${document.imgPreview ? document.imgPreview : Avatarlogo}`} alt="file" className={`min-h-[50px] max-h-[50px] border`} />
                                  <p className="">{document?.img?.name}</p>
                                </div>
                              )}
                              <div className="text-center">
                                <Button variant="contained" component="label" className={`!bg-[#4E9548] !mt-2`}>
                                  {t("feedbackForm.uploadFile")}
                                  <input type="file" onChange={handleInputChange} accept=".jpg, .jpeg, .png, .doc,.docx, .pdf" />
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="md:col-span-5 text-center mt-6">
                        <div>
                          <button type="submit" className={`btn-primary relative`} disabled={isLoadingBtn}>
                            {isLoadingBtn && <CircularProgress size="1rem" className="absolute top-[30%] left-[40%] !text-white" />}{" "}
                            <span className={`${isLoadingBtn ? "invisible" : ""}`}>{t("feedbackForm.submit")}</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BasicForm;
