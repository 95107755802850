import { useState } from "react";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import WrittenExamDetails from "./WrittenExamDetails";
import McqQuestionDetails from "./McqQuestionDetails";
import FillinTheBlankDetails from "./FillinTheBlankDetails";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import BooleanExamDetails from "./BooleanDetails";

const ExamQuestionDetails = ({ qustionsList, examDetails, sectionName, examTypeName }) => {
  console.log("quesiton Details ", qustionsList, examDetails, sectionName, examTypeName)
  let questionCount = {
    mcq:0,
    fill:0,
    written:0,
    boolean:0
  }
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    qustionsList?.length > 0 && (
      <div className="border mb-8">
      <>
          {/* <div className="bg-purple-50 px-4 py-2 rounded shadow mb-3">
            <p className="text-center font-medium text-lg block">{sectionName} {`(${examTypeName})`}</p>
          </div> */}
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            {/* <TabList onChange={handleChange} aria-label="lab API tabs example" indicatorColor="primary" centered> */}
              <TabList onChange={handleChange} aria-label="lab API tabs example" indicatorColor="primary" variant="fullWidth">
                <Tab label="MCQ" value="1" />
                <Tab label="Fill In The Blank" value="2" />
                <Tab label="written" value="3" />
                <Tab label="Boolean" value="4" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <div className="mb-2">
                <p className="text-lg md:text-xl font-medium italic">
                  Note: There are variations to the instructions per module exam which are not included in these general instructions
                </p>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {qustionsList.map(
                  (question, i) =>{
                    if(question.questionTypeId === 1){
                      questionCount.mcq += 1
                    }
                    return (
                    question.questionTypeId === 1 && (
                      <div key={i}>
                        <McqQuestionDetails questions={question} examDetails={examDetails} index={questionCount.mcq} />
                      </div>
                    )
                )}
                )}
              </div>
            </TabPanel>
            <TabPanel value="2">
              <div className="mb-2">
                <p className="text-lg md:text-xl font-medium italic">
                  Note: There are variations to the instructions per module exam which are not included in these general instructions
                </p>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {qustionsList.map(
                  (question, i) =>{
                    if(question.questionTypeId === 2){
                      questionCount.fill += 1
                    }
                    return ( question.questionTypeId === 2 && (
                      <div key={i}>
                        <FillinTheBlankDetails questions={question} examDetails={examDetails} index={questionCount.fill} />
                      </div>
                    ))}
                )}
              </div>
            </TabPanel>
            <TabPanel value="3">
              <div className="mb-2">
                <p className="text-lg md:text-xl font-medium italic">
                  Note: There are variations to the instructions per module exam which are not included in these general instructions
                </p>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {qustionsList.map(
                  (question, i) =>{
                    if(question.questionTypeId === 3){
                      questionCount.written += 1
                    }
                    return (question.questionTypeId === 3 && (
                      <div key={i}>
                        <WrittenExamDetails questions={question} examDetails={examDetails} index={questionCount.written} />
                      </div>
                    ))}
                )}
              </div>
            </TabPanel>
            <TabPanel value="4">
              <div className="mb-2">
                <p className="text-lg md:text-xl font-medium italic">
                  Note: There are variations to the instructions per module exam which are not included in these general instructions
                </p>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {qustionsList.map(
                  (question, i) =>{
                    console.log("testing it is",question?.questionTypeId)
                    if(question.questionTypeId === 4){
                      questionCount.boolean += 1
                    }
                    return (
                    question.questionTypeId === 4 && (
                      <div key={i}>
                        <McqQuestionDetails questions={question} examDetails={examDetails} index={questionCount.boolean} />
                        {/* <McqQuestionDetails questions={question} examDetails={examDetails} index={questionCount.mcq} /> */}
                      </div>
                    )
                )}
                )}
              </div>
            </TabPanel>
          </TabContext>
        </>
        </div>
      )
  );
};

export default ExamQuestionDetails;
