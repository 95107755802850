import { getApiCall, postApiCall } from "../../utils/axios";
import { BASE_URL, API } from "../../utils/defines";

export const feedbackListAPI = async (paramsData) => {
  const url = `${BASE_URL}${API.FEEDBACK_LIST}`;
  try {
    const response = await getApiCall(url, paramsData);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};

export const addStudentFeedbackAPI = async (payload) => {
  const url = `${BASE_URL}${API.FEEDBACK_ADD}`;
  try {
    const response = await postApiCall(url, payload);
    return response;
  } catch (err) {
    throw new Error(err?.data?.error?.message);
  }
};
