import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { DarkButton, LoaderSpiner } from "../../../../../components";
import { useDispatch } from "react-redux";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { AddAssignInstituteAPI, instituteListAPI } from "../../../../../services/helpers/Institute";
import InstituteCardCheckbox from "../InstituteCardCheckbox";
import { setToastMessage } from "../../../../../store/toast";

const InstituteListCheckbox = ({ handleClose, examId }) => {
    const queryClient = useQueryClient();
  const [checkedItems, setCheckedItems] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const dispatch = useDispatch()

  const handleCheckboxChange = (id) => {
    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [id]: !prevCheckedItems[id],
    }));
  };

  const { data, isLoading, hasNextPage, isFetching, refetch } = useQuery(
    ["data"],
    () => {
      const queryParams = {
        
      };
      const paramsData = {
        queryParams,
      };
      return instituteListAPI(paramsData);
    },
    {
      keepPreviousData: false,
      staleTime: 50, // milliseconds
    }
  );

  const { mutate, isLoading: addstudentloading } = useMutation(AddAssignInstituteAPI, {
    mutationKey: "AddAssignInstituteAPI",
    onSuccess: (res) => {
      if (res?.status === false) {
      } else if (res?.status === true) {
        dispatch(
            setToastMessage({
              isActive: true,
              message: res?.message,
              type: "success",
            })
          );
          queryClient.invalidateQueries("INSTITUTELISTAPI");
        handleClose();
      }
    },
    onError: () => {},
    onSettled: () => {},
  });

  const submitHanlder = () => {
    const instituteFilterList = [];

    for (const key in checkedItems) {
      if (checkedItems[key] === true) {
        instituteFilterList.push({ instituteId: key });
      }
    }
    if (instituteFilterList.length > 0) {
      let payload = {
        examId: examId,
        instituteList: instituteFilterList,
      };
      mutate(payload);
    } else {
      alert("Please select a student");
    }
  };

  useEffect(() => {
    // refetch();
  }, []);

  return (
    <div className="w-full">
      <h1 className="text-xl text-center mb-4 font-semibold">Assign Institute</h1>
      {isLoading || isFetching ? (
        <div className="w-full flex justify-center items-center h-[50vh]">
          <LoaderSpiner />
        </div>
      ) : (
        <>
          {data?.data?.userList?.length > 0 ? (
            <>
              <TextField
                label="Search by Institute Name"
                variant="outlined"
                className="w-full "
                sx={{
                  "& label": {
                    lineHeight: "14px", // Add your custom height here
                  },
                  "& input": {
                    padding: "10px",
                  },
                }}
                onChange={(e) => setSearchTerm(e.target.value)}
                value={searchTerm}
              />
              <div className="w-full flex border-b min-h-[40px] items-center font-bold">
                <p className="flex-1">
                  <span className="pl-12">Institute Name:</span>
                </p>
                <p className="flex-1">Institute email:</p>
              </div>
              <div className="overflow-auto">
                {data?.data?.userList?.length > 0 && <InstituteCardCheckbox handleCheckboxChange={handleCheckboxChange} checkedItems={checkedItems} searchTerm={searchTerm} data={data?.data?.userList} />}
              </div>
              <div className="mt-2  flex justify-center">
                <DarkButton
                  type="submit"
                  btnTitle="Submit"
                  handler={() => {
                    submitHanlder();
                  }}
                  loading={addstudentloading}
                />
              </div>
            </>
          ) : (
            <div className="flex min-h-[60vh] justify-center items-center">
              <h3 className="text-2xl">Institute List not found</h3>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default InstituteListCheckbox;
