import { useState, useRef, useEffect } from "react";
import SettingsVoiceIcon from "@mui/icons-material/SettingsVoice";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import MicOffIcon from "@mui/icons-material/MicOff";
import FileUploadIcon from "@mui/icons-material/FileUpload";

const mimeType = "audio/mp3";

const SpeakerCard = () => {
  const [speak, setSpeak] = useState(false);
  const [permission, setPermission] = useState(false);
  const mediaRecorder = useRef(null);
  const [recordingStatus, setRecordingStatus] = useState("inactive");
  const [stream, setStream] = useState(null);
  const [audioChunks, setAudioChunks] = useState([]);
  const [audio, setAudio] = useState(null);
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    let intervalId;

    if (recordingStatus === "recording") {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer + 1);
      }, 1000);
    } else {
      clearInterval(intervalId);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [recordingStatus]);

  const getMicrophonePermission = async () => {
    if ("MediaRecorder" in window) {
      try {
        const streamData = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false,
        });
        setPermission(true);
        setStream(streamData);
      } catch (err) {
        alert(err.message);
      }
    } else {
      alert("The MediaRecorder API is not supported in your browser.");
    }
  };

  // start recording
  const startRecording = async () => {
    setRecordingStatus("recording");
    setTimer(0); // Reset timer when recording starts
    const media = new MediaRecorder(stream, { type: mimeType });
    mediaRecorder.current = media;
    mediaRecorder.current.start();
    let localAudioChunks = [];
    mediaRecorder.current.ondataavailable = (event) => {
      if (typeof event.data === "undefined") return;
      if (event.data.size === 0) return;
      localAudioChunks.push(event.data);
    };
    setAudioChunks(localAudioChunks);
  };

  // stop recording
  const stopRecording = () => {
    setRecordingStatus("inactive");
    mediaRecorder.current.stop();
    mediaRecorder.current.onstop = () => {
      const audioBlob = new Blob(audioChunks, { type: mimeType });
      const audioUrl = URL.createObjectURL(audioBlob);
      setAudio(audioUrl);
      setAudioChunks([]);
    };
  };

  const handleSpeak = () => {
    setSpeak(!speak);
  };
  return (
    <div className="bgColorSecondary w-full flex flex-col justify-center items-center mt-4 mb-10 rounded py-5 px-4">
      <h2 className="mb-1 text-white font-medium text-lg">
        Read Above Question and Speak the answer
      </h2>
      <div className="mt-5">
        {!permission ? (
          <button
            className="w-full flex flex-col items-center"
            onClick={getMicrophonePermission}
            type="button"
          >
            <KeyboardVoiceIcon
              fontSize="large"
              sx={{ color: "#fff" }}
              className="cursor-pointer"
            />
            <p className="pt-2 text-white">Start Exam & Setting Mic</p>
          </button>
        ) : null}
        {permission && recordingStatus === "inactive" ? (
          <button
            className="w-full flex flex-col items-center"
            onClick={startRecording}
            type="button"
          >
            <KeyboardVoiceIcon
              fontSize="large"
              sx={{ color: "#fff" }}
              className="cursor-pointer"
            />
            <p className="pt-2 text-white">Start Recording</p>
          </button>
        ) : null}
        {permission && recordingStatus === "recording" ? (
          <div className="w-full flex flex-col items-center">
            <MicOffIcon
              fontSize="large"
              sx={{ color: "#fff" }}
              className="cursor-pointer"
            />
            <p className="pt-2 text-white">Recording...</p>
            <p className="text-white">{`Time: ${timer} seconds`}</p>
            <button
              className="mt-2 bg-red-500 px-[10px] py-[5px] rounded-sm text-white"
              onClick={stopRecording}
              type="button"
            >
              Stop Recording
            </button>
          </div>
        ) : null} 

        {audio && (
          <div className="mt-5 flex flex-col justify-between items-center">
            <audio src={audio} controls></audio>
            <a
              download
              href={audio}
              className="mt-5 bg-gray-700 px-[20px] py-[10px] rounded-sm text-white shadow-xl"
            >
              <FileUploadIcon /> Upload Answer
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default SpeakerCard;
