import { Table, TableBody, TableRow, TableContainer, TableCell, Avatar } from "@mui/material";
import TableHeading from "../common/Table/TableHeading";

const DashboardInstituteTable = ({ data }) => {
  const tableHeading = [
    {
      title: "S.No.",
    },
    {
      title: "Institute Name",
    },
    {
      title: "Email",
    },
    {
      title: "Address",
    },
  ];
  return (
    <div className="bg-white px-4 py-1 rounded-xl">
     
      <div className="flex flex-col">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 sm:px-6 ">
            <div className="overflow-hidden">
              <TableContainer>
                <Table aria-label="simple table">
                  <TableHeading tableHeading={tableHeading} />
                  <TableBody>
                    {data.map((item, index) => (
                      <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                        <TableCell>{index + 1}</TableCell>

                        <TableCell align="left">{item.instituteName}</TableCell>
                        <TableCell align="left">{item.email}</TableCell>
                        <TableCell align="left">{item.address}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardInstituteTable;
