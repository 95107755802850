import { store } from "./store";
import { Provider } from "react-redux";
import { Toaster } from "react-hot-toast";
import AppNavigator from "./AppNavigator";
import { QueryClient, QueryClientProvider } from "react-query";
import CustomSnackBar from "./components/common/CustomSnackBar";

function App() {

  const queryClient = new QueryClient();

  return (
    <div className="App">
      <Provider store={store}>
        <CustomSnackBar />
        <QueryClientProvider client={queryClient}>
          <Toaster
            toastOptions={{
              position: "top-center",
            }}
          />
          <AppNavigator />
        </QueryClientProvider>
      </Provider>
    </div>
  );
}

export default App;
