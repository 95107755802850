import LogoutSharpIcon from "@mui/icons-material/LogoutSharp";
import CookieStorage from '../../../services/cookieStorage';
import { useNavigate, useLocation } from "react-router-dom";
import toast from "react-simple-toasts";
import ieltsLogo from '../../../assets/img/ilteslogo.png'
import { useState } from "react";
import { TfiClose } from "react-icons/tfi";
import { FaBars } from "react-icons/fa";

const AppSideBarTest = () => {
  const [mobileMenu, setMobildMenu] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const cookie = new CookieStorage();
  return (
    <nav
        id="customScrollbar"
        className="md:left-0 md:block md:fixed md:top-0  md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-20 py-4 px-6"
      >
        <div
          style={{ transition: "all 1.5s" }}
          className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto"
        >
          <button
            className="bg-white m-2  cursor-pointer text-black opacity-50 md:hidden px-3 py-2 text-xl leading-none bg-transparent rounded border border-solid border-transparent border-[#e4e4e7]"
            type="button"
            onClick={() => setMobildMenu(!mobileMenu)}
          >
            {!mobileMenu ? <FaBars /> : <TfiClose />}
          </button>
          <div className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-md uppercase font-bold md:p-4 px-0 py-0 cursor-pointer">
            {/* <h1 className="text-dark font-bold text-5xl text-center hover:text-purple-800 transition-all duration-700 drop-shadow-lg shadow-black">IELTS</h1> */}
            <img src={ieltsLogo} alt="ieltslogo" className="w-[120px] " />
          </div>

          {mobileMenu && (
            <div className="top-0; h-auto w-full md:hidden">
              <ul className="flex flex-col p-4 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
                
                

                <li className="items-center">
                  <div
                    className={`text-xs uppercase py-3 font-bold cursor-pointer flex items-center justify-center hover:bg-gray-100`}
                    onClick={() => {
                      cookie.logout();
                      navigate("/login");
                      window.location.reload();
                    }}
                  >
                    <LogoutSharpIcon className="mr-1" /> Logout
                  </div>
                </li>
              </ul>
            </div>
          )}

          <div className="md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded hidden">
            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
            <li className="items-center">
                <div
                  className={`pl-2 text-sm uppercase py-3 font-medium cursor-pointer flex items-center }`}
                  onClick={() => {
                    cookie.logout();
                    navigate("/login");
                    window.location.reload();
                    
                    toast("Logged out Successfully", {
                      theme: "dark",
                      position: "top-center",
                    });
                    
                  }}
                >
                  <LogoutSharpIcon fontSize="small" className="mr-1" /> <span className="ml-1">Logout</span>
                </div>
              </li>
                </ul>
        </div>
        </div>
        </nav>

  )
}

export default AppSideBarTest