import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useState } from "react";

const SelectInputField = ({ label, exam, id, flag, defaultValue, options, formik, value }) => {
  const [selectedExam, setSelectedExam] = useState("");

  const handleChange = (event) => {
    console.log(event);
    setSelectedExam(event.target.value);
    if (flag) {
      exam(event.target.value);
    }

    console.log(selectedExam);
  };
  return (
    <FormControl  className="w-full" size="small">
      <InputLabel id="demo-simple-select-autowidth-label">{label}</InputLabel>
      <Select
      width={"100%"}
     
        id="demo-simple-select"
        // value={selectedExam}
        value={formik.values}
        defaultValue={defaultValue}
        labelId="demo-simple-select-autowidth-label"
        name={id}
        {...formik.getFieldProps(id)}
        error={formik.touched[id] && formik.errors[id]}
        onChange={(e) => {
          formik.handleChange(e);
          handleChange(e);
        }}
        helperText={formik.touched[id] && formik.errors[id]}
        label={label}
      >
        {options.map((item, index) => (
          <MenuItem style={{width:"100%"}} key={index} value={item.option} className="">
            {item.label}
          </MenuItem>
        ))}
      </Select>
      {formik.touched[id] && formik.errors[id] && <p className="text-red-500 text-sm pl-4">{formik.errors[id]}</p>}
    </FormControl>
  );
};

export default SelectInputField;
