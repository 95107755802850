import { useFormik } from "formik";
import { useMutation } from "react-query";
import WrittingForm from "./WrittingForm";
import { useDispatch } from "react-redux";
import { setToastMessage } from "../../../store/toast";
import { AddQuestionAPI } from "../../../services/helpers/Question";
import { initialValueWriting as initialValues, validationSchemaWriting as validationSchema } from "../../../services/validate/AddQuestion";
import { Button } from "@mui/material";
import AddSharpIcon from "@mui/icons-material/AddSharp";
import RemoveIcon from '@mui/icons-material/Remove';
import { useNavigate, useParams } from "react-router-dom";
const Writting = ({ questionType, examId, writtenQList, setWrittenQList, sectionId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const {sectionId} = useParams()
  const { mutate, isLoading } = useMutation(AddQuestionAPI, {
    mutationKey: "addFillQuestion",
    onSuccess: (res) => {
      if (res?.status === false) {
      } else if (res?.status === true) {
        dispatch(
          setToastMessage({
            isActive: true,
            message: res?.message,
            type: "success",
          })
        );
        formik.resetForm();
        navigate(`/exams/${examId}/${sectionId}`)
        
      }
    },
    onError: () => { },
    onSettled: () => { },
  });

  const submitHandler = (values) => {
    const newValues = {
      questionType: questionType,
      sectionId: sectionId,
      questionType: questionType,
      examId: examId,
      writtenQuestion: values.questions.map((question, index) => ({
        question: question[`question`],
        time: question[`time`],
      })),
    };
    if(sectionId){
      mutate(newValues);
    } else {
      alert("Select Section of question")
    }
  };

  const formik = useFormik({
    initialValues: {
      questions: Array.from({ length: writtenQList.length }, (_, index) => ({
        question: "",
        time: "1",
      })),
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      submitHandler(values);
    },
  });

  return (
    <div className="w-full mt-4">
      <div className="flex justify-end gap-4">
        <Button variant="contained" onClick={() => {
          setWrittenQList((prevState) => {
            return [...prevState, prevState.length]
          })
          formik.values.questions.push({
            question: "",
            time: "1",
          })
        }}>Add Question <AddSharpIcon className="ml-2" /></Button>
        {writtenQList.length !== 1 && <Button variant="contained" onClick={() => {
          if (writtenQList.length !== 1) {
            let questions = formik.values.questions
            questions.pop()
            formik.values.questions = questions
            setWrittenQList((prevState) => {
              prevState.pop()
              return [...prevState]
            })
          }
        }}>Remove Question <RemoveIcon className="ml-2"/></Button>}
      </div>
      <WrittingForm formik={formik} btnTitle="Add Question" isLoading={isLoading} writtenQList={writtenQList} setWrittenQList={setWrittenQList} />
    </div>
  );
};

export default Writting;
