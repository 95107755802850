import React from "react";

const examDetails = {
  id: 1,
  fullName: "Dig Vijay",
  course: "Course One",
  country: "Astralia",
  university: "university",
  degree: "Bachelor",
  applyFor: "Apply For Study",
  resultStatus: "Passed",
  bandScore: 7.8,
};

const ApplicantDetail = () => {
  function formatKey(key) {
    const camelCaseKey = key.replace(/_([a-z])/g, (match, letter) => letter.toUpperCase());

    return camelCaseKey.charAt(0).toUpperCase() + camelCaseKey.slice(1).replace(/([A-Z])/g, " $1");
  }

  return (
    <div className="w-full">
      <h1 className="text-xl text-center font-semibold pb-4">Applicant Details</h1>
      <hr />
      <div className="mt-4 grid grid-cols-1 lg:grid-cols-2">
        {Object.keys(examDetails).map((key) => (
          <div key={key} className="flex flex-wrap">
            <h2 className=" font-medium flex-1">{formatKey(key)} :</h2>
            <p className="flex-1 md:flex-2 text-start ">{examDetails[key]}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ApplicantDetail;
