import * as yup from "yup";
export const initialValueListening = {
  testTime: "",
  testType: "",
  question: "",
  opt1: "",
  opt2: "",
  opt3: "",
  opt4: "",
};
export const validationSchemaListening = yup.object().shape({
  testTime: yup.string().required("Time is required"),
  testType: yup.string().required("Type is required"),
  question: yup.string().required("Question is required"),
  opt1: yup.string().required("Option is required"),
  opt2: yup.string().required("Option is required"),
  opt3: yup.string().required("Option is required"),
  opt4: yup.string().required("Option is required"),
});
export const initialValueMcq = {
  question: "",
  opt1: "",
  opt2: "",
  opt3: "",
  opt4: "",
  answer: "",
  time: "",
};
export const validationSchemaMcq = yup.object().shape({
  questions: yup.array().of(
    yup.object().shape({
      question: yup.string().required("Question is required"),
      answer: yup.string().required("Answer is required"),
      opt1: yup.string().required("Option is required"),
      opt2: yup.string().required("Option is required"),
      opt3: yup.string().required("Option is required"),
      opt4: yup.string().required("Option is required"),
      time: yup.string().required("Time is required"),
      // marks: yup.string().required("Mark is required"),
    })
  ),
});
export const initialValueFillInTheBlanks = {
  question: "",
  writtenAnswer: "",
  time: "",
};
export const validationSchemaFillInTheBlanks = yup.object().shape({
  questions: yup.array().of(
    yup.object().shape({
      question: yup.string().required("Question is required"),
      writtenAnswer: yup.string().required("Answer is required"),
      time: yup.string().required("Time is required"),
    })
  ),
});
export const initialValueWriting = {
  question: "",
  time: "2",
};
export const validationSchemaWriting = yup.object().shape({
  questions: yup.array().of(
    yup.object().shape({
      question: yup.string().required("Question is required"),
      time: yup.string().required("Time is required"),
    })
  ),
});

export const initialValueBoolean = {
  question: "",
  correctAnswer: "",
};
export const validationSchemaBoolean = yup.object().shape({
  questions: yup.array().of(
    yup.object().shape({
      question: yup.string().required("Question is required"),
      answer: yup.string().required("Answer is required"),
      opt1: yup.string().required("Option is required"),
      opt2: yup.string().required("Option is required"),
      opt3: yup.string().required("Option is required"),
      opt4: yup.string().required("Option is required"),
      time: yup.string().required("Time is required"),
      // marks: yup.string().required("Mark is required"),
    })
  ),
});