import { Table, TableBody, TableRow, TableHead, TableContainer, TableCell, Avatar } from "@mui/material";

const DashboardCourseTable = ({ data }) => {
  return (
    <TableContainer>
      <Table aria-label="simple table">
        <TableHead style={{ backgroundColor: "#E5E7EB" }}>
          <TableRow>
            <TableCell className="!font-semibold">S.No.</TableCell>
            <TableCell className="!font-semibold" align="left">Exam Name</TableCell>
            <TableCell className="!font-semibold" align="left">Module Type</TableCell>
            {/* <TableCell align="right">University</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, index) => (
            <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell className="!p-2">{index + 1}</TableCell>
              {/* <TableCell align="left">{item.course}</TableCell>
              <TableCell align="elft">{item.examType}</TableCell>
              <TableCell align="right">{item.university}</TableCell> */}
              <TableCell className="!p-2" align="left">{item.examName}</TableCell>
              <TableCell className="!p-2" align="elft">
                {item?.examType.includes("[")
                  ? JSON.parse(item?.examType).map((examType) => {
                      return (
                        <>
                          {examType == "1" ? "Reading" : examType == "2" ? "Writing" : examType == "3" ? "Listening" : examType == "4" ? "Speaking" : examType == "12" ? "General Reading" : examType == "11" ? "Academic Reading" : null} <br />
                        </>
                      );
                    })
                  : item.examType == "1"
                  ? "Reading"
                  : item.examType == "2"
                  ? "Writing"
                  : item.examType == "3"
                  ? "Listening"
                  : item.examType == "4"
                  ? "Speaking"
                  : null}
              </TableCell>

            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DashboardCourseTable;
