import { useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import Cookies from 'js-cookie';
import { useQuery } from "react-query";
import LoaderSpiner from '../../common/LoaderSpiner';
import { GETEXAMRESULTAPI, GETEXAMSUBMITLISTAPI } from '../../../services/helpers/Exam';
import { Card } from '@mui/material'
import { useParams } from 'react-router-dom'
const ResultDetailComp = () => {
  const navigate = useNavigate()
  const { id } = useParams()


  const {
    data,
    isLoading,
    isFetching,
    isError,
  } = useQuery(
    ["examimationResult"],
    () => {
      const queryParams = {
        submitExamId: id,
      };
      const paramsData = {
        queryParams,
      };
      return GETEXAMRESULTAPI(paramsData);
    },
    {
      mutationKey: "examimationResult",
    }
  );

  console.log("resosdfl ksdf", data, id)


  return (
    <div className="w-full ">
      <div className="p-8">
        <div className="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6 min-h-[60vh]">{isError ? <h1 className='text-center text-red-400'>Something went wrong!</h1> :
          isLoading || isFetching ? (
            <div className="w-full flex justify-center items-center min-h-[60vh]">
              <LoaderSpiner />
            </div>
          ) : (
            <>
              <div className='flex flex-col w-full mb-20'>
                {data?.data && data?.data?.length > 0 &&
                  <div className="w-full relative px-8 my-4">
                    <div className="text-center flex justify-center items-center text-2xl bg-[#fff] min-h-[60vh] flex-col gap-4">
                      <div className='flex flex-col w-full mb-20'>
                        <h3 className='pb-6'>Result</h3>
                        {data?.data.map((examResult, index) => {
                          return (
                            <Card className='w-[90%] md:w-1/2 m-auto py-12 my-2'>
                              <h1 className='text-center text-base mb-2 '><span className='font-semibold'>Module Name: </span> <span>{examResult?.examTypeName}</span></h1>
                              <div className='w-full m-auto flex text-base font-semibold mb-2'>
                                <div className='w-1/2 text-right'>Total Question:</div>
                                <div className='w-1/2 text-left pl-8'>{examResult?.result?.totalQuestion}</div>
                              </div>

                              <div className='w-full m-auto flex text-base font-semibold mb-2'>
                                <div className='w-1/2 text-right'>Right Answer:</div>
                                <div className='w-1/2 text-left pl-8'>{examResult?.result?.correctAnswer}</div>
                              </div>

                              <div className='w-full m-auto flex text-base font-semibold mb-2'>
                                <div className='w-1/2 text-right'>Wrong Answer:</div>
                                <div className='w-1/2 text-left pl-8'>{examResult?.result?.incorrectAnswer}</div>
                              </div>

                              <div className='w-full m-auto flex text-base font-semibold mb-2'>
                                <div className='w-1/2 text-right'>Review Answer:</div>
                                <div className='w-1/2 text-left pl-8'>{examResult?.result?.reviewAnswer}</div>
                              </div>

                              <div className='w-full m-auto flex text-base font-semibold mb-2'>
                                <div className='w-1/2 text-right'>Not Attempt:</div>
                                <div className='w-1/2 text-left pl-8'>{examResult?.result?.notAttempt}</div>
                              </div>

                              <div className='w-full m-auto flex text-base font-semibold mb-2'>
                                <div className='w-1/2 text-right'>Band score:</div>
                                <div className='w-1/2 text-left pl-8'>{examResult?.result?.bandScore}</div>
                              </div>
                            </Card>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                }
              </div>
            </>
          )
        }
        </div>
      </div>
    </div>
  )
}

export default ResultDetailComp