import AddStudentForm from "./Form";
import { DashboadPagesLayout } from "../../components";
import { useEffect } from "react";
import { useDispatch } from "react-redux"
import { removeSelectedInstituteId } from "../../store/student";

const AddStudent = () => {
  const dispatch = useDispatch()
  useEffect(()=>{
    return () => {
      dispatch(removeSelectedInstituteId())
    }
  },[])
  return (
    <DashboadPagesLayout>
      <AddStudentForm />
    </DashboadPagesLayout>
  );
};

export default AddStudent;
