import { useQuery } from "react-query";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import notfound from "../../assets/no_data_found.png"
import AddSharpIcon from "@mui/icons-material/AddSharp";
import { ExamList, ExamSearchList } from "../../services/helpers/Institute";
import BreadcrumbsC from "../../components/common/BreadcrumbsC";
import { CustomPagination, DarkButton, ExamListTable } from "../../components";
import { CircularProgress, IconButton, InputAdornment, TextField } from "@mui/material";
import Cookies from "js-cookie";

const Exams = () => {
  const [searchflag, setSearchFlag] = useState(false);
  const [search, setSearch] = useState(null);
  console.log(search);
  const [stopList, setStopList] = useState(true);
  // console.log(search);
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [serial, setSerial] = useState();
  const [change, setChange] = useState(false);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState();
  console.log(pagination);
  const [isupdated, setIsupdated] = useState(false);

  console.log("Test", search);

  const navigate = useNavigate();
  const handleSearch = (e) => {
    e.preventDefault();
    // setSearchFlag(true);
    // setLoading(true);
  };

  useEffect(() => {
    if (search?.length>0) {
      setSearchFlag(true);
    }
  }, [search]);

  useEffect(() => {
    
    if(search?.length>0){
      setSearchFlag(true)
    }
    else{
      setStopList(true);
    }
  }, [isupdated,page]);

  useEffect(() => {
    if (search === "") {
      setStopList(true);
      // setLoading(true);
    }
  }, [search]);

  // useEffect(() => {
  //   if (data?.length === 0) {
  //     setStopList(true);
  //     // setLoading(true);
  //   }
  // }, [data]);

  

  const getStartingSerialNumber = () => {
    return setSerial((page - 1) * pagination?.numberPerPage + 1);
  };

  useEffect(() => {
    getStartingSerialNumber();
  }, [data]);

  const handler = () => {
    navigate("/exams/add");
  };
  const breadcrumbb = [
    {
      id: 1,
      title: "Dashboard",
      link: "/dashboard",
    },
    {
      id: 1,
      title: "Exams",
      link: "/exams",
    },
  ];

  useQuery(
    ["EXAMAPI", page, change, isupdated],
    () => {
      const queryParams = {
        pageNum: page,
      };
      if (Cookies.get("id") == "institute") {
        queryParams.addById = Cookies.get("userId");
      }
      const paramsData = {
        queryParams,
      };
      return ExamList(paramsData);
    },
    {
      retry: false,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: 0,
      mutationKey: "EXAMAPI",
      enabled: stopList,
      onSuccess: (res) => {
        setLoading(false);
        if (res?.status === true) {
          setStopList(false);
          setChange(false);
          setIsupdated(false);
          setData(res?.data?.userList);
          console.log(res);
          setPagination(res?.data?.pagination);
        } else {
        }
      },
    }
  );

  useQuery(
    ["EXAM_SEARCH_API", page, change, isupdated,search],
    () => {
      const queryParams = {
        search: search,
        pageNum: page,
      };
      if (Cookies.get("id") == "institute") {
        queryParams.addById = Cookies.get("userId");
      }
      const paramsData = {
        queryParams,
      };
      return ExamSearchList(paramsData);
    },
    {
      retry: false,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: 0,
      mutationKey: "EXAMAPI",
      enabled: searchflag,
      onSuccess: (res) => {
        setLoading(false);
        if (res?.status === true) {
          setSearchFlag(false);
          setStopList(false);
          setChange(false);
          setIsupdated(false);
          setData(res?.data?.examList);
          // console.log(res);
          console.log(res?.data?.examList);
          setPagination(res?.data.pagination);
        } else {
        }
      },
    }
  );

  const examsTables = [];
  return (
    <div className="relative px-8 py-4 bg-[#F3F3F3] md:ml-64">
      <div className="w-full">
        <div className="flex justify-between items-center">
          <BreadcrumbsC data={breadcrumbb} />
        </div>
        <div className="flex flex-col-reverse sm:grid sm:grid-cols-2 justify-center sm:justify-between items-center mt-8 gap-5">
          <form onSubmit={handleSearch}>
            <div className="flex items-center w-full gap-4">
              <TextField
                id="outlined-start-adornment"
                className="w-full sm:w-auto"
                size="small"
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                sx={{ backgroundColor: "white" }}
                placeholder="Search or Type Here"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton aria-label="toggle password visibility" edge="end">
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </form>
          <div className="flex w-full sm:justify-end">
            <DarkButton btnTitle="Add Exam" endIcon={<AddSharpIcon />} handler={handler} />
          </div>
        </div>
      </div>

      {/* lower section */}
      <div className={`bg-white mt-4 rounded-lg p-5 mb-10 ${examsTables.length > 2 && examsTables.length <= 4 ? "mb-20" : "" || examsTables.length <= 2 ? "mb-20" : ""}`}>
        {loading ? (
          <div className="flex justify-center min-h-[50vh] items-center">
            <CircularProgress className="mt-12" />
          </div>
        ) : (
        <div className="">
            {data?.length!==0?(<ExamListTable data={data} serial={serial} setChange={setChange} isupdated={isupdated} setIsupdated={setIsupdated} />):(<div className=" "><span className="text-2xl text-center flex justify-center">No Exam Found </span><div className="flex justify-center"><img style={{width:"300px"}} src={notfound}/></div>
            </div>)}
          </div>
        )}
      </div>
      {data?.length!==0?<CustomPagination data={data} page={page} setPage={setPage} paginationData={pagination} />:null}
    </div>
  );
};

export default Exams;
