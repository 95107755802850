import { Button } from "@mui/material";
import { useNavigate } from "react-router";

const NotFound404 = () => {
  const navigate = useNavigate();
  return (
    <div className="md:ml-64 mb-64 flex justify-center h-[600px] pt-28 text-center">
      <div className="h-full flex flex-col items-center justify-center">
        <div className="title text-[8rem] font-bold text-red-600 mb-[2rem] shadow-sm " data-content="404">
          404
        </div>

        <div className="text-[20px] font-bold text-[#1976d2] mb-[2rem]">Oops, the page you're looking for doesn't exist.</div>
        <br />
        <br />
        <Button variant="contained" className="!bg-Primary hover:!bg-PrimaryHover">
          <a
            href="/"
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard");
            }}
          >
            Go to homepage
          </a>
        </Button>
      </div>
    </div>
  );
};

export default NotFound404;
