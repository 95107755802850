import React from "react";

const StatusPills = ({ status, clickHandle }) => {
  return (
    <>
      <button onClick={clickHandle} className={`${status==="1"  ? "bg-green-200" : "bg-orange-200"} px-4 py-1 rounded-full`}>
        <p className={`${status==="1"  ? "text-green-700" : "text-orange-700"}`}>{status==="1"  ? "Active" : "Inactive"}</p>
      </button>
    </>
  );
};

export default StatusPills;
