import { Card } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { useEffect, useState } from "react";

const ListCardCheckbox = ({ handleCheckboxChange, checkedItems, searchTerm, examList, data }) => {
  const [filterExamList, setFilterExamList] = useState(data);
  useEffect(() => {
    const filteredExams = data.filter((exam) => `${exam.firstName.toLowerCase()} ${exam.lastName.toLowerCase()}`.includes(searchTerm.toLowerCase()));
    setFilterExamList(filteredExams);
  }, [searchTerm]);
  const filteredExams = data.filter((exam) => `${exam.firstName.toLowerCase()}${exam.lastName.toLowerCase()}`.includes(searchTerm.toLowerCase()));
  console.log("sdfilset=> ", filteredExams, data);
  return (
    <Card style={{ height: "56vh", marginTop: "0.5rem", overflowY: "auto" }}>
      {filterExamList.map((exam) => (
        <div className="w-full flex border-b items-center" key={exam.userId}>
          <p className="flex-1">
            <Checkbox checked={checkedItems[exam.userId] || false} onChange={() => handleCheckboxChange(exam.userId)} inputProps={{ "aria-label": "controlled" }} />
            <span>{`${exam?.firstName} ${exam?.lastName} `}</span>
          </p>
          <p className="flex-1">{exam?.phone}</p>
        </div>
      ))}
    </Card>
  );
};

export default ListCardCheckbox;
