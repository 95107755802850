import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { GrClose } from "react-icons/gr";
import { CircularProgress } from "@mui/material";
import { SelectInputField } from "../../Forms";

const style = {
  bgcolor: "background.paper",
};

const UpdateStatusModal = ({ formik, handleClose, open, loading, id, option, defaultValue }) => {
  console.log("default ==> ", formik);

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={style} className="w-[400px] top-[50%] left-[50%] absolute translate-x-[-50%] translate-y-[-50%] shadow-lg rounded">
        <div className="pt-4 px-5 flex justify-between">
          <h2 className="text-bold font-bold text-lg">Update Status</h2>
          <GrClose className="cursor-pointer" onClick={handleClose} />
        </div>
        <div className="w-100 bg-slate-200 h-[.25px] mt-2 mb-4 shadow-lg" />
        <Typography id="modal-modal-title" variant="h6" component="h2" className="w-full text-center pt-1 rounded px-5">
          <SelectInputField formik={formik} id={id} options={option} defaultValue={defaultValue.isActive} />
        </Typography>
        <div className="w-100 bg-slate-200 h-[.25px] mt-6 mb-2 shadow-lg" />
        <div className="w-full p-5 pb-4 flex justify-between">
          <button onClick={handleClose} className="w-2/5 py-2 rounded border">
            Cancel
          </button>
          <button
            onClick={() => {
              formik.handleSubmit();
            }}
            className="w-2/5 rounded bg-green-600 hover:bg-green-500 text-white py-2"
          >
            {loading ? <CircularProgress /> : "Update"}
          </button>
        </div>
      </Box>
    </Modal>
  );
};

export default UpdateStatusModal;
