import React, { useEffect, useState } from "react";
import RecordView from "../../Videotest";
import { useDispatch } from "react-redux";
import { examSelectedId, examUpdateById } from "../../../store/exam";

const VideoRecordExam = ({ change, selectedExam, time }) => {
  console.log("changeselectedexam", change, selectedExam, time)
  const [examD, setExamD] = useState();
  const dispatch = useDispatch();
  useEffect(() => {
    if (selectedExam.length > 0) {
      setExamD(selectedExam[change]);
      // dispatch(examSelectedId(selectedExam[change].id));
      console.log("selectedExam[change] ==", selectedExam[change]);
    }
  }, [change, selectedExam]);

  const questionsAnswersHandle = (id, answer) => {
    // dispatch(examUpdateById({ id, answer }));
  };
  return (
    <>
      <ul className="">
        {examD && examD?.questionTypeId == 3 && (
          <li
            key={examD.id}
            className="mt-2 min-h-[270px] sm:min-h-[230px] md:min-h-[230px] lg:min-h-[260px] xl:min-h-[230px]"
          >
            <div className="flex justify-end">
              <p>{time} min</p>
            </div>
            <div className="text-gray-500 flex mb-6">
              <p className="mr-2 font-semibold text-gray-900">
                Ques.{change + 1}
              </p>
              <p>{examD?.question}</p>
            </div>
            <div className="mt-3">
              <RecordView questionsAnswersHandle={questionsAnswersHandle} id={examD?.id} />
            </div>
          </li>
        )}
      </ul>
    </>
  );
};

export default VideoRecordExam;
