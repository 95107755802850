import Cookies from "js-cookie";
import Box from "@mui/material/Box";
import dashboardCard from "./mockData";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import DashboardCard from "../../components/common/Card/DashboardCard";
import { DashboardStudentTable, DashboardCourseTable } from "../../components";
import DashboardInstituteTable from "../../components/DashboardInstituteTable";

const DashboardP = ({ examList, studentList, instituteList, totalStudent, totalInstitute, totalExam }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (Cookies.get("id") === "student") {
      if ((window.location.href = "/")) {
        window.location.href = "/test";
      }
    }
  }, []);

  const [isLoading, setIsLoading] = useState(false);

  const { totalStudents, totalCourse, feeCollection } = dashboardCard;

  return (
    Cookies.get("id") !== "student" && (
      <div className="relative px-2 sm:px-8 py-4 bg-[#F3F3F3] md:ml-64">
        {isLoading ? (
          <div className="w-full h-[80vh] flex justify-center items-center">
            <Box sx={{ display: "flex" }}>
              <CircularProgress />
            </Box>
          </div>
        ) : (
          <div className="">

            <div className={`grid grid-cols-1 ${Cookies.get("id") === "super_admin" ? "md:grid-cols-1 lg:grid-cols-3 gap-4 pt-2" : "lg:grid-cols-2 gap-4 pt-2"}`}>
              <DashboardCard propsValue={totalStudents} color={"#1367C8"} mainText={totalStudent} />
              {Cookies.get("id") === "super_admin" ? <DashboardCard propsValue={totalCourse} color={"#673BB7"} mainText={totalInstitute} /> : null}
              <DashboardCard propsValue={feeCollection} color={"#FF1616"} mainText={totalExam} />
            </div>
            <div className="flex flex-col lg:flex-row justify-between  pt-4 gap-4 ">
              <div className=" rounded-xl flex-1 px-4 pb-4 bg-white">
                <p className="text-2xl my-2 text-center">
                  <span className="font-medium">Exam</span>
                </p>
                {examList.length !== 0 ? <DashboardCourseTable data={examList} /> : <div className="text-center"><p>No Exam Found</p></div>}
              </div>
            </div>
            {instituteList && (
              <div className=" rounded-xl w-full m-auto bg-white mt-3 mb-8">
                <p className="text-2xl mt-2 text-center">
                  <span className="font-medium">Institute</span>
                </p>
                {instituteList.length !== 0 ? <DashboardInstituteTable data={instituteList} /> : <div className="text-center "><p className="mt-4 pb-3">No Institute Found</p></div>}
              </div>
            )}
            <div className=" rounded-xl w-full m-auto bg-white mt-3 mb-8">
              <p className="text-2xl my-2 text-center">
                <span className="font-medium">Students</span>
              </p>
              {examList.length !== 0 ? <DashboardStudentTable data={studentList} /> : <div className="text-center"><p>No Student Found</p></div>}
            </div>
          </div>
        )}
      </div>
    )
  );
};

export default DashboardP;
