import React, { useEffect } from "react";
import { Test } from "../takeTest";
import ExamHeader from "../../components/ExamHeader";
import { DashboadPagesLayout, Header } from "../../components";
import AppSideBarExam from "../../components/common/AppSideBar/AppSideBarExam";
import { FaBars } from "react-icons/fa";
import { TfiClose } from "react-icons/tfi";
import DrawIcon from "@mui/icons-material/Draw";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import LogoutSharpIcon from "@mui/icons-material/LogoutSharp";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import { useSelector } from 'react-redux'
import ResultListComp from "../../components/ResultListComp";

const ResultList = () => {


  return (
    <div>
      <AppSideBarExam type="dashboard"  />
      <Header />

      <DashboadPagesLayout>
        <ResultListComp />
      </DashboadPagesLayout>
    </div>
  );
};

export default ResultList;
