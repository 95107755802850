import { Card } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { useEffect, useState } from "react";

const InstituteCardCheckbox = ({ handleCheckboxChange, checkedItems, searchTerm, examList, data }) => {
  const [filterExamList, setFilterExamList] = useState(data);
  useEffect(() => {
    const filteredExams = data.filter((exam) => `${exam.instituteName.toLowerCase()}`.includes(searchTerm.toLowerCase()));
    setFilterExamList(filteredExams);
  }, [searchTerm]);
  const filteredExams = data.filter((exam) => `${exam.instituteName.toLowerCase()}`.includes(searchTerm.toLowerCase()));
  return (
    <Card style={{ height: "56vh", marginTop: "0.5rem", overflowY: "auto" }}>
      {filterExamList.map((exam) => (
        <div className="w-full flex border-b items-center" key={exam.instituteId}>
          <p className="flex-1">
            <Checkbox checked={checkedItems[exam.instituteId] || false} onChange={() => handleCheckboxChange(exam.instituteId)} inputProps={{ "aria-label": "controlled" }} />
            <span>{`${exam?.instituteName}  `}</span>
          </p>
          <p className="flex-1">{exam?.email}</p>
        </div>
      ))}
    </Card>
  );
};

export default InstituteCardCheckbox;
