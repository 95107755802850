import { postApiCall, getApiCall, putApiCall, deleteApiCall } from "../../utils/axios";
import { BASE_URL, API } from "../../utils/defines";

export const AddQuestionAPI = async (payload) => {
  const url = `${BASE_URL}${API.ADD_QUESTION}`;
  try {
    const response = await postApiCall(url, payload);
    return response;
  } catch (err) {
    throw new Error(err?.data?.error?.message);
  }
};

export const QuestionTypeListAPI = async (paramsData) => {
  const url = `${BASE_URL}${API.QUESTION_TYPE_LIST}`;
  try {
    const response = await getApiCall(url, paramsData);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};

export const updateQuestionAPI = async (paramsData) => {
  const url = `${BASE_URL}${API.UPDATE_QUESTION}`;
  try {
    const response = await putApiCall(url, paramsData);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};

// export const deleteQuestionAPI = async (questionId) => {
//   const url = `${BASE_URL}${API.DELETE_QUESTION}?id=${questionId}`;
//   try {
//     const response = await deleteApiCall(url);
//     return response;
//   } catch ({ response }) {
//     throw new Error(response?.data?.error?.message);
//   }
// };

export const deleteQuestionAPI = async (paramsData) => {
  const url = `${BASE_URL}${API.DELETE_QUESTION + "?questionId=" + paramsData.queryParams.questionId}`;
  try {
    const response = await deleteApiCall(url, paramsData);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};
