import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { InstitutetableHeading, tableHeading } from "../../mockData";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../components/common/DeleteModal";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import TableHeading from "../../components/common/Table/TableHeading";
import { Table, TableBody, TableRow, TableContainer, TableCell, MenuItem, Menu, CircularProgress } from "@mui/material";
import { instituteListAPI } from "../../services/helpers/Institute";

const UsersList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  // const data = [
  //     { id: 1, fullName: "Neeraj Chopra", fatherName: "Ramesh", mobile: 9787655652, email: "neeraj@gmail.com", country: "India", applyFor: "Apply For Study" },
  //     { id: 1, fullName: "Neeraj Chopra", fatherName: "Ramesh", mobile: 9787655652, email: "neeraj@gmail.com", country: "India", applyFor: "Apply For Study" },
  //     { id: 1, fullName: "Neeraj Chopra", fatherName: "Ramesh", mobile: 9787655652, email: "neeraj@gmail.com", country: "India", applyFor: "Apply For Study" },
  //   ];
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  useQuery(
    ["USERLISTAPI"],
    () => {
      const queryParams = {
        pageNum: 1,
        role: "institute",
      };
      const paramsData = {
        queryParams,
      };
      return instituteListAPI(paramsData);
    },
    {
      retry: false,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: 0,
      mutationKey: "USERLISTAPI",
      enabled: true,
      onSuccess: (res) => {
        setLoading(false);
        if (res?.status === true) {
          console.log(res);
          setData(res?.data?.userList);
          // setPaginationdata(res?.data.pagination);
        } else {
        }
      },
    }
  );

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

    console.log("dafdfayf",data)
  return (
    <>
    <div className="mt-20 overflow-auto lg:px-8 md:ml-64">
      <h1 className="text-center mt-4 text-3xl">Institutes List</h1>
      {loading?<div className="flex justify-center"><CircularProgress className="mt-12"/></div>:<TableContainer className="!mt-4 font-semibold">
        <Table aria-label="simple table">
          <TableHeading tableHeading={InstitutetableHeading} />
          <TableBody>
            {data.map((item, index) => (
              <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell>{index + 1}</TableCell>
                <TableCell align="left">{item.fullName}</TableCell>
                <TableCell align="left">{item.phone}</TableCell>
                <TableCell align="left">{item.email}</TableCell>
                {/* <TableCell align="left">{item.country}</TableCell> */}
                <TableCell align="left">{"Delhi"}</TableCell>
                <TableCell align="left">
                  <MoreVertIcon onClick={handleClick} className="cursor-pointer" />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>}
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem
          onClick={(e) => {
            navigate("/student/Details");
            handleClose();
          }}
        >
          View Details
        </MenuItem>
        {/* <MenuItem
          onClick={(e) => {
            navigate("/exams/add");
            handleClose();
          }}
        >
          Edit
        </MenuItem> */}
          <MenuItem
            onClick={() => {
              setOpenModal(true);
              setAnchorEl(null);
            }}
          >
            Delete Student
          </MenuItem>
        </Menu>
        <DeleteModal
          open={openModal}
          deleteHandle={handleCloseModal}
          handleClose={handleCloseModal}
          title="Are you sure to delete!!"
          deleteText="Delete Student"
          className="w-100 h-100 bg-slate-800"
        />
      </div>
    </>
  );
};

export default UsersList;
