import { ExamFormHeader, AnswerCardButton, ExamHeadingCard, ExamPagination, SpeakingQuiz, CustomBreadcrums, QueChangerButton } from "../../../components";

const SpeakingExam = () => {
  const breadcrumbb = [
    {
      id: 1,
      title: "Dashboard",
      link: "/dashboard",
    },
    {
      id: 1,
      title: "Speaking Exam",
      link: "/speaking-exam",
    },
  ];

  const examsTables = [];
  const currTime = new Date().toLocaleTimeString("en-US");
  return (
    <div className="relative px-8 py-4 bg-[#F3F3F3] md:ml-64">
      <div className="w-full">
        <div className="flex justify-between items-center">
          <CustomBreadcrums data={breadcrumbb} />
        </div>
      </div>

      {/* lower section */}
      <div className={`bg-white mt-8 rounded-lg p-5 mb-10 ${examsTables.length > 2 && examsTables.length <= 4 ? "mb-40" : "" || examsTables.length <= 2 ? "mb-20" : ""}`}>
        <ExamFormHeader />
        <div className="w-full lg:flex mt-10">
          <div className="w-full lg:w-4/6 lg:pr-10">
            <ExamHeadingCard title="Speaking Exam" />
            <SpeakingQuiz />
            <QueChangerButton />
          </div>
          <div className="bg-slate-200 lg:w-2/6 py-10 w-full rounded-md">
            <div>
              <h2 className="text-center text-lg font-semibold">
                Time: <span className="color-Primary">{currTime}</span>
              </h2>
            </div>
            <AnswerCardButton />
          </div>
        </div>
      </div>
      <ExamPagination />
    </div>
  );
};

export default SpeakingExam;
