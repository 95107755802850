// withPagination.js
import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { SCHEDULELISTAPI } from "../../../services/helpers/ScheduleExam";
import Cookies from "js-cookie";

const withPagination = (WrappedComponent, fetchData, paramsData, SCHEDULELISTAPI) => {
  console.log("paramsData---> ", paramsData)
  const MemoizedComponent = React.memo(WrappedComponent);
  return (props) => {
    const [page, setPage] = React.useState(1);

    const { data, isLoading, hasNextPage, refetch, isFetching } = useQuery(
      ["data", page],
      () => {
        
        return SCHEDULELISTAPI(paramsData);
      },
      {
        keepPreviousData: true,
        staleTime: 5000, // milliseconds
      }
    );

    const handlePrevClick = () => {
      setPage((prevPage) => Math.max(prevPage - 1, 1));
    };

    const handleNextClick = () => {
      setPage((prevPage) => prevPage + 1);
    };

    useEffect(() => {
      refetch()
    },[])

    return (
      <>
        {/* Pass the pagination props to the wrapped component */}
        <MemoizedComponent
          {...props}
          data={data}
          isLoading={isLoading||isFetching}
          hasNextPage={hasNextPage}
          page={page}
          setPage={setPage}
          handlePrevClick={handlePrevClick}
          handleNextClick={handleNextClick}
        />
      </>
    );
  };
};

export default withPagination;
