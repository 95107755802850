import { useState } from "react";
import "react-simple-toasts/dist/theme/dark.css";
import MockUsers from "./dummydata";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { TextField, Button, InputAdornment, IconButton, CircularProgress } from "@mui/material";
import CookieStorage from "../../services/cookieStorage";
import toast from "react-simple-toasts";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { ResetAPI } from "../../services/helpers/Users";
import { initialValuesReset as initialValues, validationSchemaReset as validationSchema } from "../../services/validate/Users";
import { useDispatch } from "react-redux";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Logindata } from "../../store/userData";
import logo from "../../assets/img/logo.jpg";
import { useParams } from "react-router-dom";

const ResetPassword = () => {
  const loginData = useSelector((state) => state?.caretaker?.login);
  const { hash } = useParams();
  console.log("loginData", hash);
  console.log(MockUsers);
  const cookie = new CookieStorage();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const { mutate, isLoading } = useMutation(ResetAPI, {
    mutationKey: "resetPassword",
    onSuccess: (res) => {
      console.log("skjdflkj", res.data);
      if (res?.status === false) {
        setLoading(false);
        toast(res.message, {
          theme: "dark",
          position: "top-center",
        });
      } else if (res?.status === true) {
        setLoading(false);
        toast(res.message, {
          theme: "dark",
          position: "top-center",
        });
        setTimeout(() => {
          navigate("/login");
        }, 1000);
      }
    },
    onError: () => {
      setLoading(false);
    },
    onSettled: () => {
    },
  });
  const handleLogin = () => {
    // Find the user with the provided username
    const user = MockUsers.find((u) => u.username === username);

    if (!user) {
      toast("User not found", {
        theme: "dark",
        position: "top-center",
      });
    } else if (user.password !== password) {
      toast("Incorrect Password", {
        theme: "dark",
        position: "top-center",
      });
    } else {
      // Successful login - Redirect or set user role in state
      console.log({
        email: username,
        password: password,
        role: user.role,
      });

      dispatch(
        Logindata({
          role: user.role,
        })
      );
      cookie.setLogin("dskadksadjskjdksaklakld");
      if (user.role === "student") {
        navigate("/test");
      } else {
        navigate("/dashboard");
      }

      toast("Logged in Successfully", {
        theme: "dark",
        position: "top-center",
      });
    }
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      console.log(values);
      const payload = {
        token: hash,
        password: values.password,
      };
      mutate(payload);
      setLoading(true);
    },
  });

  return (
    <div className="h-[100vh] flex justify-center items-center">
      <div className="w-11/12 md:w-8/12 lg:w-4/12 h-[88vh] m-auto shadow bg-white">
        <div style={{ backgroundColor: "#6673FD" }} className=" h-1/4 md:h-1/6 lg:h-1/4 ">
          <div className="flex justify-center pt-3 md:pt-4 lg:pt-10 ">
            <div className="bg-white w-[80px] h-[80px] rounded-full flex justify-center items-center">
              <img src={logo} alt="LogoImage" className="w-full h-auto rounded-full" />
            </div>
          </div>
        </div>

        <form onSubmit={formik.handleSubmit}>
          <div style={{ marginTop: "-27px" }} className="px-4 loginshadow w-4/5 m-auto bg-white">
            <h1 className="text-2xl pt-5 pb-3 font-medium text-center">Reset Password</h1>
            <hr className="w-10/12 m-auto mt-1" />
            <div className="w-full text-center">
              <TextField
                className="w-11/12 !mt-[2.25rem]"
                id="password"
                name="password"
                type={showPassword ? "text" : "password"}
                label="New Password *"
                variant="standard"
                {...formik.getFieldProps("password")}
                error={formik.touched.password && formik.errors.password}
                helperText={formik.touched.password && formik.errors.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                className="w-11/12 !mt-[2.25rem]"
                id="confirmpassword"
                name="confirmpassword"
                type={showPassword ? "text" : "password"}
                label="Confirm Password *"
                variant="standard"
                {...formik.getFieldProps("confirmpassword")}
                error={formik.touched.confirmpassword && formik.errors.confirmpassword}
                helperText={formik.touched.confirmpassword && formik.errors.confirmpassword}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <p
                className="underline text-right text-sm mr-4 mt-2 cursor-pointer hover:font-medium"
                onClick={() => {
                  navigate("/login");
                }}
              >
                Login
              </p>
            </div>

            <div className="flex justify-center">
              <Button
                type="submit"
                className="!mb-8 !bg-Primary hover:!bg-PrimaryHover  !text-[18px] w-11/12  !mt-[48px] transition-all duration-300"
                variant="contained"
                sx={{
                  "&.Mui-disabled": {
                    color: "#d1d1d1",
                  },
                }}
              >
                {loading && <CircularProgress size="1rem" className="absolute !text-white" />}
                <span className={`${loading ? "invisible" : ""}`}>Submit</span>
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
