import React from 'react'
import AppSideBarExam from '../../../components/common/AppSideBar/AppSideBarExam'

const SidebarExam = ({type,data,activeSection,setActiveSection,examStudentSidebar, stopExamModule }) => {
  console.log("type,data,activeSection,setActiveSection,examStudentSidebar, stopExamModule", examStudentSidebar, stopExamModule)
  return (
    <AppSideBarExam type={type} data={data} activeSection={activeSection} setActiveSection={setActiveSection} examStudentSidebar={examStudentSidebar} stopExamModule={stopExamModule} />
  )
}

export default SidebarExam