import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { CustomPagination, LoaderSpiner, TableHeading } from "../../components";
import { TestTableHeading } from "../../mockData";
import {
  Table,
  TableBody,
  TableRow,
  TableContainer,
  TableCell,
  Button,
} from "@mui/material";
import LinkButton from "../../components/common/CustomButtons/LinkButton";
import { GETEXAMLISTSTUDENTAPI } from "../../services/helpers/Exam";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import {
  examAttemptUpdate,
  examMediaType,
  examRefresh,
} from "../../store/exam";
import Cookies from "js-cookie";
import DeviceHint from "./DeviceHint";

export const Test = () => {
  const dispatch = useDispatch();
  const exam = useSelector((state) => state.exam);
  const [mediaItem, setMediaItem] = useState(null);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const [ExamStartStatus, setExamStartStatus] = useState({
    started: false,
    showModal: false,
  });

  const queryParams = {
    studentId: Cookies.get("userId"),
    pageNum: page,
  };
  const paramsData = {
    queryParams,
  };

  const {
    data: StudentExamList,
    isLoading,
    isFetching,
    isError,
  } = useQuery(
    ["getStudentExamList", page],
    () => GETEXAMLISTSTUDENTAPI(paramsData),
    {
      keepPreviousData: false,
      // staleTime: Infinity,
    }
  );

  const attemptExamHandler = (item) => {
    console.log("exam ===", exam);
    let examType = JSON.parse(item?.examType);
    if (examType.includes("3") || examType.includes("4")) {
      setMediaItem(item);
      // const expires = Date.now() + 3 * 60 * 60 * 1000;
      // Cookies.set("sExamId", item?.examId, { expires });
      // Cookies.set("sScheduleId", item?.scheduleId, { expires });
      // setTimeout(()=> {navigate(`/device-test`)},200)
      if (examType.includes("4")) {
        // handleMicPermission();
        handleCameraPermission();
        // dispatch(examMediaType({...exam.VideoAudio,video:true, audio:true}))
      } else {
        handleCameraPermission();
        // dispatch(examMediaType({...exam.VideoAudio,audio:true}))
      }
    } else {
      dispatch(
        examAttemptUpdate({
          examId: item?.examId,
          scheduleId: item?.scheduleId,
        })
      );
      const expires = Date.now() + 3 * 60 * 60 * 1000;
      Cookies.set("sExamId", item?.examId, { expires });
      Cookies.set("sScheduleId", item?.scheduleId, { expires });
      // dispatch(examRefresh(true));
      setTimeout(() => {
        navigate(`/examination/${item?.examId}`);
      }, 200);
    }
  };

  const handleMicPermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      console.log("stream===", stream);
      // setMicStatus("on");
      // setEnableStartExam((prev) => {
      //   return {
      //     ...prev,
      //     mediaEnabled: true,
      //   };
      // });
      // Stop the stream to release the microphone when not in use
      stream.getTracks().forEach((track) => track.stop());
    } catch (error) {
      console.log("errorr===", error);
      // setMicStatus("off");

      // setEnableStartExam((prev) => {
      //   return {
      //     ...prev,
      //     mediaEnabled: false,
      //   };
      // });
    }
  };

  const handleCameraPermission = async () => {
    try {
      if (navigator.mediaDevices) {
        const devices = await navigator.mediaDevices.enumerateDevices();

        const hasWebcam = devices.some(
          (device) => device.kind === "videoinput"
        );
        const hasMicrophone = devices.some(
          (device) => device.kind === "audioinput"
        );
        console.log("handleCameraPermission", hasWebcam, hasMicrophone);

        if (hasWebcam && hasMicrophone) {
          try {
            const stream = await navigator.mediaDevices.getUserMedia({
              video: true,
              audio: true,
            });
            console.log("stream --- camera mic", stream);
            dispatch(examMediaType({ key: "video", value: true }));
            dispatch(examMediaType({ key: "audio", value: true }));
            stream.getTracks().forEach((track) => track.stop());
          } catch (error) {
            console.error("Error getting webcam and microphone stream:", error);
          }

          try {
            const stream = await navigator.mediaDevices.getUserMedia({
              video: true,
            });
            console.log("stream --- camera111", stream);
            dispatch(examMediaType({ key: "video", value: true }));
            stream.getTracks().forEach((track) => track.stop());
          } catch (error) {
            console.error("Error getting webcam stream:", error);
          }

          try {
            const stream = await navigator.mediaDevices.getUserMedia({
              audio: true,
            });
            console.log("stream --- audio", stream);
            dispatch(examMediaType({ key: "audio", value: true }));
            stream.getTracks().forEach((track) => track.stop());
          } catch (error) {
            console.error("Error getting audio stream:", error);
          }
        } else if (hasWebcam) {
          try {
            const stream = await navigator.mediaDevices.getUserMedia({
              video: true,
            });
            console.log("stream --- camera (no audio)", stream);
            dispatch(examMediaType({ key: "video", value: true }));
            stream.getTracks().forEach((track) => track.stop());
          } catch (error) {
            console.error("Error getting webcam stream:", error);
          }
        } else if (hasMicrophone) {
          try {
            const stream = await navigator.mediaDevices.getUserMedia({
              audio: true,
            });
            console.log("stream --- audio (no camera)", stream);
            dispatch(examMediaType({ key: "audio", value: true }));
            stream.getTracks().forEach((track) => track.stop());
          } catch (error) {
            console.error("Error getting microphone stream:", error);
          }
        } else {
          console.warn("No webcam or microphone found.");
          dispatch(examMediaType({ key: "video", value: false }));
          dispatch(examMediaType({ key: "audio", value: false }));
        }
      } else {
        dispatch(examMediaType({ key: "video", value: false }));
        dispatch(examMediaType({ key: "audio", value: false }));
        console.warn("MediaDevices API not supported.");
      }

      setTimeout(() => {
        // console.log("set timeout ==", exam.VideoAudio);
        // let examE = returnNewExam();
        // if (exam.VideoAudio.audio && exam.VideoAudio.video) {
        //   console.log("videoaudiosdsdf==2", exam.VideoAudio.audio && exam.VideoAudio.video);
        // } else if (exam.VideoAudio.audio) {
        //   console.log("videoaudiosdsdf==", exam.VideoAudio.audio);
        // } else {
        //   // Handle other cases
        // }
        setExamStartStatus((prev) => {
          return {
            ...prev,
            started: true,
          };
        });
      }, 1000);
    } catch (error) {
      dispatch(
        examMediaType({ ...exam.VideoAudio, video: false, audio: false })
      );
      console.error("Error in handleCameraPermission:", error);
      // Handle errors or set camera status accordingly
      // setCameraStatus("off");
    }
  };

  useEffect(() => {
    if (ExamStartStatus.started) {
      if (exam?.VideoAudio.video && exam?.VideoAudio.audio) {
        console.log("222222");
        const expires = Date.now() + 3 * 60 * 60 * 1000;
        Cookies.set("sExamId", mediaItem?.examId, { expires });
        Cookies.set("sScheduleId", mediaItem?.scheduleId, { expires });
        setTimeout(() => {
          navigate(`/examination/${mediaItem?.examId}`);
        }, 600);
      } else if (exam?.VideoAudio.audio) {
        console.log("11111");
        setExamStartStatus((prev) => {
          return {
            ...prev,
            showModal: true,
            started: false,
          };
        });
      } else if (exam?.VideoAudio.video) {
        console.log("1111100000");
        const expires = Date.now() + 3 * 60 * 60 * 1000;
        Cookies.set("sExamId", mediaItem?.examId, { expires });
        Cookies.set("sScheduleId", mediaItem?.scheduleId, { expires });
        setTimeout(() => {
          navigate(`/examination/${mediaItem?.examId}`);
        }, 600);
      } else {
        console.log("1111100000---------------");
        setExamStartStatus((prev) => {
          return {
            ...prev,
            showModal: true,
            started: false,
          };
        });
      }
    }
  }, [ExamStartStatus]);

  useEffect(() => {
    if (exam?.SwitchtoExam) {
      window.location.reload();
    }
  }, []);

  const modalCleanup = () => {
    console.log("modalCleanup");
    setExamStartStatus((prev) => {
      return {
        ...prev,
        showModal: false,
        started: false,
      };
    });
  };
  console.log("exam status ==", exam.SwitchtoExam)
  console.log("examexamexamexam", exam?.VideoAudio, ExamStartStatus, isLoading, isFetching, exam.SwitchtoExam == false);
  return (
    // <div className="mt-20 overflow-auto lg:px-8  ">
    <>
      <div className="w-full ">
        <div className="p-8">
          <div className="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6 min-h-[60vh]">
            {isLoading || isFetching ? (
              <div className="w-full flex justify-center items-center min-h-[60vh]">
                <LoaderSpiner />
              </div>
            ) : (
              exam.SwitchtoExam == false && (
                <>
                  <TableContainer>
                    <Table style={{ overflow: "auto" }} aria-label="simple table">
                      <TableHeading tableHeading={TestTableHeading} />
                      <TableBody>
                        {StudentExamList?.data?.examList?.map((item, index) => (
                          <TableRow
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell>{index + 1}</TableCell>
                            <TableCell align="left">{item.examName}</TableCell>
                            <TableCell align="left">
                              {item.instituteName}
                            </TableCell>
                            <TableCell align="left">
                              <ExamType item={item.examType} />
                            </TableCell>
                            {/* <TableCell align="left">{"item.country"}</TableCell> */}
                            <TableCell align="left">
                              {item.examScheduleTime.slice(0, 5)}
                            </TableCell>
                            <TableCell align="left">
                              <Button
                                variant="contained"
                                onClick={() => attemptExamHandler(item)}
                              >
                                Take Exam
                              </Button>
                              {/* <Button
                              variant="contained"
                              onClick={(event) => {
                                (function disableButtonForAMinute() {
                                  attemptExamHandler(item);
                                  event.currentTarget.disabled = true;
                                  setTimeout(
                                    () =>
                                    (event.currentTarget.disabled = false),
                                    6000
                                    ); // 60 seconds
                                  })();
                                }}
                                >
                                Take Exam
                              </Button> */}
                              {/* <LinkButton btnTitle="Take Exam" linkUrl={'/present-exam'} type="button" /> */}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {StudentExamList?.data?.examList !== 0 ? <CustomPagination data={StudentExamList?.data?.examList} page={page} setPage={setPage} paginationData={StudentExamList?.data?.pagination} /> : null}
                </>
              )
            )}
          </div>
        </div>
        {ExamStartStatus?.showModal && (
          <DeviceHint
            show={ExamStartStatus?.showModal}
            modalCleanup={modalCleanup}
          />
        )}
      </div>
    </>
  );
};

let examType = [
  {
    id: "1",
    Name: "Reading Exam",
  },
  {
    id: "2",
    Name: "Writing Exam",
  },
  {
    id: "3",
    Name: "Listening Exam",
  },
  {
    id: "4",
    Name: "Speaking Exam",
  },
  {
    id: "11",
    Name: "Academic Reading",
  },
  {
    id: "12",
    Name: "General Reading",
  }
];

export const ExamType = ({ item }) => {
  const [Item, setItem] = useState(JSON.parse(item));
  return (
    <div className="flex flex-col">
      {Item.map((id) => {
        return <span>{examType.find((type) => type?.id === id)?.Name}</span>;
      })}
    </div>
  );
};
