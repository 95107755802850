import { FaBars } from "react-icons/fa";
import { TfiClose } from "react-icons/tfi";
// import logo from "../../assets/img/image 1.png"
import GridViewIcon from "@mui/icons-material/GridView";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import toast from 'react-simple-toasts';
import PolicyIcon from "@mui/icons-material/Policy";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";

import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import QuizIcon from "@mui/icons-material/Quiz";
import DrawIcon from "@mui/icons-material/Draw";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import LogoutSharpIcon from "@mui/icons-material/LogoutSharp";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import CookieStorage from "../../services/cookieStorage";
import ieltsLogo from '../../assets/img/ilteslogo.png'
import FeaturedPlayListIcon from "@mui/icons-material/FeaturedPlayList";

import NoteAltIcon from "@mui/icons-material/NoteAlt";
const StudentSection = () => {
    const [mobileMenu, setMobildMenu] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const cookie = new CookieStorage();

    


    const clickLink = (pathname) => {
        navigate(pathname);
        
    };

    const checkHyperLink = (pathname) => {
        if (typeof pathname == "string") {
            return location.pathname === pathname ? "bg-gray-200 " : "hover:hover:bg-gray-200";
        } else {
            if (pathname[1].split("/")[1] === location.pathname.split("/")[1]) {
                return "bg-gray-200";
            }
            for (let i = 0; i < pathname.length; i++) {
                if (location.pathname === pathname[i]) {
                    return "bg-gray-200";
                }
            }
            return "hover:hover:bg-gray-200";
        }
    };

    useEffect(() => {
        setMobildMenu(false);
    }, [location.pathname]);

    return (
        <>
            <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-20 py-4 px-6">
                <div
                    style={{ transition: "all 1.5s" }}
                    className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto"
                >
                    <button
                        className="bg-white m-2  cursor-pointer text-black opacity-50 md:hidden px-3 py-2 text-xl leading-none bg-transparent rounded border border-solid border-transparent border-[#e4e4e7]"
                        type="button"
                        onClick={() => setMobildMenu(!mobileMenu)}
                    >
                        {!mobileMenu ? <FaBars /> : <TfiClose />}
                    </button>
                    <div className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-md uppercase font-bold md:p-4 px-0 py-0 cursor-pointer">
                        {/* <h1 className="text-dark font-bold text-5xl text-center hover:text-purple-800 transition-all duration-700 drop-shadow-lg shadow-black">IELTS</h1> */}
                        <img src={ieltsLogo} alt="ieltslogo" className="w-[120px] " />
                        {/* <div className="w-[35%] md:w-[80%] mb-2 mx-auto float-right md:float-none">
                            <img
                                src="https://rosmertatech.com/images/logo.svg"
                                className=""
                                alt="navbarlogo"
                                onClick={() => {
                                    clickLink("/");
                                }}
                            />
                        </div> */}
                    </div>
                    {mobileMenu && (
                        <div className="top-0; h-auto w-full md:hidden">
                            <ul className="flex flex-col p-4 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
                                <li className="items-center ">
                                    <div
                                        className={`pl-2 text-sm uppercase py-3 font-medium cursor-pointer  pl-4 ${checkHyperLink("/listening-exam")}`}
                                        onClick={() => {
                                            clickLink("/listening-exam");
                                        }}
                                    >
                                        <div className="float-right mr-7 capitalize">Time</div><br />
                                        <div className="flex items-center"><HeadsetMicIcon fontSize="small" className="mr-1" /> <span className="ml-1   ">
                                            Section C
                                            {/* <div className="pt-4">Section A</div> */}
                                        </span>

                                        </div>
                                    </div>
                                    {/* <div
                                        className={`text-xs uppercase py-3 font-bold cursor-pointer flex items-center justify-center hover:bg-gray-100 ${checkHyperLink("/home")}`}
                                        onClick={() => {
                                            clickLink("/home");
                                        }}
                                    >
                                        <GridViewIcon className="mr-1" /> Home
                                    </div> */}
                                </li>
                                <li className="items-center">
                                    <div
                                        className={`text-xs uppercase py-3 font-bold cursor-pointer flex items-center justify-center hover:bg-gray-100 ${checkHyperLink([
                                            "/caretaker",
                                            "/caretaker/new",
                                        ])}`}
                                        onClick={() => {
                                            clickLink("/bookmark");
                                        }}
                                    >
                                        <PlaylistAddCheckIcon className="mr-1" /> All Users
                                    </div>
                                </li>
                                <li className="items-center">
                                    <div
                                        className={`text-xs uppercase py-3 font-bold cursor-pointer flex items-center justify-center hover:bg-gray-100 ${checkHyperLink([
                                            "/circular",
                                            "/circular/add",
                                        ])}`}
                                        onClick={() => {
                                            clickLink("/clients");
                                        }}
                                    >
                                        <FeaturedPlayListIcon className="mr-1" /> ClientList
                                    </div>
                                </li>

                                <li className="items-center">
                                    <div
                                        className={`text-xs uppercase py-3 font-bold cursor-pointer flex items-center justify-center hover:bg-gray-100 ${checkHyperLink([
                                            "/circular",
                                            "/circular/add",
                                        ])}`}
                                        onClick={() => {
                                            clickLink("/queries");
                                        }}
                                    >
                                        <FeaturedPlayListIcon className="mr-1" /> Queries
                                    </div>
                                </li>
                                <li className="items-center">
                                    <div
                                        className={`text-xs uppercase py-3 font-bold cursor-pointer flex items-center justify-center hover:bg-gray-100 ${checkHyperLink([
                                            "/circular",
                                            "/circular/add",
                                        ])}`}
                                        onClick={() => {
                                            clickLink("/upload");
                                        }}
                                    >
                                        <FeaturedPlayListIcon className="mr-1" /> Upload  Data
                                    </div>
                                </li>


                                <li className="items-center">
                                    <div
                                        className={`text-xs uppercase py-3 font-bold cursor-pointer flex items-center justify-center hover:bg-gray-100 ${checkHyperLink("/logout")}`}
                                        onClick={() => {
                                            cookie.logout();
                                            toast.success("Logged Out Successfully");
                                            navigate("/login");
                                            window.location.reload();
                                        }}
                                    >
                                        <LogoutSharpIcon className="mr-1" /> Logout
                                    </div>
                                </li>
                            </ul>
                        </div>
                    )}

                    <div className="md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded hidden">
                        <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200">
                            <div className="flex flex-wrap">
                                <div className="w-6/12">
                                    <div className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"> GST Sahayak </div>
                                </div>
                                <div className="w-6/12 flex justify-end">
                                    <button
                                        type="button"
                                        className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                                    >
                                        button
                                    </button>
                                </div>
                            </div>
                        </div>

                        <ul className="md:flex-col md:min-w-full flex flex-col list-none">
                            <li className="items-center ">
                                <div
                                    className={`pl-2 text-sm uppercase py-3 font-medium cursor-pointer  pl-4 ${checkHyperLink("/speaking-exam")}`}
                                    onClick={() => {
                                        clickLink("/speaking-exam");
                                    }}
                                >
                                    <div className="float-right mr-7 capitalize">Time</div><br />
                                    <div className="flex items-center"><RecordVoiceOverIcon fontSize="small" className="mr-1" /><span className="ml-1   ">
                                        Section A
                                        {/* <div className="pt-4">Section A</div> */}
                                    </span>

                                    </div>
                                </div>
                                {/* <div
                                    className={`pl-2 text-sm uppercase py-3 font-medium cursor-pointer flex items-center ${checkHyperLink("/home")}`}
                                    onClick={() => {
                                        clickLink("/home");
                                    }}
                                >
                                    <GridViewIcon fontSize="small" className="mr-1" /> <span className="ml-1 ">Section A</span>
                                </div> */}
                            </li>
                            <li className="items-center">
                                <div
                                    className={`pl-2 text-sm uppercase py-3 font-medium cursor-pointer  pl-4 ${checkHyperLink("/reading-exam")}`}
                                    onClick={() => {
                                        clickLink("/reading-exam");
                                    }}
                                >
                                    <div className="float-right mr-7 capitalize">Time</div><br />
                                    <div className="flex items-center"><RecordVoiceOverIcon fontSize="small" className="mr-1" /><span className="ml-1   ">
                                        Section B
                                        {/* <div className="pt-4">Section A</div> */}
                                    </span>

                                    </div>
                                </div>
                                {/* <div
                                    className={`pl-2 text-sm uppercase py-3 font-medium cursor-pointer flex items-center ${checkHyperLink("/bookmark")}`}
                                    onClick={() => {
                                        clickLink("/users");
                                    }}
                                >
                                    <PlaylistAddCheckIcon fontSize="small" className="mr-1" /> <span className="ml-1">Section B</span>
                                </div> */}
                            </li>
                            <li className="items-center">
                                <div
                                    className={`pl-2 text-sm uppercase py-3 font-medium cursor-pointer  pl-4 ${checkHyperLink("/writing-exam")}`}
                                    onClick={() => {
                                        clickLink("/writing-exam");
                                    }}
                                >
                                    <div className="float-right mr-7 capitalize">Time</div><br />
                                    <div className="flex items-center"><RecordVoiceOverIcon fontSize="small" className="mr-1" /><span className="ml-1   ">
                                        Section C
                                        {/* <div className="pt-4">Section A</div> */}
                                    </span>

                                    </div>
                                </div>


                                <div
                                    className={`pl-2 text-sm uppercase py-3 font-medium cursor-pointer  pl-4 ${checkHyperLink("/testing")}`}
                                    onClick={() => {
                                        clickLink("/testing");
                                    }}
                                >
                                    <div className="float-right mr-7 capitalize">Time</div><br />
                                    <div className="flex items-center"><RecordVoiceOverIcon fontSize="small" className="mr-1" /><span className="ml-1   ">
                                        Section D
                                        {/* <div className="pt-4">Section A</div> */}
                                    </span>

                                    </div>
                                </div>
                                {/* <div
                                    className={`pl-2 text-sm uppercase py-3 font-medium cursor-pointer flex items-center ${checkHyperLink("/contact-us")}`}
                                    onClick={() => {
                                        clickLink("/clients");
                                    }}
                                >
                                    <FeaturedPlayListIcon fontSize="small" className="mr-1" /> <span className="ml-1">Section C </span>
                                </div> */}
                            </li>

                            <li className="items-center">
                                {/* <div
                                    className={`pl-2 text-sm uppercase py-3 font-medium cursor-pointer flex items-center ${checkHyperLink("/contact-us")}`}
                                    onClick={() => {
                                        clickLink("/queries");
                                    }}
                                >
                                    <FeaturedPlayListIcon fontSize="small" className="mr-1" /> <span className="ml-1">Section D </span>
                                </div> */}
                            </li>
                            <li className="items-center">
                                <div
                                    className={`pl-2 text-sm uppercase py-3 font-medium cursor-pointer flex items-center ${checkHyperLink("/contact-us")}`}
                                    onClick={() => {
                                        clickLink("/upload");
                                    }}
                                >
                                    <FeaturedPlayListIcon fontSize="small" className="mr-1" /> <span className="ml-1">Upload Data</span>
                                </div>
                            </li>
                            {/* <li className="items-center">
                                <div
                                    className={`pl-2 text-sm uppercase py-3 font-medium cursor-pointer flex items-center ${checkHyperLink("/termsandcondition")}`}
                                    onClick={() => {
                                        clickLink("/termsandcondition");
                                    }}
                                >
                                    <DirectionsWalkIcon fontSize="small" className="mr-1" /> <span className="ml-1">Terms and Conditions</span>
                                </div>
                            </li> */}

                            <li className="items-center">
                                <div
                                    className={`pl-2 text-sm uppercase py-3 font-medium cursor-pointer flex items-center ${checkHyperLink("/logout")}`}
                                    onClick={() => {
                                        cookie.logout();
                                        navigate("/");
                                        toast("Logged Out Successfully", {
                                            theme: 'dark',
                                            position: 'top-center'
                                        })
                                    }}
                                >
                                    <LogoutSharpIcon fontSize="small" className="mr-1" /> <span className="ml-1">Log Out</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    );
};

export default StudentSection;