import { createSlice } from "@reduxjs/toolkit";

const submitExamStatusInit = {
  userStatus: "pending",
  timerStatus: "running",
  moduleTimerEnd: false,
  startNewModule: false,
  startModuleExam: false,
  lastModule: false,
}

const examinationSubmitStatus = {
  id: "",
  // submittedStatus: false,
}

const initialState = {
  mediaUploading: false,
  examStatus: {
    submittedStatus: false,
  },
  questions: [],
  submitExamStatus: submitExamStatusInit,
  mediaQuestion:{
    selectedQuestion:{}
  },
  examinationSubmitStatus: examinationSubmitStatus,
  //   examAttempt:{},
  //   examDetails:{},
  //   questionSelectedId:"",
  //   SwitchtoExam:false,
  //   VideoAudio:{
  //     audio:false,
  //     video:false,
  //     examVideo:false,
  //     audio:false,
  //   },
  //   RecorderStatus: {
  //     audio:true,
  //     video:true,
  //   }
};

export const examinationSlice = createSlice({
  name: "examination",
  initialState,
  reducers: {
    videoRecorderExam: (state, action) => {
      return {
        ...state,
        RecorderStatus: {
          ...state.RecorderStatus,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    mediaUploadingToggle: (state, action) => {
      return {
        ...state,
        mediaUploading: action.payload
      };
    },
    moduleExamEnd: (state, action) => {
      console.log("moduleExamEndmoduleExamEnd", action)
      return {
        ...state,
        submitExamStatus: {
          ...state.submitExamStatus,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    examStatusSubmitted: (state, action) => {
      return {
        ...state,
        examStatus: {submittedStatus: true},
      }
    },
    submitExamStatusReset: (state, action) => {
      return {
        ...state,
        submitExamStatus: {...submitExamStatusInit, startNewModule: true},
        ExamSubmitHandler:[],
        examStatus: {
          submittedStatus: false,
        },
        mediaQuestion:{
          selectedQuestion:{}
        },
      };
    },
    mediaQuestionSelected: (state, action) => {
      console.log("moduleExamEndmoduleExamEnd", action)
      return {
        ...state,
        mediaQuestion: {
          ...state.mediaQuestion,
          selectedQuestion: action.payload
        }
      };
    },
    // examMediaType: (state, action) => {
    //   return {...state, VideoAudio:{...state.VideoAudio, [action.payload.key]: action.payload.value}}
    // },
    // examRefresh: (state, action) => {
    //   return {...state, SwitchtoExam: action.payload}
    // },
    // examAttemptUpdate: (state, action) => {
    //   return { ...state, examAttempt: action.payload }
    // },
    // examUpdate: (state, action) => {
    //   return { ...state, examDetails: action.payload }
    // },
    // examSelectedId: (state, action) =>{
    //   return { ...state, questionSelectedId:action.payload}
    // },
    // examRemove: (state, action) => {
    //   return { ...initialState, examAttempt:{} }
    // },
    examinationUpdateQuestion: (state, action) => {
      return { ...state, questions: action.payload };
    },
    examinationUpdateById: (state, action) => {
      const { id, answer } = action.payload; // Destructure the payload to get the ID and data
      const selectedQuestion = state.questions.find(question => question.id === id); // Find the question object with the specified ID

      if (selectedQuestion) {
        const updatedQuestion = { ...selectedQuestion, answer, attempt:true }; // Create a new copy of the question object with the updated answer
        const updatedQuestions = state.questions.map(question => {
          if (question.id === id) {
            return updatedQuestion; // Replace the old question object with the updated one
          } else {
            return question; // Leave other questions unchanged
          }
        });
        return { ...state, questions: updatedQuestions }; // Return the updated state with the modified questions array
      } else {
        console.error(`Question with ID ${id} not found`); // Handle the case where the question is not found
        return state; // Return the original state if the question is not found
      }
    },
    examinationSubmittedStatus: (state, action) => {
      return {
        ...state,
        examinationSubmitStatus: {
          ...state.examinationSubmitStatus,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    // examUpdateById: (state, action) => {
    //   // const {id, answer} = action.payload;
    //   // const selectedQuestion = state.questions.find((question)=>question.id == id)
    //   // let filterState = state.questions;
    //   // console.log("payload ---skdjflskfdj", selectedQuestion)
    //   // return {...state,  questions:action.payload}
    //   const { id, answer } = action.payload; // Destructure the payload to get the ID and data
    //   const selectedQuestion = state.questions.find(question => question.id === id); // Find the question object with the specified ID
    //   if (selectedQuestion) {
    //     const updatedQuestions = state.questions.map(question => {
    //       if (question.id === id) {
    //         return { ...question, answer }; // Update the question object with the new data
    //       } else {
    //         return question; // Leave other questions unchanged
    //       }
    //     });
    //     return { ...state, questions: updatedQuestions }; // Return the updated state with the modified questions array
    //   } else {
    //     console.error(`Question with ID ${id} not found`); // Handle the case where the question is not found
    //     return state; // Return the original state if the question is not found
    //   }
    // },
  },
});

export const { videoRecorderExam, examinationUpdateQuestion, examinationUpdateById, submitExamStatusReset, moduleExamEnd, examStatusSubmitted, mediaQuestionSelected, mediaUploadingToggle, examinationSubmittedStatus } =
  examinationSlice.actions;

export default examinationSlice.reducer;
