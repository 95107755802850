// MainComponent.js
import React, { useState } from "react";
import withPagination from "./withPagination";
import { Table, TableBody, TableRow, TableContainer, TableCell, MenuItem, Menu, CircularProgress } from "@mui/material";
import { ScheduleExamListHeading } from "../../../mockData";
import { CustomPagination, TableHeading } from "../../../components";
import { SCHEDULELISTAPI } from "../../../services/helpers/ScheduleExam";
import Cookies from "js-cookie";
import { useNavigate } from 'react-router-dom'
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { CustomMenu, listRef } from '../ScheduleStudent/ScheduleStudentTable'
import DataNotFoundImg from "../../../assets/no_data_found.png"
import { useEffect } from "react";

const fetchData = async (page) => {
  const response = await fetch(`http://eacademyeducation.com:8099/v1/exam/schedule-exam-list?instituteId=7aac9699-d843-407b-9cce-57161eba2217&pageNum=${page}`);
  return response.json();
};

const queryParams = {
  instituteId: Cookies.get("userId"),

};
const paramsData = {

  queryParams,
};

const MainComponent = ({ data, isLoading, hasNextPage, page, setPage, handlePrevClick, handleNextClick }) => {
  const navigate = useNavigate();
  console.log("examScheduleData",data);
  const [serial,setSerial] = useState();
 
  useEffect(()=>{
    queryParams.pageNum = page
  },[page])


  const getStartingSerialNumber = () => {
    return setSerial((page - 1) * data?.data?.pagination?.numberPerPage + 1);
  };

  useEffect(() => {
    getStartingSerialNumber();
  }, [data]);
  
  if (isLoading) {
    return (<div className="flex justify-center min-h-[50vh] items-center w-full">
      <CircularProgress className="mt-12" />
    </div>)
  }

  return (
    <>
    {data?.data?.examList?.length !==0?   <TableContainer>
      <Table aria-label="simple table">
        <TableHeading tableHeading={ScheduleExamListHeading} />
        <TableBody>
          {data?.data?.examList?.length > 0 &&
            data?.data?.examList.map((exam, index) => {
              return (
                <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell>{serial + index}</TableCell>
                  <TableCell>{exam?.description}</TableCell>
                  <TableCell>{exam?.examName}</TableCell>
                  <TableCell>{exam?.examScheduleDate}</TableCell>
                  <TableCell>{exam?.examScheduleTime}</TableCell>
                  {/* <TableCell>{exam?.isActive ? "Active" : "Inactive"}</TableCell> */}
                  <TableCell>
                    <CustomMenu menus={listRef(<MoreVertIcon />, [["View", () => { navigate(`/examschedule/${exam?.id}`) }, <></>]])} />
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <div>
      {data?.data?.examList?.length!==0?<CustomPagination data={data.data} page={page} setPage={setPage} paginationData={data?.data?.pagination}  />:null}
    </div>
    </TableContainer>:<div className="text-center h-1/12 w-1/2  mx-auto"><h3>No Exam Schedule Found</h3><img className="w-[300px] mx-auto" src={DataNotFoundImg}/></div>}
    </>
    
  );
 
};

const MainComponentWithPagination = withPagination(MainComponent, fetchData, paramsData, SCHEDULELISTAPI);

export default MainComponentWithPagination;
