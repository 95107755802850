import { styled } from "@mui/material/styles";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import ProgessBar from "./ProgressBar";

const DashboardCard = ({ propsValue, color, status, mainText  }) => {
  const { id, counts, barvalue, title, icons, description } = propsValue;
  return (
    <div style={{ backgroundColor: `${color}` }} className={`flex justify-between items-center rounded-md px-3 gap-3 w-full py-4 bg-[${color}]`}>
      <div className="bg-[#F5F7FB] w-10 h-10 flex justify-center items-center rounded-full text-[#464a53]">{icons}</div>
      <div className="flex justify-center flex-col flex-1">
        <p className="text-start text-base text-white uppercase font-medium">{title}</p>
        <p className="text-start text-2xl text-white uppercase font-medium">{mainText}</p>
        {/* <p className="text-start text-sm text-white font-normal">{description}</p> */}
      </div>
    </div>
  );
};

export default DashboardCard;
