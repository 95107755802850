import { useState } from "react";
import { useQuery } from "react-query";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import { useParams } from "react-router-dom";
import StudentExamList from "./StudentExamList";
import LoaderSpiner from "../common/LoaderSpiner";
import PhoneIcon from "@mui/icons-material/Phone";
import StudentDetailsTable from "./StudentDetailsTable";
import MarkunreadIcon from "@mui/icons-material/Markunread";
import SchoolIcon from '@mui/icons-material/School';
import { STUDENTDETAILSAPI } from "../../services/helpers/Users";
import { useNavigate } from "react-router-dom";

const StudentDetail = () => {
  const { id } = useParams();
  const [studentDetails, setStudentDetails] = useState(null);
  const navigate = useNavigate();

  useQuery(
    ["STUDENTDETAILSAPI"],
    () => {
      const queryParams = {
        userId: id,
      };
      const paramsData = {
        queryParams,
      };
      return STUDENTDETAILSAPI(paramsData);
    },
    {
      retry: false,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: 0,
      mutationKey: "STUDENTDETAILSAPI",
      enabled: true,
      onSuccess: (res) => {
        if (res?.status === true) {
          setStudentDetails(res.data);
        } else {
        }
      },
    }
  );

  return (
    <div className="w-full">
      <h1 className="text-2xl text-center font-semibold pb-4">Student Details</h1>

      <hr />

      {studentDetails ? (
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-8">
          <div className="bg-blue-50 shadow-sm rounded">
            <div className="bg-gray-500 rounded-t px-4 py-2 text-white gap-2 flex">
              <h2>ID:</h2>
              {studentDetails.userId}
            </div>
            <div className="p-10 rounded-b">
              <div className="w-full flex justify-center items-center mb-4">
                {/* <Stack direction="row" spacing={2}>
                  <Avatar
                    alt="Remy Sharp"
                    src={studentDetails.profileImg}
                    sx={{ width: 100, height: 100, border: "3px solid #9E9E9E", boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)" }}
                  />
                </Stack> */}
              </div>
              <h2 className="text-center text-2xl md:text-4xl font-medium capitalize text-gray-700">
                {studentDetails.firstName} {studentDetails.lastName}
              </h2>
              <div className="flex flex-col justify-center items-center mt-5 gap-2">
                <div className="flex flex-wrap justify-center gap-1 text-gray-500 font-medium text-lg items-center">
                  <PhoneIcon className="text-gray-600" />
                  <p> {studentDetails?.phone}</p>
                </div>
                <div className="flex justify-center flex-wrap gap-1 text-gray-500 font-medium text-lg items-center">
                  <MarkunreadIcon className="text-gray-600" />
                  <p>{studentDetails?.email}</p>
                </div>
                <div className="flex justify-center flex-wrap gap-1 text-gray-500 font-medium text-lg items-center cursor-pointer">
                  <SchoolIcon className="text-gray-600" />
                  {(studentDetails?.instituteName && studentDetails?.instituteId)? <p onClick={()=>{
                    navigate("/institute/"+studentDetails?.instituteId);
                  }}>{studentDetails?.instituteName}</p> : <p>None</p>}
                </div>
              </div>
            </div>
          </div>
          <div className="shadow-sm rounded bg-teal-50 ">
            <strong>{<StudentDetailsTable data={studentDetails} />}</strong>
          </div>
        </div>
      ) : (
        <div className="w-full min-h-[50vh] flex justify-center items-center">
          <LoaderSpiner />
        </div>
      )}

      {/* <div className="mt-10">
        <StudentExamList />
      </div> */}
    </div>
  );
};

export default StudentDetail;
