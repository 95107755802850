import toast from "react-simple-toasts";
import { useEffect, useState } from "react";
import TaskIcon from "@mui/icons-material/Task";
import "react-simple-toasts/dist/theme/dark.css";
import { useNavigate, useLocation } from "react-router-dom";
import CookieStorage from "../../services/cookieStorage";
import LogoutSharpIcon from "@mui/icons-material/LogoutSharp";
import DescriptionIcon from "@mui/icons-material/Description";
import { useSelector } from 'react-redux'
import ieltsLogo from '../../assets/img/ilteslogo.png'
import { TfiClose } from "react-icons/tfi";
import { FaBars } from "react-icons/fa";
import Cookies from "js-cookie";

const ExamSideBar = ({ type, data,activeSection,setActiveSection, examStudentSidebar, stopExamModule }) => {
  const [mobileMenu, setMobildMenu] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState();
  const state = useSelector((state) => state.exam)

  useEffect(() => {
    if (type == "exam") {
      setTimeRemaining(examStudentSidebar?.sections[0]?.time);
    }
  }, []);

  useEffect(() => {
    if (type == "exam") {
      const interval = setInterval(() => {
        if (timeRemaining > 0) {
          setTimeRemaining((prevTime) => prevTime - 1);
        } else {
          // Move to the next section when the time is up
          if (activeSection < examStudentSidebar?.sections?.length - 1) {
            setActiveSection((prevSection) => prevSection + 1);
            setTimeRemaining(examStudentSidebar?.sections[activeSection + 1]?.time);
          } else {
            // End of exam, you can handle it as needed
            clearInterval(interval);
            stopExamModule()
          }
        }
      }, 1000);

      // Clean up the interval on component unmount
      return () => clearInterval(interval);
    }
  }, [activeSection, timeRemaining]);

  const navigate = useNavigate();
  const location = useLocation();
  const cookie = new CookieStorage();

  const clickLink = (pathname) => {
    navigate(pathname);
  };

  const checkHyperLink = (pathname) => {
    if (typeof pathname == "string") {
      return location.pathname === pathname ? "bg-blue-200 border-r-2 border-blue-600 transition-all ease-in delay-100	" : "hover:hover:bg-gray-200";
    } else {
      if (pathname[1].split("/")[1] === location.pathname.split("/")[1]) {
        return "bg-blue-200 border-r-2 border-blue-600";
      }
      for (let i = 0; i < pathname.length; i++) {
        if (location.pathname === pathname[i]) {
          return "bg-gray-200";
        }
      }
      return "hover:hover:bg-gray-200";
    }
  };

  return (
    <>
      <nav
        id="customScrollbar"
        className="md:left-0 md:block md:fixed md:top-0  md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-20 py-4 px-6"
      >
        <div
          style={{ transition: "all 1.5s" }}
          className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto"
        >
          <button
            className="bg-white m-2  cursor-pointer text-black opacity-50 md:hidden px-3 py-2 text-xl leading-none bg-transparent rounded border border-solid border-transparent border-[#e4e4e7]"
            type="button"
            onClick={() => setMobildMenu(!mobileMenu)}
          >
            {!mobileMenu ? <FaBars /> : <TfiClose />}
          </button>
          <div className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-md uppercase font-bold md:p-4 px-0 py-0 cursor-pointer">
            {/* <h1 className="text-dark font-bold text-5xl text-center hover:text-purple-800 transition-all duration-700 drop-shadow-lg shadow-black">IELTS</h1> */}
            <img src={ieltsLogo} alt="ieltslogo" className="w-[120px] " />
          </div>
          {mobileMenu && (
            <div className="top-0; h-auto w-full md:hidden">
              <ul className="flex flex-col p-4 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
                {Cookies.get("id") === "student" ? (
                  <li className="items-center ">
                    <div
                      className={`text-xs uppercase py-3 font-bold cursor-pointer flex items-center justify-center hover:bg-gray-100 ${checkHyperLink("/test")}`}
                      onClick={() => {
                        clickLink("/test");
                      }}
                    >
                      <TaskIcon className="mr-1" /> Exam
                    </div>
                  </li>
                ) : null}
                
                {type == "exam" && <>
              <h4 className="text-2xl text-center mb-4 border-b pb-2">{examStudentSidebar?.moduleName}</h4>
              {
                examStudentSidebar?.sections?.map((section, index) => (
                  <>
                    {Cookies.get("id") === "student" ? (
                      <li className="items-center " onClick={(e)=>{
                        e.preventDefault();
                        e.stopPropagation();
                      }}>
                        <div
                          className={`pl-2 text-sm uppercase py-3 font-medium  ${
                            index === activeSection && "bg-gray-200"
                          } relative`}
                          // onClick={() => {
                          //   index === activeSection || (index > activeSection && clickLink(section.link));
                          // }}
                        >
                          <div className="flex items-center justify-between">
                            <div className="flex flex-1 items-center text-center">
                              {section.icon} <span className="ml-1 items-center p-0">{section.set}</span>
                            </div>
                            <div
                              className={`ml-4 capitalize mr-2 ${index == activeSection && "text-Primary"} ${index > activeSection && "text-orange-500"} ${
                                index < activeSection && "text-green-500"
                              }`}
                            >
                              {index === activeSection && <>{Math.floor(timeRemaining / 60)+":"+(timeRemaining % 60)}</>} {index < activeSection && "done"} {index > activeSection && <>{Math.floor((section.time*1) / 60)+":"+((section.time*1) % 60)}</>}
                            </div>
                          </div>
                        </div>
                      </li>
                    ) : null}
                  </>
                ))
                            }
                </>
              }

                <li className="items-center">
                  <div
                    className={`text-xs uppercase py-3 font-bold cursor-pointer flex items-center justify-start hover:bg-gray-100 ${checkHyperLink("/logout")}`}
                    onClick={() => {
                      cookie.logout();
                      navigate("/login");
                      window.location.reload();
                    }}
                  >
                    <LogoutSharpIcon className="mr-1" /> Logout
                  </div>
                </li>
              </ul>
            </div>
          )}

          <div className="md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded hidden">
            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              {type == "exam" && <>
              <h4 className="text-2xl text-center mb-4 border-b pb-2">{examStudentSidebar?.moduleName}</h4>
              {
                examStudentSidebar?.sections?.map((section, index) => (
                  <>
                    {Cookies.get("id") === "student" ? (
                      <li className="items-center " onClick={(e)=>{
                        e.preventDefault();
                        e.stopPropagation();
                      }}>
                        <div
                          className={`pl-2 text-sm uppercase py-3 font-medium  ${
                            index === activeSection && "bg-gray-200"
                          } relative`}
                          // onClick={() => {
                          //   index === activeSection || (index > activeSection && clickLink(section.link));
                          // }}
                        >
                          <div className="flex items-center justify-between">
                            <div className="flex flex-1 items-center">
                              {section.icon} <span className="ml-1 items-center p-0">{section.set}</span>
                            </div>
                            <div
                              className={`ml-4 capitalize mr-2 ${index == activeSection && "text-Primary"} ${index > activeSection && "text-orange-500"} ${
                                index < activeSection && "text-green-500"
                              }`}
                            >
                              {index === activeSection && <>{Math.floor(timeRemaining / 60)+":"+(timeRemaining % 60)}</>} {index < activeSection && "done"} {index > activeSection && <>{Math.floor((section.time*1) / 60)+":"+((section.time*1) % 60)}</>}
                            </div>
                          </div>
                        </div>
                      </li>
                    ) : null}
                  </>
                ))
                            }
                </>
              }

              {type == "dashboard" && (
                <>
                  <li className="items-center">
                    <div
                      className={`pl-2 text-sm uppercase py-3 font-medium cursor-pointer flex items-center ${checkHyperLink("/logout")}`}
                      onClick={() => {
                        navigate("/test");
                      }}
                    >
                      <TaskIcon fontSize="small" className="mr-1" /> <span className="ml-1">Exam</span>
                    </div>
                  </li>
                  <li className="items-center">
                    <div
                      className={`pl-2 text-sm uppercase py-3 font-medium cursor-pointer flex items-center ${checkHyperLink("/logout")}`}
                      onClick={() => {
                        navigate("/results");
                      }}
                    >
                      <DescriptionIcon fontSize="small" className="mr-1" /> <span className="ml-1">Result</span>
                    </div>
                  </li>
                </>
              )}

              <li className="items-center">
                <div
                  className={`pl-2 text-sm uppercase py-3 font-medium cursor-pointer flex items-center ${checkHyperLink("/logout")}`}
                  onClick={() => {
                    cookie.logout();
                    navigate("/login");
                    toast("Logged out Successfully", {
                      theme: "dark",
                      position: "top-center",
                    });
                    window.location.reload();
                  }}
                >
                  <LogoutSharpIcon fontSize="small" className="mr-1" /> <span className="ml-1">Logout</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default ExamSideBar;
