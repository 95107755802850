// src/components/RichTextEditor.js
import React, { useState, useRef, useEffect, useMemo } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ImageUploader from 'quill-image-uploader';
import axios from 'axios';
import { CircularProgress } from '@mui/material';
import { BASE_URL } from '../../services/utils/defines';

// Register the ImageUploader module with Quill
const Quill = ReactQuill.Quill;
Quill.register('modules/imageUploader', ImageUploader);

const QuillEditor = ({setContentData, newValue='',formik,index,initialValue,setLoading,loading,}) => {
  const quillRef = useRef(null);
  console.log(initialValue)
  const [content, setContent] = useState(initialValue);

 
  console.log(content)

  useEffect(() => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();

      quill.getModule('toolbar').addHandler('image', () => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();

        input.onchange = async () => {
          const file = input.files[0];

          try {
            const formData = new FormData();
            formData.append('file', file);
            setLoading(true);
            const response = await fetch(`${BASE_URL}users/upload-icon`, {
              method: 'POST',  
              
              body: formData,  
            })
              .then(response => response.json());
              if(response.status){
                setLoading(false);
              }
              
            const imageUrl = response?.data?.path
            const cursorPosition = quill.getSelection(true).index;
          quill.clipboard.dangerouslyPasteHTML(cursorPosition, `<img src="${imageUrl}"/>`)
            // quill.clipboard.dangerouslyPasteHTML(cursorPosition, `<img src="${imageUrl}"/>`);
          } catch (error) {
            console.error('Error uploading image:', error.message);
          }
        };
      });
    }
  }, []);

  const handleContentChange = (value) => {
    setContent(value);
    setContentData(value);
    formik.setFieldValue(index,value);
    
    

  };
  const modules = useMemo(() => ({
    toolbar: {
      container: [
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ['link', 'image'],
        ['clean'],
      ],
    },
    imageUploader: {
      upload: (file) => {
        // The upload function should return a Promise
        return new Promise(async (resolve, reject) => {
          try {
            // Upload the image and get the URL
            const formData = new FormData();
            formData.append('image', file);

            const response = await axios.post('YOUR_IMAGE_UPLOAD_ENDPOINT', formData);

            // Get the URL of the uploaded image
            const imageUrl = response.data.imageUrl;

            // Resolve the promise with the image URL
            resolve(imageUrl);
          } catch (error) {
            // Reject the promise with an error
            reject(error.message);
          }
        });
      },
    },
  }), []);  

  const formats = [
    'header', 'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'link', 'image',
  ];

  const quillContainerStyle = {
    // maxHeight: '400px', // Set your desired max height here
    // overflow: 'auto',
  };

  return (
    <div className='w-full' style={quillContainerStyle}>
      <ReactQuill
      style={{height:"250px",marginBottom:"45px"}}
        ref={quillRef}
        value={content}
        onChange={handleContentChange}
        modules={modules}
        formats={formats}
        placeholder="Add Question"
      />
    </div>
  );
};

export default QuillEditor;