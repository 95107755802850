import { useEffect, useState } from "react";
import "react-simple-toasts/dist/theme/dark.css";
import MockUsers from "./dummydata";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { TextField, Button, InputAdornment, IconButton, CircularProgress } from "@mui/material";
import CookieStorage from "../../services/cookieStorage";
import toast from "react-simple-toasts";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { LoginAPI } from "../../services/helpers/Users";
import { initialValuesLogin as initialValues, validationSchemaLogin as validationSchema } from "../../services/validate/Users";
import { useDispatch } from "react-redux";
import { profileUpdate } from "../../store/profile";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Logindata } from "../../store/userData";
import Cookies from "js-cookie";
import logo from "../../assets/img/logo.jpg";

const Login = () => {
  const loginData = useSelector((state) => state?.caretaker?.login);
  console.log("loginData", loginData);
  console.log(MockUsers);
  const cookie = new CookieStorage();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const { mutate, isLoading } = useMutation(LoginAPI, {
    mutationKey: "login",
    onSuccess: (res) => {
      console.log("skjdflkj", res.data)
      if (res?.status === false) {
        setLoading(false);
        toast(res.message, {
          theme: "dark",
          position: "top-center",
        });
      } else if (res?.status === true) {
        // dispatch(profileUpdate(res.data));
        // Cookies.set("email", username);
        // Cookies.set("role", user.role);
        cookie.setLogin(res.data.tokens.access.token, res.data.user.role, res.data.user.id, res.data.user.instituteId);
        // navigate("/");
        setLoading(false);
        toast(res.message, {
          theme: "dark",
          position: "top-center",
        });
        window.location.reload();
      }
    },
    onError: () => {},
    onSettled: () => {},
  });
  const handleLogin = () => {
    // Find the user with the provided username
    const user = MockUsers.find((u) => u.username === username);

    if (!user) {
      toast("User not found", {
        theme: "dark",
        position: "top-center",
      });
    } else if (user.password !== password) {
      toast("Incorrect Password", {
        theme: "dark",
        position: "top-center",
      });
    } else {
      // Successful login - Redirect or set user role in state
      console.log({
        email: username,
        password: password,
        role: user.role,
      });

      dispatch(
        Logindata({
          role: user.role,
        })
      );
      cookie.setLogin("dskadksadjskjdksaklakld");
      if (user.role === "student") {
        navigate("/test");
      } else {
        navigate("/dashboard");
      }

      toast("Logged in Successfully", {
        theme: "dark",
        position: "top-center",
      });
      //  window.location.reload();
    }
  };

  // useEffect(() => {
  //   console.log("useffect", loginData);
  // }, [loginData]);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      console.log(values);
      mutate(values);
      setLoading(true);
    },
  });

  return (
    <div className="h-[100vh] flex justify-center items-center">
      {/* <img className="fix -z-40 " src={photo} alt="BGImage" /> */}
      <div className="w-11/12 md:w-8/12 lg:w-4/12 h-[88vh] m-auto shadow bg-white">
        <div style={{ backgroundColor: "#6673FD" }} className=" h-1/4 md:h-1/6 lg:h-1/4 ">
          <div className="flex justify-center pt-3 md:pt-4 lg:pt-10 ">
            <div className="bg-white w-[80px] h-[80px] rounded-full flex justify-center items-center">
              {/* <img src="https://iyaantechnologies.com/assets/images/white-logo.jpg" alt="LogoImage" className="w-full h-auto rounded-full" /> */}
              <img src={logo} alt="LogoImage" className="w-full h-auto rounded-full" />
            </div>

            {/* <img className=" w-3/12 lg:w-2/12   rounded-full" src="https://iyaantechnologies.com/assets/images/white-logo.jpg" /> */}
          </div>
        </div>

        <form onSubmit={formik.handleSubmit}>
          <div style={{ marginTop: "-27px" }} className="px-4 loginshadow w-4/5 m-auto bg-white">
            <h1 className="text-2xl pt-5 pb-3 font-medium text-center">LOGIN</h1>
            <hr className="w-10/12 m-auto mt-1" />
            <div className="w-full text-center">
              <TextField
                className="w-11/12 m-auto !mt-[2.25rem]"
                id="email"
                name="email"
                label="Email *"
                variant="standard"
                {...formik.getFieldProps("email")}
                error={formik.touched.email && formik.errors.email}
                helperText={formik.touched.email && formik.errors.email}
              />

              <TextField
                className="w-11/12 !mt-[2.25rem]"
                id="password"
                name="password"
                type={showPassword ? "text" : "password"}
                label="Password *"
                variant="standard"
                // onChange={(e) => setPassword(e.target.value)}
                // value={password}

                {...formik.getFieldProps("password")}
                error={formik.touched.password && formik.errors.password}
                helperText={formik.touched.password && formik.errors.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <p className="underline text-right text-sm mr-4 mt-2 cursor-pointer hover:font-medium" onClick={()=>{navigate("/forgot-password");}}>Forgot Password</p>
            </div>

            {/* <div className="flex justify-end w-11/12 m-auto">
              <div className="text-xs mt-3">Forgot password</div>
            </div> */}
            <div className="flex justify-center">
              <Button
                type="submit"
                className="!mb-8 !bg-Primary hover:!bg-PrimaryHover  !text-[18px] w-11/12  !mt-[48px] transition-all duration-300"
                variant="contained"
                // disabled={loading}
                sx={{
                  "&.Mui-disabled": {
                    color: "#d1d1d1",
                  },
                }}
              >
                {loading && <CircularProgress size="1rem" className="absolute !text-white" />}
                <span className={`${loading ? "invisible" : ""}`}>LOGIN</span>
              </Button>
            </div>
            {/* <div className="text-center font-semibold  mt-4 ">Don't have an account yet ?</div> */}
            {/* <a href={"/createUser"}><div className="flex justify-center">
              <Button
                type="button"


                className="!mb-8 !bg-Primary hover:!bg-PrimaryHover  !text-[18px] w-11/12  !mt-[48px] transition-all duration-300"
                variant="contained"
                // disabled={loading}
                sx={{
                  "&.Mui-disabled": {
                    color: "#d1d1d1",
                  },
                }}
              >
                {loading && <CircularProgress size="1rem" className="absolute !text-white" />}
                <span className="">SIGN UP</span>
              </Button>

            </div>
            </a> */}
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
