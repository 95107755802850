import { useState } from "react";
import ExamForm from "./ExamForm";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useQuery, useMutation } from "react-query";
import { setToastMessage } from "../../../store/toast";
import { AddExamAPI, MASTERLISTAPI } from "../../../services/helpers/Users";
import { initialValues, validationSchemaExam } from "../../../services/validate/course";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const AddExamFrom = () => {
  const [data, setData] = useState();
  const navigate = useNavigate();
  const handleSwitch = ()=>{
    navigate("/exams")
  }


  const dispatch = useDispatch();

  const { mutate, isLoading } = useMutation(AddExamAPI, {
    mutationKey: "addInstitute",
    onSuccess: (res) => {
      if (res?.status === false) {
      } else if (res?.status === true) {
        console.log(res.status);
        dispatch(
          setToastMessage({
            isActive: true,
            message: res?.message,
            type: "success",
          })
        );
        formik.resetForm();
        setTimeout(()=>{
          handleSwitch();
        },2000)
      }
    },
    onError: () => {},
    onSettled: () => {},
  });

  const formik = useFormik({
    initialValues: Cookies.get("id") == "super_admin" ? {...initialValues, instituteId:""}: {...initialValues, instituteId:Cookies.get("userId")},
    validationSchema: validationSchemaExam,
    onSubmit: (values) => {
      let { duration, second, ...payload} = values
      mutate({duration:duration, ...payload});
    },
  });

  useQuery(
    ["MASTERLISTAPI"],
    () => {
      const queryParams = {};
      const paramsData = {
        queryParams,
      };
      return MASTERLISTAPI(paramsData);
    },
    {
      retry: false,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: 0,
      mutationKey: "MASTERLISTAPI",
      enabled: true,
      onSuccess: (res) => {
        if (res?.status === true) {
          console.log("test", res.data);
          setData(res?.data);
        } else {
        }
      },
    }
  );

  let arr = [];
  for (var i = 0; i < data?.length; i++) {
    let check = { label: data[i].Name, option: data[i].id };
    arr.push(check);
  }

  return <ExamForm formik={formik} isLoading={isLoading} arr={arr} data={data} />;
};

export default AddExamFrom;
