import { writingQuiz } from "./mockQuiz";
import SpeakerCard from "../ExamForm/SpeakerCard";
const SpeakingQuiz = () => {
  return (
    <div className="mt-5">
      <ul>
        {writingQuiz.map((item, index) => (
          <li key={index} className="mt-3">
            <h2 className="text-gray-500">
              <span className="mr-4 font-semibold text-gray-900">Question.{item.id}</span>
              {item.question}
            </h2>
            <SpeakerCard />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SpeakingQuiz;
