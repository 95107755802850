import { useState } from "react";
import { useQuery } from "react-query";
import PostAddIcon from "@mui/icons-material/PostAdd";
import AddSharpIcon from "@mui/icons-material/AddSharp";
import LoaderSpiner from "../../common/LoaderSpiner";
import { useNavigate, useParams } from "react-router-dom";
import QueueIcon from "@mui/icons-material/Queue";
import DarkButton from "../../common/CustomButtons/DarkButton";
import ExamDetailsTable from "./ExamDetailsTable";
import ExamQuestionDetails from "./ExamQuestionDetails";
import CustomPagination from "../../common/CoustomPagination";
import { ExamDetailsAPI, MASTERLISTAPI } from "../../../services/helpers/Users";
import Section from "../../../pages/SectionList";
import ScheduleStudentAdd from "../../../pages/ScheduleExam/ScheduleStudent/ScheduleStudentAdd";
import Cookies from "js-cookie";
import ExamStudentList from "./ExamStudentList";

const ExamDetail = () => {
  const [examType, setExamType] = useState(null);
  const { id } = useParams();
  const [data, setdata] = useState({});
  const [examDetails, setExamDetails] = useState(null);
  const [page, setPage] = useState(1);
  console.log(page);
  const [pagination, setPagination] = useState(null);
  const [examId, setExamId] = useState(null);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    // setRefetchState(true)
  };
  const [openAssign, setOpenAssign] = useState(false);
  const handleOpenAssign = () => setOpenAssign(true);
  const handleCloseAssign = () => setOpenAssign(false);

  useQuery(
    ["EXAMDETAILSAPI"],
    () => {
      const queryParams = {
        examId: id,
      };
      const paramsData = {
        queryParams,
      };
      return ExamDetailsAPI(paramsData);
    },
    {
      retry: false,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: 0,
      mutationKey: "EXAMDETAILSAPI",
      enabled: true,
      onSuccess: (res) => {
        if (res?.status === true) {
          setdata(res.data);
          setExamDetails(res.data);
          setExamId(id);
        } else {
        }
      },
    }
  );

  useQuery(
    ["MASTERLISTAPI"],
    () => {
      const queryParams = {};
      const paramsData = {
        queryParams,
      };
      return MASTERLISTAPI(paramsData);
    },
    {
      retry: false,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: 0,
      mutationKey: "MASTERLISTAPI",
      enabled: true,
      onSuccess: (res) => {
        if (res?.status === true) {
          setExamType(res?.data);
        } else {
        }
      },
    }
  );
  console.log("examType===> ", examType);
  const navigate = useNavigate();
  // const handler = () => {
  //   navigate(`/${examId}/add-questions`);
  // };
  const handleSection = () => {
    navigate(`/${examId}/add-section`);
  };

  const TypeModule = (examType) => {
    return examType == "1"
      ? "Reading"
      : examType == "2"
      ? "Writing"
      : examType == "3"
      ? "Listening"
      : examType == "4"
      ? "Speaking"
      : examType == "12"
      ? "General Reading"
      : examType == "11"
      ? "Academic Reading"
      : null;
  };

  const TypesModule = (examType) => {
    return examType == "1"
      ? "Reading"
      : examType == "2"
      ? "Writing"
      : examType == "3"
      ? "Listening"
      : examType == "4"
      ? "Speaking"
      : examType == "12"
      ? "General Reading"
      : examType == "11"
      ? "Academic Reading"
      : null;
  };

  const handler = () => {};

  return (
    <div className="w-full">
      {/* <div className="flex justify-end mb-4 items-center">
       
      </div> */}
      <div className="flex justify-between mb-4 items-center flex-col lg:flex-row">
        <h1 className="text-xl text-center font-semibold mb-2 lg:mb-0">Exam Details</h1>
        <div className="flex justify-between gap-4 flex-col md:flex-row">
          <div className="justify-center flex">
            <DarkButton
              btnTitle="Add Section"
              endIcon={<QueueIcon className="ml-1" />}
              handler={handleSection}
            />
          </div>
          {/* <div className=""><DarkButton btnTitle="Add Student" endIcon={<QueueIcon
          />} handler={handleSection} /></div> */}
          <div className="">
            <DarkButton
              btnTitle="Assign Student"
              endIcon={<AddSharpIcon />}
              handler={() => {
                handleOpen();
              }}
            />
          </div>
          {/* <DarkButton btnTitle="Add Question" endIcon={<PostAddIcon />} handler={handler} /> */}
        </div>
      </div>

      <hr />
      {examDetails ? (
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-8">
          <div className="bg-blue-50 shadow-sm rounded">
            <div className="bg-gray-500 rounded-t px-4 py-2 text-white gap-2 flex">
              <h2>ID:</h2>
              {examDetails.id}
            </div>
            <div className="p-10 rounded-b">
              <h2 className="text-center text-2xl md:text-4xl font-medium capitalize">
                {examDetails.examName}
              </h2>
              <div className="flex flex-col justify-center items-center mt-5 gap-2">
                <div className="flex flex-wrap justify-center gap-1 font-medium text-lg items-center">
                  <p>
                    Time Duration:{" "}
                    <span className="text-gray-700">
                      {" "}
                      {examDetails.duration} min
                    </span>
                  </p>
                </div>
                <div className="flex flex-wrap justify-center gap-1 font-medium text-lg items-center">
                  <p className="flex">
                    <span>Module Type:</span>
                    <span className="text-gray-700 pl-2">
                      {examDetails?.examType.includes("[") ? (
                        JSON.parse(examDetails?.examType).map((examType) => {
                          return (
                            <>
                              {TypeModule(examType)}
                              <br />
                            </>
                          );
                        })
                      ) : (
                        <>{TypesModule(examDetails.examType)}</>
                      )}
                    </span>
                  </p>
                </div>
                {/* <div className="flex flex-wrap justify-center gap-1 font-medium text-lg items-center">
                  <p>
                    Total band: <span className="text-gray-700"> {examDetails?.totalMarks}</span>
                  </p>
                </div> */}
                <div className="flex justify-center flex-wrap gap-1 text-blue-800 font-medium text-lg items-center">
                  {/* <p>{instituteDetail.email}</p> */}
                </div>
              </div>
            </div>
          </div>
          <div className="shadow-sm rounded bg-teal-50 ">
            <strong>
              <ExamDetailsTable
                questionTypeDetails={
                  examDetails.finalResult?.questionTypeCounts
                }
                handleOpen={handleOpenAssign}
                student={true}
              />
            </strong>
          </div>
        </div>
      ) : (
        <div className="w-full min-h-[50vh] flex justify-center items-center">
          <LoaderSpiner />
        </div>
      )}

      <div className="mt-10">
        <Section />
        <br />
        {/* {examDetails?.finalResult?.questionList?.questionType == "MCQ"}
        {examDetails?.finalResult?.sections && examDetails?.finalResult?.sections.map((question) => {
          return (
            <ExamQuestionDetails examDetails={examDetails} qustionsList={question?.questions} sectionName={question?.sectionName} examTypeName={question?.examTypeName} />
          )
        })} */}
        {/* <ExamQuestionDetails examDetails={examDetails} qustionsList={examDetails?.finalResult?.sections[1]?.questions} /> */}
      </div>
      {/* {examDetails && <CustomPagination data={data} page={page} setPage={setPage} paginationData={pagination} />} */}
      {examDetails && <ScheduleStudentAdd
        open={open}
        handleClose={handleClose}
        instituteIdexam={examDetails?.instituteId}
      />}
      {openAssign && <ExamStudentList open={openAssign} handleClose={handleCloseAssign} />}
    </div>
  );
};

export default ExamDetail;
