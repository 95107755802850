import { Table, TableBody, TableRow, TableContainer, TableCell, Avatar } from "@mui/material";
import TableHeading from "../common/Table/TableHeading";

const DashboardStudentTable = ({ data }) => {
  const tableHeading = [
    {
      title: "S.No.",
    },
    // {
    //   title: "Profile",
    // },
    {
      title: "Student Name",
    },
    // {
    //   title: "Qualification",
    // },
    {
      title: "Father's Name",
    },
    {
      title: "Phone",
    },
    {
      title: "Address",
    },
  ];
  return (
    <div className="bg-white px-4 py-1 rounded-xl">
      {/* <p className="text-2xl mt-2 text-center">
        <span className="font-medium">Students</span>
      </p> */}
      <div className="flex flex-col">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 sm:px-6 ">
            <div className="overflow-hidden">
              <TableContainer>
                <Table aria-label="simple table">
                  <TableHeading tableHeading={tableHeading} />
                  <TableBody>
                    {data.map((item, index) => (
                      <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                        <TableCell>{index + 1}</TableCell>
                        {/* <TableCell align="left">
                          <Avatar alt={item.firstName} src="/static/images/avatar/1.jpg" />
                        </TableCell> */}
                        <TableCell align="left">
                          {item?.firstName} {item?.lastName}
                        </TableCell>
                        {/* <TableCell align="left">{item?.qualification}</TableCell>
                        <TableCell align="left">{item?.course}</TableCell> */}
                        <TableCell align="left">{item?.fatherName}</TableCell>
                        <TableCell align="left">{item?.phone}</TableCell>
                        <TableCell align="left">{item?.address}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardStudentTable;
