import { getApiCall, postApiCall, putApiCall } from "../../utils/axios";
import { BASE_URL, API } from "../../utils/defines";

export const updateExamAPI = async (paramsData) => {
  const url = `${BASE_URL}${API.UPDATE_EXAM}`;
  try {
    const response = await putApiCall(url, paramsData);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};

export const GETEXAMLISTSTUDENTAPI = async (paramsData) => {
  const url = `${BASE_URL}${API.EXAMSTUDENTLIST}`;
  try {
    const response = await getApiCall(url, paramsData);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};

export const GETEXAMSUBMITLISTAPI = async (paramsData) => {
  const url = `${BASE_URL}${API.EXAMSUBMITLIST}`;
  try {
    const response = await getApiCall(url, paramsData);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};

export const GETEXAMDETAILSTUDENTAPI = async (paramsData) => {
  const url = `${BASE_URL}${API.EXAMSTUDENTDETAILS}`;
  try {
    const response = await getApiCall(url, paramsData);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};

export const STUDENTEXAMSUBMITAPI = async (payload) => {
  const url = `${BASE_URL}${API.STUDENTEXAMSUBMIT}`;
  try {
    const response = await postApiCall(url, payload);
    return response;
  } catch (err) {
    throw new Error(err?.data?.error?.message);
  }
};

export const GETEXAMRESULTAPI = async (paramsData) => {
  const url = `${BASE_URL}${API.EXAMRESULT}`;
  try {
    const response = await getApiCall(url, paramsData);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};