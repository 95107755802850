import { useEffect, useState } from "react";
import ExamModule from "./ExamModule";
import VdeoRecordExam from "./Module/SpeakingExam/VdeoRecordExam";
import { useSelector } from "react-redux";
import AuidoTest from "../../../pages/Videotest/AuidoTest";
import AuidoRecordExam from "./Module/ListeningExam/AuidoRecordExam";

const ExamModules = ({ selectedModule, activeSection, examName }) => {
  const examination = useSelector((state) => state.examination);
  const questionsAnswersHandle = (id, answer) => {
    // dispatch(examUpdateById({ id, answer }));
  };
  return (
    <>
      {selectedModule && (
        <ExamModule
          selectedModule={selectedModule}
          activeSection={activeSection}
          examName={examName}
        >
          {selectedModule?.moduleTypeId == 4 && (
            <VdeoRecordExam
              change={0}
              time={examination?.mediaQuestion?.selectedQuestion?.time}
              mediaType={true}
            />
          )}
          {selectedModule?.moduleTypeId == 3 && (
            <AuidoRecordExam
              change={0}
              time={examination?.mediaQuestion?.selectedQuestion?.time}
              mediaType={false}
            />
          )}
        </ExamModule>
      )}
    </>
  );
};

export default ExamModules;
