import * as yup from "yup";
import Cookies from "js-cookie";

export const initialValues = {
  examName: "",
  examType: [],
  addById: Cookies.get("userId"),
  duration:"",
  // totalMarks:"",
  // second:"",
  // examDate:"",
  // timingMinute:"",
  // timingHour:"",
};
export const validationSchemaExam = yup.object().shape({
  // examName: yup.string()
  //   .required('Email is required'),
  //  Course: yup.string()
  // .required('Course is required'),
  examName: yup.string().required("Exam Name is required"),
  instituteId: yup.string().required("institute is required"),
  // applyFor:yup.string()
  // .required('Apply For  is required'),
  // university:yup.string()
  // .required('University  is required'),
  // degree:yup.string()
  // .required('Degree is required'),
  // testType:yup.string()
  // .required('Test Type  is required'),
  // duration: yup.string().required("Minutes is required"),
  // second: yup.string().required("Seconds is required"),
  examType: yup.array().required("Module Type is required").min(1, "Module Type is required"),
  // totalMarks: yup.string().required("Total band is required"),
  // examDate:yup.string()
  // .required('Exam Date is required'),
  // timingMinute:yup.string()
  // .required('Exam Time is required in minutes'),
  // timingHour:yup.string()
  // .required('Exam Time is required in hours'),
});
