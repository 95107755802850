import { FormControl, FormLabel, RadioGroup, Radio, Checkbox, FormControlLabel } from "@mui/material";
import { useEffect, useState } from "react";

const ModuleTypeCheck = ({ moduleType, readingType, formik, setSelectedExam }) => {
    const [checkDatas, setCheckDatas] = useState(moduleType)
    const [radioValue, setRadioValue] = useState('12');
    const handleCheckboxChange = (id, value) => {
        setCheckDatas((prev) => {
            let newPrev = prev.map((p) => {
                if (p.id == id) {
                    return {
                        ...p,
                        checked: !value
                    }
                } else {
                    return p
                }
            })
            return newPrev
        })
    }
    const handleRadioChange = (event) => {
        setRadioValue(event.target.value);
    };

    useEffect(()=>{
        setCheckDatas((prev) => {
            let newPrev = prev.map((p) => {
                let newFilter = formik.values.examType.find((f) => f.length>1)
                console.log("newFilternewFilter", newFilter)
                if(newFilter){
                    if(p.id == 1){
                        return {
                            ...p,
                            checked: true
                        }
                    }
                    setRadioValue(newFilter)
                }
                if (formik.values?.examType.includes(p.id)) {
                    return {
                        ...p,
                        checked: true
                    }
                } else {
                    return p
                }
            })
            return newPrev
        })
    },[])

useEffect(()=>{
    let newType = []
    checkDatas.map((check)=>{
            if(check.checked){
                if(check.id ==1){
                    newType.push(radioValue)
                } else {
                    newType.push(check.id)
                }
            }
    })
    formik.setFieldValue("examType", newType)
    setSelectedExam(newType)
},[checkDatas, radioValue])

    return (
        <div className="flex flex-col ml-4">
            {checkDatas.map((data) => {
                return (
                    <>
                        <FormControlLabel
                            control={<Checkbox checked={data.checked} onChange={() => {
                                handleCheckboxChange(data.id, data.checked);
                            }} />}
                            label={data.Name}
                        />
                        {data.id == 1 && data.checked && <div className="block ml-12 mt-4" >{data.checked && (
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Choose Reading Module Type</FormLabel>
                                <RadioGroup style={{ marginTop: "10px" }} value={radioValue} onChange={handleRadioChange} >
                                    {readingType.map((data) => {
                                        return (
                                            <FormControlLabel value={data.id} control={<Radio />} label={data.Name} />
                                        )
                                    })}
                                    {/* <FormControlLabel value="General Reading" control={<Radio />} label="General Reading" /> */}
                                </RadioGroup>
                            </FormControl>
                        )}
                        </div>}
                    </>
                )
            })}
        </div>
    )
}

export default ModuleTypeCheck