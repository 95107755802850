import { useFormik } from "formik";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { InstituteForm } from "../../components";
import { setToastMessage } from "../../store/toast";
import { updateInstituteAPI } from "../../services/helpers/Institute";
import { validationSchemaInstitute } from "../../services/validate/Institute";
import { useDispatch } from "react-redux";
import { useState } from "react";

const UpdateInstitute = ({ initialValue, instituteId }) => {
  console.log("initialValueinitialValueinitialValue", initialValue)
  const [initialValueFormik, setInitialValueFormik] = useState({
    country: initialValue?.country,
    state: initialValue?.state,
    city: initialValue?.city,
  })
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: validationSchemaInstitute,
    onSubmit: (values) => {
      submitHandler(values);
    },
  });

  const submitHandler = () => {
    const newValues = {
      ...formik.values,
      instituteId: instituteId,
    };
    mutate(newValues);
  };

  const { mutate, isLoading } = useMutation(updateInstituteAPI, {
    mutationKey: "updateInstitute",
    onSuccess: (data) => {
      if (data.status === true) {
        dispatch(
          setToastMessage({
            isActive: true,
            message: data?.message,
            type: "success",
          })
        );

        navigate("/institute");
        formik.resetForm();
      }
    },
  });

  return (
    <div className="w-full ">
      <div className="p-8">
        <div className="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6 ">
          <h1 className="text-xl text-center font-semibold mb-6 pb-4">Edit Institute</h1>
          <InstituteForm formik={formik} isLoading={isLoading} initialValueFormik={initialValueFormik} setInitialValueFormik={setInitialValueFormik} />
        </div>
      </div>
    </div>
  );
};

export default UpdateInstitute;
