import { TextField } from "@mui/material"
import { useMutation, useQuery } from "react-query";
import { setToastMessage } from "../../store/toast";
import { InputTextField, SelectInputField } from "../../components/Forms";
import { initialValues, validationSchema } from "../../services/validate/AddSection";
import { useFormik } from "formik";
import { DarkButton } from "../../components";
import { useEffect, useState } from "react";
import React from 'react'
import { CircularProgress,Backdrop } from "@mui/material";

import ReactPlayer from 'react-player'
import { useNavigate, useParams } from "react-router-dom";
import { AddSection, SECTIONDETAIL, SECTIONLIST, UpdateSection, UploadSection } from "../../services/helpers/Users";
import { useDispatch } from "react-redux";
import { SelectSingleInput } from "../../components/Forms/SelectMultipleInput";
import QuillEditor from "../../components/editortest";
import QuillNew from "../../components/common/EditorNew";

export const UpdateSectionForm = ({ setFlag, flag, setnewValue, newValue, arrD }) => {
    console.log("newValue---> ", newValue)
    const dispatch = useDispatch();
    const [instructions,setInstructions] = useState(null);
    const [instructionsImage,setInstructionsImage] = useState();
    console.log(instructions);
    const[imageloading,setImageLoading] = useState(false);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const formdata = new FormData();
    const [showFileInput, setShowFileInput] = useState(false);
    const handleButtonClick = () => {
        setType(newValue?.sectionType)
        setShowFileInput(true);
    };

    useEffect(() => {
        if (newValue?.sectionType == "paragraph") {
            setType(newValue?.sectionType)
            setShowFileInput(true);
        }
    }, [])

    // const [flag,setFlag] = useState(false);
    // const [newValue,setnewValue] = useState({})
    const { id } = useParams();
    const [uploadedName, setUploadedName] = useState(null);
    const [type, setType] = useState();
    const [error, setError] = useState();
    console.log("type", type)
    const [examVal, setExamVal] = useState(newValue?.sectionTypeValue);
    const [audioVal, setAudioVal] = useState("");
    const [videoVal, setVideoVal] = useState("");
    const [selectedExam, setSelectedExam] = useState();
    const minutes_arr = [];
    for (var i = 0; i < 60; i++) {
        let obj = { label: i, option: i };
        minutes_arr.push(obj);
    }
    console.log("test", examVal)
    console.log(error);

    const reload = () => {
        return navigate(`/exams/${newValue.examId}`)
    }


    const { mutate, isLoading } = useMutation(AddSection, {
        mutationKey: "addSection",
        onSuccess: (res) => {
            if (res?.status === false) {
            } else if (res?.status === true) {

                setToastMessage({
                    isActive: true,
                    message: res?.message,
                    type: "success",
                })

                formik.resetForm();
            }
        },
        onError: () => { },
        onSettled: () => { },
    });

    const { mutate: check } = useMutation(UploadSection, {
        mutationKey: "uploadSection",
        onSuccess: (res) => {
            if (res?.status === false) {
            } else if (res?.status === true) {

                console.log(res?.data?.fileName)
                setUploadedName(res?.data?.fileName)
                let { time, second, ...values } = formik.values;
                let newTime = time + ":" + second
                UPDATE({ ...values, time: newTime, sectionTypeValue: res?.data?.fileName });
                setToastMessage({
                    isActive: true,
                    message: res?.message,
                    type: "success",
                })

                // formik.resetForm();
            }
        },
        onError: () => { },
        onSettled: () => { },
    });

    const { mutate: UPDATE } = useMutation(UpdateSection, {
        mutationKey: "uploadSection",
        onSuccess: (res) => {
            if (res?.status === false) {
            } else if (res?.status === true) {
                setLoading(false)
                console.log(res);
                // setUploadedName(res?.data?.fileName)
                // mutate({ ...formik.values, sectionTypeValue: res?.data?.fileName, examId: id });
                dispatch(setToastMessage({
                    isActive: true,
                    message: res?.message,
                    type: "success",
                }))
                setTimeout(() => {
                    reload();
                }, 2000)

                // formik.resetForm();
            }
        },
        onError: () => { },
        onSettled: () => { },
    });


    const options = [

        {
            label: "Paragraph",
            option: "paragraph",
        },
        {
            label: "Audio",
            option: "audio",
        },
        {
            label: "Video",
            option: "video",
        }

    ];





    console.log("new Values", newValue);


    const formik = useFormik({
        initialValues: newValue,
        validationSchema,
        onSubmit: (values) => {
            console.log(initialValues);

            if (type === "paragraph") {
                if (examVal) {
                    setError("");
                    setLoading(true)
                    let { time, second, ...valuesd } = values;
                    let newTime = time + ":" + second
                    UPDATE({ ...values, time: newTime, sectionTypeValue: examVal })

                }
                else {
                    setError("Data is required");
                }
            }
            else if (type === "audio") {
                formdata.append("file", audioVal);
                if (audioVal) {
                    setError("")
                    setLoading(true);
                    check(formdata);

                }
                else {
                    setError("Data is required");
                }

            }
            else if (type === "video") {
                formdata.append("file", videoVal);
                if (videoVal) {
                    setError("");
                    setLoading(true)
                    check(formdata);
                }
                else {
                    setError("Data is required");

                }

            }
            else {
                setLoading(true)
                let { time, second, ...valuesd } = values;
                let newTime = time + ":" + second
                UPDATE({ ...values, time: newTime });
            }






        },
    });
    console.log("Test", formik);
    console.log("video", videoVal);

    console.log(formik);
    console.log(examVal);

    return (
        <div className="">
            <h1 className="text-3xl text-center">Update Section</h1>
            {flag ?
                <form className="" onSubmit={formik.handleSubmit}>
                    <div className="grid grid-cols-1 gap-8 mt-4">
                        <div className="flex gap-4">
                            <div className="flex flex-1">
                                <InputTextField type="text" formik={formik} id="sectionName" placeholder="Add Title" />


                            </div>
                            <div className="flex flex-1">

                                <SelectSingleInput
                                    flag={true}
                                    id="examTypeId"
                                    label="Select Module Type"
                                    exam={(selectedExams) => formik.setFieldValue("examTypeId", selectedExams)}
                                    options={arrD}
                                    formik={formik}
                                />
                            </div>
                        </div>
                        {/* <InputTextField type="text" formik={formik} id="sectionTitle" placeholder="Add Title" /> */}
                        {/* <InputTextField type="text" formik={formik} id="time" placeholder="Add Time " /> */}
                        <div>
                            <p className="mb-2">Duration*</p>
                            <div className="flex justify-between gap-4 ">
                                <div className="w-full"><SelectInputField flag={true} id="time" label="Choose Minutes" exam={setSelectedExam} options={[...minutes_arr, { label: 60, option: 60 }].slice(1)} formik={formik} /></div>
                                <div className="w-full"><SelectInputField flag={true} id="second" label="Choose Seconds" exam={setSelectedExam} options={minutes_arr} formik={formik} /></div>
                            </div>
                            <div className="mt-3" >
                                <p className="mb-2">Update Instructions</p>
                                <QuillEditor paddingBottom={20} setContentData={setInstructions} formik={formik} setLoading={setInstructionsImage} loading={instructionsImage} fieldName={"sectionTitle"} newValue={newValue?.sectionTitle} />
                                {instructionsImage &&
                                    <Backdrop
                                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                        open={instructionsImage}
                                    >
                                        <CircularProgress color="inherit" />
                                    </Backdrop>
                                }
                                <p className="text-red-500 mt-8 text-sm">{formik.errors["sectionTitle"]}</p>
                            </div>
                        </div>
                        <SelectInputField formik={formik} id="sectionType" label="Select Question Type " options={options} flag={true} exam={setType} />
                        <div className="w-full mx-auto ">
                            {type === "paragraph" && <Paragraph setExamVal={setExamVal} examVal={examVal} error={error} newValue={newValue} setLoading={setLoading} formik={formik} />}

                            <div className="flex justify-start">{type === "audio" && <Audio setAudioVal={setAudioVal} examVal={examVal} error={error} />}</div>
                            <div className="flex justify-start">{type === "video" && <Video setExamVal={setVideoVal} examVal={examVal} error={error} />}</div>
                        </div>

                    </div>
                    <div className="mt-4">
                        {newValue.sectionType == "audio" ? <audio controls>
                            <source src={newValue.url + "/" + newValue.sectionTypeValue} type="audio/ogg" />
                            <source src="horse.mp3" type="audio/mpeg" />

                        </audio> : null}
                        {newValue.sectionType === "video" ? <div className="player-wrapper w-12/12 flex flex-row justify-center"><ReactPlayer url={newValue.url + "/" + newValue.sectionTypeValue} controls className="react-player" width="100%" height="100%" /></div> : null}
                        <div className="ml-6 mt-4 ">
                            {!showFileInput && newValue?.sectionType != "paragraph" ? <button className="px-2 py-2 bg-[#1e78fe] text-white rounded-xl" onClick={handleButtonClick}>Change {newValue?.sectionType}</button> : null}
                        </div>
                    </div>

                    <div className="flex justify-center mt-14">
                        <DarkButton type="submit" btnTitle="Submit" loading={loading} />
                    </div>


                </form> : null}

        </div>

    )


}

const Paragraph = ({ examVal, setExamVal, error, newValue, formik, setLoading }) => {
    console.log("Component Mounted", examVal)
    const handleChange = (e) => {
        console.log(e.target.value);
        setExamVal(e.target.value);
    }
    return (
        <div className=" ">
            {/* <TextField
                name="test123"
                style={{ width: "100%" }}
                multiline
                rows={4}
                onChange={handleChange}
                placeholder="Upload Paragraph"
                defaultValue={examVal}
            /> */}
            <QuillNew setContentData={setExamVal} newValue={newValue?.sectionTypeValue} setLoading={setLoading} paddingBottom={80} formik={formik} fieldName={"sectionTypeValue"} />
            <p className="text-red-600">{error ? error : null}</p>


        </div>
    )
}
const Audio = ({ setAudioVal, audioVal, error }) => {
    console.log("Component Mounted");
    const handleChange = (e) => {
        console.log("targetsdfs", e.target.files);
        setAudioVal(e.target.files[0]);
    }
    return (
        <div>
            <h1>  Upload Audio</h1>

            <input className="mt-2" type="file" accept=".wav, .mp3, .aac"
                onChange={handleChange} 
            />
            <p className="text-red-600 mt-2">{error ? error : null}</p>


        </div>
    )
}

const Video = ({ setExamVal, examVal, error }) => {
    const handleChange = (e) => {
        console.log(e.target.value)
        setExamVal(e.target.files[0]);
    }
    console.log("Component Mounted");
    return (
        <div>
            <h1>  Upload Video</h1>

            <input className="mt-2" type="file"
                onChange={handleChange}
                accept=".mp4, .mov, .wmv, .avi, .avchd" 
            />
            <p className="text-red-600 mt-2">{error ? error : null}</p>

            {/* <textarea/> */}

        </div>
    )
}