import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { CircularProgress } from "@mui/material";
import { Link } from "react-router-dom";

const LinkButton = ({ btnTitle, endIcon, handler, type, loading, linkUrl }) => {
  return (
    <Stack direction="row" spacing={2}>
      <Link to={linkUrl}>
        <Button variant="contained" type={type} disabled={false}>
          {loading && <CircularProgress size="1rem" className="absolute top-[30%] left-[40%] " />}
          <span className={`${loading ? "invisible" : ""}`}>
            {btnTitle}
            {endIcon}
          </span>
        </Button>
      </Link>
    </Stack>
  );
};

export default LinkButton;
