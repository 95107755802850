import { useState } from "react";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../common/DeleteModal";
import { resultsTableHeading } from "../../mockData";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import TableHeading from "../common/Table/TableHeading";
import { Table, TableBody, TableRow, TableContainer, TableCell, MenuItem, Menu } from "@mui/material";

const ResultsTable = ({ data }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHeading tableHeading={resultsTableHeading} />
          <TableBody>
            {data.map((item, index) => (
              <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell>{index + 1}</TableCell>
                <TableCell align="left">{item.examName}</TableCell>
                <TableCell align="left">{item.applyFor}</TableCell>
                <TableCell align="left">{item.country}</TableCell>
                <TableCell align="left">{item.appliedStudent}</TableCell>
                <TableCell align="left">{item.appearedStudent}</TableCell>
                <TableCell align="left">
                  <MoreVertIcon onClick={handleClick} className="cursor-pointer" />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem
          onClick={(e) => {
            navigate("/result/details");
            handleClose();
          }}
        >
          View Details
        </MenuItem>
        {/* <MenuItem
          onClick={(e) => {
            navigate("/exams/add");
            handleClose();
          }}
        >
          Edit
        </MenuItem> */}
        <MenuItem
          onClick={() => {
            setOpenModal(true);
            setAnchorEl(null);
          }}
        >
          Delete Result
        </MenuItem>
      </Menu>
      <DeleteModal
        open={openModal}
        deleteHandle={handleCloseModal}
        handleClose={handleCloseModal}
        title="Are you sure to delete!!"
        deleteText="Delete Results"
        className="w-100 h-100 bg-slate-800"
      />
    </>
  );
};

export default ResultsTable;
