import BreadcrumbsC from "../../components/common/BreadcrumbsC";
import { ButtonSwitch } from "../../components/common/ButtonC";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const Course = () => {
  const handler = () => {};
  const breadcrumbb = [
    {
      id: 1,
      title: "Dashboard",
      link: "/dashboard",
    },
    {
      id: 1,
      title: "Course",
      link: "/course",
    },
  ];

  const examsTables = [];
  return (
    <div className="relative px-8 py-4 bg-[#F3F3F3] md:ml-64">
      <div className="w-full">
        <div className="flex justify-between items-center">
          <BreadcrumbsC data={breadcrumbb} />
        </div>
        <div className="flex justify-between items-center mt-4">
          <div className="flex">
            <ButtonSwitch handler={handler} text="View All" active={false} css="border-r-0 rounded-r-none rounded-b-none" />
            <ButtonSwitch handler={handler} text="Active" active={true} css="border-l-0 rounded-t-none rounded-l-none" />
          </div>
          <div className="flex items-center gap-4">
            <TextField
              id="outlined-start-adornment"
              className=""
              size="small"
              sx={{ backgroundColor: "white" }}
              placeholder="Search or Type Here"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton aria-label="toggle password visibility" edge="end">
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
      </div>

      {/* lower section */}
      <div className={`bg-white mt-8 rounded-lg p-5 mb-10 ${examsTables.length > 2 && examsTables.length <= 4 ? "mb-40" : "" || examsTables.length <= 2 ? "mb-80" : ""}`}>
        <div className="grid md:grid-cols-2 gap-4">
          {/* {examsTables?.map((item, index) => (
            <FeebackCard key={index} name={item.full_name} id={item.centerId} image={item.feedback_images} feedback={item.feedback} width="md:w-[49%] w-full" />
          ))} */}
        </div>
      </div>
    </div>
  );
};

export default Course;
