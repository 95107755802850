import { DashboadPagesLayout } from "../../../components";
import SectionDetail from "../../../components/Exams/SectionDetail";
import BreadcrumbsC from "../../../components/common/BreadcrumbsC";
import { useParams} from 'react-router-dom'



const Section = () => {

  const { id, sectionId } = useParams();

  const breadcrumbb = [
    {
      id: 1,
      title: "Dashboard",
      link: "/dashboard",
    },
    {
      id: 2,
      title: "Exam",
      link: `/exams/${id}`,
    },
    {
      id: 3,
      title: "Section Details",
      link: `/exams/${id}/${sectionId}`,
    },
  ];
  
  console.log("section id ==", sectionId, id)
  
  return (
    <DashboadPagesLayout>
      <div className="w-full px-8 py-4">
        <div className="flex justify-start items-center">
          <BreadcrumbsC data={breadcrumbb} />
        </div>
        <div className={`bg-white mt-8 rounded-lg p-5 mb-10 min-h-[60vh] `}>
          <div className="flex flex-col w-full">
            <SectionDetail />
          </div>
        </div>
      </div>
    </DashboadPagesLayout>
  );
};

export default Section;
