export const BASE_URL = "https://api.ieltspretest.com:8099/v1/";
// export const BASE_URL = "https://eacademyeducation.com:8099/v1/";
// export const BASE_URL = "http://localhost:8909/v1/";
// export const BASE_URL = "http://3.19.237.132:8080/v1/";

export const API = {
  /* Users */
  ADDSECTION:"section/createSection",
  UPLOADSECTION:"section/upload-section",
  SECTIONUPDATE:"section/updateSection",
  SECTIONLIST:"section/getSetions",
  SECTIONDETAIL:"section/detail-section",
  UPLOADIMAGE: "users/upload-icon",
  MASTERLISTAPI: "master/getExammaster",
  LOGIN: "users/userlogin",
  FORGOT: "auth/forgot-password",
  RESET: "auth/reset-password",
  SIGNUP: "users/createUsers",
  USERLIST: "users",
  DASHBOARD: "admin/dashboard",
  DASHBOARDDETAIL: "dashboard/dashboard",
  ADDSTUDENT: "users/register",
  SELECTLIST: "institues/institute-list",
  ADDINSTITUTE: "institues/createInstitue",
  STUDENTDETAILSAPI: "users/getUser",
  STUDENTLIST: "users/getUsers",
  ADDEXAM: "exam/createExam",
  EXAMLIST: "exam/getExams",
  ALLEXAM: "exam/allExams",
  EXAMDETAILS: "exam/getExam",
  SECTIONDETAILS: "section/section-details",
  USER_DETAILS: "users/getUser",
  UPDATESECTION:"section/updateSection",
  ExamSearch:"exam/searchExam",
  USERSEARCH:"users/searchUser",
  INSTITUTEUSERAPI:"institues/userListById",
  

  //conmmon
  TERMSCONDTION: "admin/add-termCondition",
  TERMSCONDTIONDetail: "admin/termCondition-details",
  PRIVACYPOLICY: "admin/add-privacy-policy",
  PRIVACYPOLICYDetail: "admin/privacyPolicy-details",

  FEEDBACKADD: "admin/add-feedback",
  FEEDBACKLIST: "admin/feedback-list",

  // institute
  INSTITUTELIST: "institues/get-all-institues",
  UPDATE_INSTITUTE: "institues/updateInstitute",
  INSTITUTEDETAILS: "institues/institute-details",
  UPDATEINSTITUTESTATUS: "exam/toggleExam",
  INSTITUTEUPDATESTATUS: "institues/toggleInstitute",

  //student
  USERDELETE: "users/deleteUser",
  EXAMDELETE: "exam/deleteExam",
  UPDATE_STUDENT: "users/updateUser",
  INSTITUTEDELETE: "institues/delete-institute",
  STUDENTLISTALL: "exam/student-list",
  STUDENTDETAILSUBMITTED: "exam/submit-exam-details-by-id",
  
  DIRECTSTUDENTLISTALL: "exam/direct-student-list",

  REMOVEASSIGNSTUDENT: "exam/remove-assign-student",

  // update Exam
  UPDATEEXAM: "users/toggleUser",

  // exam
  UPDATE_EXAM: "exam/updateExam",
  INSTITUTEASSIGNEXAM: "exam/institute-assign-exam",

  // Question
  ADD_QUESTION: "question/createQuestion",
  UPDATE_QUESTION: "question/updatequestion",
  DELETE_QUESTION: "question/deleteQuestion",
  QUESTION_TYPE_LIST: "question/questiontypeList",

  // 
  SECTIONDELETE:"section/deleteSection",

  //SCHEDULE 
  SCHEDULELIST: "exam/schedule-exam-list",
  SCHEDULEADD: "exam/add-schedule-exam",
  SCHEDULESTUDENTLIST: "exam/assign-exam-list",
  SCHEDULESTUDENTADD: "exam/add-assign-exam",
  
  ASSIGNSTUDENTLIST: "exam/direct-assign-exam-list",
  ASSIGNSTUDENTADD: "exam/direct-assign-exam",

  //Instruction
  Instruction:"exam/exam-instruction-detail",
  AddInstruction:"exam/add-exam-instruction",
  // for Exam 

  //Student List
  EXAMSUBMITLIST: "exam/submit-exam-list",
  EXAMSTUDENTLIST: "exam/student-examList",
  EXAMSTUDENTDETAILS: "exam/exam-details-by-studentid",
  STUDENTEXAMSUBMIT: "exam/submitExam",

  // student feedback
  FEEDBACK_ADD: "dashboard/add-feedback",
  FEEDBACK_LIST: "dashboard/feedback-list",

  Policy:"dashboard/get-policy-condition",
  addpolicy:"dashboard/add-policy-condition",

  //exam Result 
  EXAMRESULT: "exam/submit-exam-details",

};
