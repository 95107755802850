import { useState } from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";


const SelectMultipleInput = ({ label, exam, id, flag, defaultValue, options, formik, value }) => {
    const [selectedExams, setSelectedExams] = useState([]);

  const handleChange = (event) => {
    setSelectedExams(event.target.value);
    if (flag) {
      exam(event.target.value);
    }
  };

  return (
    <FormControl className="w-full" size="small">
      <InputLabel id="demo-simple-select-autowidth-label">{label}</InputLabel>
      <Select
        multiple
        id={id}
        value={selectedExams}
        labelId="demo-simple-select-autowidth-label"
        name={id}
        {...formik.getFieldProps(id)}
        error={formik.touched[id] && formik.errors[id]}
        onChange={(e) => {
          formik.handleChange(e);
          handleChange(e);
        }}
        helperText={formik.touched[id] && formik.errors[id]}
        label={label}
      >
        {options.map((item, index) => (
          <MenuItem key={index} value={item.option}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      {formik.touched[id] && formik.errors[id] && (
        <p className="text-red-500 text-sm pl-4 mt-1">{formik.errors[id]}</p>
      )}
    </FormControl>
  );
};

export const SelectSingleInput = ({ label, exam, id, flag, defaultValue, options, formik, value }) => {
    const [selectedExams, setSelectedExams] = useState([]);

  const handleChange = (event) => {
    setSelectedExams(event.target.value);
    if (flag) {
      exam(event.target.value);
    }
  };

  return (
    <FormControl className="w-full" size="small">
      <InputLabel id="demo-simple-select-autowidth-label">{label}</InputLabel>
      <Select
        
        id={id}
        value={selectedExams}
        labelId="demo-simple-select-autowidth-label"
        name={id}
        {...formik.getFieldProps(id)}
        error={formik.touched[id] && formik.errors[id]}
        onChange={(e) => {
          formik.handleChange(e);
          handleChange(e);
        }}
        helperText={formik.touched[id] && formik.errors[id]}
        label={label}
      >
        {options.map((item, index) => (
          <MenuItem key={index} value={item.option}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      {formik.touched[id] && formik.errors[id] && (
        <p className="text-red-500 text-sm pl-4">{formik.errors[id]}</p>
      )}
    </FormControl>
  );
};

export default SelectMultipleInput